<section *ngIf="businessService?.businessID?.getValue()">
    <div class="grid-filter merch-info">
        <div class="row">
            <div class="col-sm-7">
                <div class="row">
                    <div class="merch_type col-sm-3" style="width:200px;">
                        <select
                            class="form-control form-control-sm select-days"
                            [(ngModel)]="filter"
                            (change)="fetchFilter()"
                        >
                            <option
                                *ngIf="filter === 'advancedDateFilter'"
                                value="advancedDateFilter"
                            >
                                <label class="radio1">
                                    <span></span> -None-
                                </label>
                            </option>
                            <option value="all">
                                <label class="radio1">
                                    <span></span> All
                                </label>
                            </option>
                            <option value="last_24_hrs">
                                <label class="radio1">
                                    <span></span> Last 24 Hrs
                                </label>
                            </option>
                            <option value="last_7_days">
                                <label class="radio1">
                                    <span></span> Last 7 Days
                                </label>
                            </option>
                            <option value="last_30_days">
                                <label class="radio1">
                                    <span></span> Last 30 Days
                                </label>
                            </option>
                            <option value="last_60_days">
                                <label class="radio1">
                                    <span></span> Last 60 Days
                                </label>
                            </option>
                            <option value="last_90_days">
                                <label class="radio1">
                                    <span></span> Last 90 Days
                                </label>
                            </option>
                        </select>
                        <span> <i class="fa fa-angle-down"></i> </span>
                    </div>
                    <div class='merch_type col-sm-3 m-l-5'>
						<select class="form-control form-control-sm select-days" placeholder='Select Date Filter' [(ngModel)]="advancedDateFilterType">
							<option value=''>
								<label class="radio1">
									<span></span>
									Select Date Filter
								</label>
							</option>
							<option [value]='DATE_FILTER_AUTH'>
								<label class="radio1">
									<span></span>
									Authorize Date
								</label>
							</option>
							<option [value]="DATE_FILTER_FINALIZE">
								<label class="radio1">
									<span></span>
									Finalize Date
								</label>
							</option>
							<option [value]="DATE_FILTER_SETTLE">
								<label class="radio1">
									<span></span>
									Settle Date
								</label>
							</option>
						</select>
						<span>
							<i class="fa fa-angle-down"></i>
						</span>
					</div>
					<div class="col-sm-5 datepicker-container p-l-0">
						<i class="fa fa-calendar" aria-hidden="true"></i>
						<input type="text" placeholder="Set to and from date" class="form-control" bsDaterangepicker [(ngModel)]="dateRangeValue"
							[bsConfig]="{ containerClass: 'theme-custom-date-picker',showWeekNumbers:false }">
						<button tooltip="Filter (via date)" placement="bottom" container="body" containerClass="tooltip-global" 
						type='button' class="btn btn-sm btn-primary" [disabled]='!dateRangeValue.length || !advancedDateFilterType'
							(click)="onDateFilterClick()">
							<i class="fa fa-angle-double-right" aria-hidden="true"></i>
						</button>
					</div>
                </div>
            </div>
            <div class="col-sm-5">
                <label
                    *ngIf="storeName && batchNum"
                    class="pull-right"
                >
                    <span class="tooltip-wrap-span" [tooltip]="storeName"
                        placement="bottom" container="body" containerClass="tooltip-global">
                        <b>Store: </b>{{abbreviatedStoreName}}
                    </span>
                    <span class="text-muted"> &nbsp; | &nbsp;</span>
                    <b>Batch Number: </b>{{ batchNum }}
                </label>
            </div>
        </div>
    </div>
    <form class="form-horizontal padder">
        <!-- <div class="row  m-b-1"> -->
        <div class="grid-add-wrap grid-bottom-btns">
            <grid-cmp
                [attributes]="attributes"
                [storeValue]="storeValue"
                [refreshGrid]="refreshGrid"
                [fetchByStore]="fetchByStore"
                [parameters]="parameters"
                [storeObj]="storeObject"
                [action]="action"
                [report]="true"
                (actionRequest)="onActionRequest($event)"
                (emitChecked)="onEmitChecked($event)"
                (detailVisible)="onShowDetails($event)"
                (viewSummary)="onGenerateReport($event)"
                (refresh)="onRefreshRequest($event)"
                (selectedTxnEvent)="onTxnObjReceived($event)"
                (actionIsCancelled)="onActionIsCancel($event)"
                (pctrListEvent)="recievePCtrList($event)"
            ></grid-cmp>
            <span
				placement="top" 
				container="body" 
				containerClass="tooltip-global"
				class="tooltip-wrap-span" 
				[tooltip]="!txnActionPermissions?.canReverse ? noPermMsg: 'Reverse'"
			>
                <button
                    (click)="setDoReverse()"
                    class="btn btn-sm btn-primary"
                    [disabled]="reverseLoader || disableActionBtns || !txnActionPermissions?.canReverse"
                >
                    <span *ngIf="reverseLoader">
                        <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
                        Please wait...
                    </span>
                    <span *ngIf="!reverseLoader">
                        <!-- <i class="fa fa-print" aria-hidden="true"></i> -->
                        Reverse
                    </span>
                </button>
            </span>
            <span
                placement="top" 
                container="body" 
                containerClass="tooltip-global"
                class="tooltip-wrap-span" 
                [tooltip]="!txnActionPermissions?.canRedo ? noPermMsg: 'Redo'"
            >
                <button
                    (click)="setDoRedo()"
                    class="btn btn-sm btn-primary"
                    [disabled]="redoLoader || disableActionBtns || !txnActionPermissions?.canRedo"
                >
                    <span *ngIf="redoLoader">
                        <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
                        Please wait...
                    </span>
                    <span *ngIf="!redoLoader">
                        <!-- <i class="fa fa-print" aria-hidden="true"></i> -->
                        Redo
                    </span>
                </button>
            </span>
            <span
                placement="top" 
                container="body" 
                containerClass="tooltip-global"
                class="tooltip-wrap-span" 
                [tooltip]="!txnActionPermissions?.canReprint ? noPermMsg: 'Print Report'"
            >
                <button
                    (click)="setRePrint()"
                    class="btn btn-sm btn-primary"
                    [disabled]="rePrintLoader || disableActionBtns || !txnActionPermissions?.canReprint"
                >
                    <span *ngIf="rePrintLoader">
                        <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
                        Please wait...
                    </span>
                    <span *ngIf="!rePrintLoader">
                        <i class="fa fa-print" aria-hidden="true"></i> Re Print
                    </span>
                </button>
            </span>
            <span
                placement="top" 
                container="body" 
                containerClass="tooltip-global"
                class="tooltip-wrap-span" 
                [tooltip]="!txnActionPermissions?.canReprint ? noPermMsg: 'Export Detail'"
            >
                <button
                    (click)="setPrintDetail()"
                    class="btn btn-sm btn-primary"
                    [disabled]="printDetailLoader || disableActionBtns || !txnActionPermissions?.canPrintDetail"
                >
                    <span *ngIf="printDetailLoader">
                        <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
                        Please wait...
                    </span>
                    <span *ngIf="!printDetailLoader">
                        <!-- <i class="fa fa-print" aria-hidden="true"></i> -->
                        Export Detail
                    </span>
                </button>
            </span>
            <div class="pull-right">
                <div
                    *ngIf="totalObj && totalObj?.totTxn && totalObj?.totTxnAmt"
                    class="transaction-bottom-info"
                >
                    <label>Total</label> <br />
                    <span>Transactions: </span>
                    <span class="badge badge-primary">
                        {{ totalObj?.totTxn }}</span
                    >&nbsp; <span>Amount: </span>
                    <span class="badge badge-primary">{{
                        totalObj?.totTxnAmt
                    }}</span>
                </div>
                <div
                    *ngIf="
                        selectedObj &&
                        selectedObj?.selTxn &&
                        selectedObj?.selTxnAmt
                    "
                    class="transaction-bottom-info"
                >
                    <label>Selected</label> <br />
                    <span>Transactions: </span>
                    <span class="badge badge-primary">{{
                        selectedObj?.selTxn
                    }}</span
                    >&nbsp; <span>Amount: </span>
                    <span class="badge badge-primary">{{
                        selectedObj?.selTxnAmt
                    }}</span>
                </div>
            </div>
        </div>
        <div *ngIf="visibleModal">
            <app-summary-report
                [type]="type"
                [store]="storeObject"
                [showModal]="visibleModal"
                (onCloseModal)="onSummaryModalClose($event)"
            ></app-summary-report>
        </div>

        <div *ngIf="visiblePrintDetailModal">
            <app-summary-report
                [type]="type"
                [store]="storeObject"
                [showModal]="visiblePrintDetailModal"
                [showTxn]="showTxn"
                (onCloseModal)="onPrintDetailModalClose($event)"
            ></app-summary-report>
        </div>
    </form>
    <details-cmp
        [id]="transactionID"
        [viewDetails]="viewDetails"
        [storeValue]="storeValue"
        [label]="'Transaction'"
        [store]="storeObject"
        [show]="showDetails"
        (onViewClose)="viewClosed($event)"
    ></details-cmp>
    <div *ngIf="rePrint">
        <app-re-print
            [transactionStoreData]="transactionStoreData"
            (reprintComplete)="onRePrintComplete($event)"
        ></app-re-print>
    </div>
    <div *ngIf="showOverLay" class="overlay">
        <div class="overlay-content">
            <div>
                <i
                    style="color:white;"
                    class="fa fa-circle-o-notch fa-spin fa-3x fa-fw"
                ></i>
            </div>
            <div>
                <span style="font-size:20px"
                    >Please wait. Performing selected action on
                    Transactions.</span
                >
            </div>
        </div>
    </div>
    <div *ngIf="showResponseModal">
        <app-txn-batch-response-modal
            [type]="typeForResponseModal"
            [txnBatchType]="type"
            [responseForModal]="responseForResponseModal"
            (onCloseModal)="onResponseModalClose($event)"
        ></app-txn-batch-response-modal>
    </div>
    <div *ngIf="showPaginationModal">
        <app-pagination-modal
            [actionType]="typeForPaginationModal"
            [storeObject]="storeObject"
            [totalRecords]="totalNumberOfRecords"
            [totalPages]="totalPages"
            [showPaginationModal]="showPaginationModal"
            [parameters]="paginationParams"
            [donePages]="donePages"
            [type]="type"
            (onCloseModal)="onPaginationModalClose($event)"
            (finalTxnsDetailsObj)="onTxnsDetailReceive($event)"
        ></app-pagination-modal>
        <!--
            <app-txn-batch-response-modal [selectAll]=selectAll [type]=typeForResponseModal [storeObject]=transactionsStore [txnBatchType]=type [responseForModal]=responseForResponseModal (onCloseModal)="onResponseModalClose($event)"></app-txn-batch-response-modal>
        -->
    </div>
</section>

<div bsModal #editModal="bs-modal" *ngIf="showEditModal" [config]="{ show: true, ignoreBackdropClick: true}"
    class="modal fade">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="onEditModalClose()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <h4 class="modal-title">{{editModalTitle}}</h4>
            </div>
            <div>
                <table class="table proc-table table-grid">
                    <thead>
                        <tr>
                            <th width="25%">
                                Transaction Number
                            </th>
                            <!--<th>
                                                Key
                                            </th>-->
                            <th width="25%">
                                Tip Amount
                            </th>
                            <th width="25%">
                                Base Amount
                            </th>
                            <th width="25%">
                                Tax Amount
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let obj of editTxns; let i=index">
                            <tr [class.pending-row]="obj?.disabled" [class.status]="obj?.disabled">
                                <td width="25%">
                                    <span class="right pull-right" *ngIf="obj?.disabled">
                                        <i class="fa fa-info-circle"
                                            tooltip="Status: Pending (This Transaction will be ignored for further processing)"
                                            placement="top" containerClass="tooltip-global" container="body"
                                            aria-hidden="true"></i>
                                    </span>
                                    <span class="right pull-right" *ngIf="obj?.disableOnlyEdit">
                                        <i class="fa fa-info-circle" tooltip="Cannot modify a refund type transaction during reverse action."
                                            placement="top" containerClass="tooltip-global" container="body" aria-hidden="true"></i>
                                    </span>
                                    {{editTxns[i].txnNum ? editTxns[i].txnNum : '-'}}
                                </td>
                                <!--<td>
                                                    {{configParam.xParamKey}}
                                                </td>-->
                                <td width="25%">
                                    <div class="addon-icon">
                                        <i class="fa fa-usd"></i>
                                        <input type="text" placeholder="0.00" (keypress)="onKeypress($event)"
                                            (focus)="onModalInputFocused($event)" (paste)="onPaste($event)"
                                            [(ngModel)]="editTxns[i].tipAmt"
                                            [disabled]="checkTipAmtDisable(editTxns[i])" class="form-control"
                                            (blur)="validateTxnAmts(editTxns[i],'tipAmt',i)"
                                            [ngClass]="{'has-error': editTxns[i].tipAmtError}" />
                                        <span class="right" *ngIf="!(editTxns[i].hasOwnProperty('tipAmt'))">
                                            <i class="fa fa-info-circle"
                                                tooltip="Tip amount does not exist for this transaction" placement="top"
                                                containerClass="tooltip-global" container="body" aria-hidden="true"></i>
                                        </span>
                                        <span class="right" *ngIf="editTxns[i].tipAmtError">
                                            <i class="fa fa-exclamation-triangle inline-danger-color" tooltip="{{INVALID_AMT_MSG}}" placement="top" containerClass="tooltip-custom inline-error"
                                                container="body" aria-hidden="true"></i>
                                        </span>
                                    </div>

                                    <!-- <input type='text' [value]="procParams.value[i].xParamVal" (input)="procParams.value[i].xParamVal=$event.target.value"> -->
                                </td>
                                <td width="25%">
                                    <div class="addon-icon">
                                        <i class="fa fa-usd"></i>
                                        <input type="text" placeholder="0.00" (keypress)="onKeypress($event)"
                                            (click)="onModalInputFocused($event)" (paste)="onPaste($event)"
                                            [(ngModel)]="editTxns[i].baseAmt" [disabled]="obj?.disabled || obj?.disableOnlyEdit"
                                            class="form-control"
                                            (blur)="validateTxnAmts(editTxns[i],'baseAmt',i)"
                                            [ngClass]="{'has-error': editTxns[i].baseAmtError}" />
                                            <span class="right" *ngIf="editTxns[i].baseAmtError">
                                                <i class="fa fa-exclamation-triangle inline-danger-color" tooltip="{{INVALID_AMT_MSG + ' ' + INVALID_BASE_AMT_MSG}}" placement="top" containerClass="tooltip-custom inline-error"
                                                    container="body" aria-hidden="true"></i>
                                            </span>
                                    </div>
                                    <!-- <input type='text' [value]="procParams.value[i].xParamVal" (input)="procParams.value[i].xParamVal=$event.target.value"> -->
                                </td>
                                <td width="25%">
                                    <div class="addon-icon">
                                        <i class="fa fa-usd"></i>
                                        <input type="text" placeholder="0.00" (keypress)="onKeypress($event)"
                                            (click)="onModalInputFocused($event)" (paste)="onPaste($event)"
                                            [(ngModel)]="editTxns[i].taxAmt" [disabled]="obj?.disabled || obj?.disableOnlyEdit"
                                            class="form-control" 
                                            (blur)="validateTxnAmts(editTxns[i],'taxAmt',i)"
                                            [ngClass]="{'has-error': editTxns[i].taxAmtError}"/>
                                            <span class="right" *ngIf="editTxns[i].taxAmtError">
                                                <i class="fa fa-exclamation-triangle inline-danger-color" tooltip="{{INVALID_AMT_MSG}}" placement="top" containerClass="tooltip-custom inline-error"
                                                    container="body" aria-hidden="true"></i>
                                            </span>
                                    </div>
                                    <!-- <input type='text' [value]="procParams.value[i].xParamVal" (input)="procParams.value[i].xParamVal=$event.target.value"> -->
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
            <div class="modal-footer">
                <a class="btn btn-sm btn-secondary" (click)="onEditModalClose()">
                    <i class="fa fa-times" aria-hidden="true"></i> Cancel</a>
                <button class="btn btn-sm btn-primary" (click)="performAction()">Continue
                    <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                </button>
            </div>
        </div>
    </div>
</div>

<section *ngIf="!businessService?.businessID?.getValue()">
    <form class="form-horizontal">
        <div class="grid-add-wrap">
            <div class="row m-b-1">
                <div class="col-sm-10 offset-sm-1 m-t-30">
                    <div class="coming-soon">
                        <h4 class="text-muted">Please select a Business</h4>
                    </div>
                </div>
            </div>
        </div>
    </form>
</section>
<!-- [class.col-sm-10]=" !detailVisible " [class.col-sm-8]="detailVisible " -->
