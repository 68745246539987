//settled txns
export const XPESETTLEDTXNS = {
	xName: 'xName',
	/** (string) name */
	xStoreID: 'xStoreID',
	/** (string) mongo id of store*/
};

//outstanding txns
export const XPETXNS = {
	xName: 'xName',
	/** (string) name */
	xStoreID: 'xStoreID',
	/** (string) mongo id of store*/
};

//xpcSafes
export const XPCSAFES = {
	xName: 'xName',
	/** (str) name */
	xTntTag: 'xTntTag',
	/*(str) Tenant*/
	xWall1EntyID: 'xWall1EntyID',
	/*(ID)  Wall1 (Company)*/
	xWall2EntyID: 'xWall2EntyID',
	/*(ID)  Wall2 (Business)*/
	xValutID: 'xValutID',
	/*(ID)  (idx:4) Vault that the safe belongs to*/
	xBusinessID: 'xBusinessID',
	/*(ID)  (idx:5) Business that the safe belongs to*/
	xStoreID: 'xStoreID',
	/*(ID)  (idx:6) Store that the safe belongs to*/
	xCustomerID: 'xCustomerID',
	/*(ID)  (idx:7) Customer that the safe belongs to*/
	xMdmTag: 'xMdmTag',
	/*(str) Medium; bank, credit card, etc*/
	xBrandTag: 'xBrandTag',
	/*(str) Brand; Visa, MasterCard, etc*/
	xBankName: 'xBankName',
	/*(str) Bank name*/
	xAcctName: 'xAcctName',
	/*(str) Account name; optional; e.g. checking, savings. used as AccountType in pgcom api*/
	xHolderName: 'xHolderName',
	/*(str) Account holder name; name on card, on account*/
	xRtgNum: 'xRtgNum',
	/*(str) Routing number*/
	xAcctTok: 'xAcctTok',
	/*(str) (idx:8) Account token; for external usage; currently UUID; (may have checking digits)*/
	xFakeAcctNum: 'xFakeAcctNum',
	/*(str) (idx:9) Fake account number for external usage; with leading 6 digits and trailing 4 digits potentially the same as the real account number*/
	xRealAcctNum: 'xRealAcctNum',
	/*(str) Real acccount number*/
	xMaskedAcctNum: 'xMaskedAcctNum',
	/*(str) Masked account number for external viewing*/
	xExpYear: 'xExpYear',
	/*(int) Expiration year*/
	xExpMonth: 'xExpMonth',
	/*(int) Expiration month*/
	xSignupPCtrID: 'xSignupPCtrID',
	/*(ID) Profit Center ID to which the safe is linked via GMID*/
};

export const XPCTERMINALS = {
	_id: '_id',
	xName: 'xName',
	xMainPCtrID: 'xMainPCtrID',
	xGTID: 'xGTID',
};

export const DB_MONGOID_KEY = '_id';

export const xAddr = {
	xCO: 'xCO',
	/*(str) Care of*/
	xLine1: 'xLine1',
	/*(str) Line 1 of the address*/
	xLine2: 'xLine2',
	/*(str) Line 2 of the address*/
	xSuite: 'xSuite',
	/*(str) Suite of the address*/
	xCity: 'xCity',
	/*(str) City*/
	xSOP: 'xSOP',
	/*(str) State or province*/
	xPostCode: 'xPostCode',
	/*(str) Post code*/
	xCountry: 'xCountry',
	/*(str) Country*/
};

export const xAltAddr = xAddr;

export const xContact = {
	xEmail: 'xEmail',
	/*(str) Email address*/
	xEmailTypeTag: 'xEmailTypeTag',
	/*(str) Email type: office, private, etc*/
	xPhone: 'xPhone',
	/*(str) Phone number*/
	xPhoneTypeTag: 'xPhoneTypeTag',
	/*(str) Phone type: office, mobile, etc*/
};

export const xFormalName = {
	xPrefix: 'xPrefix',
	/*(str) Prefix: salutation*/
	xForename: 'xForename',
	/*(str) Given name / First name*/
	xMiddleName: 'xMiddleName',
	/*(str) Middle name*/
	xSurname: 'xSurname',
	/*(str) Family name / Last name*/
	xSuffix: 'xSuffix',
	/*(str) Suffix: Jr, Sr*/
	xFullName: 'xFullName',
	/*(str) Full name*/
};

export const XBCCUSTOMERS = {
	xTag: 'xTag',
	/*(str) (idx:1) Optional unique index; can be used in AtTag strings: @thistag*/
	xStatus: 'xStatus',
	/*(int) Status: 1=Active, 0=Inactive, -1=Deleted*/
	xName: 'xName',
	/*(str) Name [For rep, employee, customer, contact models --> (Fill with xFirstName + xLastName when blank)]*/
	xTypeTag: 'xTypeTag',
	/*(str) (idx:2) type definied in Consts*/
	xNotes: 'xNotes',
	/*(str) Notes*/
	xRefNum: 'xRefNum',
	/*(str) (idx:3) Reference number to identify it externally*/
	xTntTag: 'xTntTag',
	/*(str) Tenant*/
	xWall1EntyID: 'xWall1EntyID',
	/*(ID)  Wall1 (Company)*/
	xWall2EntyID: 'xWall2EntyID',
	/*(ID)  Wall2 (Business)*/
	xCustomerNum: 'xCustomerNum',
	/*(str) (idx:4) Corporate number to identify it within the organization*/
	// xMemberNumber:
	/* customer member number TBD  */
	xFormalName: 'xFormalName',
	/*(obj) Formal name; first name, last name, etc*/
	xAddr: 'xAddr',
	/*(obj) Address*/
	xContact: 'xContact',
	/*(obj) Phone, email, etc*/
	xAltAddr: 'xAltAddr',
	/*(obj) Alternative address*/
	xAltContact: 'xAltContact',
	/*(obj) Alternative contact*/
	xBusinessID: 'xBusinessID',
	/*(ID)  Business the customer belongs to*/
	xMainStoreID: 'xMainStoreID',
	/*(ID)  Store that the customer mainly at*/
	xSignupStoreID: 'xSignupStoreID',
	/*(ID)  Store that the customer signed up at*/
	xUserID: 'xUserID',
	xPayAccts: 'xPayAccts',
	/*(arr) Payment accounts*/
	xNotify: 'xNotify',
	/*(obj) Notification receivers*/
	xDBAudit: 'xDBAudit',
	/*(obj) DB access audit*/
	xCompanyName: 'xCompanyName',
	/*(str) company name of customer*/
	xVendorCustomerID: 'xVendorCustomerID',
	xDealerCustomerID:'xDealerCustomerID',
};

export const IMPORT_TXN = {
	xBusinessID: 'xBusinessID',
	xStoreID: 'xStoreID',
	xPCtrID: 'xPCtrID',
	xTerminalID: 'xTerminalID',
	definitionFileID: 'definitionFileID',
	inputFilePath: 'inputFilePath',
	outputFilePath: 'outputFilePath',
	xStatus: 'xStatus',
	createdAt: 'createdAt',
	updatedAt: 'updatedAt',
};

export const XSCUSERS = {
	_id: '_id',
	xTntTag: 'xTntTag',
	xWall1EntyID: 'xWall1EntyID',
	xWall2EntyID: 'xWall2EntyID',
	xLoginUName: 'xLoginUName',
	xHashedPwd: 'xHashedPwd',
	xStatus: 'xStatus',
	xRoleID: 'xRoleID',
	xEntyTypeTag: 'xEntyTypeTag',
	xEntyID: 'xEntyID',
	xTag: 'xTag',
	xName: 'xName',
	xTypeTag: 'xTypeTag',
	xNotes: 'xNotes',
	xRefNum: 'xRefNum',
	xContact: 'xContact',
	xEmail: 'xEmail',
	xValidIP: 'xValidIP',
	xFailedLoginAttempts: 'xFailedLoginAttempts',
	xLoginCoolDown: 'xLoginCoolDown',
	xInSecure: 'xInSecure',
	registeredAt: 'registeredAt',
	xRoles: 'xRoles',
	enableTwoFA: 'enableTwoFA',
};

export const XSCROLES = {
	xTntTag: 'xTntTag',
	xWall1EntyID: 'xWall1EntyID',
	/*(ID)  Wall1 (Company)*/
	xWall2EntyID: 'xWall2EntyID',
	/*(ID)  Wall2 (Business)*/
	xDBAudit: 'xDBAudit',
	/*(obj) DB access audit*/
	xEntyPerm: 'xEntyPerm',
	/* (array of ID) Entity permission ids*/
	xCompanyID: 'xCompanyID',
	/*(ID) Company ID */
	xRoles: 'xRoles',
	enableTwoFA: 'enableTwoFA',
};

export const XBCBUSINESS = {
	/** (str) Business name */
	xName: 'xName',
	/**(str) ID of business as a customer in xbccustomers */
	xAsCustomerID: 'xAsCustomerID',
	xTntTag: 'xTntTag',
	/*(str) Tenant*/
	xBusinessNum: 'xBusinessNum',
	/*(str) (idx:4) Corporate number to identify it within the organization*/
	xDBAName: 'xDBAName',
	/*(str) Doing business as name*/
	xAKAName: 'xAKAName',
	/*(str) Also known as name*/
	xCntyTag: 'xCntyTag',
	/*(str) Country*/
	xTZTag: 'xTZTag',
	/*(str) Time Zone*/
	xMainCcyTag: 'xMainCcyTag',
	/*(str) Main currency*/
	xAddr: xAddr,
	/*(obj) Address*/
	// xPhone:'xPhone',
	/*(str) Phone number (PBX)*/
	xSocial: 'xSocial',
	/*(obj) Social profile; website, facebook, etc.*/
	xMainContact: 'xContact',
	/*(obj) Main contact; phone, email, etc.*/
	xSvcCompanyID: 'xSvcCompanyID',
	/*(ID) (idx:5) Support service company (cannot change)*/
};

export const XPEBILLINGRULE = {
	xStartMonth: 'xStartMonth',
};

export const XPEBILLING = {
	xCompanyID: 'xCompanyID',
	xCompanyName: 'xCompanyName',
	xBusinessID: 'xBusinessID',
	xBusinessName: 'xBusinessName',
	// xAsCustomerID: 'xAsCustomerID',
	xSerialNum: 'xSerialNum',
	xBillingCycleStart: 'xBillingCycleStart',
	xBillingCycleEnd: 'xBillingCycleEnd',
	xPromotionStartDate: 'xPromotionStartDate',
	xPromotionEndDate: 'xPromotionEndDate',
	xDiscountRate: 'xDiscountRate',
	xDiscountAmount: 'xDiscountAmount',
	xIsDiscountForever: 'xIsDiscountForever',
	xSafeID: 'xSafeID',
	xLicenseName: 'xLicenseName',
	xOneTimeSetupFee: 'xOneTimeSetupFee',
	xCreditAmount: 'xCreditAmount',
	xSimpleRecurrents: 'xSimpleRecurrents',
	xTieredRecurrents: 'xTieredRecurrents',
	xProcessorExclusions: 'xProcessorExclusions',
	xIsOveridden: 'xIsOverridden',
	xAmt: 'xAmt',
	xVendorCompanyID: 'xVendorCompanyID',
	xVendorBusinessID: 'xVendorBusinessID',
	xVendorStoreID: 'xVendorStoreID',
	xVendorPCtrID: 'xVendorPCtrID',
	xVendorMerAcctID: 'xVendorMerAcctID',
	xVendorTerminalID: 'xVendorTerminalID',
	xBusinessOverrides: 'xBusinessOverrides',
	xStoreOverrides: 'xStoreOverrides',
	xStoreID: 'xStoreID',
	xStoreName: 'xStoreName',
	xStatus: 'xStatus',
	xOverRideUser: 'xOverRideUser',
	xOverRideFormDefs: 'xOverRideFormDefs',
	xName: 'xName',
	xValue: 'xValue',
	xBillingBreakDown: 'xBillingBreakDown',
	xGTRCList: 'xGTRCList',
	createdAt: 'createdAt',
	updatedAt: 'updatedAt',
	xBillingState: 'xBillingState',
	xFinalBillingDate: 'xFinalBillingDate',
	xDueBillingDate: 'xDueBillingDate',
	xIsSuspended: 'xIsSuspended',
	xSuspendState: 'xSuspendState',
	xPaymentStatus: 'xPaymentStatus',
	xRunningStatus: 'xRunningStatus',
	xMonth: 'xMonth',
	xYear: 'xYear',
	xTag:'xTag',
	xFrequency: 'xFrequency',
};

export const XBCSTORE = {
	xName: 'xName',
};

export const XBCPCTRS = {
	xName: 'xName',
	xGMID: 'xGMID',
	xGMPW: 'xGMPW',
};

export const XBCCOMPANIES = {
	xName: 'xName',
	xTypeTag: 'xTypeTag',
};

export const XPCMERACCTS = {
	xName: 'xName',
};
