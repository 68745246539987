import {
    Component,
    OnInit,
    OnDestroy
} from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { forkJoin } from "rxjs";
import { takeWhile, skip } from "rxjs/operators";
import { TAGSCONSTS } from "../../../../../../constants/tags.constants";

import {
    BreadcrumbService,
    NotificationService,
    BusinessService,
    XpaConstsService,
    UserHandlerService,
    TreeService,
    GmNumberService,
    HttpService,
    UrlStoreService,
    TabStructureService,
    GlobalValidator
} from "../../../../../../shared/services";
import {
    SetupStructureProfitCenterStore,
    SetupBusinessStructureStore,
} from "../../../../../../store";
import {
    Industry,
    Attendance,
    Presence,
    ProfitCenter,
    DBAudit
} from "../../../../../../models";
import { WEB_APP_HOST } from './../../../../../../shared/config/webApp.config';
import { 
    MULTIPLE_CC_MERCHANT_ACCOUNT_FOR_TXN_MSG, 
    MULTIPLE_ACH_MERCHANT_ACCOUNT_FOR_TXN_MSG, 
    ACH_TERM_NUM_KEY, CC_TERM_NUM_KEY 
} from "app/dashboard/common-functions/validate-txn-terminals";
import { customSwal, NO_PERMISSION_MSG } from '../../../../../../constants';
import { validateFor } from '../../../../../../common-functions';

// API endpoints
const CHECK_TRACKER_API_END_POINT = "cloud9pg/v1/checkTrackers";
const VALID_INDUSTRY_END_POINT = "industry_configurations";
const VTERM_ENCRYPT_END_POINT = 'vTerm/encrypt';

// BE fields
const BE_DATA_KEY: string = 'data';
const BE_MERCHANT_STRUCTURE_MERCHANT_KEY: string = 'merchants';
const BE_MERCHANT_STRUCTURE_TERMINAL_KEY: string = 'terminals';
const BE_ERROR_KEY: string = 'error';
const VTERM_TOKEN_KEY: string = 'vTermToken';
const PARAM_STRING_KEY: string = '?param=';
const INVALID_MERCHANT_SETUP_ERROR_KEY: string = 'invalidMerAcctSetupreError';
const CC_MERCHANTS_KEY: string = 'ccMerchants';
const ACH_MERCHANTS_KEY: string = 'achMerchants';

const MULTIPLE_CC_TERM_NUM_ERR: string = 'multipleCCTerminalNumError';
const MULTIPLE_ACH_TERM_NUM_ERR: string = 'multipleACHTerminalNumError';
const CUSTOMER_LOGIN_PATH_STRING: string = '/customer/login';
const MERCHANT_STRUCTURE_ENDPOINT: string = 'merchantStructure';
const BRAND_CC: string = TAGSCONSTS.brandTags.brandItemTags.genericCreditCard;
const BRAND_ACH: string = TAGSCONSTS.brandTags.brandItemTags.genericElectronicCheck;

const CREATE_PCTR_BEFORE_LINK_CAN_BE_GENERATED_MSG: string = 'Please setup Profit Center.'
const NO_MERCHANT_ACCT_SETUP_MESSAGE: string = 'No Merchant Account properly setup for performing transactions.';
const NO_TERMINAL_SETUP_MESSAGE: string = 'No Terminal is setup for performing transaction';
const TERMINAL_NUMBER_ENCRYPTION_FAILED_MSG: string = 'Failed to encrypt Terminal Number. Please try again.';

@Component({
    selector: "add-edit",
    templateUrl: "./add-edit.component.html",
    styleUrls: ["./add-edit.component.scss"]
})
export class SetupStructureProfitCenterComponent implements OnInit, OnDestroy {
    bizID: string = ""; //for storing the businessID fetched via service
    nodePath: any[] = []; //For storing the path where the payment device belongs
    profitCenterModel: any = {}; //For storing value retrieved from server in edit mode
    serverErrors: any = {}; //Stores errors returned by server
    form: FormGroup; //Required for reactive form validation
    submitting: boolean = false; //For handling form submission
    edit: boolean = false; //Check for add/edit mode
    alive: boolean = true;
    industryList: Industry[] = [];
    attendanceList: Attendance[] = [];
    presenceList: Presence[] = [];
    formErrors = {
        xName: "",
        xTag: "",
        xPCtrNum: "",
        xShortCode: "",
        xGMID: "",
        xGMPW: "",
        xIndstTag: "",
        xAttnTag: "",
        xPresTag: ""
    };
    loading: boolean = true;
    tag: string;
    gmID: string = "";
    gmPW: string;
    gmIDEdit: string = "";
    observableCalls: any[] = [];
    previousUrl: string = "";
    storeName: string;
    chainName: string;
    regionName: string;
    propName: string;
    numberKey: string = "xPCtrNum";
    parentMode: boolean = false;
    public toggleValidationMessage: boolean = true;
    public GV = GlobalValidator;
    validIndustryTagList: any[] = [];
    validIndustryEndPoint: string = "industry_configurations";
    bizChangeEvent: boolean = false;
    customerLinkGenerationLoader : boolean = true; // loader during api call fetch for terminal number
    customerLink: string = ''; // customer link to be provided for customer login
    customerLinkErrorMessage: string = ''; // customer link generation fail message
    canEdit = true;
    canAdd = true;    
    noPermMsg = NO_PERMISSION_MSG;
    CREATE_PCTR_BEFORE_LINK_CAN_BE_GENERATED_MSG: string = CREATE_PCTR_BEFORE_LINK_CAN_BE_GENERATED_MSG; // message shown for link section when pctr is being created

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private breadcrumbService: BreadcrumbService,
        private notificationService: NotificationService,
        private businessService: BusinessService,
        private setupStructureProfitCenterStore: SetupStructureProfitCenterStore,
        private setupBusinessStructureStore: SetupBusinessStructureStore,
        private xpaConstsService: XpaConstsService,
        private fb: FormBuilder,
        public userHandlerService: UserHandlerService,
        private treeService: TreeService,
        private gmNumberService: GmNumberService,
        private httpService: HttpService,
        public urlStoreService: UrlStoreService,
        private tabStructureService: TabStructureService,
        public globalValidator: GlobalValidator
    ) {}

    ngOnInit() {
        //Get Contants
        // this.initValidation(); //Initialise Form Validation
        console.log(
            "globalValidator.isValidatingGMID -->",
            GlobalValidator.isValidatingGMID
        );
        this.setupPerms();
        this.tabStructureService.checkAndSetTabNameToDef(
            this.tabStructureService.tabMode,
            this.tabStructureService.isCancelClick,
            this.tabStructureService.isSubmitClick
        );
        this.urlStoreService.submitAction = false;
        // incase of edit mode we get profit center id here
        this.route.params.forEach((params: Params) => {
            if (params.hasOwnProperty("Id")) {
                this.edit = true;
                if (
                    !this.treeService.profitCenterID.getValue() &&
                    !this.treeService.isParent
                ) {
                    console.log("Inside If");
                    this.treeService.setId(params.Id, "profit_center");
                }
                this.fetchPctrData(params["Id"]);
            }
        });
        this.setBreadCrumbValue();
        this.nodePath = this.setupBusinessStructureStore.getNodePath();
        this.observableCalls = [
            this.xpaConstsService
                .getXpaItems("indst"),
            this.xpaConstsService
                .getXpaItems("attn"),
            this.xpaConstsService
                .getXpaItems("pres")
        ];
        this.toFormGroup(new ProfitCenter());
        this.fetchRelevantData();

        if (this.tabStructureService.tabMode) {
            this.checkParentMode();
        }
        this.setTabNameNewPctr();

        this.setupBusinessStructureStore.watchNodePath$
            .pipe(
                takeWhile(() => this.alive),
                skip(1)
            )
            .subscribe((nodePath: any[]) => {
                console.log("This is updated node path profitcenter", nodePath);
                // if (this.treeService.profitCenterID.getValue()) {
                //     this.edit=false;
                //     this.treeService.setId(this.businessService.businessID.getValue(), 'business');
                // }
                // if (this.treeService.profitCenterID.getValue()) {
                this.bizID = this.businessService.businessID.getValue();
                this.nodePath = nodePath;
                this.form.controls["xWall2EntyID"].setValue(this.bizID);
                this.setStoreId();
                this.setBusinessID();
                // this.fetchRelevantData();
                // this.addModeActions();
                // }
                // this.parentMode=false;
                // this.checkParentMode();
                this.parentMode = false;
                // this.setTabNameNewPctr();
            });
        this.addModeActions();
        this.tabStructureService.watchParentFetched$
            .pipe(
                takeWhile(() => this.alive),
                skip(1)
            )
            .subscribe(res => {
                console.log("Here in watcher of parent fetched", res);
                if (res) {
                    console.log("Here in watcher of parent fetched res true");
                    this.setTabNameNewPctr();
                }
            });
        this.businessService.watchBusinessID$
            .pipe(
                takeWhile(() => this.alive),
                skip(1)
            )
            .subscribe(bizId => {
                if (bizId) {
                    this.bizChangeEvent = true;
                } else {
                    this.router.navigate([
                        "/dashboard/setup/business/structure/information"
                    ]);
                }
            });
    }

    checkParentMode() {
        if (this.tabStructureService.tabLabelItems.length) {
            this.tabStructureService.tabLabelItems.map(labelItem => {
                if (labelItem.label.includes("Profit")) {
                    if (
                        labelItem.hasOwnProperty("type") &&
                        labelItem["type"] === "Parent"
                    ) {
                        this.parentMode = true;
                    } else {
                        this.parentMode = false;
                    }
                }
            });
        }
    }

    setTabNameNewPctr() {
        if (!this.edit) {
            console.log(
                "%c Add mode hai k??",
                "background: blue; color: white"
            );
            if (this.tabStructureService.tabLabelItems.length) {
                // console.log('%c Add mode hai k??', 'background: pink; color: white');
                this.tabStructureService.tabLabelItems.map(labelItem => {
                    // console.log('%c Add mode hai k??', 'background: grey; color: black', labelItem);
                    if (labelItem.label.includes("Profit")) {
                        if (labelItem.hasOwnProperty("type")) {
                            labelItem["label"] = "Profit Center";
                            labelItem["type"] = "New";
                            labelItem["name"] = "";
                        }
                    }
                });
                console.log(
                    "%c Profit Add mode hai k??",
                    "background: grey; color: black",
                    this.tabStructureService.tabLabelItems
                );
                this.tabStructureService.setTabStructureNames(
                    this.tabStructureService.tabLabelItems
                );
            }
        }
    }

    addModeActions() {}

    fetchPctrData(id: string) {
        this.setupStructureProfitCenterStore
            .get(id)
            .subscribe((response: Response | any) => {
                this.edit = true;
                this.profitCenterModel = response.data;
                this.toFormGroup(new ProfitCenter(this.profitCenterModel));
                this.nodePath = this.setupBusinessStructureStore.getNodePath();
                this.setStoreId({ storeID: this.profitCenterModel.xStoreID });
            });
        const indstTagQuery = {
            pCtrID: id
        };
        this.httpService
            .getAll(VALID_INDUSTRY_END_POINT, indstTagQuery)
            .subscribe(res => {
                if (
                    res &&
                    res.hasOwnProperty("validIndustryTags") &&
                    res.validIndustryTags
                )
                    this.validIndustryTagList = res.validIndustryTags;
                console.log("validindustrylist", this.validIndustryTagList);
            });
        this.generateCustomerLink(id);
    }

    fetchRelevantData() {
        if (!this.edit) {
            this.form.get("xIndstTag").enable();
            this.bizID = this.businessService.businessID.getValue();
            this.form.controls["xWall2EntyID"].setValue(this.bizID);
            this.setStoreId();
            this.setBusinessID();
            this.observableCalls.push(
                this.gmNumberService.fetchGMData("pgComm/numberAPI_GMPWs", {
                    count: "1"
                })
            );
            forkJoin(this.observableCalls).subscribe(
                (res: any) => {
                    console.log(
                        "Response inside add inside fetchRelevantData",
                        res
                    );
                    this.form.controls.xGMPW.setValue(res[3].data.NumList);
                    this.industryList = res[0].data.filter(tag => tag.xStatus);
                    this.attendanceList = res[1].data.filter(
                        tag => tag.xStatus
                    );
                    this.presenceList = res[2].data.filter(tag => tag.xStatus);
                    this.gmNumberService
                        .fetchGMData("pgComm/numberAPI_GMIDs", { count: "1" })
                        .subscribe(
                            (res: any) => {
                                this.form.controls.xGMID.setValue(
                                    res.data.NumList
                                );
                                this.form.controls.xRefNum.setValue(
                                    res.data.NumList
                                );
                                this.gmID = res.data.NumList;
                                this.loading = false;
                            },
                            (err: any) => {
                                console.log(
                                    "Error in fetching GMID inside add-edit",
                                    this.urlStoreService.previousUrl
                                );
                                this.loading = false;
                                this.notificationService.error(
                                    "Unable to fetch required data. Please try after sometime.",
                                    "Error"
                                );
                                this.urlStoreService.routeBackToPreviousUrl();
                            }
                        );
                },
                (err: any) => {
                    console.log(
                        "Error in fetching consts inside add-edit",
                        this.urlStoreService.previousUrl
                    );
                    this.loading = false;
                    this.notificationService.error(
                        "Unable to fetch required data. Please try after sometime.",
                        "Error"
                    );
                    this.urlStoreService.routeBackToPreviousUrl();
                }
            );
        } else {
            forkJoin(this.observableCalls).subscribe(
                (res: any) => {
                    console.log(
                        "Response inside edit inside fetchRelevantData",
                        res
                    );
                    this.industryList = res[0].data.filter(tag => tag.xStatus);
                    this.attendanceList = res[1].data.filter(
                        tag => tag.xStatus
                    );
                    this.presenceList = res[2].data.filter(tag => tag.xStatus);
                    this.loading = false;
                    // this.form.get("xIndstTag").disable();
                },
                (err: any) => {
                    console.log(
                        "Error in fetching consts inside add-edit",
                        this.urlStoreService.previousUrl
                    );
                    this.loading = false;
                    this.notificationService.error(
                        "Unable to fetch required data. Please try after sometime.",
                        "Error"
                    );
                    this.urlStoreService.routeBackToPreviousUrl();
                }
            );
        }
    }

    setBreadCrumbValue(): void {
        const breadCrumb: any = {};
        breadCrumb.root = "Setup";
        breadCrumb.child = "Business";
        breadCrumb.subChild = "Structure";
        breadCrumb.subChildLink = "setup/business/structure/information";
        breadCrumb.grandChild = "Profit-Center";
        breadCrumb.rootList = [];
        this.breadcrumbService.setBreadCrumb(breadCrumb);
    }

    setStoreId(storeObject?: any) {
        const storeObj: any = this.nodePath.filter(
            node => node.type === "store"
        );
        const chainObj: any = this.nodePath.filter(
            //For tab name
            node => node.type === "chain"
        );
        const regionObj: any = this.nodePath.filter(
            //For tab name
            node => node.type === "region"
        );
        const propObj: any = this.nodePath.filter(
            //For tab name
            node => node.type === "property"
        );
        if (storeObj.length) {
            this.storeID.setValue(storeObj[0].id);
            this.storeName = storeObj[0].name;
        } else {
            if (this.treeService.storeID.getValue()) {
                this.storeID.setValue(this.treeService.storeID.getValue());
                this.storeName = this.tabStructureService.currentNodeName;
            } else {
                if (storeObject && storeObject.hasOwnProperty("storeID")) {
                    this.storeID.setValue(storeObject.storeID);
                    this.storeName = "";
                    this.tabStructureService.defaultTabStructureLabel.map(
                        singleLabel => {
                            if (
                                singleLabel &&
                                singleLabel.hasOwnProperty("label") &&
                                singleLabel.hasOwnProperty("name") &&
                                singleLabel.label === "Store"
                            ) {
                                this.storeName = singleLabel.name.substring(
                                    3,
                                    singleLabel.name.length
                                );
                            }
                        }
                    );
                } else {
                    this.storeID.setValue("");
                    this.storeName = storeObj[0] ? storeObj[0].name : "";
                }
            }
        }
        this.chainName = chainObj.length ? chainObj[0].name : ""; //For tab name
        this.regionName = regionObj.length ? regionObj[0].name : ""; //For tab name
        this.propName = propObj.length ? propObj[0].name : ""; //For tab name
        // else {
        //   this.notificationService.error("Cannot alter this store profit", "ERROR");
        //   this.routeBackToBusinessStructure();
        // }
    }

    setBusinessID() {
        const bizObj: any = this.nodePath.filter(
            node => node.type === "business"
        );
        if (bizObj.length) {
            if (this.bizID === bizObj[0].id) {
                this.businessID.setValue(bizObj[0].id);
            } else {
                if (this.bizID) {
                    this.businessID.setValue(this.bizID);
                } else {
                    this.notificationService.error(
                        "Cannot modify this business",
                        "ERROR"
                    );
                    this.urlStoreService.routeBackToPreviousUrl();
                }
            }
        } else {
            this.businessID.setValue(this.bizID);
        }
    }

    get storeID(): any {
        return this.form.get("xStoreID");
    }
    get businessID(): any {
        return this.form.get("xBusinessID");
    }

    toFormGroup(data: ProfitCenter) {
        console.log("to form grup function");
        this.form = this.fb.group({
            xTntTag: [data.xTntTag || "", []],
            xWall1EntyID: [data.xWall1EntyID || "", []],
            xWall2EntyID: [data.xWall2EntyID || this.bizID, []],
            xTag: [data.xTag || "", []],
            xStatus: [data.xStatus || "1", []],
            xName: [
                data.xName || "",
                [Validators.required, GlobalValidator.validateName]
            ],
            xTypeTag: [
                data.xTypeTag || TAGSCONSTS.profitCenterTags.regularPCtr,
                []
            ],
            xNotes: [data.xNotes || "", []],
            xRefNum: [data.xGMID || "", []],
            xBusinessID: [data.xBusinessID || "", []],
            xStoreID: [data.xStoreID || "", []],
            xGMID: [data.xGMID || "", [Validators.required]],
            xGMPW: [data.xGMPW || "", [Validators.required]],
            xIndstTag: [data.xIndstTag || "", [Validators.required]],
            xAttnTag: [data.xAttnTag || "", [Validators.required]],
            xPresTag: [data.xPresTag || "", [Validators.required]],
            xPCtrNum: [data.xPCtrNum || "", [Validators.required]],
            xShortCode: [data.xShortCode || "", [Validators.required]],
            xDBAudit: this.fb.group(
                new DBAudit({
                    xDataSrc: TAGSCONSTS.dataSourceTags.dataSrcItemTags.self
                })
            )
        });
        this.form
            .get("xGMID")
            .setAsyncValidators(GlobalValidator.checkGMID("", "VALID"));
        if (this.edit) {
            this.form.controls["xRefNum"].disable();
            this.form.controls["xGMID"].disable();
            this.gmID = data.xGMID;
            this.gmIDEdit = data.xGMID;
            console.log("gmid get", data.xGMID);
            console.log("gmid and refnum saved", this.gmID, this.gmIDEdit);
        } else {
            this.form.controls["xRefNum"].disable();
            this.form.controls["xGMID"].enable();
        }
        // this.form.controls['xGMPW'].disable();
    }
    checkValidIndstTag(profitCenterInfoIndstTag: any): boolean {
        console.log("pctr info", profitCenterInfoIndstTag);
        console.log(
            "validIndustryTagPctr length",
            this.validIndustryTagList.length
        );
        if (this.validIndustryTagList.length !== 0) {
            if (
                this.validIndustryTagList.indexOf(profitCenterInfoIndstTag) ===
                -1
            ) {
                console.log(
                    "indsttag not found in validindst array",
                    profitCenterInfoIndstTag,
                    this.validIndustryTagList
                );
                return false;
            }
        }
        return true;
    }

    onSubmit(form: FormGroup): void {
        this.urlStoreService.submitAction = true;
        this.tabStructureService.isSubmitClick = true;
        this.submitting = true;
        this.serverErrors = {}; //Reset Errors

        //TODO Need to fix it. For now making it unique
        const pcName = this.form.controls["xName"].value;
        this.form.controls["xTag"].setValue(
            "profit-center-" + pcName + "-" + Math.random()
        );
        this.form.controls["xRefNum"].setValue(
            this.form.controls["xGMID"].value
        );
        console.log("gmid saved", this.gmIDEdit);
        const profitCenterInfo = form.getRawValue();
        if (this.edit) {
            profitCenterInfo["xGMID"] = this.gmIDEdit;
            profitCenterInfo["xRefNum"] = this.gmIDEdit;
        }
        console.log("profitcenter form data", profitCenterInfo);
        if (this.edit) {
            //Update business
            if (
                profitCenterInfo.xIndstTag &&
                profitCenterInfo.xIndstTag !== ""
            ) {
                console.log("indsttag", profitCenterInfo.xIndstTag);
                if (!this.checkValidIndstTag(profitCenterInfo.xIndstTag)) {
                    // let industryName = this.industryList.find(
                    //     element => element.xTag === profitCenterInfo.xIndstTag
                    // ).xName;
                    let industryObj = {};
                    industryObj = this.industryList.find(
                        element => element.xTag === profitCenterInfo.xIndstTag
                    );
                    let industryName =
                        industryObj && industryObj["xName"]
                            ? industryObj["xName"]
                            : "";
                    console.log("industryname", industryName);
                    // this.notificationService.error(
                    //     "Can’t modify the industry type to " +
                    //         industryName +
                    //         " as the underlying processor does not support type " +
                    //         industryName +
                    //         ". Please contact support for further help",
                    //     "Error"
                    // );
                    customSwal.fire({
                        title: `Invalid Industry Type`,
                        text: "Can’t modify the industry type to " +
                            industryName +
                            " as the underlying processor does not support type " +
                            industryName +
                            ". Please contact support for further help",
                        icon: 'warning',
                        confirmButtonText: 'Close'
                    });
                    this.submitting = false;
                    return;
                }
            }
            this.setupStructureProfitCenterStore
                .update(this.profitCenterModel._id, profitCenterInfo)
                .subscribe(
                    (response: any) => {
                        this.handleSuccessResponse(
                            response.data,
                            profitCenterInfo
                        );
                    },
                    (error: any) => {
                        this.serverErrors = error.errors;
                        this.handleError(error);
                    },
                    () => {}
                );
        } else {
            //Add new business
            this.setupStructureProfitCenterStore
                .store(profitCenterInfo)
                .subscribe(
                    (response: any) => {
                        let requestID = response.data["requestID"];
                        console.log("response from create", response);
                        // when migration batches failed error occures
                        // turn off polling and in backend turn off migratemerchant
                        // in hooks after of xbcPctr

                        this.startTrackerPolling(requestID)
                            .then(resp =>
                                this.handleSuccessResponse(
                                    response.data,
                                    profitCenterInfo
                                )
                            )
                            .catch(err => this.handleErrorResponse(err));
                        // this.handleSuccessResponse(
                        //     response.data,
                        //     profitCenterInfo
                        // );
                    },
                    (error: any) => {
                        this.serverErrors = error.errors;
                        this.handleError(error);
                    },
                    () => {}
                );
        }
    }

    handleSuccessResponse(data: any, pctrModel?: any): void {
        this.submitting = false;
        const msg = this.edit
            ? "Record Successfully Updated."
            : "Record Successfully Added.";
        this.notificationService.success(msg, "Success!");
        this.businessService.toggleRefreshTree();
        // Proper routing depending upon nodeClick or any other click
        if (this.treeService.nodeClick) {
            if (this.edit) {
                this.fetchPctrData(data.id);
            } else {
                this.tabStructureService.currentNodeName = pctrModel.xName;
                this.urlStoreService.routeToCurrentUrl(
                    data,
                    "profit_center",
                    "add"
                );
            }
        } else {
            if (this.tabStructureService.tabMode) {
                this.urlStoreService.routeBackToPreviousUrl();
            } else {
                if (this.tabStructureService.addNodeBtnClick) {
                    this.tabStructureService.currentNodeName = pctrModel.xName;
                    this.urlStoreService.routeToCurrentUrl(
                        data,
                        "profit_center",
                        "add"
                    );
                } else {
                    this.treeService.setId(
                        this.businessService.businessID.getValue(),
                        "business"
                    );
                    console.log(
                        "onsuccess no tab mode and node click   pctr",
                        this.treeService.businessID.getValue(),
                        this.treeService.chainID.getValue()
                    );
                    this.urlStoreService.routeBackToPreviousUrl();
                }
            }
        }
    }

    handleError(error: any) {
        this.submitting = false;
        for (const field of Object.keys(error.errors)) {
            if (this.formErrors.hasOwnProperty(field)) {
                // this.formErrors[field] = "Field is not allowed to be empty";
                if (field === "xPCtrNum") {
                    console.log("Inside handle error", error);
                    if (error.name === "Conflict") {
                        let err = error.errors.xPCtrNum.replace(
                            /profit center/g,
                            "Profit Center"
                        );
                        this.notificationService.error(err, "Conflict");
                        this.clearErrors("xPCtrNum");
                    } else {
                        this.formErrors[field] =
                            "Field is not allowed to be empty";
                    }
                } else {
                    this.formErrors[field] = "Field is not allowed to be empty";
                }
            }
            if (field === "xRefNum") {
                console.log("Inside reference number handle error", error);
                if (error.name === "Conflict") {
                    let err = error.errors.xRefNum.replace(
                        /profit center/g,
                        "Profit Center"
                    );
                    this.notificationService.error(err, "Conflict");
                    this.clearErrors("xRefNum");
                }
            }
        }
    }

    handleErrorResponse(error: any): void {
        this.submitting = false;
        const msg = this.edit ? "Record Not Updated." : "Record Not Added.";
        this.serverErrors = JSON.parse(error._body).errors;
        this.notificationService.error(
            JSON.parse(error._body).name === "BadRequest"
                ? msg
                : JSON.parse(error._body).name,
            JSON.parse(error._body).message === "Invalid data"
                ? "Please fill all Required Details"
                : JSON.parse(error._body).message
        );
    }

    activateGMIDValidation(event: any) {
        this.toggleValidationMessage = true;
    }

    gmidTypeValidation(event: any) {
        GlobalValidator.numberOnlyFormat(event);
    }

    clearErrors(errorName: string): void {
        if (errorName === "xGMID") {
            this.toggleValidationMessage = false;
        }
        if (this.serverErrors[errorName]) {
            delete this.serverErrors[errorName];
        }
    }

    onCancel(event: Event) {
        event.preventDefault();
        if (this.tabStructureService.tabMode) {
            if (!this.edit) {
                this.urlStoreService.routeBackToPreviousUrl();
            } else {
                this.tabStructureService.defaultTabStructureLabel = this.tabStructureService.previousTabStructureLabel;
                this.urlStoreService.routeToSecondLastUrl();
            }
        } else {
            if (this.edit) {
                console.log("no tabmode and edit");
                if (!this.tabStructureService.editMode) {
                    console.log("no tabmode and edit and editmode");
                    this.tabStructureService.defaultTabStructureLabel = this.tabStructureService.previousTabStructureLabel;
                    if (
                        this.urlStoreService.previousUrl &&
                        !this.urlStoreService.previousUrl.includes("add-edit")
                    ) {
                        this.treeService.setId(
                            this.treeService.previousSelectedNode.id,
                            this.treeService.previousSelectedNode.type
                        );
                    }
                    this.urlStoreService.routeBackToPreviousUrl();
                    // }
                } else {
                    console.log("no tabmode and edit and no editmode");
                    if (this.treeService.nodeClick) {
                        this.urlStoreService.routeToSecondLastUrl();
                    } else {
                        this.treeService.setId(
                            this.treeService.previousSelectedNode.id,
                            this.treeService.previousSelectedNode.type
                        );
                        this.urlStoreService.routeBackToPreviousUrl();
                    }
                }
            } else {
                console.log("no tabmode and no edit");
                this.urlStoreService.routeBackToPreviousUrl();
            }
        }
    }

    ngOnDestroy() {
        this.alive = false;
    }

    onEditClick(event: Event) {
        console.log(
            "Here onEditClick function in biz===>",
            this.parentMode,
            JSON.parse(JSON.stringify(this.profitCenterModel))
        );
        this.tabStructureService.previousTabStructureLabel = JSON.parse(
            JSON.stringify(this.tabStructureService.defaultTabStructureLabel)
        );
        this.treeService.isParent = false;
        this.parentMode = false;
        this.edit = true;
        this.treeService.setId(this.profitCenterModel._id, "profit_center");
        // this.router.navigate(['dashboard/setup/business/structure/information'])
    }

    getNewGMID() {
        // this.form.controls.xGMID.disable();
        this.GV.isValidatingGMID = true;
        this.gmNumberService
            .fetchGMData("pgComm/numberAPI_GMIDs", { count: "1" })
            .subscribe(
                (res: any) => {
                    this.form.controls.xGMID.enable();
                    this.form.controls.xGMID.setValue(res.data.NumList);
                    this.form.controls.xRefNum.setValue(res.data.NumList);
                },
                (err: any) => {
                    console.log(
                        "Error in fetching GMID inside add-edit",
                        this.urlStoreService.previousUrl
                    );
                    this.loading = false;
                    this.notificationService.error(
                        "Unable to fetch required data. Please try after sometime.",
                        "Error"
                    );
                    this.urlStoreService.routeBackToPreviousUrl();
                }
            );
    }

    getNewGMPW() {
        this.form.controls.xGMPW.disable();
        this.gmNumberService
            .fetchGMData("pgComm/numberAPI_GMPWs", { count: "1" })
            .subscribe(
                (res: any) => {
                    this.form.controls.xGMPW.enable();
                    this.form.controls.xGMPW.setValue(res.data.NumList);
                },
                (err: any) => {
                    console.log(
                        "Error in fetching GMPW inside add-edit",
                        this.urlStoreService.previousUrl
                    );
                    this.loading = false;
                    this.notificationService.error(
                        "Unable to fetch required data. Please try after sometime.",
                        "Error"
                    );
                    this.urlStoreService.routeBackToPreviousUrl();
                }
            );
    }

    /*
    * hit api for fetching terminal number using which terminal link will be created(terminal for merchant a/cs where their xName field has '[ACH]'/'[CC]')
    *
    * @params : pctrID : mongoID : fetch terminals based on terminal 
    */
    generateCustomerLink(pctrID: string = ''){
        console.log('generateCustomerLink: pctrID',pctrID)
        let merchantStructureParams = {
            noGrid: true,
            pCtrID : pctrID
        }
        this.customerLinkGenerationLoader = true;
        this.httpService.getAll(MERCHANT_STRUCTURE_ENDPOINT, merchantStructureParams)
            .subscribe(
                async merchantStructureResponse => {
                    console.log('generateCustomerLink: merchantStructureResponse',merchantStructureResponse);
                    let token = '';
                    let merchantData = this.getMerchantData(merchantStructureResponse, pctrID);
                    let terminalNumberObjToBeEncrypted = this.getTerminalNumbersObj(merchantData);
                    //If terminalNumberObjToBeEncrypted is empty, no terminal has been setup properly.
                    if(!Object.keys(terminalNumberObjToBeEncrypted).length){
                       this.customerLink = '';
                       this.customerLinkGenerationLoader = false;
                       return;
                    }
                    try{
                        token = await this.encryptTerminalNumber(terminalNumberObjToBeEncrypted);
                    }catch(error){
                        this.setEncryptionFailedMessage();
                        return; 
                    }  
                    this.customerLinkGenerationLoader = false;
                    this.customerLink = WEB_APP_HOST + CUSTOMER_LOGIN_PATH_STRING  + PARAM_STRING_KEY + token;
                    console.log('generateCustomerLink: terminalNumberToBeEcnrypted', terminalNumberObjToBeEncrypted);
                    console.log('generateCustomerLink: customerLink', this.customerLink);
                },
                merchantStructureError => {
                    console.log('generateCustomerLink: merchantStructureError',merchantStructureError);
                    let errorsObj =  
                        validateFor('errors', merchantStructureError)
                        ? merchantStructureError.errors 
                        : {};
                    this.processMerchantAcctSetupError(errorsObj);
            })
    }

    /**
     * Check if error exists in getMerchantData return value. Return valid terminal number object.
     * @param `merchantData`: response from get merchantData function which has cc/ach merchant data
     * @returns `terminalNumberObjToBeEncrypted`: object with cc/ach terminal numbers
     */
    getTerminalNumbersObj(merchantData = {}): object {
        console.log('getTerminalNumbersObj: merchant data', merchantData);

        let ccTerminalID: string = '', achTerminalID: string = '', achTerminalNum: string = '', ccTerminalNum: string = '';
        let terminalNumberObjToBeEncrypted = {};
        let ccMerchants: any = {}, achMerchants: any = {}; 
        let ccTerminal: any = {}, achTerminal: any = {};
        
        //If merchant data is empty, no merchant account has been setup with ACH/CC prefix
        if(!Object.keys(merchantData).length){
            return {};
        }

        //More than one CC/ACH merchants exists
        if(validateFor(INVALID_MERCHANT_SETUP_ERROR_KEY, merchantData)){
            this.processMerchantAcctSetupError(merchantData[INVALID_MERCHANT_SETUP_ERROR_KEY]);
            return {};
        }

        if(validateFor(CC_MERCHANTS_KEY, merchantData)){
            ccMerchants = merchantData[CC_MERCHANTS_KEY];
        }

        if(validateFor(ACH_MERCHANTS_KEY, merchantData)){
            achMerchants = merchantData[ACH_MERCHANTS_KEY];
        }

        console.log('getTerminalNumbersObj: ccMerchants', ccMerchants);
        console.log('getTerminalNumbersObj: achMerchants', achMerchants);
        
        let ccMerchantID = ccMerchants && Object.keys(ccMerchants).length ? Object.keys(ccMerchants)[0] : '';
        let achMerchantID = achMerchants && Object.keys(achMerchants).length ? Object.keys(achMerchants)[0]: '';
        let ccMerchantData = ccMerchantID && ccMerchants[ccMerchantID] && Object.keys(ccMerchants[ccMerchantID]).length ? ccMerchants[ccMerchantID] : {};
        let achMerchantData = achMerchantID && achMerchants[achMerchantID] && Object.keys(achMerchants[achMerchantID]).length ? achMerchants[achMerchantID] : {};
        ccTerminal = validateFor(BE_MERCHANT_STRUCTURE_TERMINAL_KEY, ccMerchantData) ? ccMerchantData.terminals: {};
        achTerminal = validateFor(BE_MERCHANT_STRUCTURE_TERMINAL_KEY, achMerchantData) ? achMerchantData.terminals: {};
        ccTerminalID = ccTerminal && Object.keys(ccTerminal).length ? Object.keys(ccTerminal)[0] : '';
        achTerminalID = achTerminal && Object.keys(achTerminal).length ? Object.keys(achTerminal)[0]: '';
        let ccTerminalData = ccTerminalID && ccTerminal[ccTerminalID] && Object.keys(ccTerminal[ccTerminalID]).length ? ccTerminal[ccTerminalID]: {};
        let achTerminalData = achTerminalID && achTerminal[achTerminalID] && Object.keys(achTerminal[achTerminalID]).length ? achTerminal[achTerminalID]: {};
        achTerminalNum = 
            Object.keys(achTerminalData).length 
            && validateFor('xTerminalNum', achTerminalData) ? achTerminalData.xTerminalNum
            : '';
        ccTerminalNum = 
            Object.keys(ccTerminalData).length 
            && validateFor('xTerminalNum', ccTerminalData) ? ccTerminalData.xTerminalNum
            : '';
            if(!ccTerminalNum && !achTerminalNum){
                this.customerLink = '';
                this.customerLinkGenerationLoader = false;
                this.customerLinkErrorMessage = NO_TERMINAL_SETUP_MESSAGE;
                return {};
            }
            if(achTerminalNum){
                terminalNumberObjToBeEncrypted[ACH_TERM_NUM_KEY] = achTerminalNum;
            }
            if(ccTerminalNum){
                terminalNumberObjToBeEncrypted[CC_TERM_NUM_KEY] = ccTerminalNum;
            }
        console.log('terminalNumberObjToBeEncrypted: getTerminalNumbersObj', terminalNumberObjToBeEncrypted)
        return terminalNumberObjToBeEncrypted;
    }

    /**
     * Check for errors in merchant api response. Return if data is valid, else set error message.
     * @param `merchantStructureResponse` : object: Response from merchantStructure api 
     * @param  `pctrID`: string: mongo id of profit center 
     * @returns `merchantData`: associative merchant data
     */
    getMerchantData(merchantStructureResponse: any = {}, pctrID: string = ''): object{
        let ccMerchants: any = {}, achMerchants: any = {}, merchantData: any = {};
        let invalidMerAcctSetupreError = {};

        let merchantStructureData = 
            validateFor(BE_DATA_KEY,merchantStructureResponse)
            ? merchantStructureResponse.data 
            : {};

        if(
            !Object.keys(merchantStructureData).length 
            || (validateFor(BE_ERROR_KEY,merchantStructureData) 
            && merchantStructureData.error)
        ){
            this.customerLink = '';
            this.customerLinkGenerationLoader = false;
            this.customerLinkErrorMessage = NO_MERCHANT_ACCT_SETUP_MESSAGE;
            return {};
        }

        ccMerchants = validateFor(BRAND_CC, merchantStructureData) 
            && validateFor(pctrID, merchantStructureData[BRAND_CC]) 
            && validateFor(BE_MERCHANT_STRUCTURE_MERCHANT_KEY, merchantStructureData[BRAND_CC][pctrID]) 
            ? merchantStructureData[BRAND_CC][pctrID].merchants : {};

        achMerchants = validateFor(BRAND_ACH, merchantStructureData) 
            && validateFor(pctrID, merchantStructureData[BRAND_ACH])
            && validateFor(BE_MERCHANT_STRUCTURE_MERCHANT_KEY, merchantStructureData[BRAND_ACH][pctrID]) 
            ? merchantStructureData[BRAND_ACH][pctrID].merchants : {};
        
        //Only one CC merchant can be present i.e only one key can be present that is merchant-data's mongo ID.
        if(Object.keys(ccMerchants).length > 1){
            invalidMerAcctSetupreError[MULTIPLE_CC_TERM_NUM_ERR] = MULTIPLE_CC_MERCHANT_ACCOUNT_FOR_TXN_MSG;
        }
        //Only one ACH merchant can be present i.e only one key can be present that is merchant-data's mongo ID.
        if(Object.keys(achMerchants).length > 1){
            invalidMerAcctSetupreError[MULTIPLE_ACH_TERM_NUM_ERR] = MULTIPLE_ACH_MERCHANT_ACCOUNT_FOR_TXN_MSG;
        }
        if(Object.keys(invalidMerAcctSetupreError).length){
            merchantData[INVALID_MERCHANT_SETUP_ERROR_KEY] = invalidMerAcctSetupreError;
            return merchantData;
        } 
        if(Object.keys(ccMerchants).length){
            merchantData[CC_MERCHANTS_KEY] = ccMerchants; 
        }
        if(Object.keys(achMerchants).length){
            merchantData[ACH_MERCHANTS_KEY] = achMerchants; 
        }
        console.log('merchantData: getMerchantData',merchantData);
        return merchantData;
    }

    /**
     * Set encryption failed message
     */
    setEncryptionFailedMessage(){
        this.customerLink = '';
        this.customerLinkErrorMessage = TERMINAL_NUMBER_ENCRYPTION_FAILED_MSG; 
    }

    /**
     * Encrypt terminal numbers
     * @param `terminalNumberObjToBeEncrypted` : object: cc or ach terminal numbers
     * @returns `token`: string
     */
    encryptTerminalNumber(terminalNumberObjToBeEncrypted: any = {}): Promise<string>{
        return new Promise((resolve, reject) => {
            let param = {
                terminalNumbers: terminalNumberObjToBeEncrypted
            };
            this.httpService.store(VTERM_ENCRYPT_END_POINT, param).subscribe(
                encryptedCustomerLink => {
                    console.log("encryptedCustomerLink: generateCustomerLink", encryptedCustomerLink);
                    this.customerLinkGenerationLoader = false;
                    let token = validateFor(BE_DATA_KEY, encryptedCustomerLink) && 
                        validateFor(VTERM_TOKEN_KEY, encryptedCustomerLink[BE_DATA_KEY]) ? 
                        encryptedCustomerLink[BE_DATA_KEY][VTERM_TOKEN_KEY]: '';
                    if(!token){
                        return reject(false);
                    }
                    return resolve(token);
                },
                error => {
                    console.log("error: generateCustomerLink", error);
                    return reject(error);
                })
        })
    }

    /*
    * process errorsObj and process them as needed from the validateTxnTerminals api
    *
    * @params : errorsObj : object : errors field of error object
    */
    processMerchantAcctSetupError(errorsObj){
        console.log('processValidateTxnTerminalsError: errorsObj',errorsObj);
        this.customerLinkGenerationLoader = false;
        this.customerLink = '';
        if(Object.keys(errorsObj).length){
            let multipleCCTerminalNumError:boolean ,multipleACHTerminalNumError:boolean;
            multipleCCTerminalNumError = 
                validateFor(MULTIPLE_CC_TERM_NUM_ERR,errorsObj)
                ? errorsObj.multipleCCTerminalNumError
                : false;
            multipleACHTerminalNumError = 
                validateFor(MULTIPLE_ACH_TERM_NUM_ERR,errorsObj )
                ? errorsObj.multipleACHTerminalNumError
                : false;
            console.log('processValidateTxnTerminalsError: multipleCCTerminalNumError',multipleCCTerminalNumError)
            console.log('processValidateTxnTerminalsError: multipleACHTerminalNumError',multipleACHTerminalNumError)
            if(multipleACHTerminalNumError){
                this.customerLinkErrorMessage = MULTIPLE_ACH_MERCHANT_ACCOUNT_FOR_TXN_MSG;
                return;
            }
            if(multipleCCTerminalNumError){
                this.customerLinkErrorMessage = MULTIPLE_CC_MERCHANT_ACCOUNT_FOR_TXN_MSG;
                return;
            }
        }else{
            this.customerLinkErrorMessage = NO_MERCHANT_ACCT_SETUP_MESSAGE;
        }
    }

    /*
    * selects and copies the data in the provided html tag
    *
    * @params : target : string : id of the html tag whose data is to be copied
    */
    selectHTMLSnippet(target) {
        console.log('selectHTMLSnippet: target', target);
        let copyTarget = document.getElementById(target);
        console.log('selectHTMLSnippet: copytarget', copyTarget);
        window.getSelection().empty();
        window.getSelection().removeAllRanges();
        var range = document.createRange();
        range.selectNode(copyTarget);
        window.getSelection().addRange(range);
        document.execCommand('copy');
    }

    startTrackerPolling(requestID) {
        return new Promise((resolve, reject) => {
            let checktrackerPayload = {
                requestID: requestID
            };
            console.log("checktrackerPayload -->", checktrackerPayload);
            let pCtrCreateRequestTimer = setInterval(() => {
                this.httpService
                    .store(CHECK_TRACKER_API_END_POINT, checktrackerPayload)
                    .subscribe(res => {
                        console.log("res===>", res);
                        if (res.hasOwnProperty("isTracker") && res.isTracker) {
                            console.log("Tracker synced to mongo... PROCEED");
                            clearInterval(pCtrCreateRequestTimer);
                            return resolve(true);
                        } else {
                            console.log(
                                "Tracker NOT YET synced to mongo... Poll Again"
                            );
                        }
                    });
            }, 3000);
            // to test handle success w/o sync open this code
            // setTimeout(()=>{
            //     console.log('startTrackerPolling: resolve true');
            //     return resolve(true);
            // }, 3000);
        });
    }
    
    setupPerms(){
        this.canAdd = this.userHandlerService.userPerms.canCreateSetup;
        this.canEdit = this.userHandlerService.userPerms.canEditSetup;
    }
}
