import { Injectable } from '@angular/core';
import { IMPORT_TXN, TAGSCONSTS, TYPES, XPEBILLINGRULE } from '../../constants';
import { MEDIUM_TAGS_MAPPING, BRAND_TAGS_MAPPING } from '../../constants/mapping.constants';
import { maskCardToken, getFormattedDate, validateFor } from '../../common-functions';
import { SHORT_MASKED_CARD_TOKEN, XPEBILLING } from '../../constants/index';
import { STORE_NAMES } from 'app/constants/store-names.constants';
import moment from 'moment-timezone';

const XPETXNS_CUSTNUM = 'xCustNum';
const XPETXNS_INVNUM = 'xInvNum';
const SHORT_MASKED_TOKEN_LENGTH: number = 8;
const SOURCE_OF_TXN_MAPPING = {
	'vTerm': 'vTerminal',
	'webApp': 'WebApp',
	'POS': 'POS'
};
const IMPORT_TXN_PROCESSING_STATUS = {
	failed: 'Failed',
	completed: 'Completed',
	pending: 'Pending'
};
const LAST_TXN_DATE_TIME_KEY = 'xLastTxnDateTime'
const LAST_TXN_DATE_KEY = 'xLastTxnDate';
const LAST_TXN_TIME_KEY = 'xLastTxnTime';

const PROCESSING_STAUTS_FAILED_VALUE: string = 'failed';
@Injectable()
export class ConstantService {
	public transactionData: any = [];
	public STRLENGTH: number = 30;

	updateData(store, data): Promise<any> {
		return new Promise((resolve, reject) => {
			let upData = [];
			if (data.length > 0) {
				switch (store) {
					case 'TransactionsStore':
						this.updateTransactionData(data)
							.then(updatedData => {
								return resolve(updatedData);
							})
							.catch(err => {
								console.log('catch of txnStore const ser', err);
							});
						break;
					case 'SettledTransactionsStore':
						this.updateTransactionData(data)
							.then(updatedData => {
								return resolve(updatedData);
							})
							.catch(err => {
								console.log('catch of settled const ser', err);
							});
						break;
					case 'BatchStore':
						this.updateBatchData(data)
							.then(updatedData => {
								return resolve(updatedData);
							})
							.catch(err => {
								console.log('catch of batch const ser');
							});
						break;
					case 'SettledBatchStore':
						this.updateBatchData(data)
							.then(updatedData => {
								return resolve(updatedData);
							})
							.catch(err => {
								console.log('catch of batch const ser');
							});
						break;
					case 'SetupBusinessInformationStore':
						console.log('Business case');
						data.map(element => {
							Object.keys(TAGSCONSTS.buisnessTags).map(key => {
								if (element.xTypeTag === TAGSCONSTS.buisnessTags[key]) {
									element.xTypeTag = TYPES.biz[key];
								}
							});
							element = this.commonListUpdates(element);
						});
						return resolve(data);
					// break;
					case 'SetupCompanyStore':
						console.log('Company case', data);
						data.map(element => {
							Object.keys(TAGSCONSTS.companyTags).map(key => {
								if (element.xTypeTag === TAGSCONSTS.companyTags[key]) {
									element.xTypeTag = TYPES.comp[key];
								}
							});
							element = this.commonListUpdates(element);
						});
						return resolve(data);
					// break;
					case 'SetupBusinessStructureStore':
						console.log('Structure case');
						data.map(element => {
							Object.keys(TAGSCONSTS.structTags).map(key => {
								if (element.xTypeTag === TAGSCONSTS.structTags[key]) {
									element.xTypeTag = TYPES.stru[key];
								}
							});
							element = this.commonListUpdates(element);
						});
						return resolve(data);
					// break;
					case 'SetupBusinessStructureStoreSetupStore':
						console.log('Store Case');
						data.map(element => {
							Object.keys(TAGSCONSTS.storeTags).map(key => {
								if (element.xTypeTag === TAGSCONSTS.storeTags[key]) {
									element.xTypeTag = TYPES.sto[key];
								}
							});
							element = this.commonListUpdates(element);
						});
						return resolve(data);
					// break;
					case 'SetupStructureProfitCenterStore':
						console.log('PCTR case');
						data.map(element => {
							Object.keys(TAGSCONSTS.profitCenterTags).map(key => {
								if (element.xTypeTag === TAGSCONSTS.profitCenterTags[key]) {
									element.xTypeTag = TYPES.pctr[key];
								}
							});
							element = this.commonListUpdates(element);
						});
						return resolve(data);
					// break;
					case 'SetupPaymentProcessingMerchantAccountStore':
						console.log('Mer Acct case');
						data.map(element => {
							Object.keys(TAGSCONSTS.merchantAccountTags).map(key => {
								if (element.xTypeTag === TAGSCONSTS.merchantAccountTags[key]) {
									element.xTypeTag = TYPES.meracct[key];
								}
							});
							element = this.commonListUpdates(element);
						});
						return resolve(data);
					// break;
					case 'SetupStructureTerminalStore':
						console.log('Terminal case');
						data.map(element => {
							Object.keys(TAGSCONSTS.terminalTags).map(key => {
								if (element.xTypeTag === TAGSCONSTS.terminalTags[key]) {
									element.xTypeTag = TYPES.terminal[key];
								}
							});
							element = this.commonListUpdates(element);
						});
						return resolve(data);
					// break;
					case 'SetupStructurePeripheralStore':
						console.log('Peripheral case');
						data.map(element => {
							Object.keys(TAGSCONSTS.peripheralTags).map(key => {
								if (element.xTypeTag === TAGSCONSTS.peripheralTags[key]) {
									element.xTypeTag = TYPES.peri[key];
								}
							});
							element = this.commonListUpdates(element);
						});
						return resolve(data);
					// break;
					case 'SetupCompanyRepresentativeStore':
						console.log('Rep case');
						data.map(element => {
							Object.keys(TAGSCONSTS.representativeTags).map(key => {
								if (element.xTypeTag === TAGSCONSTS.representativeTags[key]) {
									element.xTypeTag = TYPES.rep[key];
								}
							});
							element = this.commonListUpdates(element);
						});
						return resolve(data);
					// break;
					case 'SetupSecurityRolesStore':
						console.log('Role case');
						data.map(element => {
							Object.keys(TAGSCONSTS.roleTags).map(key => {
								if (element.xTypeTag === TAGSCONSTS.roleTags[key]) {
									element.xTypeTag = TYPES.role[key];
								}
							});
							element = this.commonListUpdates(element);
						});
						return resolve(data);
					// break;
					case 'SetupBusinessEmployeeStore':
						console.log('Emp case');
						data.map(element => {
							Object.keys(TAGSCONSTS.employeeTags).map(key => {
								if (element.xTypeTag === TAGSCONSTS.employeeTags[key]) {
									element.xTypeTag = TYPES.emp[key];
								}
							});
							element = this.commonListUpdates(element);
						});
						return resolve(data);
					// break;
					case 'SetupBillingCustomerStore':
						console.log('Customer Case');
						data.map(element => {
							Object.keys(TAGSCONSTS.customerTags).map(key => {
								if (element.xTypeTag === TAGSCONSTS.customerTags[key]) {
									element.xTypeTag = TYPES.cust[key];
								}
								if (!element.xCompanyName) {
									element.xCompanyName = '-';
								}
							});
							element = this.commonListUpdates(element);
						});
						return resolve(data);
					// break;
					case 'CsvFileHistoryStore':
						console.log('csv file history case', data);
						data.map(singleCsvObj => {
							let date = new Date(singleCsvObj.updatedAt);
							let day = date.getUTCDate().toString();
							let month = (date.getUTCMonth() + 1).toString();
							let hour = date.getUTCHours().toString();
							let minutes = date.getUTCMinutes().toString();
							day = day.length < 2 ? '0' + day : day;
							month = month.length < 2 ? '0' + month : month;
							hour = hour.length < 2 ? '0' + hour : hour;
							minutes = minutes.length < 2 ? '0' + minutes : minutes;
							singleCsvObj.convertedTime = month + '/' + day + ' ' + hour + ':' + minutes;
							singleCsvObj.updatedAt = date.toString();
							if (!singleCsvObj.csv_details.resultFileName) {
								singleCsvObj.csv_details.resultFileName = '-';
							}
						});

					case 'memberAccountStore':
						console.log('memberAccountStore: constant service: data', JSON.parse(JSON.stringify(data)));
						data = data.map(memberAccount => this.modifyMemberAccountValues(memberAccount));
						console.log('data: case: memberAccountStore---->', data);
						return resolve(data);
					case STORE_NAMES.importTransactionsStore:
						// console.log('import txn store: constant service: data', JSON.parse(JSON.stringify(data)));
						data = data.map(txnData => this.modifyImportTxnValues(txnData));
						return resolve(data);
					case STORE_NAMES.billingStore:
						data = data.map(billingData => this.modifyBillingData(billingData))
						return resolve(data);
					case STORE_NAMES.invoiceStore:
						data = data.map(billingData => this.modifyBillingInvoiceData(billingData))
						return resolve(data);
				}
			}
		});
	}

	updateDetailsData(store, data): any {
		switch (store) {
			case 'TransactionsStore':
				data = this.updateTransactionDetailsData(data);
				break;
			case 'SettledTransactionsStore':
				data = this.updateTransactionDetailsData(data);
				break;
			case 'BatchStore':
				data = this.updateBatchDetailData(data);
				let batchdate = JSON.stringify(data['xBizDate']).substring(4);
				data['xBizDate'] = batchdate.substring(0, 2) + '/' + batchdate.substring(2);
				data.xStatus = data.xStatus ? 'Active' : 'Inactive';
				break;
			case 'SettledBatchStore':
				data = this.updateBatchDetailData(data);
				let settledBatchDate = JSON.stringify(data['xBizDate']).substring(4);
				data['xBizDate'] = settledBatchDate.substring(0, 2) + '/' + settledBatchDate.substring(2);
				data.xStatus = data.xStatus ? 'Active' : 'Inactive';
				break;
			case 'SetupBusinessInformationStore':
				Object.keys(TAGSCONSTS.buisnessTags).map(key => {
					if (data.xTypeTag === TAGSCONSTS.buisnessTags[key]) {
						data.xTypeTag = TYPES.biz[key];
					}
				});
				data = this.commonUpdates(data);
				break;
			case 'SetupCompanyStore':
				Object.keys(TAGSCONSTS.companyTags).map(key => {
					if (data.xTypeTag === TAGSCONSTS.companyTags[key]) {
						data.xTypeTag = TYPES.comp[key];
					}
				});
				data = this.commonUpdates(data);
				break;
			case 'SetupBusinessStructureStore':
				Object.keys(TAGSCONSTS.structTags).map(key => {
					if (data.xTypeTag === TAGSCONSTS.structTags[key]) {
						data.xTypeTag = TYPES.stru[key];
					}
				});
				data = this.commonUpdates(data);
				break;
			case 'SetupBusinessStructureStoreSetupStore':
				Object.keys(TAGSCONSTS.storeTags).map(key => {
					if (data.xTypeTag === TAGSCONSTS.storeTags[key]) {
						data.xTypeTag = TYPES.sto[key];
					}
				});
				data = this.commonUpdates(data);
				break;
			case 'SetupStructureProfitCenterStore':
				Object.keys(TAGSCONSTS.profitCenterTags).map(key => {
					if (data.xTypeTag === TAGSCONSTS.profitCenterTags[key]) {
						data.xTypeTag = TYPES.pctr[key];
					}
				});
				data = this.commonUpdates(data);
				break;
			case 'SetupPaymentProcessingMerchantAccountStore':
				Object.keys(TAGSCONSTS.merchantAccountTags).map(key => {
					if (data.xTypeTag === TAGSCONSTS.merchantAccountTags[key]) {
						data.xTypeTag = TYPES.meracct[key];
					}
				}),
					Object.keys(TAGSCONSTS.processorTags.procCategoryTags).map(key => {
						if (data.xProcTag === TAGSCONSTS.processorTags.procCategoryTags[key]) {
							data.xProcTag = TYPES.procCategoryTags[key];
						}
					});
				data = this.commonUpdates(data);
				break;
			case 'SetupStructureTerminalStore':
				Object.keys(TAGSCONSTS.terminalTags).map(key => {
					if (data.xTypeTag === TAGSCONSTS.terminalTags[key]) {
						data.xTypeTag = TYPES.terminal[key];
					}
				});
				data = this.commonUpdates(data);
				break;
			case 'SetupStructurePeripheralStore':
				Object.keys(TAGSCONSTS.peripheralTags).map(key => {
					if (data.xTypeTag === TAGSCONSTS.peripheralTags[key]) {
						data.xTypeTag = TYPES.peri[key];
					}
				});
				data = this.commonUpdates(data);
				break;
			case 'SetupCompanyRepresentativeStore':
				Object.keys(TAGSCONSTS.representativeTags).map(key => {
					if (data.xTypeTag === TAGSCONSTS.representativeTags[key]) {
						data.xTypeTag = TYPES.rep[key];
					}
				});
				data = this.commonUpdates(data);
				break;
			case 'SetupSecurityRolesStore':
				Object.keys(TAGSCONSTS.roleTags).map(key => {
					if (data.xTypeTag === TAGSCONSTS.roleTags[key]) {
						data.xTypeTag = TYPES.role[key];
					}
				});
				data = this.commonUpdates(data);
				break;
			case 'SetupBusinessEmployeeStore':
				Object.keys(TAGSCONSTS.employeeTags).map(key => {
					if (data.xTypeTag === TAGSCONSTS.employeeTags[key]) {
						data.xTypeTag = TYPES.emp[key];
					}
				});
				data = this.commonUpdates(data);
				break;
			case 'SetupBillingCustomerStore':
				Object.keys(TAGSCONSTS.customerTags).map(key => {
					if (data.xTypeTag === TAGSCONSTS.customerTags[key]) {
						data.xTypeTag = TYPES.cust[key];
					}
				});
				data = this.commonUpdates(data);
				break;
			case 'memberAccountStore':
				data = this.modifyMemberAccountValues(data);
				break;
			case STORE_NAMES.importTransactionsStore:
				data = this.modifyImportTxnValues(data);
				break;
			case STORE_NAMES.billingStore:
				data = this.modifyBillingData(data);
				break;
			case STORE_NAMES.invoiceStore:
				data = this.modifyBillingInvoiceData(data);
				break;
		}
		return data;
	}

	updateTransactionDetailsData(data) {
		console.log("Transaction data: ", data);
		Object.keys(TAGSCONSTS.transactionTags).map(key => {
			if (data.xTypeTag === TAGSCONSTS.transactionTags[key]) {
				data.xTypeTag = TYPES.txn[key];
			}
		});

		Object.keys(TAGSCONSTS.transactionActionTags.txnActionItemTags).map(key => {
			if (data.xTxnActTag === TAGSCONSTS.transactionActionTags.txnActionItemTags[key]) {
				if (data.xIsVoided) {
					data.xTxnActTag = TYPES.txnActionItem[key] + ' ' + '(Voided)';
				} else if (data.xRspStatus === 'fail') {
					data.xTxnActTag = TYPES.txnActionItem[key] + ' ' + '(Failed)';
				} else {
					data.xTxnActTag = TYPES.txnActionItem[key];
				}
			}
		});

		Object.keys(TAGSCONSTS.brandTags.brandItemTags).map(key => {
			if (data.xBrandTag === TAGSCONSTS.brandTags.brandItemTags[key]) {
				data.xBrandTag = TYPES.brandItemTags[key];
			}
		});

		Object.keys(TAGSCONSTS.accountEntryTags.acctEntItemTags).map(key => {
			if (data.xAcctEntrTag === TAGSCONSTS.accountEntryTags.acctEntItemTags[key]) {
				data.xAcctEntrTag = TYPES.acctEntItemTags[key];
			}
		});

		Object.keys(TAGSCONSTS.mediumTags.mdmItemTags).map(key => {
			if (data.xMdmTag === TAGSCONSTS.mediumTags.mdmItemTags[key]) {
				data.xMdmTag = TYPES.mdmItemTags[key];
			}
		});
		data.xTxnNum = this.updateTxnNum(data);
		data.xIsOffline = data.xIsOffline ? 'Yes' : 'No';
		data.xIsTokUsed = data.xIsTokUsed ? 'Yes' : 'No';
		data.xIsTokUsed = data.xIsTokUsed ? 'Yes' : 'No';
		data.xStatus = data.xStatus ? 'Active' : 'Inactive';
		data = this.updateAmounts(data);
		data['xAuthDateTime'] = this.updateDateAndTime(data.xAuthTime, data.xAuthDate);
		data['xFinalizeDateTime'] = this.updateDateAndTime(data.xFinalizeTime, data.xFinalizeDate);
		data['xVoidDateTime'] = this.updateDateAndTime(data.xVoidTime, data.xVoidDate);
		data['xSettleDateTime'] = this.updateDateAndTime(data.xSettleTime, data.xSettleDate);
		data['sourceOfTransaction'] = data.hasOwnProperty('xTerminalInfo')
			&& data.xTerminalInfo
			&& data.xTerminalInfo.hasOwnProperty('TerminalType')
			&& data.xTerminalInfo.TerminalType
			&& SOURCE_OF_TXN_MAPPING[data.xTerminalInfo.TerminalType]
			? SOURCE_OF_TXN_MAPPING[data.xTerminalInfo.TerminalType]
			: '-';
		let date = JSON.stringify(data['xBizDate']).substring(4);
		data['xBizDate'] = date.substring(0, 2) + '/' + date.substring(2);
		data['processorName'] = validateFor('processor', data)
			&& validateFor('xName', data.processor)
			? data.processor.xName
			: '-';
		if (!data.xInvNum) data.xInvNum = '-';
		if (!data.xCustNum) data.xCustNum = '-';
		return data;
	}

	updateBatchDetailData(data) {
		Object.keys(TAGSCONSTS.batchTags).map(key => {
			if (data.xTypeTag === TAGSCONSTS.batchTags[key]) {
				data.xTypeTag = TYPES.batch[key];
			}
		});

		Object.keys(TAGSCONSTS.brandTags.brandItemTags).map(key => {
			if (data.xBrandTag === TAGSCONSTS.brandTags.brandItemTags[key]) {
				data.xBrandTag = TYPES.brandItemTags[key];
			}
		});

		Object.keys(TAGSCONSTS.accountEntryTags.acctEntItemTags).map(key => {
			if (data.xAcctEntrTag === TAGSCONSTS.accountEntryTags.acctEntItemTags[key]) {
				data.xAcctEntrTag = TYPES.acctEntItemTags[key];
			}
		});

		Object.keys(TAGSCONSTS.captureTags.captureItemTags).map(key => {
			if (data.xCaptTag === TAGSCONSTS.captureTags.captureItemTags[key]) {
				data.xCaptTag = TYPES.captureItemTags[key];
			}
		});
		data.netTotal = data.netTotal ? '$' + (parseInt(data.netTotal) / 100).toFixed(2) : '$0.00';
		data.serviceCharge = data.serviceCharge ? '$' + (parseInt(data.serviceCharge) / 100).toFixed(2) : '$0.00';
		data['xOpenDateTime'] = this.updateDateAndTime(data.xOpenTime, data.xOpenDate);
		data['xCloseDateTime'] = this.updateDateAndTime(data.xCloseTime, data.xCloseDate);
		data['xSettleDateTime'] = this.updateDateAndTime(data.xSettleTime, data.xSettleDate);
		data[LAST_TXN_DATE_TIME_KEY] = data.txnData ? this.updateDateAndTime(data.txnData[LAST_TXN_TIME_KEY], data.txnData[LAST_TXN_DATE_KEY]) : '-';
		console.log("data.txnData ==>", data.txnData)
		
		return data;
	}

	updateTransactionData(data): Promise<any> {
		return new Promise((resolve, reject) => {
			data.map(element => {
				element.xTxnNum = this.updateTxnNum(element);
				Object.keys(TAGSCONSTS.transactionTags).map(key => {
					if (typeof TAGSCONSTS.transactionTags[key] === 'string' && element.xTypeTag === TAGSCONSTS.transactionTags[key]) {
						element.xTypeTag = TYPES.txn[key];
					}
				});

				Object.keys(TAGSCONSTS.transactionActionTags.txnActionItemTags).map(key => {
					if (element.xTxnActTag === TAGSCONSTS.transactionActionTags.txnActionItemTags[key]) {
						element.xTxnActTag = TYPES.txnActionItem[key];
						// code for void and failed to be shown in type column itself
						// if (element.xIsVoided) {
						//     element.xTxnActTag =
						//         TYPES.txnActionItem[key] + " " + "(Voided)";
						// } else if (element.xRspStatus === "fail") {
						//     element.xTxnActTag =
						//         TYPES.txnActionItem[key] + " " + "(Failed)";
						// } else {
						//     element.xTxnActTag = TYPES.txnActionItem[key];
						// }
					}
				});

				Object.keys(TAGSCONSTS.brandTags.brandItemTags).map(key => {
					if (element.xBrandTag === TAGSCONSTS.brandTags.brandItemTags[key]) {
						element.xBrandTag = TYPES.brandItemTagsSC[key];
					}
				});

				Object.keys(TAGSCONSTS.accountEntryTags.acctEntItemTags).map(key => {
					if (element.xAcctEntrTag === TAGSCONSTS.accountEntryTags.acctEntItemTags[key]) {
						element.xAcctEntrTag = TYPES.acctEntItemTagsSC[key];
					}
				});

				Object.keys(TAGSCONSTS.mediumTags.mdmItemTags).map(key => {
					if (element.xMdmTag === TAGSCONSTS.mediumTags.mdmItemTags[key]) {
						element.xMdmTag = TYPES.mdmItemTagsSC[key];
					}
				});
				let acctNum = element.xMaskedAcctNum.length
					? element.xMaskedAcctNum.substring(0, 6) + '...' + element.xMaskedAcctNum.substring(element.xMaskedAcctNum.length - 4)
					: '-';
				element.xMaskedAcctNum = acctNum;
				element = this.updateAmounts(element);
				element['xAuthDateTime'] = this.updateDateAndTime(element.xAuthTime, element.xAuthDate);
				element['xSettleDateTime'] = this.updateDateAndTime(element.xSettleTime, element.xSettleDate);
				element['date'] = this.updateDate(element.xAuthDate);
				element['time'] = this.updateTime(element.xAuthTime);
				element['xFinalizeDateTime'] = this.updateDateAndTime(element.xFinalizeTime, element.xFinalizeDate);
				element[XPETXNS_CUSTNUM] = element[XPETXNS_CUSTNUM] ? element[XPETXNS_CUSTNUM] : '-';
				element[XPETXNS_INVNUM] = element[XPETXNS_INVNUM] ? element[XPETXNS_INVNUM] : '-';
			});

			return resolve(data);
		});
	}

	updateBatchData(data) {
		return new Promise((resolve, reject) => {
			data.map(element => {
				Object.keys(TAGSCONSTS.batchTags).map(key => {
					if (element.xTypeTag === TAGSCONSTS.batchTags[key]) {
						element.xTypeTag = TYPES.batch[key];
					}
				});

				Object.keys(TAGSCONSTS.brandTags.brandItemTags).map(key => {
					if (element.xBrandTag === TAGSCONSTS.brandTags.brandItemTags[key]) {
						element.xBrandTag = TYPES.brandItemTags[key];
					}
				});

				Object.keys(TAGSCONSTS.accountEntryTags.acctEntItemTags).map(key => {
					if (element.xAcctEntrTag === TAGSCONSTS.accountEntryTags.acctEntItemTags[key]) {
						element.xAcctEntrTag = TYPES.acctEntItemTags[key];
					}
				});

				Object.keys(TAGSCONSTS.captureTags.captureItemTags).map(key => {
					if (data.xCaptTag === TAGSCONSTS.captureTags.captureItemTags[key]) {
						data.xCaptTag = TYPES.captureItemTags[key];
					}
				});
				console.log('element.netTotal', element.netTotal);
				element.netTotal = element.netTotal ? '$' + (parseInt(element.netTotal) / 100).toFixed(2) : '$0.00';
				element.serviceCharge = element.serviceCharge ? '$' + (parseInt(element.serviceCharge) / 100).toFixed(2) : '$0.00';
				element['xOpenDateTime'] = this.updateDateAndTime(element.xOpenTime, element.xOpenDate);
				element['xCloseDateTime'] = this.updateDateAndTime(element.xCloseTime, element.xCloseDate);
				element['xSettleDateTime'] = this.updateDateAndTime(element.xSettleTime, element.xSettleDate);
				let date = JSON.stringify(element['xBizDate']).substring(4);
				data['xBizDate'] = date.substring(0, 2) + '/' + date.substring(2);
			});
			return resolve(data);
		});
	}

	updateTxnNum(data) {
		if (data.xTxnNum) {
			// New request came to show the 3 digit txnNum as it is.
			
			// if (JSON.stringify(data.xTxnNum).length < 6) {
			// 	let strTxnNum: String = JSON.stringify(data.xTxnNum);
			// 	while (strTxnNum.length < 6) {
			// 		strTxnNum = '0' + strTxnNum;
			// 	}
			// 	return strTxnNum;
			// } else {
			// 	return data.xTxnNum;
			// }
			return data.xTxnNum;
		} else {
			return '-';
		}
	}

	updateAmounts(data) {
		data.xMainAmt = data.xMainAmt ? '$' + (parseInt(data.xMainAmt) / 100).toFixed(2) : '$0.00';
		data.xIncTaxAmt = data.xIncTaxAmt ? '$' + (parseInt(data.xIncTaxAmt) / 100).toFixed(2) : '$0.00';
		data.xIncCashBkAmt = data.xIncCashBkAmt ? '$' + (parseInt(data.xIncCashBkAmt) / 100).toFixed(2) : '$0.00';
		data.xIncShipAmt = data.xIncShipAmt ? '$' + (parseInt(data.xIncShipAmt) / 100).toFixed(2) : '$0.00';
		data.xIncDutyAmt = data.xIncDutyAmt ? '$' + (parseInt(data.xIncDutyAmt) / 100).toFixed(2) : '$0.00';
		data.xIncSurcAmt = data.xIncSurcAmt ? '$' + (parseInt(data.xIncSurcAmt) / 100).toFixed(2) : '$0.00';
		data.xTipAmt = data.xTipAmt ? '$' + (parseInt(data.xTipAmt) / 100).toFixed(2) : '$0.00';
		data.xTtlAmt = data.xTtlAmt ? '$' + (parseInt(data.xTtlAmt) / 100).toFixed(2) : '$0.00';
		data.xInitMainAmt = data.xInitMainAmt ? '$' + (parseInt(data.xInitMainAmt) / 100).toFixed(2) : '$0.00';
		data.xAuthedAmt = data.xAuthedAmt ? '$' + (parseInt(data.xAuthedAmt) / 100).toFixed(2) : '$0.00';
		data.xBalAmt = data.xBalAmt ? '$' + (parseInt(data.xBalAmt) / 100).toFixed(2) : '$0.00';
		//@cary not convert AdjistAmount
		// data.xAdjRemainMainAmt = data.xAdjRemainMainAmt ? '$' + (parseInt(data.xAdjRemainMainAmt) / 100).toFixed(2) : '$0.00';
		// data.xAdjRemainTipAmt = data.xAdjRemainTipAmt ? '$' + (parseInt(data.xAdjRemainTipAmt) / 100).toFixed(2) : '$0.00';
		if (data.xTxnDtl.length) {
			data.xTxnDtl.map(val => {
				val.xAmt = data.xAmt ? '$' + (parseInt(val.xAmt) / 100).toFixed(2) : '$0.00';
			});
		}

		return data;
	}

	updateDateAndTime(initTime, initDate) {
		if (initTime && initDate) {
			let date = JSON.stringify(initDate);
			let modTime = JSON.stringify(initTime);
			while (modTime.length < 6) {
				modTime = '0' + modTime;
			}
			let time = modTime.substring(0, 4);
			let modDateTime = '';
			modDateTime = date.substring(0,4) + '-' + date.substring(4, 6) + '-' + date.substring(6) + ' ' + time.substring(0, 2) + ':' + time.substring(2);
			//console.log("updateDateAndTime:" + modDateTime);
			let newYork    = moment.tz(modDateTime, "America/New_York");
			let mytimezone = moment.tz.guess(true);
			// console.log("moment time:" + moment(newYork).tz(mytimezone).format("YYYY/MM/DD HH:mm"));
			//@cary 
			return moment(newYork).tz(mytimezone).format("MM/DD/YYYY HH:mm");

			// modDateTime = date.substring(0, 2) + '/' + date.substring(2) + '  ' + time.substring(0, 2) + ':' + time.substring(2);
			// // return modDateTime; // before change to local date time
			// // todo the EDT and EST convert

			// let vDate = JSON.stringify(initDate).substring(0,4)+"/" + modDateTime + " EDT";
			// let VD = new Date(vDate);
			// let time_h = VD.toLocaleTimeString('en-GB').split(":")[0]; 
			// let time_m = VD.toLocaleTimeString('en-GB').split(":")[1];
			// let new_time = time_h + ":" + time_m;
			
			// let date_m = VD.toLocaleDateString('en-GB').split('/')[1];
			// let date_d = VD.toLocaleDateString('en-GB').split('/')[0];
			// let new_date = date_m + '/' + date_d;

			// return (new_date + "  " + new_time);
		}
		return '-';
	}

	commonUpdates(data) {
		data.xStatus = data.xStatus ? 'Active' : 'Inactive';
		return data;
	}

	commonListUpdates(data) {
		console.log('data===>', data);
		Object.keys(data).map(dataKey => {
			data[dataKey] = this.truncateString(data[dataKey]);
		});
		return data;
	}

	truncateString(value: any): any {
		// console.log("value===>", value);
		if (value && typeof value === 'string') {
			if (this.STRLENGTH && value.length > this.STRLENGTH) {
				return value.substring(0, this.STRLENGTH) + '...';
			} else {
				return value;
			}
		} else if (value && typeof value === 'object') {
			Object.keys(value).map(valueKey => {
				value[valueKey] = this.truncateString(value[valueKey]);
			});
			return value;
		} else {
			return value;
		}
	}

	sortCompleteData(data, sortType, column: string, nestedKey) {
		if (data.length <= 1) {
			// return once we hit an array with a single item
			return data;
		}

		console.log('data in sorting==>', data, column);

		const middle = Math.floor(data.length / 2); // get the middle item of the array rounded down
		const left = data.slice(0, middle); // items on the left side
		const right = data.slice(middle); // items on the right side
		if (sortType === 'asc') {
			return this.mergeDataAsc(
				this.sortCompleteData(left, sortType, column, nestedKey),
				this.sortCompleteData(right, sortType, column, nestedKey),
				// storeValue,
				column,
				nestedKey
			);
		} else if (sortType === 'dsc') {
			return this.mergeDataDsc(
				this.sortCompleteData(left, sortType, column, nestedKey),
				this.sortCompleteData(right, sortType, column, nestedKey),
				// storeValue,
				column,
				nestedKey
			);
		}
	}

	mergeDataAsc(left, right, column, nestedKey) {
		let result = [];
		let indexLeft = 0;
		let indexRight = 0;
		while (indexLeft < left.length && indexRight < right.length) {
			let leftData;
			let rightData;
			let tempRightData;
			let tempLeftData;
			if (column === 'xName') {
				console.log('tempRightData', tempRightData, tempLeftData);
				tempRightData = right[indexRight][column].toLowerCase();
				tempLeftData = left[indexLeft][column].toLowerCase();
			} else if (column.includes('Amt')) {
				tempRightData = parseFloat(right[indexRight][column].substring(1));
				tempLeftData = parseFloat(left[indexLeft][column].substring(1));
			} else {
				console.log('tempRightData 2', tempRightData, tempLeftData);
				tempRightData = right[indexRight][column];
				tempLeftData = left[indexLeft][column];
			}
			if (leftData) leftData = leftData + tempLeftData;
			else leftData = tempLeftData;

			if (rightData) rightData = rightData + tempRightData;
			else rightData = tempRightData;
			console.log('CHECK THIS mergeDataAsc', leftData, rightData, tempLeftData, tempRightData);
			if (leftData < rightData) {
				result.push(left[indexLeft]);
				indexLeft++;
			} else {
				result.push(right[indexRight]);
				indexRight++;
			}
		}
		return result.concat(left.slice(indexLeft)).concat(right.slice(indexRight));
	}

	mergeDataDsc(left, right, column, nestedKey) {
		let result = [];
		let indexLeft = 0;
		let indexRight = 0;
		while (indexLeft < left.length && indexRight < right.length) {
			let leftData;
			let rightData;
			let tempRightData;
			let tempLeftData;
			// if(column.includes("Amt")){
			if (column === 'xName') {
				console.log('tempRightData', tempRightData, tempLeftData);
				tempRightData = right[indexRight][column].toLowerCase();
				tempLeftData = left[indexLeft][column].toLowerCase();
			} else if (column.includes('Amt')) {
				tempRightData = parseFloat(right[indexRight][column].substring(1));
				tempLeftData = parseFloat(left[indexLeft][column].substring(1));
			} else {
				console.log('tempRightData 2', tempRightData, tempLeftData);
				tempRightData = right[indexRight][column];
				tempLeftData = left[indexLeft][column];
			}
			console.log('CHECK THIS 1 mergeDataDsc', JSON.parse(JSON.stringify(tempLeftData)), JSON.parse(JSON.stringify(tempRightData)));
			if (leftData) leftData = leftData + tempLeftData;
			else leftData = tempLeftData;

			if (rightData) rightData = rightData + tempRightData;
			else rightData = tempRightData;
			console.log('CHECK THIS mergeDataDsc', leftData, rightData, tempLeftData, tempRightData);
			if (leftData > rightData) {
				result.push(left[indexLeft]);
				indexLeft++;
			} else {
				result.push(right[indexRight]);
				indexRight++;
			}
		}
		return result.concat(left.slice(indexLeft)).concat(right.slice(indexRight));
	}

	updateDate(value: string) {
		let modifiedDate = value.toString();
		if (!modifiedDate || modifiedDate.length < 8) {
			return '-';
		}
		// console.log('updateDate: modifiedDate', modifiedDate);
		//@cary Format MM/DD/YYYY
		return modifiedDate.slice(4, 6) + '/' + modifiedDate.slice(6, 8) + '/' + modifiedDate.slice(0, 4);
	}

	updateTime(value: string) {
		let modifiedTime = value.toString();
		if (!modifiedTime) {
			return '-';
		}
		// console.log('updateTime: value', value);
		while (modifiedTime.length < 6) {
			modifiedTime = '0' + modifiedTime;
		}
		return modifiedTime.slice(0, 2) + ':' + modifiedTime.slice(2, 4) + ':' + modifiedTime.slice(4, 6);
	}

	getValue(element: any, key: string): any {
		key = key.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
		key = key.replace(/^\./, ''); // strip a leading dot
		const a = key.split('.');
		for (let i = 0; i < a.length; ++i) {
			const k = a[i];
			if (k in element) {
				element = element[k];
			} else {
				return;
			}
		}
		return element;
	}

	modifyMemberAccountValues(memberAccount: any): any {
		//Mask acct-token
		if (memberAccount.hasOwnProperty('xAcctTok') && memberAccount.xAcctTok) {
			memberAccount.maskedAcctToken = maskCardToken(memberAccount.xAcctTok);
			console.log('masked acctTok:modifyMemberAccountValues---> ', memberAccount.maskedAcctToken);
			memberAccount[SHORT_MASKED_CARD_TOKEN] = maskCardToken(memberAccount.xAcctTok, SHORT_MASKED_TOKEN_LENGTH);
			console.log('shortMaskedToken: modifyMemberAccountValues--->', memberAccount.shortMaskedToken);
		}

		//Brand-tag mapping
		// memberAccount.xBrandTag =
		// 	memberAccount && memberAccount.hasOwnProperty('xBrandTag') && memberAccount.xBrandTag
		// 		? BRAND_TAGS_MAPPING[memberAccount.xBrandTag]
		// 		: '-';
		memberAccount.brand =
			memberAccount && memberAccount.hasOwnProperty('xBrandTag') && memberAccount.xBrandTag
				? BRAND_TAGS_MAPPING[memberAccount.xBrandTag]
				: '-';
		//Acct-Num mapping
		if (memberAccount.hasOwnProperty('xMaskedAcctNum') && memberAccount.xMaskedAcctNum) {
			memberAccount.acctNum = memberAccount.xMaskedAcctNum;
		} else if (memberAccount.hasOwnProperty('xRealAcctNum') && memberAccount.xRealAcctNum) {
			memberAccount.acctNum = maskCardToken(memberAccount.xRealAcctNum);
		} else {
			memberAccount.acctNum = '-';
		}

		//Routing-Number mapping
		memberAccount.xRtgNum = memberAccount.hasOwnProperty('xRtgNum') && memberAccount.xRtgNum ? memberAccount.xRtgNum : '-';

		//Exp-Date mapping
		console.log(memberAccount, 'memberAccount: constant service');
		let expDate =
			memberAccount.hasOwnProperty('xExpYear') &&
				memberAccount.hasOwnProperty('xExpMonth') &&
				memberAccount.xExpYear &&
				memberAccount.xExpMonth
				? memberAccount.xExpMonth + '/' + memberAccount.xExpYear
				: '-';
		memberAccount['xExpDate'] = expDate;
		//Medium-Tag mapping
		// memberAccount.xMdmTag =
		// 	memberAccount.hasOwnProperty('xMdmTag') && memberAccount.xMdmTag ? MEDIUM_TAGS_MAPPING[memberAccount.xMdmTag] : '-';
		memberAccount.medium =
			memberAccount.hasOwnProperty('xMdmTag') && memberAccount.xMdmTag ? MEDIUM_TAGS_MAPPING[memberAccount.xMdmTag] : '-';
		return memberAccount;
	}

	/**
	 * Modify import txn values to readable format [date, porcessing status etc]
	 * @param {object} importTxnData
	 * @returns {object} modified import txn data with values in proper format
	 * @memberof ConstantService
	 */
	modifyImportTxnValues(importTxnData: any): any {
		switch (true) {
			case !importTxnData[IMPORT_TXN.outputFilePath] ||
				importTxnData[IMPORT_TXN.outputFilePath] === PROCESSING_STAUTS_FAILED_VALUE:
				importTxnData.fileProcessingStatus = IMPORT_TXN_PROCESSING_STATUS.failed;
				break;
			case importTxnData[IMPORT_TXN.outputFilePath] === '/':
				importTxnData.fileProcessingStatus = IMPORT_TXN_PROCESSING_STATUS.pending;
				break;
			case importTxnData[IMPORT_TXN.outputFilePath] !== '/':
				importTxnData.fileProcessingStatus = IMPORT_TXN_PROCESSING_STATUS.completed;
				break;
			default:
				console.log('import txn constant service: reached default');
				break;
		}
		importTxnData.createdAt = getFormattedDate(importTxnData[IMPORT_TXN.createdAt]);
		return importTxnData;
	}

	/**
	 * Set billingDate, creationDate and amount for billing data
	 * @param {object} data
	 * @returns modified billing data
	 * @memberof ConstantService
	 */
	// @todo cary
	modifyBillingData(data){
		data['orgCreateAt'] = data[XPEBILLING.createdAt];
		data[XPEBILLING.createdAt] = getFormattedDate(data[XPEBILLING.createdAt]);
		// data[XPEBILLING.xBillingCycleEnd] = getFormattedDate(data[XPEBILLING.xBillingCycleEnd]);
		data[XPEBILLING.xAmt] = '$' + (parseFloat(data[XPEBILLING.xAmt]).toFixed(2)).toString();
		data[XPEBILLINGRULE.xStartMonth] = data[XPEBILLING.xMonth]+'/'+data[XPEBILLING.xYear];
		// data[XPEBILLING.xFinalBillingDate] = getFormattedDate(data[XPEBILLING.xFinalBillingDate]);
		// data[XPEBILLING.xDueBillingDate] = getFormattedDate(data[XPEBILLING.xDueBillingDate]);
		return data;
	}

	modifyBillingInvoiceData(data){
		data[XPEBILLING.createdAt] = getFormattedDate(data[XPEBILLING.createdAt]);
		data[XPEBILLING.xBillingCycleStart] = getFormattedDate(data[XPEBILLING.xBillingCycleStart]);
		data[XPEBILLING.xBillingCycleEnd] = getFormattedDate(data[XPEBILLING.xBillingCycleEnd]);
		data[XPEBILLING.xAmt] = '$' + (parseFloat(data[XPEBILLING.xAmt]).toFixed(2)).toString();
		data[XPEBILLING.xFinalBillingDate] = getFormattedDate(data[XPEBILLING.xFinalBillingDate]);
		data[XPEBILLING.xDueBillingDate] = getFormattedDate(data[XPEBILLING.xDueBillingDate]);
		return data;
	}
}
