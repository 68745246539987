// TODO: Convert this file to work as dynamic config as well

import { environment } from '../../../environments/environment';

const WEB_APP_HOST_LOCAL = 'http://0.0.0.0:4220';
const WEB_APP_HOST_STAGING = 'https://testgatewaydashboard.netevia.com';
const WEB_APP_HOST_PRODUCTION = 'https://portal.c9pg.com';
const WEB_APP_HOST_TEST_SQL_PRODUCTION = 'https://qaportal.c9pg.com';

let WEB_APP_HOST_ENV = WEB_APP_HOST_LOCAL;
console.log('current Environment ---->', environment);
if (environment) {
    if(environment.production){
        WEB_APP_HOST_ENV = WEB_APP_HOST_PRODUCTION; 
    }
    else if(environment.staging){
        WEB_APP_HOST_ENV = WEB_APP_HOST_STAGING;
    }else if(environment.hmr){
        WEB_APP_HOST_ENV = WEB_APP_HOST_TEST_SQL_PRODUCTION;
    }else if(environment.netevia){
        WEB_APP_HOST_ENV = "https://gatewaydashboard.netevia.com";
    }
}

console.log("WEB_APP_HOST:", WEB_APP_HOST_ENV);


export const WEB_APP_HOST = WEB_APP_HOST_ENV;
