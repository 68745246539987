<div class="grid-wrapper">
	<div class="searchform transactions-btn clearfix" [ngClass]="hasActive ? 'has-active' : ''" *ngIf="showSearchBar">
		<form role="search" (ngSubmit)="onSearch(keyword)">
			<div class="row">
				<div [ngClass]="isCustomer ? 'col-md-7 col-sm-7' : 'col-md-6 col-sm-6'">
					<div class="row">
						<div class="col-sm-7">
							<div class="search-box input-group input-group-sm">
								<i class="fa fa-search"></i>
								<input
									type="search"
									class="form-control form-control-sm grid-search-btn"
									placeholder="Search .."
									[(ngModel)]="keyword"
									name="searchBar"
									#searchBar="ngModel"
									[disabled]="showSelected"
								/>
								<button
									tooltip="Global Search"
									placement="bottom"
									container="body"
									containerClass="tooltip-global"
									type="submit"
									class="btn btn-primary btn-sm"
									[disabled]="showSelected"
								>
									<i class="fa fa-search"></i>
								</button>
							</div>
						</div>
						<div class="col-sm-5">
							<span
								*ngIf="
									storeValue === 'TransactionsStore' ||
									storeValue === 'SettledTransactionsStore' ||
									storeValue === 'SetupBillingCustomerStore'
								"
								class="tooltip-wrap-span"
								tooltip="Advanced Search"
								placement="bottom"
								container="body"
								containerClass="tooltip-global"
							>
								<button
									type="button"
									class="advanced-search-icon btn btn-primary btn-sm"
									(click)="onShowAdvancedSearchClick($event)"
									[disabled]="showSelected"
								>
									<i class="fa fa-filter" aria-hidden="true"></i> Filter
								</button>
							</span>
							<span
								*ngIf="
									storeValue === 'TransactionsStore' ||
									storeValue === 'SettledTransactionsStore' ||
									storeValue === 'SetupBillingCustomerStore'
								"
								class="tooltip-wrap-span"
								tooltip="Reset"
								placement="bottom"
								container="body"
								containerClass="tooltip-global"
							>
								<button
									type="button"
									class="advanced-search-icon btn btn-primary btn-sm"
									(click)="onCompleteResetClick(true)"
									[disabled]="showSelected"
								>
									<i class="fa fa-undo" aria-hidden="true"></i> Reset
								</button>
							</span> 

							<span
								*ngIf="showSelected && (storeValue === 'TransactionsStore' || storeValue === 'SettledTransactionsStore')"
								class="tooltip-wrap-span"
								tooltip="Reset Selection"
								placement="bottom"
								container="body"
								containerClass="tooltip-global"
							>
								<button type="button" class="advanced-search-icon btn btn-primary btn-sm" (click)="onResetSelectionClick($event)">
									<i class="fa fa-undo" aria-hidden="true"></i> Reset Selection
								</button>
							</span>
						</div>
					</div>
				</div>
				<div [ngClass]="isCustomer ? 'col-md-5 text-right col-sm-5' : 'col-md-6 text-right col-sm-6'">
					<div class="update-transactions-btn pull-right">
						<label *ngIf="(checkedItem.length > 0 && checkedItem.length <= 100) || showSelectedCheckbox" class="checkbox1" style="margin-bottom: 0">
							<input
								type="checkbox"
								[(ngModel)]="showSelected"
								[ngModelOptions]="{ standalone: true }"
								(change)="onShowSelectedClick($event)"
								[disabled]="showSelectedClicked"
							/>
							<span></span>
							Show Selected
						</label>
						<label *ngIf="showDisabled" class="checkbox1" style="margin-bottom: 0">
							<input
								type="checkbox"
								[(ngModel)]="disabledChecked"
								[ngModelOptions]="{ standalone: true }"
								(change)="onShowDisabledClick($event)"
							/>
							<span></span>
							Show Deactived
						</label>
						<span
							*ngIf="(storeValue === STORE_NAMES.importTransactionsStore || storeValue === STORE_NAMES.transactionsStore) && showImportBtn"
							class="tooltip-wrap-span"
							[tooltip]="
								!gridBtnsController.canImportTxn
									? noPermMsg
									: !enableImportTxns
									? IMPORT_TXN_BTN_TOOLTIP.pctrNotSelected
									: IMPORT_TXN_BTN_TOOLTIP.pctrSelected
							"
							placement="bottom"
							container="body"
							containerClass="tooltip-global"
						>
							<button
								type="button"
								class="btn btn-primary btn-sm"
								(click)="onImportTxnsClick($event)"
								[disabled]="!enableImportTxns || !gridBtnsController.canImportTxn"
							>
								<i class="fa fa-suitcase" aria-hidden="true"></i> Import
							</button>
						</span>
						<span
							*ngIf="report"
							class="tooltip-wrap-span"
							[tooltip]="!gridBtnsController.canGenerateReport ? noPermMsg : 'Generate  Report'"
							placement="bottom"
							container="body"
							containerClass="tooltip-global"
						>
							<button
								type="button"
								class="report-icon btn btn-primary btn-sm"
								(click)="onShowSummaryClick($event)"
								[disabled]="checkDisableReport() || !gridBtnsController.canGenerateReport"
							>
								<i class="fa fa-table" aria-hidden="true"></i> Report
							</button>
						</span>
						<span *ngIf='billingNew' class="tooltip-wrap-span" [tooltip]="'New Billing'" placement="bottom" container="body"
							containerClass="tooltip-global">
							<button type="button" class="report-icon btn btn-primary btn-sm" (click)="onNewClick($event)"
								[disabled]="!gridBtnsController.canCreateNewBilling">
								<i class="fa fa-table" aria-hidden="true"></i> New
							</button>
						</span>						
                        <span 
                            *ngIf='billingReport' 
                            class="tooltip-wrap-span" 
                            [tooltip]="'Generate Report'" 
                            placement="bottom" 
                            container="body" 
                            containerClass="tooltip-global"
                        >
                            <button type="button" class="report-icon btn btn-primary btn-sm" (click)="onShowSummaryClick($event)" 
                                [disabled]="!obj.id || disableViewBtn">
                                <i class="fa fa-table" aria-hidden="true"></i> Report
                            </button>
                        </span>
						<span
							class="tooltip-wrap-span"
							*ngIf="storeValue === 'BatchStore' || storeValue === 'SettledBatchStore'"
							tooltip="Generate Summary Report"
							placement="bottom"
							container="body"
							containerClass="tooltip-global"
						>
							<button type="button" class="report-icon btn btn-primary btn-sm" 
							(click)="onExportSummaryClick($event)" 
							[disabled]="disableExportSummary">
								<i class="fa fa-table" aria-hidden="true"></i> Export Summary
							</button>
						</span>

						<span
							*ngIf="REFRESH_GRID_STORES.includes(storeValue)"
							class="tooltip-wrap-span"
							tooltip="Refresh Data"
							placement="bottom"
							container="body"
							containerClass="tooltip-global"
						>
							<button type="button" class="refresh-icon-txn btn btn-primary btn-sm" (click)="onRefreshClick($event)" [disabled]="showSelected">
								<i class="fa fa-refresh" aria-hidden="true"></i> Refresh
							</button>
						</span>
						<span
							class="tooltip-wrap-span"
							*ngIf="storeValue === 'BatchStore' || storeValue === 'SettledBatchStore'"
							tooltip="Refresh Data"
							placement="bottom"
							container="body"
							containerClass="tooltip-global"
						>
							<button type="button" class="refresh-icon-batch btn btn-primary btn-sm" (click)="onRefreshClick($event)">
								<i class="fa fa-refresh" aria-hidden="true"></i> Refresh
							</button>
						</span>
						<span
							class="tooltip-wrap-span"
							*ngIf="storeValue === 'BatchStore' || storeValue === 'SettledBatchStore'"
							tooltip="Re-Sync List"
							placement="bottom"
							container="body"
							containerClass="tooltip-global"
						>
							<button type="button" class="refresh-icon-batch btn btn-primary btn-sm" (click)="onReSyncClick($event)">
								<i class="fa fa-refresh" aria-hidden="true"></i> Re-Sync
							</button>
						</span>
						<span
							*ngIf="storeValue !== 'CsvFileHistoryStore' && showDetails"
							class="tooltip-wrap-span"
							tooltip="Show Details"
							placement="bottom"
							container="body"
							containerClass="tooltip-global"
						>
							<button
								type="button"
								class="details-icon btn btn-primary btn-sm"
								(click)="onShowDetailsClick($event)"
								[disabled]="disableViewBtn || showSelected"
							>
								<i class="fa fa-file-text-o" aria-hidden="true"></i> Details
							</button>
						</span>
					</div>
				</div>
			</div>
		</form>
	</div>
	<div *ngIf="showAdvancedSearch">
		<app-advanced-search
			[storeValue]="storeValue"
			[resetFilterForm]="resetAdvancedFilterForm"
			[advancedFilterFormData]="advancedFilterFormData"
			(advanceSearchEvent)="onAdvanceSearchEvent($event)"
		></app-advanced-search>
	</div>
	<div class="card no-padding no-margin gridDiv">
		<table
			id="main-table"
			class="table table-sm table-striped no-margin table-grid vertical-content table_main"
			[ngClass]="showFilter ? 'filter-open' : ''"
		>
			<thead class="table_header">
				<tr *ngIf="!(storeValue.includes('Transaction') || storeValue.includes('Batch'))">
					<th
						width="{{ a.width }}"
						*ngFor="let a of attributes; let i = index"
						[class.text-right]="a.header === 'Actions'"
						(click)="
							!showSelected && a.header !== 'Actions' && a.header !== 'Sel. All' && a.header !== 'Action_State'
								? sortTable(i, a.mapTo, a.nestedKey)
								: frontEndSortTable(showSelected, a, i)
						"
					>
						<span *ngIf="a.header === 'fa fa-credit-card'">
							<i class="{{ a.header }}"></i>
						</span>
						<span *ngIf="a.header !== 'Sel. All' && a.header !== 'fa fa-credit-card' && a.header !== 'Action_State'">{{ a.header }}</span>
						<span *ngIf="a.header !== 'Actions' && a.header !== 'Sel. All' && sorting">
							<i class="fa fa-sort" aria-hidden="true" *ngIf="order[i] === 'none'"></i>
							<i class="fa fa-sort-desc" aria-hidden="true" *ngIf="order[i] === 'dsc'"></i>
							<i class="fa fa-sort-asc" aria-hidden="true" *ngIf="order[i] === 'asc'"></i>
						</span>
						<span *ngIf="a.header === 'Sel. All'">
							<input type="checkbox" (click)="checkAll(sel)" />
						</span>
						<span *ngIf="a.header === 'Action_State'">
							<i class="fa fa-clock-o"></i>
						</span>
					</th>
				</tr>
				<tr *ngIf="storeValue.includes('Transaction') || storeValue.includes('Batch')">
					<th
						width="{{ a.width }}"
						*ngFor="let a of attributes; let i = index"
						[class.text-right]="a.header === 'Actions' || a.header === 'Action_State'"
						(click)="
							!showSelected && a.header !== 'Actions' && a.header !== 'Sel. All' && a.header !== 'Action_State' && a.mapTo !== 'netTotal' && a.mapTo !== 'serviceCharge'
								? sortTable(i, a.mapTo)
								: frontEndSortTable(showSelected, a, i)
						"
					>
						<span *ngIf="a.header === 'fa fa-credit-card'">
							<i class="{{ a.header }}"></i>
						</span>
						<span *ngIf="a.header !== 'Sel. All' && a.header !== 'fa fa-credit-card' && a.header !== 'Action_State'">{{ a.header }}</span>
						<span *ngIf="a.header !== 'Actions' && a.header !== 'Sel. All' && !HIDE_SORT_KEYS.includes(a.mapTo) && sorting">
							<i class="fa fa-sort" aria-hidden="true" *ngIf="order[i] === 'none'"></i>
							<i class="fa fa-sort-desc" aria-hidden="true" *ngIf="order[i] === 'dsc'"></i>
							<i class="fa fa-sort-asc" aria-hidden="true" *ngIf="order[i] === 'asc'"></i>
						</span>
						<span *ngIf="a.header === 'Sel. All'">
							<input
								#selAllCheckbox
								type="checkbox"
								(click)="checkAll(sel)"
								[(ngModel)]="selAll"
								[disabled]="this.showSelected || this.data.length === 0"
							/>
						</span>
						<span *ngIf="a.header === 'Action_State'">
							<i class="fa fa-clock-o"></i>
						</span>
					</th>
				</tr>
			</thead>
			<tbody
				virtualScroll
				[items]="data"
				[bufferAmount]="40"
				(update)="viewPortItems = $event"
				class="table_body"
				infiniteScroll
				[infiniteScrollDistance]="200"
				[infiniteScrollThrottle]="250"
				[scrollWindow]="false"
				(scrolled)="onScroll()"
				[class.table-body-height]="userHandlerService.userRoles.isCustomer"
				[class.deposit_acct_grid]="isDepositAccountEdit"
				[class.payment_method_grid]="isPaymentAccountEdit"
			>
				<ng-container *ngFor="let element of viewPortItems; let i = index">
					<tr
						[class.bgSelectedRow]="element?.isChecked"
						*ngIf="!error"
						[class.pending-row]="element?.xPullSyncInfo?.xHasPendingSync"
						[class.voided-row]="element?.xIsVoided"
						[class.refund-row]="!element?.xIsVoided && element?.xIsSettled === true && element?.xAdjCnt >0 && element?.xAdjRemainMainAmt+element?.xAdjRemainTipAmt === 0"
						[class.failed-row]="!element?.xIsVoided && element?.xRspStatus === 'fail'"
					>
						<td
							(click)="a.header !== 'Actions' && a.header !== 'Sel. All' ? performViewAction(element._id, element,$event) : null"
							*ngFor="let a of attributes"
							[class.text-right]="a.header === 'Actions'"
							[class.action-style-two-btn]="a.header === 'Actions' && action.length === 3"
							[class.action-style-three-btn]="a.header === 'Actions' && action.length === 5"
							[class.action-style-four-btn]="a.header === 'Actions' && action.length === 6"
							width="{{ a.width }}"
						>
							<span>
								<span *ngIf="a.header === 'Sel. All' && element">
									<input type="checkbox" (click)="checkItem(element, true, $event)" [(ngModel)]="element.isChecked" />
								</span>
								<span *ngIf="a.header !== 'Actions'">
									<span>
										<span
											id="notNestedKey{{ i }}"
											*ngIf="!a.nestedKey"
											[ngClass]="[
												storeValue === STORE_NAMES.importTransactionsStore && a.mapTo === 'createdAt' ? 'word-space-5px' : '',
												element[a.mapTo] === 'Sale' && !element?.xIsVoided && element?.xRspStatus !== 'fail' ? 'green-tip' : '',
												element[a.mapTo] === 'Refund' && !element?.xIsVoided && element?.xRspStatus !== 'fail' ? 'red-tip' : '',
												element[a.mapTo] === 'Auth' && !element.xIsVoided && element?.xRspStatus !== 'fail' ? 'orange-tip' : ''
											]"
										>
											<!-- {{truncateString(element[a.mapTo])}} -->
											{{ element[a.mapTo] }}
										</span>
										<span *ngIf="a.nestedKey">
											{{ getValue(element, a.mapTo) }}
										</span>
									</span>
								</span>
								<span class="action-icons text-right check" *ngIf="a.header === 'Actions'">
									<span *ngFor="let act of action">
										<span
											placement="top"
											container="body"
											containerClass="tooltip-global"
											[tooltip]="userHandlerService.userRoles.isDealer && element.hasOwnProperty('xTag') && element.xTag === 'role.ven' ? noPermMsg : act.disabled ? noPermMsg : act.text"
											placement="left"
											container="body"
											containerClass="tooltip-global"
										>
											<a
												class="btn btn-sm"
												*ngIf="
													act.visible &&
													act.type !== 'toggle' &&
													act.type !== 'view' &&
													act.type !== 'download' &&
													(element.xTypeTag === 'Vendor Company'
														? act.type !== 'edit' && act.type !== 'delete' && act.text !== 'As Customer'
														: true) &&
													(element.xTypeTag === 'Company Business' ? act.type !== 'edit' && act.type !== 'delete' : true) &&
													(element.xTypeTag === 'Company Store' ? act.type !== 'delete' : true) &&
													(act.type === 'route' ? !(act.idField === 'xAsCustomerID' && element.xTypeTag === 'comptype.ven') : true) &&
													(act.type === 'route'
														? !(
																act.idField === 'xAsCustomerID' &&
																(element.xTypeTag === 'biztype.iscomp' || element.xTypeTag === 'biztype.isven')
														  )
														: true) &&
													(act.type === 'delete' ? !(element.xTypeTag === 'biztype.iscomp') : true) &&
													(act.type === 'delete' ? !(element.xTypeTag === 'custtype.isbiz') : true) &&
													(act.type === 'delete' ? !(element.xTypeTag === 'comptype.ven') : true) &&
													(act.type === 'modal' && act.onClick === 'viewImportResponse'
														? element.action_worker_queue_status && element.action_worker_queue_status === 'Success'
														: true)
												"
												(click)="processAction(act, element._id, element, $event)"
												[class.btn-warning]="act.type == 'edit' || act.type == 'runnow'"
												[class.btn-suspend]="act.type == 'suspend'"
												[class.btn-unsuspend]="act.type == 'unsuspend'"
												[class.btn-paynow]="act.type == 'payNow'"
												[class.btn-ispaid]="act.type == 'isPaid'"
												[class.btn-danger]="act.type == 'delete' || act.type == 'disable'"
												[class.disabled]="act.disabled || userHandlerService.userRoles.isDealer && element.hasOwnProperty('xTag') && element.xTag === 'role.ven'"
												[class.btn-secondary]="act.type == 'select' && currentBizID !== element._id && currentCompID !== element._id"
												[class.btn-primary]="act.type == 'select' && (currentBizID === element._id || currentCompID === element._id)"
												[class.btn-info]="act.type == 'view'"
												[class.btn-success]="['modal', 'toggle', 'route'].includes(act.type)"
											>
												<i *ngIf="act.icon" class="{{ act.icon }}" aria-hidden="true"></i>
											</a>
											<a
												class="btn btn-sm"
												*ngIf="
													act.visible &&
													act.type === 'toggle' &&
													act.type !== 'view' &&
													act.type !== 'download' &&
													element.xTypeTag !== 'Vendor Company'
												"
												(click)="processAction(act, element._id, element)"
												[class.btn-warning]="act.type == 'edit'"
												[class.btn-danger]="act.type == 'delete'"
												[class.btn-info]="act.type == 'view'"
												[class.btn-secondary]="act.type == 'select' && currentBizID !== element._id && currentCompID !== element._id"
												[class.btn-primary]="act.type == 'select' && (currentBizID === element._id || currentCompID === element._id)"
												[class.btn-success]="act.type == 'toggle'"
												[class.btn-success]="act.type == 'modal'"
												tooltip="{{ act.text }}"
												placement="left"
												containerClass="tooltip-global"
											>
												<i *ngIf="act.icon_on && element.Status === 1" class="{{ act.icon_on }}" aria-hidden="true"></i>
												<i *ngIf="act.icon_off && element.Status === 0" class="{{ act.icon_off }}" aria-hidden="true"></i>
											</a>
											<a
												*ngIf="
													act.icon &&
													element.fileProcessingStatus === IMPORT_FILE_PROCESSING_STATUS_COMPLETED &&
													act.type == 'download'
												"
												class="btn btn-sm"
												(click)="processAction(act, element._id, element, $event)"
												[class.btn-success]="true"
												tooltip="{{ act.text }}"
												placement="top"
												container="body"
												containerClass="tooltip-global"
											>
												<i class="{{ act.icon }}" aria-hidden="true"></i>
											</a>
										</span>
									</span>
								</span>
								<span *ngIf="a.header === 'State' && !STATE_FIELD_EXCEPTIONS.includes(storeValue)" class="action-status">
									<div *ngIf="saveCheckedArray?.indexOf(element?._id) === -1">-</div>
									<div *ngIf="actionPerformed && saveCheckedArray?.indexOf(element?._id) > -1">
										<div *ngIf="element?.xPullSyncInfo?.xHasPendingSync" class="status pending">
											<i class="fa fa-clock-o" aria-hidden="true"></i>
										</div>
										<div *ngIf="!element?.xPullSyncInfo?.xHasPendingSync" class="status success">
											<i class="fa fa-check-circle" aria-hidden="true"></i>
										</div>
									</div>
								</span>
							</span>
						</td>
					</tr>
				</ng-container>
				<ng-container>
					<div *ngIf="loading" class="grid-spinner">
						<div class="bounce1"></div>
						<div class="bounce2"></div>
						<div class="bounce3"></div>
					</div>
				</ng-container>
				<tr *ngIf="error && !loading" style="height: 100%">
					<td style="text-align: center; color: #888; font-size: 18px" [colSpan]="attributes.length">
						<i class="fa fa-exclamation-triangle" aria-hidden="true"></i> {{ gridMessage }}
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>

<div *ngIf="showPctrDeleteModal">
	<app-delete-pctr-modal
		[repercussionData]="deleteRepercussionData"
		(shouldDeletePctr)="onProccedToDeletePctrClick($event)"
		[pCtrToBeDeleted]="pCtrToBeDeleted"
		(onModalCloseEvent)="onPctrDeleteModalCloseEvent($event)"
	>
	</app-delete-pctr-modal>
</div>

<div>
	<app-import-transactions-modal
		[showInfo]="storeValue === STORE_NAMES.transactionsStore"
		[showImportTxnModal]="showImportTxnModal"
		(closeModalEvent)="onImportTxnModalClose()"
		(refreshGridEvent)="onRefreshImportTxnGrid()"
	></app-import-transactions-modal>
</div>

<div *ngIf="showOverLay" class="overlay">
	<div class="overlay-content">
		<div>
			<i style="color: white" class="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i>
		</div>
		<div>
			<span style="font-size: 20px">{{ overlayText }}</span>
		</div>
	</div>
</div>
