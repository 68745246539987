<section *ngIf="!loading" class="buis-info">
    <form class="form-horizontal" [ngClass]="{'disable-form':(parentMode || userHandlerService?.userRoles?.isBusinessEmployee || (setupBusinessInformationModel?.xTypeTag==='biztype.iscomp'))}"
        [formGroup]="form" (ngSubmit)="onSubmit(form)" novalidate>
        <div class="row top-stat">
            <div class="col">
                <div class="card">
                    <div class="card-body">
                        <i class="fa fa-check-circle-o label-icon label-head-icon" aria-hidden="true"></i>
                        <label class="p-l-5" for="name">Status:</label>
                        <label class="detail-label"> {{setupBusinessInformationModel.xStatus === 1 ? 'Active' : 'InActive'}}
                        </label>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card">
                    <div class="card-body">
                        <i class="fa fa-sliders label-icon label-head-icon" aria-hidden="true"></i>
                        <label for="name" class="p-l-5">Type:</label>
                        <label class="detail-label">Business</label>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card">
                    <div class="card-body">
                        <i class="fa fa-briefcase label-icon label-head-icon" aria-hidden="true"></i>
                        <label for="dba_name" class="p-l-5">Business:</label>
                        <label class="detail-label">{{businessService.businessData?.xName}}</label>
                    </div>
                </div>
            </div>
            <!-- <div class="col">
                <div class="card">
                    <div class="card-body">
                        <i class="fa fa-building-o label-icon label-head-icon" aria-hidden="true"></i>
                        <label for="par_name" class="p-l-5">Business:</label>
                        <label class="detail-label">{{businessService.businessData?.xName}}</label>
                    </div>
                </div>
            </div> -->
        </div>
        <div class="">

        <div class="row  m-b-1">
            <div class="col-sm-6">
                <!-- <div class="clearfix">
          <small *ngIf="!serverErrors['xTypeTag'] && form.get('xTypeTag').hasError('required') && form.get('xTypeTag').touched" class="inline-error"> Business Type is required</small>
          <small *ngIf="serverErrors['xTypeTag']" class="inline-error"> {{ serverErrors['xTypeTag']?.message.replace('xTypeTag', 'Field')}}</small>
        </div> -->

                <!-- <div class="business_type card">
          <select class="form-control form-control-sm" id="business_type" formControlName="xTypeTag" [ngClass]="{'has-error': (form.get('xTypeTag').hasError('required') && form.get('xTypeTag').touched) || serverErrors['xTypeTag']}">
                          <option [ngValue]="null">Select Business Type</option>
                          <option *ngFor="let type of businessType" [value]="type.xTypeTag">{{type.xTypeTag}}</option>
                      </select>
          <span><i class="fa fa-angle-down"></i></span>
        </div> -->
                <!-- <app-basic-information [basicData]=setupBusinessInformationModel [basicForm]=form [serverErrors]=serverErrors></app-basic-information> -->



                
                <div class="card">
                    <div class="card-header">
                        <label class="control-label">Basic</label>
                    </div>
                    <div class="card-body">
                        <div class="form-group row">
                            <div class="col-md-6 col-sm-12">
                                <label for="name">Name:
                                    <small class="text-danger">*</small>
                                </label>
                                <small *ngIf="!serverErrors['xName'] && form.get('xName').hasError('required') && form.get('xName').touched" class="inline-error">
                                    <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Name is required" placement="top" containerClass="tooltip-custom inline-error"
                                        aria-hidden="true"></i>

                                </small>
                                <small *ngIf="!serverErrors['xName'] && form.get('xName').hasError('incorrectNameFormat') && form.get('xName').touched" class="inline-error">
                                    <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Name is of incorrect format. Correct Format-: 1) Name can not be more than 20 letters. 2) The first character of Name should not be an empty space. 3) The first character of Name should not be any special characters, E.g. /,& etc."
                                        placement="top" containerClass="tooltip-custom inline-error" aria-hidden="true"></i>
                                </small>
                                <small *ngIf="serverErrors.xName" class="inline-error">
                                    <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Name is required" placement="top" containerClass="tooltip-custom inline-error"
                                        aria-hidden="true"></i>

                                </small>
                                <input type="text" class="form-control form-control-sm" [ngClass]="{'has-error': serverErrors.xName || !serverErrors.xName && (form.get('xName').hasError('required') && form.get('xName').touched) || (form.get('xName').hasError('incorrectNameFormat') && form.get('xName').touched)}"
                                    id="name" (focus)="clearErrors('xName')" formControlName="xName" validateOnBlur>
                            </div>
                            <div class="col-md-6 col-sm-12">   
                                <!-- <div style="margin-top: 10px;" class="d-flex align-items-center">
                                    <span>Auto-fill Member Number</span>
                                    <label class="checkbox1" for="Option5" style="margin-left: 15px;">
                                        <input id="Option5" class="form-control form-control-sm" type="checkbox" formControlName="xAutoFillMermNum">
                                        <span></span>
                                    </label>
                                </div> -->
                                <label class="checkbox-wrap">
                                    <label class="checkbox1" for="Option1">
                                        <input id="Option1" type="checkbox" formControlName="xAutoFillMermNum">
                                        <span></span>
                                    </label>
                                    <span  class="text">
                                        Auto-fill Member Number?
                                    </span>
                                </label>
                                <label class="checkbox-wrap"  title="vTerminal Dispaly Expand Buttons? Including:Save Customer,Authorize Only,Create Token">
                                    <label class="checkbox1" for="Option2">
                                        <input id="Option2" type="checkbox" formControlName="xVTerminalShowExpandBtn">
                                        <span></span>
                                    </label>
                                    <span class="text">
                                        vTerminal Dispaly Expand Buttons?
                                    </span>
                                </label>
                            </div>

                            <!-- <div class="col-md-6 col-sm-12">
                                <label for="phone">Phone: <small class="text-danger">*</small></label>
                                <small *ngIf="!serverErrors['xPhone'] && form.get('xPhone').hasError('required') && form.get('xPhone').touched" class="inline-error">
                                    <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Phone is required" placement="top" containerClass="tooltip-custom inline-error" aria-hidden="true"></i>

                                </small>
                                <small *ngIf="serverErrors.xPhone" class="inline-error">
                                  <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Phone  is required" placement="top" containerClass="tooltip-custom inline-error" aria-hidden="true"></i>

                                </small>
                                <input type="text" class="form-control form-control-sm" [ngClass]="{'has-error': serverErrors.xName || !serverErrors.xName && (form.get('xPhone').hasError('required') && form.get('xPhone').touched)}" id="phone" formControlName="xPhone" (keypress)="onKeypress($event)"
                                    (focus)="clearErrors('xPhone')" (paste)="onPaste($event)" validateOnBlur>
                            </div> -->
                        </div>
                        <div class="form-group row">
                            <div class="col-md-6 col-sm-12">
                                <label for="dba_name">DBA Name:
                                    <!-- <small class="text-danger">*</small> -->
                                </label>
                                <small *ngIf="formErrors.xDBAName" class="inline-error">
                                    <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="DBA Name is required" placement="top" containerClass="tooltip-custom inline-error"
                                        aria-hidden="true"></i>

                                </small>
                                <input type="text" class="form-control form-control-sm" id="dba_name" formControlName="xDBAName" validateOnBlur>
                            </div>
                            <div class="col-md-6 col-sm-12">
                                <label for="aka_name">AKA Name:
                                    <!-- <small class="text-danger">*</small> -->
                                </label>
                                <small *ngIf="formErrors.xAKAName" class="inline-error">
                                    <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="AKA Name is required" placement="top" containerClass="tooltip-custom inline-error"
                                        aria-hidden="true"></i>

                                </small>
                                <input type="text" class="form-control form-control-sm" id="aka_name" formControlName="xAKAName" validateOnBlur>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-6 col-sm-12">
                                <label for="tag">Tag:
                                    <!-- <small class="text-danger">*</small> -->
                                </label>
                                <small *ngIf="formErrors.xTag" class="inline-error">
                                    <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Tag is required" placement="top" containerClass="tooltip-custom inline-error"
                                        aria-hidden="true"></i>

                                </small>
                                <input type="text" class="form-control form-control-sm" id="tag" formControlName="xTag" validateOnBlur>
                            </div>
                            <div class="col-md-6 col-sm-12">
                                <label for="ref_num">Ref Number:
                                    <!-- <small class="text-danger">*</small> -->
                                </label>
                                <small *ngIf="formErrors.xRefNum" class="inline-error">
                                    <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Ref Number is required" placement="top" containerClass="tooltip-custom inline-error"
                                        aria-hidden="true"></i>

                                </small>
                                <i class="fa fa-info-circle" tooltip="Do not enter the reference number unless instructed to do so" placement="top" container="body"
                                    containerClass="tooltip-global" aria-hidden="true"></i>
                                <input type="text" class="form-control form-control-sm" id="ref_num" formControlName="xRefNum" validateOnBlur>
                            </div>
                        </div>
                        <div class="form-group row no-margin">
                            <div class="col-md-12 col-sm-12">
                                <label for="biz_num">Business Number:
                                    <small class="text-danger">*</small>
                                </label>
                                <!-- <small *ngIf="!serverErrors['xBusinessNum'] && form.get('xBusinessNum').hasError('required') && form.get('xBusinessNum').touched" class="inline-error">
                                        <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Business number is required" placement="top" containerClass="tooltip-custom inline-error"
                                            aria-hidden="true"></i>
                                    </small>

                                <small *ngIf="serverErrors['xBusinessNum']" class="inline-error">
                                    <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Business number is required" placement="top" containerClass="tooltip-custom inline-error"
                                        aria-hidden="true"></i>
                                </small>
                                <div class="input-group">
                                    <input type="text" class="form-control form-control-sm" id="biz_num" formControlName="xBusinessNum" (focus)="clearErrors('xBusinessNum')"
                                    [ngClass]="{'has-error': (form.get('xBusinessNum').hasError('required') && form.get('xBusinessNum').touched) || serverErrors['xBusinessNum']}"
                                    validateOnBlur>
                                    <button tooltip="Get new number" placement="top" container="body" containerClass="tooltip-global" type="button" class="btn btn-light"><i class="fa fa-undo"></i></button>
                                </div> -->
                                <app-model-number [modelNumber]=setupBusinessInformationModel.xBusinessNum [form]=form [serverErrors]=serverErrors [numberKey]=numberKey></app-model-number>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header">
                        <label>Notes</label>
                    </div>
                    <div class="form-group  no-margin">
                        <div class="">
                            <small *ngIf="serverErrors['xNotes']" class="inline-error">
                                <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Notes is required" placement="top" containerClass="tooltip-custom inline-error"
                                    aria-hidden="true"></i>
                            </small>
                            <textarea class="form-control notes-area" rows="5" id="notes" (focus)="clearErrors('xNotes')" formControlName="xNotes" style="min-height: 146px;"></textarea>
                        </div>
                    </div>
                </div>

                <div>
                    <app-social-contact-row [generalContactForm]=form [generalContact]=setupBusinessInformationModel.xSocial [serverErrors]=serverErrors
                        [tagType]=setupBusinessInformationModel.xTypeTag>
                    </app-social-contact-row>
                </div>

            </div>
            <div class="col-sm-6">
                <div class="card">
                    <div class="card-header">
                        <label class="">Structure Info</label>
                    </div>
                    <div class="info-block">
                        <div class="info-body">
                            <div class="info-left" formGroupName="xStructCaps">
                                <label class="checkbox-wrap">
                                    <label class="checkbox1" for="Option">
                                        <input id="Option" type="checkbox" formControlName="xHasChains">
                                        <span></span>
                                    </label>
                                    <span for="multiple-chain" class="text">
                                        Has Chains?
                                    </span>
                                </label>
                                <label class="checkbox-wrap">
                                    <label class="checkbox1" for="Option2">
                                        <input id="Option2" type="checkbox" formControlName="xHasRegions">
                                        <span></span>
                                    </label>
                                    <span class="text" for="multiple-region"> Has Regions? </span>
                                </label>
                                <label class="checkbox-wrap">
                                    <label class="checkbox1" for="Option1">
                                        <input id="Option1" type="checkbox" formControlName="xHasProps">
                                        <span></span>
                                    </label>
                                    <span for="multiple-properties" class="text">
                                        Has Properties?
                                    </span>
                                </label>
                                <label class="checkbox-wrap">
                                    <label class="checkbox1" for="Option3">
                                        <input id="Option3" type="checkbox" formControlName="xHasMulStores">
                                        <span></span>
                                    </label>
                                    <span for="multiple-region" class="text"> Has Multiple Stores? </span>
                                </label>
                                <label class="checkbox-wrap">
                                    <label class="checkbox1" for="Option4">
                                        <input id="Option4" type="checkbox" formControlName="xHasMulPCtrs">
                                        <span></span>
                                    </label>
                                    <span for="multiple-region" class="text"> Has Multiple Profit Centers? </span>
                                </label>
                            </div>
                            <div class="info-right" formGroupName="xLimitCaps">
                                <div class="p-b-10">
                                    <label for="max_tip_amt">Max Tip Amount
                                        <!-- <small class="text-danger">*</small> -->
                                    </label>
                                    <small *ngIf="!serverErrors['xLimitCaps.xMaxTipAmt'] && form.get('xLimitCaps').get('xMaxTipAmt').hasError('required') && form.get('xLimitCaps').get('xMaxTipAmt').touched"
                                        class="inline-error">
                                        <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Tip Amount is required" placement="top" containerClass="tooltip-custom inline-error"
                                            aria-hidden="true"></i>

                                    </small>

                                    <small *ngIf="serverErrors['xLimitCaps.xMaxTipAmt']" class="inline-error">
                                        <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Tip Amount is required" placement="top" containerClass="tooltip-custom inline-error"
                                            aria-hidden="true"></i>
                                    </small>

                                    <input type="number" class="form-control form-control-sm" id="max_tip_amt" (focus)="clearErrors('xLimitCaps.xMaxTipAmt')"
                                        formControlName="xMaxTipAmt" [ngClass]="{'has-error': (form.get('xLimitCaps').get('xMaxTipAmt').hasError('required') && form.get('xLimitCaps').get('xMaxTipAmt').touched) || serverErrors['xLimitCaps.xMaxTipAmt']}"
                                        validateOnBlur>
                                </div>

                                <div class="p-b-10">
                                    <label for="max_tip_rate">Max Tip Rate
                                        <!-- <small class="text-danger">*</small> -->
                                    </label>
                                    <small *ngIf="!serverErrors['xLimitCaps.xMaxTipRate'] && form.get('xLimitCaps').get('xMaxTipRate').hasError('required') && form.get('xLimitCaps').get('xMaxTipRate').touched"
                                        class="inline-error">
                                        <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Tip Rate is required" placement="top" containerClass="tooltip-custom inline-error"
                                            aria-hidden="true"></i>

                                    </small>

                                    <small *ngIf="serverErrors['xLimitCaps.xMaxTipRate']" class="inline-error">
                                        <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Tip Rate is required" placement="top" containerClass="tooltip-custom inline-error"
                                            aria-hidden="true"></i>
                                    </small>

                                    <input type="number" class="form-control form-control-sm" id="max_tip_rate" (focus)="clearErrors('xLimitCaps.xMaxTipRate')"
                                        formControlName="xMaxTipRate" [ngClass]="{'has-error': (form.get('xLimitCaps').get('xMaxTipRate').hasError('required') && form.get('xLimitCaps').get('xMaxTipRate').touched) || serverErrors['xLimitCaps.xMaxTipRate']}"
                                        validateOnBlur>
                                </div>

                                <div class="p-b-5">
                                    <label for="max_auth_amt">Max Auth Amount
                                        <!-- <small class="text-danger">*</small> -->
                                    </label>
                                    <small *ngIf="!serverErrors['xLimitCaps.xMaxAuthAmt'] && form.get('xLimitCaps').get('xMaxAuthAmt').hasError('required') && form.get('xLimitCaps').get('xMaxAuthAmt').touched"
                                        class="inline-error">
                                        <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Auth amount is required" placement="top" containerClass="tooltip-custom inline-error"
                                            aria-hidden="true"></i>

                                    </small>

                                    <small *ngIf="serverErrors['xLimitCaps.xMaxAuthAmt']" class="inline-error">
                                        <i class="fa fa-exclamation-triangle inline-error" container="body" tooltip="Auth amount is required" placement="top" containerClass="tooltip-custom inline-error"
                                            aria-hidden="true"></i>
                                    </small>
                                    <input type="number" class="form-control form-control-sm" id="max_auth_amt" (focus)="clearErrors('xLimitCaps.xMaxAuthAmt')"
                                        formControlName="xMaxAuthAmt" [ngClass]="{'has-error': (form.get('xLimitCaps').get('xMaxAuthAmt').hasError('required') && form.get('xLimitCaps').get('xMaxAuthAmt').touched) || serverErrors['xLimitCaps.xMaxAuthAmt']}"
                                        validateOnBlur>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <app-main-contact-row [mainContactForm]=form [mainContact]=setupBusinessInformationModel.xMainContact [serverErrors]=serverErrors
                        [tagType]=setupBusinessInformationModel.xTypeTag>
                    </app-main-contact-row>
                </div>
                <div>
                    <app-address [address]=setupBusinessInformationModel.xAddr [addressForm]=form [serverErrors]=serverErrors [tagType]=setupBusinessInformationModel.xTypeTag></app-address>

                </div>

            </div>
        </div>

        <div class="row  m-t-5" *ngIf='!(parentMode || userHandlerService?.userRoles?.isBusinessEmployee)'>
            <div class="col-sm-12 text-right">
                <!-- <a class="btn btn-secondary" (click)="routeToPrevious()"><i class="fa fa-times" aria-hidden="true"></i>  Cancel</a> -->
                <!--<a class="btn btn-secondary" [routerLink]="['/dashboard/setup/business/information']" [routerLinkActive]="['router-link-active']"><i class="fa fa-times" aria-hidden="true"></i>  Cancel</a>-->
                <button *ngIf = "!(setupBusinessInformationModel?.xTypeTag==='biztype.iscomp')" class="btn btn-secondary" (click)="onCancel($event)">
                    <i class="fa fa-times" aria-hidden="true"></i> Cancel</button>
                <span 
                    class="tooltip-wrap-span" 
                    [tooltip]="(edit && !canEdit) || (!edit && !canAdd) ? noPermMsg : 'Save'" 
                    placement="bottom" 
                    container="body" 
                    containerClass="tooltip-global"
                >
                    <button *ngIf = "!(setupBusinessInformationModel?.xTypeTag==='biztype.iscomp')" class="btn btn-primary" name="next" type="submit" [disabled]="submitting || (edit && !canEdit) || (!edit && !canAdd)">
                        <span *ngIf="!submitting"> Save
                            <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                        </span>
                        <span *ngIf="submitting">Please wait...
                            <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
                        </span>
                    </button>
                </span>
            </div>
        </div>
        </div>
    </form>
    <div class="row  m-t-5" *ngIf="parentMode && !userHandlerService?.userRoles?.isBusinessEmployee && !(setupBusinessInformationModel?.xTypeTag==='biztype.iscomp')">
        <div class="col-sm-12 text-right">
            <!-- <a class="btn btn-secondary" (click)="routeToPrevious()"><i class="fa fa-times" aria-hidden="true"></i>  Cancel</a> -->
            <!--<a class="btn btn-secondary" [routerLink]="['/dashboard/setup/business/information']" [routerLinkActive]="['router-link-active']"><i class="fa fa-times" aria-hidden="true"></i>  Cancel</a>-->
            <button class="btn btn-primary" (click)="onEditClick($event)">
                <i class="fa fa-pencil" aria-hidden="true"></i> Edit</button>
        </div>
    </div>
</section>
<section *ngIf="edit && loading">
    <div style="text-align: center; position:realtive">
        <div class="col-md-12">
            <!-- <i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw" style="color:#445d75;position:relative; top:50px; text-align:Center"></i>
            <span class="sr-only">Loading Tags...</span> -->
            <div class="grid-spinner">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
            </div>
            <span style="color:#3b5877">Fetching data</span>
        </div>
    </div>
</section>