import { Component, Input, OnInit, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges, OnDestroy, TemplateRef } from '@angular/core';
// import { TreeComponent /*, TreeNode*/ } from 'angular-tree-component';
import { TreeComponent /*, TreeNode*/ } from '@circlon/angular-tree-component';
import { Router } from '@angular/router';
// import { TreeNode, TreeModel, TREE_ACTIONS, KEYS, IActionMapping, ITreeOptions } from 'angular-tree-component';
import { TreeNode, TreeModel, TREE_ACTIONS, KEYS, IActionMapping, ITreeOptions } from '@circlon/angular-tree-component';
import { BehaviorSubject } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { takeWhile, debounceTime, skip } from 'rxjs/operators';
import {
    BusinessTreeStore,
    SetupBusinessStructureStore,
    SetupBusinessInformationStore, SetupStructureTerminalStore
} from '../../../store';
import {
    BusinessService,
    UserHandlerService,
    NotificationService,
    StoreService,
    TabStructureService
} from "../../../shared/services";
import { TreeService } from "../../../shared/services/tree-service";
import { Business, Structs } from "../../../models";
import { TAGSCONSTS } from "../../../constants/tags.constants";
import { customSwal } from '../../../constants';
import { validateFor } from '../../../common-functions';
import { BE_KEY } from 'app/constants';

const actionMapping: IActionMapping = {
    mouse: {
        dblClick: (tree, node, $event) => {
            if (node.hasChildren) {
                TREE_ACTIONS.TOGGLE_EXPANDED(tree, node, $event);
            }
        },
        click: (tree, node, $event) => {
            $event.shiftKey ? TREE_ACTIONS.TOGGLE_ACTIVE_MULTI(tree, node, $event) : TREE_ACTIONS.TOGGLE_SELECTED(tree, node, $event);
        }
    }
};

const strLength = 50;
const nodeName = {
    Business: ['biz.reg', 'biz.comp', 'biz.ven'],
    Chain: ['stru.chain'],
    Region: ['stru.region'],
    Property: ['stru.prop'],
    Store: ['sto', 'sto.biz'],
    ProfitCenter: ['pctr'],
    MerchantAccount: ['meracct'],
    Terminal: ['dev'],
    Peripheral: ['peri']
};
const TYPE_TERMINAL = 'terminal';

@Component({
    selector: 'tree-cmp',
    templateUrl: 'tree.component.html'
})
export class BusinessTreeComponent implements OnInit, OnChanges, OnDestroy {
    modalRef?: BsModalRef; // Modal for Registering device
    @ViewChild('tree')
    public tree: TreeComponent;
    // @ViewChild('NgxAsidePanelLeft') ngxAsidePanelLeft: any;

    @Output()
    nodeClicked: EventEmitter<any> = new EventEmitter<any>();
    @Output()
    buttonClicked: EventEmitter<any> = new EventEmitter<any>();
    @Output()
    treeLoad: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output()
    stopClick: EventEmitter<object> = new EventEmitter<object>();
    @Input()
    addAction: boolean;
    @Input()
    nodeLinks: any;
    @Input()
    buttonLinks: any;
    @Input()
    businessInfo: any;
    @Input()
    showUptoStore: boolean = false;
    @Input()
    showUpToTerminal: boolean = false;
    @Input()
    refreshTree: boolean = false;
    @Input()
    allowSpecificNodeClick: string[] = [];

    hasData: boolean = false;
    structure: any[] = [];
    params: any = {};
    nodeId: number; // new variable
    visible: boolean;
    nodePath: any[] = []; // To maintain the path to add-button node
    nodeDetails: any = {}; // To maintain the details of add-button node
    nodes: any[];
    businessID: string = '';
    businessData: Business;
    firstStoreOccurance: boolean = true; // To maintain the occurance of first store node [for setting initial store]
    currNode: any[];
    public addBtns: any[];
    public tooltipArray: any = {
        property: 'Add Store',
        store: 'Add Profit Center',
        profit_center: 'Add Merchant Account',
        merchant_account: 'Add terminal',
        terminal: 'Add Peripheral'
    };
    filterValue: BehaviorSubject<string> = new BehaviorSubject<string>('');
    watchFilterValue$ = this.filterValue.asObservable();

    private alive: boolean = true;
    private count = 0;
    private serialNumLength: number = 0;
    terminalTypeList: any[] = [
        {value: 'Ingenico'},
        {value: 'Pax'},
        {value: 'Dejavoo'},
        {value: 'WinPDC'},
        {value: 'M6Plus'},
    ];

    customTemplateStringOptions: ITreeOptions = {
        actionMapping,
        useVirtualScroll: true,
        nodeHeight: 36,
        dropSlotHeight: 2
        // animateExpand: true,
        // animateSpeed: 100,
        // animateAcceleration: 3 (new) || 1.2 (initial)
    };
    public showTree: boolean = false;

    currentState: any;
    private xGMID: string;
    private xTerminalId: string;
    private xActualSerialNumber: string;
    private xTerminalType: string;

    constructor(
        private router: Router,
        protected businessTreeStore: BusinessTreeStore,
        private setupBusinessStructureStore: SetupBusinessStructureStore,
        private businessService: BusinessService,
        private treeService: TreeService,
        public userHandlerService: UserHandlerService,
        private notificationService: NotificationService,
        private setupBusinessInformationStore: SetupBusinessInformationStore,
        private storeService: StoreService,
        private tabStructureService: TabStructureService,
        private modalService: BsModalService,
    ) {
        this.nodes = [];
        this.visible = true;
    }
    ngOnInit() {
        this.watchFilterValue$
            .pipe(
                takeWhile(() => this.alive),
                debounceTime(500)
            )
            .subscribe((text: string) => {
                if (this.tree) {
                    // this.tree.treeModel.filterNodes(text);
                    this.tree.treeModel.clearFilter();
                    if (text === '') {
                        this.tree.treeModel.collapseAll();
                        // this.tree.treeModel.expandAll();//expand all nodes
                        return;
                    }
                    let foundResults: TreeNode[] = [];
                    this.tree.treeModel.filterNodes((node: TreeNode) => {
                        const nodeName = node.data.name.toLowerCase();
                        const searchValue = text.toLowerCase();
                        const nodeFound = nodeName.includes(searchValue);
                        if (nodeFound && node.hasChildren) {
                            foundResults.push(node);
                        }
                        return nodeFound;
                    });
                    foundResults.forEach((item) => {
                        this.expandNodeAndChildren(item);
                    });
                }
            });
        this.treeService.watchCurrentSelectedNode$
            .pipe(
                takeWhile(() => this.alive),
                skip(1)
            )
            .subscribe(currentNode => {
                if (currentNode && currentNode.id) {
                    if (this.tree) {
                        const tn = this.tree.treeModel.getNodeById(currentNode.id);
                        if (tn) {
                            tn.ensureVisible();
                        }
                    }
                }
            });
        if (this.userHandlerService.userRoles && !this.userHandlerService.userRoles.isVendor) {
            this.businessService.watchBusinessID$
                .pipe(
                    takeWhile(() => this.alive),
                    skip(1)
                )
                .subscribe((bizId: string) => {
                    // console.log(bizId, "BUSINESS ID CHANGED in tree!!", this.skipTreeLoad);
                    console.log(bizId, 'BUSINESS ID CHANGED in tree!!', this.treeService.skipTreeLoad);
                    this.firstStoreOccurance = true;
                    // if (this.skipTreeLoad) {
                    if (this.treeService.skipTreeLoad) {
                        console.log('TREE COMPONENT : SKIP LOAD');
                        // this.skipTreeLoad = false;
                        return;
                    }
                    if (bizId) {
                        this.fetchBusinessInformation(bizId);
                    } else {
                        this.nodes = [];
                        this.hasData = false;
                        return;
                        // this.fetchStructure(bizId);
                    }
                });
        }

        this.businessService.watchRefreshTree$
            .pipe(
                takeWhile(() => this.alive),
                skip(1)
            )
            .subscribe((refreshTree: boolean) => {
                if (refreshTree) {
                    if (this.businessService.businessID.getValue()) {
                        // this.treeService.setId(this.businessService.businessID.getValue(),'business');
                        // this.setupBusinessStructureStore.setNodePath([]);
                        this.fetchBusinessInformation(this.businessID);
                    }
                }
            });

        if (this.businessService.businessID.getValue()) {
            // if (this.skipTreeLoad){
            //     console.log('TREE COMPONENT : SKIP LOAD')
            //     return;
            // }
            console.log('Inside tree component showUptoStore', this.showUptoStore);
            this.treeService.setId(this.businessService.businessID.getValue(), 'business');
            this.fetchBusinessInformation(this.businessService.businessID.getValue()); // for initial tree-generation
        }
    }

    expandNodeAndChildren(node: TreeNode) {
        node.show();
        node.ensureVisible();
        node.children.forEach((child) => {
            this.expandNodeAndChildren(child);
        });
    }

    fetchBusinessInformation(businessId: string): void {
        console.log('Inside tree', businessId);
        this.setupBusinessInformationStore.get(businessId).subscribe(
            (response: any) => {
                this.businessData = response.data;
                this.fetchStructure(businessId);
            },
            (error: any) => {
                const errMsg = JSON.parse(error._body).message;
                this.notificationService.error(errMsg, businessId + ' Not Found!');
            }
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        console.log('ngonchages called', changes);
    }

    ngAfterViewInit(): void {
        console.log('afterview init called');
        this.treeService.watchShowTree$
            .pipe(
                takeWhile(() => this.alive),
                skip(1)
            )
            .subscribe((show: boolean) => {
                this.showTree = show;
            });
    }

    sortNode(node: any){
        if(node instanceof Array && node.length > 1){
            node = node.sort((a, b) =>{
                const _a = a.name.toLowerCase();
                const _b = b.name.toLowerCase();
                if(_a >_b){
                    return 1;
                }else if(_a <_b){
                    return -1;
                }else {
                    return 0;
                }
            });
        }
        
        if(node instanceof Array){
            node = node.map((item,index)=>{
                if(item && item.children && item.children.length){
                    return this.sortNode(item.children);
                }
            });
        }
        return node;
    }

    fetchStructure(bizID: string) {
        let time = new Date();
        this.visible = false;
        console.log('start building', time.getMinutes(), time.getSeconds());
        this.businessID = bizID;
        if (this.businessID && this.businessID.length) {
            this.params.id = this.businessID;
        }
        this.businessTreeStore.getStructure(this.params).subscribe(
            (response: any) => {
                console.log('Inside TreeComponent, getStructure, subscribe Success func', response);
                this.structure = response;
            },
            (error: any) => {
                console.log('Inside TreeComponent, subscribe Failure func', error);
            },
            () => {
                if (!this.structure.length) {
                    this.nodes = [];
                    this.hasData = false;
                } else {
                    this.populateTreeStructure(this.structure[0]);
                    this.sortNode(this.nodes);
                    let time1 = new Date();
                    console.log('finish building', time.getMinutes(), time.getSeconds());
                    this.hasData = true;
                    // console.log(
                    //     "has data true",
                    //     time.getMinutes(),
                    //     time.getSeconds()
                    // );
                    this.treeLoad.emit(true);
                    // console.log(
                    //     "treeload emit",
                    //     time.getMinutes(),
                    //     time.getSeconds()
                    // );
                }
                // console.log(
                //     "visible going to be true",
                //     time.getMinutes(),
                //     time.getSeconds()
                // );
                this.visible = true;
                // console.log(
                //     "visible is true",
                //     time.getMinutes(),
                //     time.getSeconds()
                // );
            }
        );
    }

    onTreeInitialize(event) {
        if (this.treeService.currentSelectedNode.getValue() && this.treeService.currentSelectedNode.getValue().id) {
            if (this.tree) {
                const tn = this.tree.treeModel.getNodeById(this.treeService.currentSelectedNode.getValue().id);
                if (tn) {
                    tn.ensureVisible();
                }
            }
        }
    }

    toggleShowTree() {
        this.treeService.setShow(!this.showTree);
    }

    populateTreeStructure(node: any) {
        // console.log("Node------------->", node);
        if (this.showUptoStore) {
            // console.log('populateTreeStructure: node', node);
            // console.log('populateTreeStructure: this.storeService.getAccessStoreIDs()', this.storeService.getAccessStoreIDs());
            // console.log('populateTreeStructure: this.storeService.getAccessStoreStatus()', this.storeService.getAccessStoreStatus());
            if (node && (node.xTypeTag === 'stotype.reg' || node.xTypeTag === 'stotype.iscomp' || !node.children)) {
                //base case when tree is to be shown upto store
                if (this.storeService.getAccessStoreIDs().indexOf(node.id) !== -1) {
                    node.accessStore = true;
                }
                return;
            }
        } else {
            if (this.userHandlerService.userRoles && this.userHandlerService.userRoles.isBusinessEmployee) {
                //case for isBusinessEmp
                if (node && (node.xTypeTag === 'stotype.reg' || node.xTypeTag === 'stotype.iscomp' || !node.children)) {
                    //case for node to be store or has no children
                    if (this.storeService.getAccessStoreIDs().indexOf(node.id) === -1) {
                        // case for store is not accessible by that employee
                        return;
                    } else if (this.storeService.getAccessStoreStatus()[this.storeService.getAccessStoreIDs().indexOf(node.id)] === 0) {
                        //case for store is accessible by that employee, but its status is 0 (Inactive)
                        return;
                    }
                    node.accessStore = true;
                    console.log('populateTreeStructure: node', node);
                }
            }
            if (!node.children) {
                //base case when full tree is to be shown
                return;
            }
        }

        if (node && node.children && !node.children.length && node._id === this.params.id) {
            //base case when structure in empty and is to be initailised
            let treeNode = this.modifyNode(node);
            if (this.addAction) {
                // console.log('One==========>');
                // treeNode = this.appendAddOptions(treeNode);
            }
            this.nodes = [treeNode];
            return;
        }

        node.children = node.children.filter((childNode: any) => {
            // console.log("ChildNode", childNode);
            if (
                this.userHandlerService.userRoles.isBusinessEmployee &&
                childNode &&
                (childNode.xTypeTag === 'stotype.reg' || childNode.xTypeTag === 'stotype.iscomp') &&
                this.storeService.getAccessStoreIDs().indexOf(childNode._id) === -1
            ) {
                // return no child for stores that are not accessible by business emp
                return;
            }
            if (
                this.userHandlerService.userRoles.isBusinessEmployee &&
                childNode &&
                (childNode.xTypeTag === 'stotype.reg' || childNode.xTypeTag === 'stotype.iscomp') &&
                this.storeService.getAccessStoreStatus()[this.storeService.getAccessStoreIDs().indexOf(childNode._id)] === 0
            ) {
                //return no child for stores that are accessible by business emp, but its status is 0 (Inactive)
                return;
            } else {
                // store accessible  by the emp
                childNode = this.modifyNode(childNode);
                this.populateTreeStructure(childNode);
                if (this.showUptoStore) {
                    // store accessible by emp but showUptoStore is true
                    if (childNode.xTypeTag === 'stotype.reg' || childNode.xTypeTag === 'stotype.iscomp') {
                        //base case when tree is to be shown upto store
                        childNode.children = [];
                    }
                }else if(this.showUpToTerminal){
                    // console.log('entered here: showing up to terminal');
                    if (childNode.xTypeTag === TAGSCONSTS.terminalTags.regularTerminal) {
                        childNode.children = [];
                    }
                }else{
                    // console.log('show all nodes');
                }
            }
            return childNode;
        });
        let treeNode = this.modifyNode(node);
        if (this.addAction) {
            // treeNode = this.appendAddOptions(treeNode);
        }
        this.nodes = [treeNode];
        let currentTime = new Date();
        // console.log(
        //     "tree nodes",
        //     this.nodes,
        //     "count",
        //     this.count++,
        //     "time",
        //     currentTime.getMinutes(),
        //     currentTime.getSeconds()
        // );
    }

    appendAddOptions(node: any): any {
        if (this.userHandlerService.userRoles && this.userHandlerService.userRoles.isBusinessEmployee) {
            return node;
        } else {
            const appendChildren = this.createAddButtonArray(node);
            appendChildren.map((child: any) => node.children.push(child));
            return node;
        }
    }

    modifyNode(node: any): any {
        // console.log("node inside modify node", node);
        const type: string = this.getType(node);
        let isLeafNode: boolean = false;

        if (this.showUptoStore) {
            if (node.xTypeTag === 'stotype.reg' || node.xTypeTag === 'stotype.iscomp') {
                //base case when tree is to be shown upto store
                node.children = [];
            }
        } else {
            if (this.userHandlerService.userRoles.isBusinessEmployee) {
                // case when business emp is logged in
                if (node.xTypeTag === 'stotype.reg' || node.xTypeTag === 'stotype.iscomp') {
                    //case when node is store
                    if (this.storeService.getAccessStoreIDs().indexOf(node._id) === -1) {
                        //case when node is not present in accessStoreIds
                        node.children = [];
                    } else if (this.storeService.getAccessStoreStatus()[this.storeService.getAccessStoreIDs().indexOf(node._id)] === 0) {
                        //case when node is present in accessStoreIds, but its status is 0 (Inactive)
                        node.children = [];
                    }
                }
            }
        }
        if (node['children'] && node.children.length) {
            isLeafNode = false;
        } else {
            isLeafNode = true;
        }

        node.id = node._id;
        node.name = node.xName;
        node.onClick = !!this.nodeLinks[type] ? this.nodeLinks[type] : 'Not-Yet-Set';
        node.iconlink = !!this.buttonLinks[type] ? this.buttonLinks[type] : 'Not-Yet-Set';
        //TODO-: Chage to edit link when available
        node.editLink = !!this.buttonLinks[type] ? this.buttonLinks[type] : 'Not-Yet-Set';
        node.hasChildren = true;
        node.children = node['children'] ? node.children : [];
        if (this.addAction) {
            node.isExpanded = this.getPreviousState(node);
        } else {
            node.isExpanded = !isLeafNode ? true : false;
        }
        node.type = type;
        node.canHaveChild = this.getAvailableTypes(type, this.businessData);
        return node;
    }

    createAddButtonArray(node: any): any[] {
        console.log('This is node===>', node);
        let addBtnArray: any[] = [];
        switch (node.type) {
            case 'business':
                console.log('Business Data', this.businessData);
                addBtnArray = this.fillAddButtonArray(this.getAvailableTypes('business', this.businessData));
                break;
            case 'chain':
                addBtnArray = this.fillAddButtonArray(this.getAvailableTypes('chain', this.businessData));
                break;
            case 'region':
                addBtnArray = this.fillAddButtonArray(this.getAvailableTypes('region', this.businessData));
                break;
            case 'property':
                addBtnArray = this.fillAddButtonArray(this.getAvailableTypes('property', this.businessData));
                break;
            case 'store':
                addBtnArray = this.fillAddButtonArray(this.getAvailableTypes('store', this.businessData));
                break;

            //********** new code as per new hierarchy start **********
            case 'profit_center':
                addBtnArray = this.fillAddButtonArray(this.getAvailableTypes('profit_center', this.businessData));
                break;
            case 'merchant_account':
                addBtnArray = this.fillAddButtonArray(this.getAvailableTypes('merchant_account', this.businessData));
                break;
            case 'terminal':
                addBtnArray = this.fillAddButtonArray(this.getAvailableTypes('terminal', this.businessData));
                break;
            //********** new code as per new hierarchy end **********

            default:
                break;
        }
        return addBtnArray;
    }

    //Returns array of entity which can be added based upon business xStructs permission
    getAvailableTypes(type: string, { xStructCaps }: { xStructCaps: Structs }): string[] {
        let types = [];
        switch (type) {
            case 'business':
                if (xStructCaps.xHasChains) {
                    types.push('chain');
                }
                if (xStructCaps.xHasRegions) {
                    types.push('region');
                }
                if (xStructCaps.xHasProps) {
                    types.push('property');
                }
                if (xStructCaps.xHasMulStores) {
                    types.push('store');
                }
                break;
            case 'chain':
                if (xStructCaps.xHasRegions) {
                    types.push('region');
                }
                if (xStructCaps.xHasProps) {
                    types.push('property');
                }
                if (xStructCaps.xHasMulStores) {
                    types.push('store');
                }
                break;
            case 'region':
                if (xStructCaps.xHasProps) {
                    types.push('property');
                }
                if (xStructCaps.xHasMulStores) {
                    types.push('store');
                }
                break;
            case 'property':
                if (xStructCaps.xHasMulStores) {
                    types.push('store');
                }
                break;
            case 'store':
                // types = ['merchant_account', 'terminal', 'peripheral'];
                if (xStructCaps.xHasMulPCtrs) {
                    // if(true){
                    types.push('profit_center');
                }
                break;

            //********** new code as per new hierarchy start **********
            case 'profit_center':
                types.push('merchant_account');
                break;
            case 'merchant_account':
                types.push('terminal');
                break;
            case 'terminal':
                types.push('peripheral');
                break;
            //********** new code as per new hierarchy end **********

            default:
                break;
        }
        //TODO for now store is mandatory
        // if (
        //     type === "store" ||
        //     type === "profit_center" ||
        //     type === "merchant_account" ||
        //     type === "terminal" ||
        //     type === "peripheral"
        // ) {
        // } else {
        //     types.push("store");
        // }
        return types;
    }

    fillAddButtonArray(arr: any[]): any[] {
        console.log('ARRRRRR', arr);
        let addBtnArray: any[] = [];
        addBtnArray = arr.map((type: string) => {
            switch (type) {
                case 'chain':
                    return {
                        id: ++this.nodeId,
                        name: 'Chain',
                        btnlink: this.buttonLinks.chain ? this.buttonLinks.chain : 'Not-Yet-Set',
                        type: 'chain',
                        btn: true
                    };
                case 'region':
                    return {
                        id: ++this.nodeId,
                        name: 'Region',
                        btnlink: this.buttonLinks.region ? this.buttonLinks.region : 'Not-Yet-Set',
                        type: 'region',
                        btn: true
                    };
                case 'property':
                    return {
                        id: ++this.nodeId,
                        name: 'Property',
                        btnlink: this.buttonLinks.property ? this.buttonLinks.property : 'Not-Yet-Set',
                        type: 'property',
                        btn: true
                    };
                case 'store':
                    return {
                        id: ++this.nodeId,
                        name: 'Store',
                        btnlink: this.buttonLinks.store ? this.buttonLinks.store : 'Not-Yet-Set',
                        type: 'store',
                        btn: true
                    };
                case 'merchant_account':
                    return {
                        id: ++this.nodeId,
                        name: 'Merchant Account',
                        btnlink: this.buttonLinks.merchant_account ? this.buttonLinks.merchant_account : 'Not-Yet-Set',
                        type: 'merchant_account',
                        btn: true
                    };
                case 'profit_center':
                    return {
                        id: ++this.nodeId,
                        name: 'Profit Center',
                        btnlink: this.buttonLinks.profit_center ? this.buttonLinks.profit_center : 'Not-Yet-Set',
                        type: 'profit_center',
                        btn: true
                    };
                case 'terminal':
                    return {
                        id: ++this.nodeId,
                        name: 'Terminal',
                        btnlink: this.buttonLinks.terminal ? this.buttonLinks.terminal : 'Not-Yet-Set',
                        type: 'terminal',
                        btn: true
                    };
                case 'peripheral':
                    return {
                        id: ++this.nodeId,
                        name: 'Peripheral',
                        btnlink: this.buttonLinks.peripheral ? this.buttonLinks.peripheral : 'Not-Yet-Set',
                        type: 'peripheral',
                        btn: true
                    };
                default:
                    return {};
            }
        });
        return addBtnArray;
    }

    getType(node: any): string {
        let nodeType: string = '';
        //TODO-: Assign the business ID here for which the structure is to be fetched.
        // let businessID  = '58c7449b9266a875a4df8c2d';
        if (node._id === this.businessID) {
            // console.log("here hahaha");
            nodeType = 'business';
            this.count = 0;
        } else {
            switch (node.xTypeTag) {
                case TAGSCONSTS.structTags.chainTags:
                    nodeType = 'chain';
                    break;
                case TAGSCONSTS.structTags.regionTags:
                    nodeType = 'region';
                    break;
                case TAGSCONSTS.structTags.propertyTags:
                    nodeType = 'property';
                    break;
                // case 'sto':
                //     nodeType = 'store';
                //     break;
                case TAGSCONSTS.storeTags.regularStore:
                    nodeType = 'store';
                    break;
                case TAGSCONSTS.storeTags.companyBizStore:
                    nodeType = 'store';
                    break;
                case TAGSCONSTS.profitCenterTags.regularPCtr:
                    nodeType = 'profit_center';
                    break;
                case TAGSCONSTS.merchantAccountTags.regularMerAcct:
                    nodeType = 'merchant_account';
                    break;
                case TAGSCONSTS.terminalTags.regularTerminal:
                    nodeType = 'terminal';
                    break;
                case TAGSCONSTS.peripheralTags.regularPeripheral:
                    nodeType = 'peripheral';
                    break;
                default:
                    break;
            }
        }
        return nodeType;
    }

    handleNodeClick(node: any, addBtnClick: boolean = false): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.nodeDetails.nodeType = node.data.type;
            this.nodeDetails.nodeId = node.id;

            this.tabStructureService.setParentsFetched(false);
            this.tabStructureService.storeDefTabStruct = true;
            if (!addBtnClick) {
                this.tabStructureService.addNodeBtnClick = false;
            } else {
                this.tabStructureService.addNodeBtnClick = true;
            }
            this.treeService.nodeClick = true;
            this.tabStructureService.tabMode = false;
            this.tabStructureService.editMode = false;
            if (node && node.data.type === 'business') {
                this.treeService.setId(node.id, 'business');
            } else if (node && node.data.type === 'chain') {
                this.treeService.setId(node.id, 'chain');
            } else if (node && node.data.type === 'region') {
                this.treeService.setId(node.id, 'region');
            } else if (node && node.data.type === 'property') {
                this.treeService.setId(node.id, 'property');
            } else if (node && node.data.type === 'store') {
                this.treeService.setId(node.id, 'store');
            } else if (node && node.data.type === 'profit_center') {
                this.treeService.setId(node.id, 'profit_center');
            } else if (node && node.data.type === 'merchant_account') {
                this.treeService.setId(node.id, 'merchant_account');
            } else if (node && node.data.type === 'terminal') {
                this.treeService.setId(node.id, 'terminal');
            } else if (node && node.data.type === 'peripheral') {
                this.treeService.setId(node.id, 'peripheral');
            }
            node.treeModel.loadState;
            node.treeModel._loadState;
            this.nodes = [].concat(this.nodes);
            // this.tree.treeModel.update();
            // node.treeModel.update();
            return resolve(true);
        });
    }

    onNodeClick(node: any): void {
        console.log('onNodeClick: allowSpecificNodeClick', this.allowSpecificNodeClick);
        console.log('onNodeClick: on click node', node);
        this.treeService.currentClickedNodeData = validateFor(BE_KEY.data, node) ? node.data: {};
        if (this.allowSpecificNodeClick.length) {
            console.log('onNodeClick: entered allowSpecificNodeClick');
            if (node.hasOwnProperty('data') && node.data && node.data.hasOwnProperty('type') && node.data.type) {
                if (!this.allowSpecificNodeClick.includes(node.data.type)) {
                    this.stopClick.emit({ nodeType: node.data.type, nodeId: node.id });
                    return;
                }
            }
        }
        this.handleNodeClick(node).then(val => {
            console.log('Ye hai node ki value========>', node);
            this.tabStructureService.currentNodeName = node.data.name;
            this.nodePath = [];
            this.generatePathNode(node);
            this.setupBusinessStructureStore.setNodePath(this.nodePath);
            this.nodeClicked.emit(this.nodeDetails);
            // })
        });
    }

    onButtonClick(node: any): void {
        console.log('Inside onButtonClick', node);
        //********** new code as per new hierarchy start **********
        if (node.data.canHaveChild.length === 0) {
            let childNode = '';
            switch (node.data.type) {
                case 'business':
                    childNode = 'chain, region, property or multiple stores';
                    break;
                case 'chain':
                    childNode = 'region, property or multiple stores';
                    break;
                case 'region':
                    childNode = 'property or multiple stores';
                    break;
                case 'property':
                    childNode = 'multiple stores';
                    break;
                case 'store':
                    childNode = 'multiple profit centers';
                    break;
            }
            customSwal.fire({
                title:`Action not allowed`,
                text: 'Selected business can not have ' + childNode,
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: 'Enable',
                cancelButtonText: 'Okay',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.router.navigate([
                        'dashboard/setup/business/structure/information',
                        this.businessService.businessID.getValue()
                    ]);
                }
            })
        } else {
            this.currNode = node;

            // if (
            //     node.data.type === 'property' ||
            //     node.data.type === 'store' ||
            //     node.data.type === 'profit_center' ||
            //     node.data.type === 'merchant_account' ||
            //     node.data.type === 'terminal'
            // ) {
            //     let addBtnArray = this.createAddButtonArray(node.data).map(child => {
            //         return child;
            //     });
            //     console.log('addBtnArray ki value', addBtnArray);
            //     this.onAddButtonClick(addBtnArray[0]);
            // } else {
            this.addBtns = this.createAddButtonArray(node.data).map(child => {
                return child;
            });
        }
        console.log('Add Buttons', this.addBtns);
        // }
        //********** new code as per new hierarchy end **********

        // let nodeType = node.data.type;
        // console.log('subscription complete func new', JSON.parse(JSON.stringify(this.structure)));
        // this.treeService.setCurrentTreestate(this.currentState);
        // this.nodePath = [];
        // this.generatePathNode(node);
        // this.nodeDetails.nodeType = node.data.type;
        // this.nodeDetails.nodePath = this.nodePath;
        // this.buttonClicked.emit(this.nodeDetails);
        // // this.showDrop=!this.showDrop;
    }

    //********** new code as per new hierarchy start **********
    onAddButtonClick(addNode: any, node: any) {
        // console.info(addNode, 'addNode inside onADDButtonClick');
        // console.info(node, 'node inside onADDButtonClick');
        this.handleNodeClick(node, true).then(val => {
            this.tabStructureService.currentNodeName = node.data.name;
            // this.treeService.nodeClick=true;
            // this.tabStructureService.tabMode=false;
            // this.tabStructureService.editMode=false;
            this.nodePath = [];
            this.generatePathNode(node);
            this.nodeDetails.nodeType = addNode.type;
            this.nodeDetails.nodePath = this.nodePath;
            this.setupBusinessStructureStore.setNodePath(this.nodePath);
            this.buttonClicked.emit(this.nodeDetails);
            // this.currNode=node;
        });
        // if (this.currNode) {
        //     // this.treeService.nodeClick=true;
        //     // this.tabStructureService.tabMode=false;
        //     // this.tabStructureService.editMode=false;
        //     this.nodePath = [];
        //     this.generatePathNode(this.currNode);
        //     this.nodeDetails.nodeType = addNode.type;
        //     this.nodeDetails.nodePath = this.nodePath;
        //     this.buttonClicked.emit(this.nodeDetails);
        // }
    }
    //********** new code as per new hierarchy end **********

    filterNodes(text, tree) {
        // this.tree.treeModel.nodes[0].children[0].isExpanded = true;
        console.log('tree: filterNodes--->', this.tree)
        if(
            this.tree.treeModel.nodes.length 
            && validateFor('children', this.tree.treeModel.nodes[0]) 
            && this.tree.treeModel.nodes[0].children.length ){
                this.tree.treeModel.nodes[0].children[0].isExpanded = true;
            }
        this.tree.treeModel.update();
        this.filterValue.next(text);
    }

    generatePathNode(node: any) {
        console.log('node while generation===========>', node);
        //********** new code as per new hierarchy start **********
        if (node.isRoot || node.level === 1) {
            //base case--> business node
            this.nodePath.push({
                type: 'business',
                id: node.data.id,
                name: node.data.name
            });
            return;
        }
        if (node && node.data) {
            switch (node.data.type) {
                case 'chain':
                    this.nodePath.push({
                        type: 'chain',
                        id: node.data.id,
                        name: node.data.name
                    });
                    this.generatePathNode(node.parent);
                    break;
                case 'region':
                    this.nodePath.push({
                        type: 'region',
                        id: node.data.id,
                        name: node.data.name
                    });
                    this.generatePathNode(node.parent);
                    break;
                case 'property':
                    this.nodePath.push({
                        type: 'property',
                        id: node.data.id,
                        name: node.data.name
                    });
                    this.generatePathNode(node.parent);
                    break;
                case 'store':
                    this.nodePath.push({
                        type: 'store',
                        id: node.data.id,
                        name: node.data.name
                    });
                    this.generatePathNode(node.parent);
                    break;
                case 'profit_center':
                    this.nodePath.push({
                        type: 'profit_center',
                        id: node.data.id,
                        name: node.data.name
                    });
                    this.generatePathNode(node.parent);
                    break;
                case 'merchant_account':
                    this.nodePath.push({
                        type: 'merchant_account',
                        id: node.data.id,
                        name: node.data.name
                    });
                    this.generatePathNode(node.parent);
                    break;
                case 'terminal':
                    this.nodePath.push({
                        type: 'terminal',
                        id: node.data.id,
                        name: node.data.name
                    });
                    this.generatePathNode(node.parent);
                    break;
                case 'peripheral':
                    this.nodePath.push({
                        type: 'peripheral',
                        id: node.data.id,
                        name: node.data.name
                    });
                    this.generatePathNode(node.parent);
                    break;

                default:
                    return;
            }
        }
        //********** new code as per new hierarchy end **********

        // if (node.parent.isRoot || node.parent.level === 1) {
        //     //base case--> business node
        //     this.nodePath.push({ type: 'business', id: node.parent.data.id });
        //     return;
        // }
        // if (node.parent && node.parent.data) {
        //     switch (node.parent.data.type) {
        //         case 'chain':
        //             this.nodePath.push({ type: 'chain', id: node.parent.data.id });
        //             this.generatePathNode(node.parent);
        //             break;
        //         case 'region':
        //             this.nodePath.push({ type: 'region', id: node.parent.data.id });
        //             this.generatePathNode(node.parent);
        //             break;
        //         case 'property':
        //             this.nodePath.push({ type: 'property', id: node.parent.data.id });
        //             this.generatePathNode(node.parent);
        //             break;
        //         case 'store':
        //             this.nodePath.push({ type: 'store', id: node.parent.data.id });
        //             this.generatePathNode(node.parent);
        //             break;
        //         default:
        //             return;
        //     }
        // }
    }

    onToggleEvent(event: any) {
        const node = event.node && event.node.data;
        // if (node && node.type === "store") {
        //   this.treeService.setId(node.id, "store");
        // }
        // else if (node && node.type === 'chain') {
        //     this.treeService.setId(node.id, 'chain');
        // }
        this.currentState = event.treeModel.expandedNodeIds;
        this.treeService.setCurrentTreestate(this.currentState);
    } //This function is called whenever any node is expanded or collapsed

    removeNode(node: any): void {
        node.data.isRemovable = false;
        node.data.hidden = true;
    }

    onUpdateEvent(event: any) {
        console.log('I have event as ----->', event);
        // const node = event.node && event.node.data;

        // this.tree.treeModel.update();
    }

    truncateString(value: any): any {
        if (typeof value === 'string') {
            if (value.length > strLength) {
                return value.substring(0, strLength) + '...';
            } else {
                return value;
            }
        } else {
            return value;
        }
    }

    getPreviousState(node: any): boolean {
        if (this.treeService.objExpandedNodes != null) {
            if (this.treeService.objExpandedNodes && this.treeService.objExpandedNodes.hasOwnProperty(node.id)) {
                if (this.treeService.objExpandedNodes[node.id]) {
                    return true;
                }
            }
        }
        return false;
    }
    openModal(template: TemplateRef<any>, xGMID, xTerminalId) {
        this.xGMID = xGMID;
        this.xTerminalId = xTerminalId;
        this.modalRef = this.modalService.show(template);
    }
    saveModal(xGMID, xSerialNumber, xName, xTerminalType) {
         if (xName !== '' && xSerialNumber !== '' && xGMID !== '' && xTerminalType) {
             try {
                 this.treeService.registerTerminal(xGMID, xSerialNumber, xName, xTerminalType, "","register");
             } catch (error) {
                 this.notificationService.error('Error while registering terminal ', 'Error!')
             }
         } else {
             this.notificationService.error('All fields must be filled ', 'Error!')
         }
         this.modalRef.hide();
         setTimeout(() => {
             this.ngOnInit();
         }, 1500 )
    }
    serialNumberInput(event: any) {
        this.xActualSerialNumber = event.target.value;
    }
    setSNLength(event: any) {
        switch (event.target.value) {
            case 'select':
                this.serialNumLength = 0;
                break;
            case 'Ingenico':
                this.serialNumLength = 8;
                this.xTerminalType = event.target.value;
                break;
            case 'Pax':
                this.serialNumLength = 10;
                this.xTerminalType = event.target.value;
                break;
            case 'M6Plus':
                this.serialNumLength = 10;
                this.xTerminalType = event.target.value;
                break;
            case 'Dejavoo':
                this.serialNumLength = 10;
                this.xTerminalType = event.target.value;
                break;
            case 'WinPDC':
                this.serialNumLength = 0;
                this.xTerminalType = event.target.value;
                break;
            default:
                this.serialNumLength = 8;
                this.xTerminalType = event.target.value;
                break;
        }
    }

    ngOnDestroy() {
        this.alive = false;
    }
} //TreeComponent Class ends
