<nav class="navbar navbar-toggleable-md navbar-inverse bg-primary container-fluid navbar-fixed-top">
	<button
		(click)="toggleState()"
		class="navbar-toggler navbar-toggler-right"
		type="button"
		data-toggle="collapse"
		data-target="#navbarSupportedContent"
		aria-controls="navbarSupportedContent"
		aria-expanded="false"
		aria-label="Toggle navigation"
	>
		<span class="navbar-toggler-icon"></span>
	</button>
	<a class="navbar-brand" href="javascript:void(0);" [routerLink]="['']"><img src="assets/images/cloud9-logo.png" class="topnav-img" alt="" /></a>

	<div class="collapse navbar-collapse topnav-navbar" id="navbarSupportedContent" [ngClass]="{ in: isIn }" [class.disabled]="companySuspended">
		<ul class="navbar-nav mr-auto navbar-left menu-bar">
			<li *ngIf="!isCustomer" routerLinkActive="active" class="nav-item">
				<a class="nav-link" routerLink="home"> <i class="fa fa-home" (click)="(setupHome)"></i> Home </a>
			</li>
			<li
				class="dropdown nav-item"
				dropdown
				routerLinkActive="active"
				triggers="hover"
				[ngClass]="{ 'open delay-menu-transition': isopen }"
				(mouseover)="isopen = true"
				(mouseout)="isopen = false"
				*ngIf="!isCustomer"
			>
				<a href="javascript:void(0);" class="dropdown-toggle nav-link" dropdownToggle> <i class="fa fa-cog" aria-hidden="true"></i> Setup </a>
				<div class="dropdown-menu animated fadeIn" *dropdownMenu [class.billing-dropdown]="isVendorRep">
					<ul class="splited-menu" *ngIf="(isDealerRep || isBusinessEmp) && !isVendorRep">
						<!-- Business Dropdown -->
						<li class="messages-top nav-item">Business</li>
						<li class="dropdown-messages nav-item" routerLinkActive="active" *ngIf="isDealerRep && !isBusinessEmp">
							<a [routerLink]="['setup/business/information']" class="nav-link"> List of Businesses </a>
						</li>
						<li routerLinkActive="active" class="dropdown-messages nav-item" *ngIf="isDealerRep && !isBusinessEmp">
							<a [routerLink]="['setup/business/structure/information']" class="nav-link"> Structure </a>
						</li>
						<li routerLinkActive="active" class="dropdown-messages nav-item" *ngIf="!isDealerRep && isBusinessEmp">
							<a [routerLink]="['setup/business/structure/information', bizId]" class="nav-link"> Structure </a>
						</li>
						<li routerLinkActive="active" class="dropdown-messages nav-item">
							<a routerLink="setup/business/employee" class="nav-link"> Employees </a>
						</li>
					</ul>
					<ul class="splited-menu" *ngIf="(isDealerRep || isVendorRep) && !isBusinessEmp">
						<!-- Company Dropdown -->
						<li class="messages-top nav-item">Company</li>
						<li routerLinkActive="active" class="dropdown-messages nav-item">
							<a routerLink="setup/company/information" class="nav-link"> List of Companies </a>
						</li>
						<li routerLinkActive="active" class="dropdown-messages nav-item">
							<a routerLink="setup/company/representative" class="nav-link"> Representatives </a>
						</li>
					</ul>
					<ul class="splited-menu">
						<li class="messages-top nav-item">Security</li>
						<li class="dropdown-messages nav-item" routerLinkActive="active">
							<a routerLink="coming-soon" class="nav-link"> Users </a>
						</li>
						<li routerLinkActive="active" class="dropdown-messages nav-item">
							<a routerLink="setup/security/roles" class="nav-link"> Roles </a>
						</li>
					</ul>
					<ul class="splited-menu" *ngIf="isVendorRep || isDealerRep">
						<li class="messages-top nav-item">Licensing</li>
						<li routerLinkActive="active" class="dropdown-messages nav-item">
							<a routerLink="setup/licensing/info/current" class="nav-link"> New Billing </a>
						</li>
					</ul>
				</div>
			</li>
			<li class="nav-item" routerLinkActive="active" *ngIf="!isCustomer && !isVendorRep">
				<a name="vTerminal-btn" class="nav-link" routerLink="vterminal"> <i class="fa fa-server" aria-hidden="true"></i> vTerminal </a>
			</li>
			<li class="nav-item" routerLinkActive="active" *ngIf="isCustomer">
				<a name="vTerminal-btn" class="nav-link" routerLink="customer/pay-now"> <i class="fa fa-server" aria-hidden="true"></i> Pay Now </a>
			</li>
			<li *ngIf="!isVendorRep && !isCustomer" class="nav-item" routerLinkActive="active">
				<a class="nav-link" routerLink="transactions">
					<i class="fa fa-exchange" aria-hidden="true"></i>
					Transactions
				</a>
			</li>
			<li *ngIf="isCustomer" class="nav-item" routerLinkActive="active">
				<a class="nav-link" routerLink="customer/outstanding-transactions">
					<i class="fa fa-exchange" aria-hidden="true"></i>
					Transactions
				</a>
			</li>
			<li
				class="dropdown nav-item"
				dropdown
				routerLinkActive="active"
				triggers="hover"
				[ngClass]="{ 'open delay-menu-transition': isReportsOpen }"
				(mouseover)="isReportsOpen = true"
				(mouseout)="isReportsOpen = false"
				*ngIf="!isCustomer"
			>
				<a href="javascript:void(0);" class="dropdown-toggle nav-link" dropdownToggle> <i class="fa fa-line-chart" aria-hidden="true"></i> Reports </a>
				<div class="dropdown-menu reports-dropdown animated fadeIn" *dropdownMenu>
					<ul class="splited-menu">
						<ng-container *ngIf="!isVendorRep && !isCustomer">
							<li class="messages-top nav-item">Import</li>
							<li class="dropdown-messages nav-item" routerLinkActive="active">
								<a [routerLink]="['/dashboard/import-transactions']" class="nav-link"> Import Reports </a>
							</li>
						</ng-container>
						<ng-container *ngIf="isVendorRep || isDealerRep || isBusinessEmp">
							<li class="messages-top nav-item">Billing</li>
							<li class="dropdown-messages nav-item" routerLinkActive="active">
								<a routerLink="setup/licensing/info/previous" class="nav-link"> Billing Reports </a>
							</li>
						</ng-container>
					</ul>
				</div>
			</li>
			<li *ngIf="isDealerRep || isBusinessEmp" class="nav-item" routerLinkActive="active">
				<a routerLink="setup/billing/customer" class="nav-link"> Customers </a>
			</li>
			<li *ngIf="isVendorRep" class="nav-item" routerLinkActive="active">
				<a class="nav-link" routerLink="csv-file-history">
					<i class="fa fa-history" routerLink="csv-file-history"></i>
					CSV File History
				</a>
			</li>
		</ul>
		<ul class="navbar-nav">
			<li *ngIf="isDealerRep" class="nav-item">
				<a class="nav-link business_type_text" href="javascript:void(0);">
					<span class="select-icon"><i class="fa fa-building-o"></i> </span>
					<span class="select-label">Company</span>
					{{ entityDetails?.xName }}
				</a>
			</li>
			<li *ngIf="isBusinessEmp" class="nav-item">
				<a class="nav-link business_type_text" href="javascript:void(0);">
					<span class="select-icon"><i class="fa fa-briefcase"></i> </span>
					<span class="select-label">Business</span>
					{{ entityDetails?.xName }}
				</a>
			</li>
			<li
				class="nav-item buis-info"
				*ngIf="
					isVendorRep &&
					!currentUrl.includes('company/information') &&
					!currentUrl.includes('customer/add-edit') &&
					!currentUrl.includes('information/add-edit')
				"
			>
				<div class="business_type topnav_business_type m-r-12">
					<span class="select-icon">
						<i class="fa fa-building-o"></i>
					</span>
					<span class="select-label pull-right">Company</span>
					<ng-select
						class="customSelect pull-right"
						[items]="dropDownEntityList"
						[loading]="entityListLoading"
						bindLabel="text"
						bindValue="id"
						clearSearchOnAdd="true"
						[(ngModel)]="selectedEntityId"
						(change)="onEntitySelect($event, 'company')"
						[disabled]="enableCompany"
					>
						<ng-template ng-option-tmp let-item="item" let-index="index">
							{{ item.text }}
						</ng-template>
					</ng-select>
					<span class="topnav-span"><i class="fa fa-sort-desc"></i></span>
				</div>
			</li>
			<li class="nav-item buis-info" *ngIf="isDealerRep && !currentUrl.includes('business/information')">
				<div class="business_type topnav_business_type m-r-12">
					<span class="select-icon"><i class="fa fa-briefcase"></i></span>
					<span class="select-label pull-right">Business</span>
					<ng-select
						class="customSelect pull-right"
						[items]="dropDownEntityList"
						[loading]="entityListLoading"
						bindLabel="text"
						bindValue="id"
						clearSearchOnAdd="true"
						[(ngModel)]="selectedEntityId"
						(change)="onEntitySelect($event, 'business')"
					>
						<ng-template ng-option-tmp let-item="item" let-index="index">
							{{ item.text }}
						</ng-template>
					</ng-select>
					<span class="topnav-span">
						<i class="fa fa-sort-desc"></i>
					</span>
				</div>
			</li>
			<li class="nav-item admin-dropdown">
				<div class="btn-group message" dropdown>
					<a class="nav-link" dropdownToggle>
						<span>{{ userTypeDetails?.xName }}</span>
						<i class="fa fa-caret-down"></i>
					</a>
					<ul class="dropdown-menu pull-right" *dropdownMenu>
						<li class="user-role">{{ userTypeDetails?.xName }}<br />( {{ role }} )</li>
						<li><a>Profile</a></li>
						<li><a (click)="logout()">Logout</a></li>
					</ul>
				</div>
			</li>
		</ul>
	</div>
</nav>

<ul class="mobile navbar-nav">
	<li class="nav-item buis-info" *ngIf="isDealerRep && !currentUrl.includes('business/information')">
		<div class="business_type topnav_business_type m-r-12">
			<span class="select-icon">
				<i class="fa fa-briefcase"></i>
			</span>
			<span class="select-label">Business</span>
			<ng-select
				class="customSelect pull-right"
				[items]="dropDownEntityList"
				[loading]="entityListLoading"
				bindLabel="text"
				bindValue="id"
				clearSearchOnAdd="true"
				[(ngModel)]="selectedEntityId"
				(change)="onEntitySelect($event, 'business')"
			>
				<ng-template ng-option-tmp let-item="item" let-index="index">
					{{ item.text }}
				</ng-template>
			</ng-select>
			<span class="topnav-span">
				<i class="fa fa-sort-desc"></i>
			</span>
		</div>
	</li>
</ul>

<div *ngIf="showOverLay" class="overlay">
	<div class="overlay-content">
		<div>
			<i style="color: white" class="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i>
		</div>
		<div>
			<span style="font-size: 20px">Logging you out...</span>
		</div>
	</div>
</div>
