import { Component, OnInit, OnDestroy } from '@angular/core';
import * as crypto from 'crypto-js';
import { BatchStore } from '../../../../store/index';
import { customSwal } from '../../../../constants';
import {
    TreeService,
    HttpService,
    BatchActionService,
    BusinessService,
    TransactionActionService,
    NotificationService,
    BreadcrumbService,
    UserHandlerService
} from "../../../../shared/services";

import { validateFor } from "../../../../common-functions/index";
import { skip, takeWhile } from 'rxjs/operators';
import { LAST_DAYS_FILTER } from '../../../../constants';
import { BatchPermissions } from 'app/models';

// const SETTLE_ACTION_RESPONSE_INITIATION_WAIT_TIME = 15000; // set initiation wait time to 15 sec
const SETTLE_ACTION_INTERVAL = 2000;
const STRUCT_TYPE_CHAIN = 'chain';
const STRUCT_TYPE_REGION = 'region';
const STRUCT_TYPE_PROPERTY = 'property';
const DISALLOW_SETTLE_BATCH_MSG = 'There are no finalised transactions to batch';
const SETTLE_ACTION_NOT_ALLOWED_SWAL_TIMEOUT_DUR = 300;
const ERR_MSG_KEY = 'message';
const FALL_BACK_MSG = 'Unable to complete the action.';
const LAST_TXN_DATE_TIME_KEY = 'xLastTxnDateTime';

@Component({
    selector: 'app-outstanding-batches',
    templateUrl: './outstanding-batches.component.html',
    styleUrls: ['./outstanding-batches.component.scss']
})
export class OutstandingBatchesComponent implements OnInit, OnDestroy {
    public attributes: any[] = [];
    public finalHashedData: any[] = [];
    public storeValue: string = '';
    public storeObject: any = {};
    public action: any[] = [];
    showDetails: boolean = false;
    fetchByStore: boolean = true;
    public parameters: any = {
        // noGrid: true
        xIsSettled: false
    };
    public refreshGrid: boolean = false;
    public showOverLay: boolean = false;
    public dateRangeValue: Date[];
    public filter: string = 'all';
    // private param: any = {
    //     GMID: '1001396250',
    //     GTID: 'GT1001396253',
    //     GMPW: '123',
    //     TransType: 'Batch',
    //     AllowsPartialAuth: 'N',
    //     xIsOffline: 'N',
    //     SourceTraceNum: '000033',
    //     NeedSwipCard: 'Y'
    // };

    // public detailVisible: boolean = true;
    public batchID: string = '';
    public batchType: string = 'all';
    alive: boolean = true;
    type: string = 'batch';

    public showResponseModal: boolean = false;
    public responseForResponseModal: any = {};
    public typeForResponseModal: String = '';

    pctrListString: string = '';
    gmidList = [];

    public viewDetails: any[] = [
        { header: 'Status', mapTo: 'xStatus' },
        { header: 'Num', mapTo: 'xBatchNum' },
        { header: 'Type', mapTo: 'xTypeTag' },
        { header: 'Terminal', mapTo: 'xMainTerminalNum' },
        { header: 'Capture Setting', mapTo: 'xCaptTag' },
        { header: 'Mer. Acct', mapTo: 'xMerchantNum' },
        {
            header: 'Open Date',
            mapTo: 'xOpenDateTime'
        },
        {
            header: 'Last Transaction Date',
            mapTo: LAST_TXN_DATE_TIME_KEY
        },
        { header: 'Net Total', mapTo: 'netTotal' },
        {
            header: 'Settle Date',
            mapTo: 'xSettleDateTime'
        },
        {
            header: 'Close date',
            mapTo: 'xCloseDateTime'
        },
        {
            header: 'Response Code',
            mapTo: 'xRspCode'
        },
        {
            header: 'Response Text',
            mapTo: 'xRspText'
        }
    ];

    batchPerms: BatchPermissions = {};

    constructor(
        public batchStore: BatchStore,
        private treeService: TreeService,
        private batchActionService: BatchActionService,
        private httpService: HttpService,
        public businessService: BusinessService,
        // private timerService: TimerService, //Added for BG polling
        private transactionActionService: TransactionActionService,
        private notificationService: NotificationService,
        public breadcrumbService: BreadcrumbService,
        private userHandlerService: UserHandlerService
    ) {}

    ngOnInit() {
        this.setBatchActionPermissions();
        this.setBreadCrumbValue('GMID - ', '', true);
        this.setInitParams();
        this.filterData();
        this.businessService.watchBusinessID$
            .pipe(
                takeWhile(() => this.alive),
                skip(1)
            )
            .subscribe(bizID => {
                if (bizID) {
                    this.setBreadCrumbValue('GMID - ', '', true);
                    this.setGridProperties();
                    // this.parameters = { xBusinessID: bizID };
                    if (this.batchType === 'all') {
                        // console.log("Inside out batch watchBizId all case");
                        this.parameters = {
                            xBusinessID: this.treeService.businessID.getValue(),
                            xIsSettled: false
                        };
                    } else if (this.batchType === 'open') {
                        // console.log("Inside out batch watchBizId open case");
                        this.parameters = {
                            xBusinessID: this.treeService.businessID.getValue(),
                            xIsClosed: false,
                            xIsSettled: false
                        };
                    } else if (this.batchType === 'closed') {
                        // console.log("Inside out batch watchBizId close case");
                        this.parameters = {
                            xBusinessID: this.treeService.businessID.getValue(),
                            xIsClosed: true,
                            xIsSettled: false
                        };
                    }
                } else {
                    // this.router.navigate(['/dashboard/home']);
                    this.parameters = {
                        xBusinessID: null
                    };
                }
            });

        this.treeService.watchBusinessID$
            .pipe(
                takeWhile(() => this.alive),
                skip(1)
            )
            .subscribe(bizID => {
                if (bizID) {
                    this.setGridProperties();
                    // this.parameters = { xBusinessID: bizID };
                    if (this.batchType === 'all') {
                        this.parameters = {
                            xBusinessID: this.treeService.businessID.getValue(),
                            xIsSettled: false
                        };
                    } else if (this.batchType === 'open') {
                        this.parameters = {
                            xBusinessID: this.treeService.businessID.getValue(),
                            xIsClosed: false,
                            xIsSettled: false
                        };
                    } else if (this.batchType === 'closed') {
                        this.parameters = {
                            xBusinessID: this.treeService.businessID.getValue(),
                            xIsClosed: true,
                            xIsSettled: false
                        };
                    }
                }
            });
        this.treeService.watchChainID$
            .pipe(
                takeWhile(() => this.alive),
                skip(1)
            )
            .subscribe(chainID => {
                if (chainID) {
                    this.setGridProperties();
                    // console.log("outstandingbatches chain watcher", chainID);
                    if (this.batchType === 'all') {
                        this.parameters = {
                            xIsSettled: false,
                            struct_type: STRUCT_TYPE_CHAIN,
                            structID: this.treeService.chainID.getValue()
                        };
                    } else if (this.batchType === 'open') {
                        this.parameters = {
                            struct_type: STRUCT_TYPE_CHAIN,
                            structID: this.treeService.chainID.getValue(),
                            xIsClosed: false,
                            xIsSettled: false
                        };
                    } else if (this.batchType === 'closed') {
                        this.parameters = {
                            struct_type: STRUCT_TYPE_CHAIN,
                            structID: this.treeService.chainID.getValue(),
                            xIsClosed: true,
                            xIsSettled: false
                        };
                    }
                }
            });
        this.treeService.watchRegionID$
            .pipe(
                takeWhile(() => this.alive),
                skip(1)
            )
            .subscribe(regionID => {
                if (regionID) {
                    this.setGridProperties();
                    // console.log("outstandingbatches region watcher", regionID);
                    if (this.batchType === 'all') {
                        this.parameters = {
                            xIsSettled: false,
                            struct_type: STRUCT_TYPE_REGION,
                            structID: this.treeService.regionID.getValue()
                        };
                    } else if (this.batchType === 'open') {
                        this.parameters = {
                            struct_type: STRUCT_TYPE_REGION,
                            structID: this.treeService.regionID.getValue(),
                            xIsClosed: false,
                            xIsSettled: false
                        };
                    } else if (this.batchType === 'closed') {
                        this.parameters = {
                            struct_type: STRUCT_TYPE_REGION,
                            structID: this.treeService.regionID.getValue(),
                            xIsClosed: true,
                            xIsSettled: false
                        };
                    }
                }
            });
        this.treeService.watchPropertyID$
            .pipe(
                takeWhile(() => this.alive),
                skip(1)
            )
            .subscribe(propertyID => {
                if (propertyID) {
                    this.setGridProperties();
                    // console.log(
                    //     "outstandingbatches property watcher",
                    //     propertyID
                    // );
                    if (this.batchType === 'all') {
                        this.parameters = {
                            xIsSettled: false,
                            struct_type: STRUCT_TYPE_PROPERTY,
                            structID: this.treeService.propertyID.getValue()
                        };
                    } else if (this.batchType === 'open') {
                        this.parameters = {
                            struct_type: STRUCT_TYPE_PROPERTY,
                            structID: this.treeService.propertyID.getValue(),
                            xIsClosed: false,
                            xIsSettled: false
                        };
                    } else if (this.batchType === 'closed') {
                        this.parameters = {
                            struct_type: STRUCT_TYPE_PROPERTY,
                            structID: this.treeService.propertyID.getValue(),
                            xIsClosed: true,
                            xIsSettled: false
                        };
                    }
                }
            });
        this.treeService.watchStoreID$
            .pipe(
                takeWhile(() => this.alive),
                skip(1)
            )
            .subscribe(storeID => {
                if (storeID) {
                    this.setGridProperties();
                    // this.parameters = { xStoreID: storeID };
                    if (this.batchType === 'all') {
                        this.parameters = {
                            xStoreID: this.treeService.storeID.getValue(),
                            xIsSettled: false
                        };
                    } else if (this.batchType === 'open') {
                        this.parameters = {
                            xStoreID: this.treeService.storeID.getValue(),
                            xIsClosed: false,
                            xIsSettled: false
                        };
                    } else if (this.batchType === 'closed') {
                        this.parameters = {
                            xStoreID: this.treeService.storeID.getValue(),
                            xIsClosed: true,
                            xIsSettled: false
                        };
                    }
                }
            });
        this.treeService.watchProfitCenterID$
            .pipe(
                takeWhile(() => this.alive),
                skip(1)
            )
            .subscribe(profitCenterID => {
                if (profitCenterID) {
                    this.setGridProperties();
                    // this.parameters = { xPCtrID: profitCenterID };
                    if (this.batchType === 'all') {
                        this.parameters = {
                            xPCtrID: this.treeService.profitCenterID.getValue(),
                            xIsSettled: false
                        };
                    } else if (this.batchType === 'open') {
                        this.parameters = {
                            xPCtrID: this.treeService.profitCenterID.getValue(),
                            xIsClosed: false,
                            xIsSettled: false
                        };
                    } else if (this.batchType === 'closed') {
                        this.parameters = {
                            xPCtrID: this.treeService.profitCenterID.getValue(),
                            xIsClosed: true,
                            xIsSettled: false
                        };
                    }
                }
            });
        this.treeService.watchMerchantAccID$
            .pipe(
                takeWhile(() => this.alive),
                skip(1)
            )
            .subscribe(merID => {
                if (merID) {
                    this.setGridProperties();
                    // this.parameters = { xMerAcctID: merID };
                    if (this.batchType === 'all') {
                        this.parameters = {
                            xMerAcctID: this.treeService.merchantAccID.getValue(),
                            xIsSettled: false
                        };
                    } else if (this.batchType === 'open') {
                        this.parameters = {
                            xMerAcctID: this.treeService.merchantAccID.getValue(),
                            xIsClosed: false,
                            xIsSettled: false
                        };
                    } else if (this.batchType === 'closed') {
                        this.parameters = {
                            xMerAcctID: this.treeService.merchantAccID.getValue(),
                            xIsClosed: true,
                            xIsSettled: false
                        };
                    }
                }
            });
        this.treeService.watchTerminalID$
            .pipe(
                takeWhile(() => this.alive),
                skip(1)
            )
            .subscribe(paymentDevID => {
                if (paymentDevID) {
                    this.setGridProperties();
                    // this.parameters = { xTerminalID: paymentDevID };
                    if (this.batchType === 'all') {
                        this.parameters = {
                            xTerminalID: this.treeService.terminalID.getValue(),
                            xIsSettled: false
                        };
                    } else if (this.batchType === 'open') {
                        this.parameters = {
                            xTerminalID: this.treeService.terminalID.getValue(),
                            xIsClosed: false,
                            xIsSettled: false
                        };
                    } else if (this.batchType === 'closed') {
                        this.parameters = {
                            xTerminalID: this.treeService.terminalID.getValue(),
                            xIsClosed: true,
                            xIsSettled: false
                        };
                    }
                }
            });
        this.treeService.watchPeriphID$
            .pipe(
                takeWhile(() => this.alive),
                skip(1)
            )
            .subscribe(perphID => {
                if (perphID) {
                    this.setGridProperties();
                    // this.parameters = { xPeriphID: perphID };
                    if (this.batchType === 'all') {
                        this.parameters = {
                            xPeriphID: this.treeService.periphID.getValue(),
                            xIsSettled: false
                        };
                    } else if (this.batchType === 'open') {
                        this.parameters = {
                            xPeriphID: this.treeService.periphID.getValue(),
                            xIsClosed: false,
                            xIsSettled: false
                        };
                    } else if (this.batchType === 'closed') {
                        this.parameters = {
                            xPeriphID: this.treeService.periphID.getValue(),
                            xIsClosed: true,
                            xIsSettled: false
                        };
                    }
                }
            });
    }

    setBreadCrumbValue(gmidString: string, tooltip: string, loading: boolean): void {
        console.log('tooltipstring', tooltip.length);
        const breadCrumb: any = {};
        breadCrumb.root = 'Transactions';
        breadCrumb.child = gmidString;
        breadCrumb.subChild = '';
        breadCrumb.subChildLink = null;
        breadCrumb.grandChild = null;
        if (tooltip.length === 0) {
            breadCrumb.tooltip = false;
        } else {
            breadCrumb.toolTip = true;
        }
        breadCrumb.toolTipData = tooltip;
        breadCrumb.loading = loading;
        this.breadcrumbService.setBreadCrumb(breadCrumb);
    }

    recievePCtrList(event: any) {
        console.log('event in pctrlist', event);
        this.gmidList = [];
        this.pctrListString = '';
        event.map(item => {
            this.pctrListString += item + ',';
            // remove this statement once api call added
            // this.gmidList.push(item);
        });
        this.pctrListString = this.pctrListString.slice(0, -1);
        let params = { pctrIDs: this.pctrListString, selectKey: 'xGMID' };
        this.setBreadCrumbValue('GMID - ', '', true);
        console.log('params for gmid', params);
        this.httpService.getAll('xbcPCtrs', params).subscribe(
            response => {
                console.log('response get gmid', response);
                response.data.map(item => {
                    this.gmidList.push(item.xGMID);
                });
                console.log('gmidlist', this.gmidList);
                console.log('pctrliststring', this.pctrListString);
                let gmidStringBreadCrumb = 'GMID - None',
                    tooltipGMID = '';
                if (this.gmidList.length === 1) gmidStringBreadCrumb = 'GMID - ' + this.gmidList[0];
                else if (this.gmidList.length >= 2) gmidStringBreadCrumb = 'GMID - ' + this.gmidList[0] + ', ' + this.gmidList[1];
                this.gmidList.map(item => {
                    tooltipGMID += item + ',';
                });
                tooltipGMID = tooltipGMID.slice(0, -1);
                this.setBreadCrumbValue(gmidStringBreadCrumb, tooltipGMID, false);
            },
            error => {
                console.log('Failed api call for gmid');
            }
        );
    }

    setInitParams() {
        if (this.treeService.businessID.getValue())
            this.parameters = Object.assign({}, this.parameters, {
                xBusinessID: this.treeService.businessID.getValue()
            });

        if (this.treeService.chainID.getValue())
            this.parameters = Object.assign({}, this.parameters, {
                struct_type: STRUCT_TYPE_CHAIN,
                structID: this.treeService.chainID.getValue()
            });

        if (this.treeService.regionID.getValue())
            this.parameters = Object.assign({}, this.parameters, {
                struct_type: STRUCT_TYPE_REGION,
                structID: this.treeService.regionID.getValue()
            });

        if (this.treeService.propertyID.getValue())
            this.parameters = Object.assign({}, this.parameters, {
                struct_type: STRUCT_TYPE_PROPERTY,
                structID: this.treeService.propertyID.getValue()
            });

        if (this.treeService.storeID.getValue())
            this.parameters = Object.assign({}, this.parameters, {
                xStoreID: this.treeService.storeID.getValue()
            });

        if (this.treeService.profitCenterID.getValue())
            this.parameters = Object.assign({}, this.parameters, {
                xPCtrID: this.treeService.profitCenterID.getValue()
            });

        if (this.treeService.merchantAccID.getValue())
            this.parameters = Object.assign({}, this.parameters, {
                xMerAcctID: this.treeService.merchantAccID.getValue()
            });

        if (this.treeService.terminalID.getValue())
            this.parameters = Object.assign({}, this.parameters, {
                xTerminalID: this.treeService.terminalID.getValue()
            });

        if (this.treeService.periphID.getValue())
            this.parameters = Object.assign({}, this.parameters, {
                xPeriphID: this.treeService.periphID.getValue()
            });
    }

    filterData() {
        this.transactionActionService.setResetFilter(true);
        switch (this.filter) {
            case LAST_DAYS_FILTER.all:
                // console.log('here 2');
                delete this.parameters['filterType'];
                if (this.parameters.hasOwnProperty('startDate')) delete this.parameters['startDate'];
                if (this.parameters.hasOwnProperty('endDate')) delete this.parameters['endDate'];
                if (this.parameters.hasOwnProperty('currDate')) delete this.parameters['currDate'];
                this.parameters = Object.assign({}, this.parameters);
                break;
            case LAST_DAYS_FILTER.last_24_hrs:
                this.parameters = Object.assign({}, this.parameters, {
                    filterType: LAST_DAYS_FILTER.last_24_hrs
                });
                break;
            case LAST_DAYS_FILTER.last_7_days:
                this.parameters = Object.assign({}, this.parameters, {
                    filterType: LAST_DAYS_FILTER.last_7_days
                });
                break;
            case LAST_DAYS_FILTER.last_30_days:
                this.parameters = Object.assign({}, this.parameters, {
                    filterType: LAST_DAYS_FILTER.last_30_days
                });
                break;
            case LAST_DAYS_FILTER.last_60_days:
                this.parameters = Object.assign({}, this.parameters, {
                    filterType: LAST_DAYS_FILTER.last_60_days
                });
                break;
            case LAST_DAYS_FILTER.last_90_days:
                this.parameters = Object.assign({}, this.parameters, {
                    filterType: LAST_DAYS_FILTER.last_90_days
                });
                break;
            case 'advancedDateFilter':
                delete this.parameters['currDate'];
                this.parameters = Object.assign({}, this.parameters, {
                    filterType: 'advancedDateFilter'
                });
                break;
        }

        if (this.filter !== 'all' && this.filter !== 'advancedDateFilter') {
            if (this.parameters.hasOwnProperty('startDate')) delete this.parameters['startDate'];
            if (this.parameters.hasOwnProperty('endDate')) delete this.parameters['endDate'];
            this.parameters = Object.assign({}, this.parameters, {
                currDate: new Date().toDateString()
            });
        } else if (this.filter === 'advancedDateFilter') {
            this.parameters = Object.assign({}, this.parameters, {
                startDate: new Date(this.dateRangeValue[0]).toDateString(),
                endDate: new Date(this.dateRangeValue[1]).toDateString()
            });
        }

        // if (this.batchedtxns)
        //     this.parameters = Object.assign({}, this.parameters, {
        //         xBatchID: this.paramId
        //     });

        console.log('this.parameters=====>', this.parameters);

        this.setGridProperties();
    }

    setGridProperties() {
        this.storeValue = 'BatchStore';
        this.storeObject = this.batchStore;
        // console.log("this.storeObject in bacth cmp", this.storeObject, this.batchStore);
        this.attributes = [
            { header: "Number", mapTo: "xBatchNum", width: "12%" },
            { header: "Merchant Account", mapTo: "xMerchantNum", width: "17%" },
            {
                header: 'Open Date',
                mapTo: 'xOpenDateTime',
                width: '12%'
            },
            {
                header: 'Settle Date',
                mapTo: 'xSettleDateTime',
                width: '12%'
            },
            { header: 'Net Total', mapTo: 'netTotal', width: '15%' },
            { header: "Service Charge", mapTo: "serviceCharge", width: "12%" },
            { header: 'Actions', mapTo: '', width: '15%' }
        ];

        this.action = [
            {
                type: 'view',
                visible: true,
                text: 'Details',
                icon: 'fa fa-eye',
                action: 'emit'
            },
            {
                type: 'modal',
                visible: true,
                text: 'Settle',
                icon: 'fa fa-usd',
                action: 'emit',
                value: 'settle',
                disabled: !this.batchPerms.canSettle
            },
            {
                type: 'modal',
                visible: true,
                text: 'Batch Cleanup',
                icon: 'fa fa-eraser',
                action: 'emit',
                value: 'clean',
                disabled: !this.batchPerms.canCleanup
            },
            {
                type: 'modal',
                visible: true,
                text: 'Re-Sync',
                icon: 'fa fa-refresh',
                action: 'emit',
                value: 'reSync',
                disabled: !this.batchPerms.canResync
            },
            {
                type: 'edit',
                visible: true,
                text: "View Transactions",
                icon: "fa fa-exchange",
                action: "func",
                value: "transaction",
                onClick: "/dashboard/outstanding-transactions/batch",
                disabled: !this.batchPerms.canViewBatchTxns
            }
        ];
    }

    onActionRequest(obj: any) {
        console.log('onActionRequest: obj', obj);
        if (obj.act.type === 'view') {
            this.batchID = obj.id;
            // this.detailVisible = true;
        } else if (obj.act.type === 'modal') {
            // console.log("obj inside settle click", obj);
            if (obj.act.value === 'settle') {
                if (obj.id) {
                    this.typeForResponseModal = 'Settle';
                    this.batchStore
                        .getAll({
                            xBusinessID: this.businessService.businessID.getValue(),
                            batchIds: obj.id.toString(),
                            hashData: true
                        })
                        .subscribe(
                            batchResponse => {
                                console.log('Batch Response===>', batchResponse);
                                if (
                                    batchResponse &&
                                    batchResponse.hasOwnProperty('data') &&
                                    batchResponse.data.length &&
                                    batchResponse.hasOwnProperty('meta') &&
                                    batchResponse.meta.hasOwnProperty('hashedData') &&
                                    batchResponse.meta.hashedData.length
                                ) {
                                    this.finalHashedData = [];
                                    let batchId = batchResponse.data[0]._id;

                                    batchResponse.data.map(singleBatchData => {
                                        if (
                                            singleBatchData.hasOwnProperty('xPullSyncInfo') &&
                                            singleBatchData.xPullSyncInfo.hasOwnProperty('xHasPendingSync') &&
                                            !singleBatchData.xPullSyncInfo.xHasPendingSync
                                        ) {
                                            this.finalHashedData.push(
                                                batchResponse.meta.hashedData.filter(
                                                    singleHashedData => singleHashedData.record_id === singleBatchData._id
                                                )[0]
                                            );
                                        } else {
                                            this.notificationService.error('Inconsistent data. Please refresh.', 'Error');
                                            return;
                                        }
                                    });
                                    customSwal.fire({
                                        title: 'Are you sure?',
                                        text: 'Click Yes to continue, No to decline',
                                        icon: 'warning',
                                        showCancelButton: true,
                                        confirmButtonText: 'Yes',
                                        cancelButtonText: 'No',
                                        reverseButtons: true
                                    }).then((result) => {
                                        if (result.value) {
                                            this.showOverLay = true;
                                                let reqstID = crypto
                                                    .HmacSHA256(this.businessService.businessID.getValue(), Math.random().toString())
                                                    .toString();
                                                // setTimeout(()=>{
                                                this.httpService
                                                    .store('pgComm/settleBatches', {
                                                        batchId: batchId,
                                                        originalData: this.finalHashedData,
                                                        requestID: reqstID
                                                    })
                                                    .subscribe(
                                                        (response: Response | any) => {
                                                            console.log('Response inside Batch Settle', response);
                                                            if (response.hasOwnProperty('succeededAPI') && response.succeededAPI.length) {
                                                                let trackerInterval = setInterval(() => {
                                                                    this.httpService
                                                                        .store('cloud9pg/v1/checkTrackers', {
                                                                            requestID: reqstID
                                                                        })
                                                                        .subscribe(trackerResponse => {
                                                                            console.log('trackerResponse', trackerResponse);
                                                                            if (
                                                                                trackerResponse &&
                                                                                trackerResponse.hasOwnProperty('isTracker')
                                                                            ) {
                                                                                if (trackerResponse.isTracker) {
                                                                                    clearInterval(trackerInterval);

                                                                                    this.responseForResponseModal = [response];
                                                                                    this.showResponseModal = true;
                                                                                    // let failedIds = [];
                                                                                    // let idString = '';

                                                                                    this.showOverLay = false;
                                                                                    this.transactionActionService.setActionPerformed(true);
                                                                                }
                                                                            } else {
                                                                                clearInterval(trackerInterval);
                                                                            }
                                                                        });
                                                                }, SETTLE_ACTION_INTERVAL);
                                                            } else {
                                                                this.responseForResponseModal = [response];
                                                                this.showResponseModal = true;
                                                                // let failedIds = [];
                                                                // let idString = '';

                                                                this.showOverLay = false;
                                                                this.transactionActionService.setActionPerformed(true);
                                                            }
                                                        },
                                                        err => {
                                                            console.log('Error inside Batch Settle', err);
                                                            this.transactionActionService.setRefreshData(true);
                                                            this.transactionActionService.setActionPerformed(true);
                                                            // this.transactionActionService.setContinuousFetch(true);
                                                            setTimeout(()=>{
                                                                console.log('onActionRequest: settle timeout: err', err);
                                                                this.showOverLay = false;
                                                                this.showSwalForInvalidSettleAction(err, obj.id);
                                                            },SETTLE_ACTION_NOT_ALLOWED_SWAL_TIMEOUT_DUR);
                                                            this.performCommonActionsOnErrorResponse();
                                                        }
                                                    );
                                        } else {
                                            this.transactionActionService.setRefreshData(true);
                                        }
                                    });
                                }
                            },
                            batchResponseError => {
                                console.log('onActionRequest: batchResponseError', batchResponseError);
                                let message = validateFor(ERR_MSG_KEY,batchResponseError) ? batchResponseError[ERR_MSG_KEY] : FALL_BACK_MSG;
                                this.notificationService.error(message,'Error');
                            }
                        );
                } else {
                    customSwal.fire({
                        title: `Something went wrong`,
                        text:'Settle action could not be completed',
                        icon: 'warning',
                        confirmButtonText: 'Okay'
                    }).then((result) => {
                        if (result.value) {
                            this.batchActionService.setDoSettle(false);
                            this.transactionActionService.setRefreshData(true);
                        }
                    })
                }
            } else if (obj.act.value === 'reSync') {
                if (obj.id) {
                    customSwal.fire({
                        title: 'This action might take some time.',
                        text: 'Click Yes to continue, No to decline',
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Yes',
                        cancelButtonText: 'No',
                        reverseButtons: true
                    }).then((result) => {
                        if (result.value) {
                            this.showOverLay = true;
                                this.httpService
                                    .getAll(
                                        'sync/v1/resync_openTxns',
                                        {
                                            batchID: obj.id,
                                            batchRefNum: obj.refNum
                                        },
                                        true
                                    )
                                    .subscribe(
                                        (response: Response | any) => {
                                            this.showOverLay = false;
                                            this.transactionActionService.setRefreshData(true);
                                            this.transactionActionService.setActionPerformed(true);
                                            this.notificationService.success('Re-Sync Successful', 'Success');
                                            // }
                                        },
                                        err => {
                                            // console.log("Error inside Batch resync", err);
                                            this.transactionActionService.setRefreshData(true);
                                            this.transactionActionService.setActionPerformed(true);
                                            // this.transactionActionService.setContinuousFetch(true);
                                            this.showOverLay = false;
                                            this.performCommonActionsOnErrorResponse();
                                        }
                                    );
                        }
                    })
                } else {
                    customSwal.fire({
                        title: `Something went wrong`,
                        text: 'Settle action could not be completed',
                        icon: 'warning',
                        confirmButtonText: 'Okay',
                    }).then((result) => {
                            this.transactionActionService.setRefreshData(true);
                    })
                }
            } else if (obj.act.value === 'clean') {
                if (obj.id) {
                    this.typeForResponseModal = 'Clean';
                    this.batchStore
                        .getAll({
                            xBusinessID: this.businessService.businessID.getValue(),
                            batchIds: obj.id.toString(),
                            hashData: true
                        })
                        .subscribe(
                            batchResponse => {
                                console.log('Batch Response===>', batchResponse);
                                if (
                                    batchResponse &&
                                    batchResponse.hasOwnProperty('data') &&
                                    batchResponse.data.length &&
                                    batchResponse.hasOwnProperty('meta') &&
                                    batchResponse.meta.hasOwnProperty('hashedData') &&
                                    batchResponse.meta.hashedData.length
                                ) {
                                    this.finalHashedData = [];
                                    let batchId = batchResponse.data[0]._id;

                                    batchResponse.data.map(singleBatchData => {
                                        if (
                                            singleBatchData.hasOwnProperty('xPullSyncInfo') &&
                                            singleBatchData.xPullSyncInfo.hasOwnProperty('xHasPendingSync') &&
                                            !singleBatchData.xPullSyncInfo.xHasPendingSync
                                        ) {
                                            this.finalHashedData.push(
                                                batchResponse.meta.hashedData.filter(
                                                    singleHashedData => singleHashedData.record_id === singleBatchData._id
                                                )[0]
                                            );
                                        } else {
                                            this.notificationService.error('Inconsistent data. Please refresh.', 'Error');
                                            return;
                                        }
                                    });
                                    customSwal.fire({
                                        title: 'Are you sure?',
                                        text: 'Click Yes to continue, No to decline',
                                        icon: 'warning',
                                        showCancelButton: true,
                                        confirmButtonText: 'Yes',
                                        cancelButtonText: 'No',
                                        reverseButtons: true
                                    }).then((result) => {
                                        if (result.value) {
                                            this.showOverLay = true;
                                                this.httpService
                                                    .store('pgComm/cleanBatches', {
                                                        batchId: batchId,
                                                        originalData: this.finalHashedData
                                                    })
                                                    .subscribe(
                                                        (response: Response | any) => {
                                                            console.log('Response inside Batch Clean', response);
                                                            this.responseForResponseModal = [response];
                                                            this.showResponseModal = true;
                                                            this.showOverLay = false;
                                                            this.transactionActionService.setActionPerformed(true);
                                                        },
                                                        err => {
                                                            console.log('Error inside Batch Clean', err);
                                                            this.transactionActionService.setRefreshData(true);
                                                            this.transactionActionService.setActionPerformed(true);
                                                            this.showOverLay = false;
                                                            this.performCommonActionsOnErrorResponse();
                                                        }
                                                    );
                                        } else {
                                            this.transactionActionService.setRefreshData(true);
                                        }
                                    })
                                }
                            },
                            error => {}
                        );
                } else {
                    customSwal.fire({
                        title: 'Something went wrong',
                        text: 'Clean action could not be completed',
                        icon: 'warning',
                        confirmButtonText: 'Okay'
                    }).then((result) => {
                            this.transactionActionService.setRefreshData(true);
                    })
                }
            }
        }
    }

    /**
     * show swal when settle action fails due to no good txns
     *
     * @params : errorObject : error object from BE
     * @params : batchID : string : batch id
     */
    showSwalForInvalidSettleAction(errorObject, batchID: string) {
        console.log('showSwalForInvalidSettleAction: errorObject', errorObject);
        console.log('showSwalForInvalidSettleAction: batchID', batchID);
        let errorObjectData = errorObject && errorObject.hasOwnProperty('data') && errorObject.data ? errorObject.data : {};
        // let shouldShowCleanseOption = Object.keys(errorObjectData).length && errorObjectData.hasOwnProperty('shouldShowCleanseOption')
        if (Object.keys(errorObjectData).length && errorObjectData.hasOwnProperty('shouldShowCleanseOption')) {
            console.log('showSwalForInvalidSettleAction: enter show swal section');
            // swal('test test test test test test test test test test test test test test test test test test test test ');
            let shouldShowCleanseOption = errorObjectData.shouldShowCleanseOption;
            if (shouldShowCleanseOption) {
                console.log('showSwalForInvalidSettleAction: shouldShowCleanseOption', shouldShowCleanseOption);
                this.showSwalForSettleCleanseBatchAndErrorMsg(batchID);
            } else {
                console.log('showSwalForInvalidSettleAction: shouldShowCleanseOption', shouldShowCleanseOption);
                this.showSwalForSettleErrorMsg();
            }
        }
    }

    /**
     * open swal for displaying settle error message and give option to perform cleanse action
     *
     * @params : batchID : string : batch id
     */
    showSwalForSettleCleanseBatchAndErrorMsg(batchID: string) {
        console.log('showSwalForSettleCleanseBatchAndErrorMsg: batchID', batchID);
        customSwal.fire({
            title: DISALLOW_SETTLE_BATCH_MSG,
            text: DISALLOW_SETTLE_BATCH_MSG,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Cleanse Batch',
            cancelButtonText: 'Cancel',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                console.log('showSwalForSettleCleanseBatchAndErrorMsg: isConfirm', result.value);
                /* Process flow for how batch action to be performed from swal: 
                - create an object similar to what grid would emit when batchAction is performed
                - this object is then passed to onActionRequest function call
                - this allows the action to be handled similar to how it would be handled if action came from grid
                */
                let batchAction = {
                    act: {
                        type: 'modal',
                        visible: true,
                        text: 'Batch Cleanup',
                        icon: 'fa fa-eraser',
                        action: 'emit',
                        value: 'clean'
                    },
                    id: batchID
                };
                console.log('showSwalForSettleCleanseBatchAndErrorMsg: batchAction', batchAction);
                this.onActionRequest(batchAction);
            }
        })
    }

    /**
     * open swal for displaying settle error message
     *
     */
    showSwalForSettleErrorMsg() {
        customSwal.fire({
            title: DISALLOW_SETTLE_BATCH_MSG,
            text: DISALLOW_SETTLE_BATCH_MSG,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Okay',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                console.log('showSwalForSettleErrorMsg: isConfirm', result.value);
            }
        })
    }

    performCommonActionsOnErrorResponse() {
        this.storeObject = this.batchStore;
        this.parameters = JSON.parse(JSON.stringify(this.parameters));
    }

    viewClosed(visible: boolean) {
        if (!visible) {
            this.batchID = '';
            this.showDetails = false;
        }
    }

    setDoSettle() {
        // this.batchActionService.setDoSettle(true);
    }

    onShowDetails(obj: any) {
        // console.log("Show object", obj);
        this.showDetails = obj;
    }

    onRefreshRequest(event) {
        this.parameters = Object.assign({}, this.parameters, {
            refreshGrid: event
        });
        // this.parameters = {
        //     refreshGrid: event,
        //     xIsSettled: false
        // }
    }

    onReSyncRequest(event) {
        customSwal.fire({
            title: 'This action might take some time.',
            text: 'Click Yes to continue, No to decline',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.showOverLay = true;
                    this.httpService
                        .getAll(
                            'sync/v1/resync_openBatches',
                            {
                                bizID: this.businessService.businessID.getValue()
                            },
                            true
                        )
                        .subscribe(
                            response => {
                                // console.log("Response inside Batch resync All", response);
                                this.showOverLay = false;
                                this.transactionActionService.setRefreshData(true);
                                this.transactionActionService.setActionPerformed(true);
                                this.notificationService.success('Re-Sync Successful', 'Success');
                            },
                            error => {
                                // console.log("Error inside Batch resync All", error);
                                this.transactionActionService.setRefreshData(true);
                                this.transactionActionService.setActionPerformed(true);
                                // this.transactionActionService.setContinuousFetch(true);
                                this.showOverLay = false;
                            }
                        );
            }
        })
    }

    onResponseModalClose() {
        // this.parameters = Object.assign({}, this.parameters, {xBusinessID: this.treeService.businessID.getValue()});
        // this.parameters = {
        //     xBusinessID: this.treeService.businessID.getValue(),
        //     xIsSettled: false
        // };
        this.showResponseModal = false;
        this.transactionActionService.setRefreshData(true);
        // this.transactionActionService.setContinuousFetch(true);
    }

    fetchFilter() {
        switch (this.filter) {
            case LAST_DAYS_FILTER.all:
                if (
                    this.parameters.hasOwnProperty('filterType') &&
                    (this.parameters.hasOwnProperty('currDate') ||
                        (this.parameters.hasOwnProperty('startDate') && this.parameters.hasOwnProperty('endDate')))
                ) {
                    this.filterData();
                }
                this.dateRangeValue = [];
                break;
            case LAST_DAYS_FILTER.last_24_hrs:
                this.filterData();
                this.dateRangeValue = [];
                break;
            case LAST_DAYS_FILTER.last_7_days:
                this.filterData();
                this.dateRangeValue = [];
                break;
            case LAST_DAYS_FILTER.last_30_days:
                this.filterData();
                this.dateRangeValue = [];
                break;
            case LAST_DAYS_FILTER.last_60_days:
                this.filterData();
                this.dateRangeValue = [];
                break;
            case LAST_DAYS_FILTER.last_90_days:
                this.filterData();
                this.dateRangeValue = [];
                break;
            default:
                console.log('reached default of date filters: outstanding batches');
                break;
        }
    }

    onDateFilterClick(event) {
        console.log('this.dateRangeValue', this.dateRangeValue);
        // console.log(
        //     "this.dateRangeValue",
        //     JSON.parse(JSON.stringify(this.dateRangeValue[0]))
        // );
        if (this.dateRangeValue && this.dateRangeValue.length) {
            this.filter = 'advancedDateFilter';
            this.filterData();
        }
    }

    /**
     * Based on user permission, enable/disable batch actions
     */
     setBatchActionPermissions(){
        const userPerms = this.userHandlerService.userPerms;
        this.batchPerms.canCleanup = userPerms.canCleanBatch;
        this.batchPerms.canResync = userPerms.canReSyncBatch;
        this.batchPerms.canSettle = userPerms.canSettleBatch;
        this.batchPerms.canViewBatchTxns = userPerms.canViewBatchTxns;
    }

    ngOnDestroy() {
        this.alive = false;
    }
}
