import { Component, OnInit, OnDestroy } from '@angular/core';
import { SettledTransactionsStore, BatchStore, SetupBusinessStructureStoreSetupStore } from '../../../../store/index';
import { customSwal,LAST_DAYS_FILTER, NO_PERMISSION_MSG, TAGSCONSTS } from '../../../../constants';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
    NotificationService,
    TreeService,
    BusinessService,
    TransactionActionService,
    HttpService,
    SummaryReportService,
    BreadcrumbService,
    GlobalValidator,
    UserHandlerService
} from 'app/shared/services';
import { forkJoin } from 'rxjs';
import { skip, takeWhile } from 'rxjs/operators';
import {
    processToAssociativeObject,
    createEditObj,
    checkValidAmt,
    INVALID_AMT_MSG,
    INVALID_BASE_AMT_MSG,
    abbreviateStoreName,
    KEEP_NO_OF_CHARACTERS_FIRST,
    KEEP_NO_OF_CHARACTERS_LAST,
    STORE_NAME_ABBREVIATION_LENGTH_MAX_CAP
} from '../../shared/common-functions/generic-functions';
import { BE_KEY, XPESETTLEDTXNS } from '../../../../constants/index'
import { validateFor } from '../../../../common-functions';
import { TxnActionPerms } from 'app/models';

const STRUCT_TYPE_CHAIN = 'chain';
const STRUCT_TYPE_REGION = 'region';
const STRUCT_TYPE_PROPERTY = 'property';

const RE_AUTH_END_POINT = 'pgComm/reAuth';
const REVERSE_END_POINT = 'pgComm/reverseTxns';

const TRANSACTIONS_TYPE_CLOSED_TXNS = 'settledTxns';
const TRANSACTIONS_ACTION_REVERSE = 'reverse';
const TRANSACTIONS_ACTION_REDO = 'redo';

const UNABLE_TO_SET_STORE_NAME_ERROR: string = 'Unable to set Store Name.';

// Advanced Date Filter Types
const DATE_FILTER_AUTH = 'auth';
const DATE_FILTER_FINALIZE = 'finalize';
const DATE_FILTER_SETTLE = 'settle';

// Advanced Date Filter Field
const AUTH_DATE = 'xAuthDate';
const FINALIZE_DATE = 'xFinalizeDate';
const SETTLE_DATE = 'xSettleDate';

const GET_PROC_KEY = 'getProc';
const GET_PROC_FIELDS = 'xName';

@Component({
    selector: 'app-settled-transactions',
    templateUrl: './settled-transactions.component.html',
    styleUrls: ['./settled-transactions.component.scss']
})
export class SettledTransactionsComponent implements OnInit, OnDestroy {
    batchedtxns: boolean;
    paramId: string;
    batchNum: number = 0;
    fetchByStore: boolean = true;
    alive: boolean = true;
    showDetails: boolean = false;
    refreshGrid: boolean = false;
    public checkedIds: string[];
    observables: any[] = [];
    rePrint: boolean = false;
    transactionStoreData: any[] = [];
    storeData: any[] = [];
    type: string = 'settledTxn';
    totalNumberOfRecords: number = 0;
    totalPages: number[] = [];
    paginationParams: any = {};

    public attributes: any[] = [];
    public finalHashedData: any[] = [];
    public storeValue: string = '';
    public storeObject: any = {};
    public action: any[] = [];
    public parameters: any = {
        // noGrid: true,
        // xIsSettled: true
    };
    public showOverLay: boolean = false;
    public showPaginationModal: boolean = false;
    public selectAll: boolean = false;
    public dateRangeValue: Date[] = [];
    public donePages: any[] = [];
    public previousAction: string = '';
    public currentAction: string = '';

    public visibleModal: boolean = false;
    public showResponseModal: boolean = false;
    public responseForResponseModal: any = {};
    public typeForResponseModal: String = '';
    public typeForPaginationModal: String = '';
    public visiblePrintDetailModal: boolean = false;
    public showTxn: boolean = true;
    public transactionID: string = '';
    public filter: string = LAST_DAYS_FILTER.last_90_days;
    public advancedDateFilterType:string = ''; // defines which kind of date filter needs to be run auth/finalize/settle
    public DATE_FILTER_AUTH:string = DATE_FILTER_AUTH;
    public DATE_FILTER_FINALIZE:string = DATE_FILTER_FINALIZE;
    public DATE_FILTER_SETTLE:string = DATE_FILTER_SETTLE;
    public redoLoader: boolean = false;
    public reverseLoader: boolean = false;
    public rePrintLoader: boolean = false;
    public printDetailLoader: boolean = false;
    public disableActionBtns: boolean = false;

    // edit modal
    public editTxns: any[] = [];
    showEditModal: boolean = false;
    public editModalTitle: string = '';

    totalObj: any = {
        totTxn: 0,
        totTxnAmt: '$0.00'
    };
    selectedObj: any = {
        selTxn: 0,
        selTxnAmt: 0.0
    };

    pctrListString: string = '';
    gmidList = [];

    txnActionPermissions: TxnActionPerms = {};
    noPermMsg = NO_PERMISSION_MSG;

    public INVALID_AMT_MSG: string = INVALID_AMT_MSG;
    public INVALID_BASE_AMT_MSG: string = INVALID_BASE_AMT_MSG;

    public viewDetails: any[] = [
        { header: 'Store Name', mapTo: 'xStoreName' },
        { header: 'Store Number', mapTo: 'xStoreNum' },
        { header: 'Status', mapTo: 'xStatus' },
        { header: 'Type', mapTo: 'xTxnActTag' },
        { header: 'Is Offline', mapTo: 'xIsOffline' },
        { header: 'Trans#', mapTo: 'xTxnNum' },
        { header: 'GTRC', mapTo: 'xSysTrcNum' },
        { header: 'Processor', mapTo: 'processorName'},
        { header: 'Medium', mapTo: 'xMdmTag' },
        { header: 'Brand', mapTo: 'xBrandTag' },
        { header: 'Entry Type', mapTo: 'xAcctEntrTag' },
        { header: 'Account Number', mapTo: 'xMaskedAcctNum' },
        { header: 'Customer Number', mapTo: 'xCustNum'},
        { header: 'Authorization Code', mapTo: 'xAuthCode' },
        { header: 'Invoice Number', mapTo: 'xInvNum' },
        { header: 'Included cash back amount', mapTo: 'xIncCashBkAmt' },
        {
            header: 'Base Amount',
            mapTo: 'xMainAmt'
        },
        { header: 'Included tax amount', mapTo: 'xIncTaxAmt' },
        { header: 'Included shipping amount', mapTo: 'xIncShipAmt' },
        { header: 'Included duty amount', mapTo: 'xIncDutyAmt' },
        { header: 'Included surcharge amount', mapTo: 'xIncSurcAmt' },
        { header: 'Tip Amount', mapTo: 'xTipAmt' },
        // { header: "Amount (Before Tax)", mapTo: "xAmtBfrTax" },
        { header: 'Total Amount', mapTo: 'xTtlAmt' },
        { header: 'Balance Amount', mapTo: 'xBalAmt' },
        {
            header: 'Transaction business date',
            mapTo: 'xBizDate'
        },
        {
            header: 'Transaction authorization time',
            mapTo: 'xAuthDateTime'
        },
        {
            header: 'Transaction voided time',
            mapTo: 'xVoidDateTime'
        },
        {
            header: 'Transaction finalized time',
            mapTo: 'xFinalizeDateTime'
        },
        {
            header: 'Transaction settlement time',
            mapTo: 'xSettleDateTime'
        },
        {
            header: 'Response Code',
            mapTo: 'xRspCode'
        },
        {
            header: 'Response Text',
            mapTo: 'xRspText'
        },
        {
            header: 'Source of Transaction',
            mapTo: 'sourceOfTransaction'
        },
        {
            header: 'Items Detail',
            mapTo: 'xItemsInfo',
        }
    ];

    public storeName: string = '';
    public abbreviatedStoreName: string = '';

    constructor(
        private settledTransactionsStore: SettledTransactionsStore,
        private batchStore: BatchStore,
        private route: ActivatedRoute,
        private router: Router,
        private notificationService: NotificationService,
        private treeService: TreeService,
        public businessService: BusinessService,
        private transactionActionService: TransactionActionService,
        private httpService: HttpService,
        public summaryReportService: SummaryReportService,
        public breadcrumbService: BreadcrumbService,
        public setupBusinessStructureStoreSetupStore: SetupBusinessStructureStoreSetupStore,
        private userHandlerService: UserHandlerService
    ) {}

    ngOnInit() {
        this.setTxnActionPermissions();
        this.setBreadCrumbValue('GMID - ', '', true);
        this.route.params.forEach((params: Params) => {

            // console.log('Settled Transactions: CHECK THIS --->',this.route);

            // console.log('Settled Transactions: routeConfig --->', this.route['routeConfig']);
            // console.log('Settled Transactions: PATH --->', this.route['routeConfig'].path);
            const routePath = this.route['routeConfig'].path;

            if (params.hasOwnProperty('Id')) {
                if(routePath && routePath.includes('/batch/')){
                    this.batchedtxns = true;
                    if(!params.batchID){
                        //TODO-: Handle No ID case
                    }
                    this.paramId = params['Id'];
                    this.filter = "all";
                    this.fetchBatchInfo(this.paramId);
                }
            }
        });

        this.setInitParams();
        this.filterData();

        this.businessService.watchBusinessID$
            .pipe(
                takeWhile(() => this.alive),
                skip(1)
            )
            .subscribe(bizID => {
                if (bizID) {
                    this.setBreadCrumbValue('GMID - ', '', true);
                    this.parameters = {
                        xBusinessID: this.treeService.businessID.getValue(),
                        hashData: true
                    };
                    this.batchedtxns = false;
                    this.batchNum = 0;
                    this.filterData();
                } else {
                    // this.router.navigate(['/dashboard/home']);
                    this.handleWatcher_NullValueCase('biz');
                    // this.parameters = {
                    //     xBusinessID: null
                    // };
                }
            });

        //Watchers for changes in the tree--------->
        this.treeService.watchBusinessID$
            .pipe(
                takeWhile(() => this.alive),
                skip(1)
            )
            .subscribe(bizID => {
                if (bizID) {
                    this.parameters = {
                        xBusinessID: this.treeService.businessID.getValue(),
                        hashData: true
                    };
                    this.filterData();
                } else this.handleWatcher_NullValueCase('biz');
            });
        this.treeService.watchChainID$
            .pipe(
                takeWhile(() => this.alive),
                skip(1)
            )
            .subscribe(chainID => {
                if (chainID) {
                    console.log('settledtxn watcher chain', chainID);
                    this.parameters = {
                        struct_type: STRUCT_TYPE_CHAIN,
                        structID: this.treeService.chainID.getValue(),
                        hashData: true
                    };
                    this.filterData();
                } else this.handleWatcher_NullValueCase('chain');
            });
        this.treeService.watchRegionID$
            .pipe(
                takeWhile(() => this.alive),
                skip(1)
            )
            .subscribe(regionID => {
                if (regionID) {
                    console.log('settledtxn watcher region', regionID);
                    this.parameters = {
                        struct_type: STRUCT_TYPE_REGION,
                        structID: this.treeService.regionID.getValue(),
                        hashData: true
                    };
                    this.filterData();
                } else this.handleWatcher_NullValueCase('region');
            });
        this.treeService.watchPropertyID$
            .pipe(
                takeWhile(() => this.alive),
                skip(1)
            )
            .subscribe(propertyID => {
                if (propertyID) {
                    console.log('settledtxn watcher property', propertyID);
                    this.parameters = {
                        struct_type: STRUCT_TYPE_PROPERTY,
                        structID: this.treeService.propertyID.getValue(),
                        hashData: true
                    };
                    this.filterData();
                } else this.handleWatcher_NullValueCase('property');
            });
        this.treeService.watchStoreID$
            .pipe(
                takeWhile(() => this.alive),
                skip(1)
            )
            .subscribe(storeID => {
                if (storeID) {
                    this.parameters = {
                        xStoreID: this.treeService.storeID.getValue(),
                        hashData: true
                    };
                    this.filterData();
                } else this.handleWatcher_NullValueCase('store');
            });
        this.treeService.watchProfitCenterID$
            .pipe(
                takeWhile(() => this.alive),
                skip(1)
            )
            .subscribe(profitCenterID => {
                if (profitCenterID) {
                    this.parameters = {
                        xPCtrID: this.treeService.profitCenterID.getValue(),
                        hashData: true
                    };
                    this.filterData();
                } else this.handleWatcher_NullValueCase('profit_center');
            });
        this.treeService.watchMerchantAccID$
            .pipe(
                takeWhile(() => this.alive),
                skip(1)
            )
            .subscribe(merID => {
                if (merID) {
                    this.parameters = {
                        xMerAcctID: this.treeService.merchantAccID.getValue(),
                        hashData: true
                    };
                    this.filterData();
                } else this.handleWatcher_NullValueCase('merchant_account');
            });
        this.treeService.watchTerminalID$
            .pipe(
                takeWhile(() => this.alive),
                skip(1)
            )
            .subscribe(paymentDevID => {
                if (paymentDevID) {
                    this.parameters = {
                        xTerminalID: this.treeService.terminalID.getValue(),
                        hashData: true
                    };
                    this.filterData();
                } else this.handleWatcher_NullValueCase('terminal');
            });
        this.treeService.watchPeriphID$
            .pipe(
                takeWhile(() => this.alive),
                skip(1)
            )
            .subscribe(perphID => {
                if (perphID) {
                    this.parameters = {
                        xPeriphID: this.treeService.periphID.getValue(),
                        hashData: true
                    };
                    this.filterData();
                } else this.handleWatcher_NullValueCase('peripheral');
            });

        //End of Watchers for changes in the tree--------->
    }

    setBreadCrumbValue(gmidString: string, tooltip: string, loading: boolean): void {
        console.log('tooltipstring', tooltip.length);
        const breadCrumb: any = {};
        breadCrumb.root = 'Transactions';
        breadCrumb.child = gmidString;
        breadCrumb.subChild = '';
        breadCrumb.subChildLink = null;
        breadCrumb.grandChild = null;
        if (tooltip.length === 0) {
            breadCrumb.tooltip = false;
        } else {
            breadCrumb.toolTip = true;
        }
        breadCrumb.toolTipData = tooltip;
        breadCrumb.loading = loading;
        this.breadcrumbService.setBreadCrumb(breadCrumb);
    }

    recievePCtrList(event: any) {
        console.log('event in pctrlist', event);
        this.gmidList = [];
        this.pctrListString = '';
        event.map(item => {
            this.pctrListString += item + ',';
            // remove this statement once api call added
            // this.gmidList.push(item);
        });
        this.pctrListString = this.pctrListString.slice(0, -1);
        let params = { pctrIDs: this.pctrListString, selectKey: 'xGMID' };
        this.setBreadCrumbValue('GMID - ', '', true);
        console.log('params for gmid', params);
        this.httpService.getAll('xbcPCtrs', params).subscribe(
            response => {
                console.log('response get gmid', response);
                response.data.map(item => {
                    this.gmidList.push(item.xGMID);
                });
                console.log('gmidlist', this.gmidList);
                console.log('pctrliststring', this.pctrListString);
                let gmidStringBreadCrumb = 'GMID - None',
                    tooltipGMID = '';
                if (this.gmidList.length === 1) gmidStringBreadCrumb = 'GMID - ' + this.gmidList[0];
                else if (this.gmidList.length >= 2) gmidStringBreadCrumb = 'GMID - ' + this.gmidList[0] + ', ' + this.gmidList[1];
                this.gmidList.map(item => {
                    tooltipGMID += item + ',';
                });
                tooltipGMID = tooltipGMID.slice(0, -1);
                this.setBreadCrumbValue(gmidStringBreadCrumb, tooltipGMID, false);
            },
            error => {
                console.log('Failed api call for gmid');
            }
        );
    }

    setInitParams() {
        if (this.treeService.businessID.getValue())
            this.parameters = Object.assign({}, this.parameters, {
                xBusinessID: this.treeService.businessID.getValue(),
                hashData: true
            });

        if (this.treeService.chainID.getValue())
            this.parameters = Object.assign({}, this.parameters, {
                struct_type: STRUCT_TYPE_CHAIN,
                structID: this.treeService.chainID.getValue(),
                hashData: true
            });

        if (this.treeService.propertyID.getValue())
            this.parameters = Object.assign({}, this.parameters, {
                struct_type: STRUCT_TYPE_PROPERTY,
                structID: this.treeService.propertyID.getValue(),
                hashData: true
            });

        if (this.treeService.regionID.getValue())
            this.parameters = Object.assign({}, this.parameters, {
                struct_type: STRUCT_TYPE_REGION,
                structID: this.treeService.regionID.getValue(),
                hashData: true
            });

        if (this.treeService.storeID.getValue())
            this.parameters = Object.assign({}, this.parameters, {
                xStoreID: this.treeService.storeID.getValue(),
                hashData: true
            });

        if (this.treeService.profitCenterID.getValue())
            this.parameters = Object.assign({}, this.parameters, {
                xPCtrID: this.treeService.profitCenterID.getValue(),
                hashData: true
            });

        if (this.treeService.merchantAccID.getValue())
            this.parameters = Object.assign({}, this.parameters, {
                xMerAcctID: this.treeService.merchantAccID.getValue(),
                hashData: true
            });

        if (this.treeService.terminalID.getValue())
            this.parameters = Object.assign({}, this.parameters, {
                xTerminalID: this.treeService.terminalID.getValue(),
                hashData: true
            });

        if (this.treeService.periphID.getValue())
            this.parameters = Object.assign({}, this.parameters, {
                xPeriphID: this.treeService.periphID.getValue(),
                hashData: true
            });

        if (this.batchedtxns)
            this.parameters = Object.assign({}, this.parameters, {
                xBatchID: this.paramId,
                hashData: true
            });
    }

    filterData() {
        let filterField:string = '';
        this.transactionActionService.setResetFilter(true);
        switch (this.filter) {
            case LAST_DAYS_FILTER.all:
                // console.log('here 2');
                delete this.parameters['filterType'];
                if (this.parameters.hasOwnProperty('startDate')) delete this.parameters['startDate'];
                if (this.parameters.hasOwnProperty('endDate')) delete this.parameters['endDate'];
                if (this.parameters.hasOwnProperty('currDate')) delete this.parameters['currDate'];
                if (this.parameters.hasOwnProperty('filterField')) delete this.parameters['filterField'];
                this.parameters = Object.assign({}, this.parameters);
                break;

            case LAST_DAYS_FILTER.last_24_hrs:
                if (this.parameters.hasOwnProperty('filterField')) delete this.parameters['filterField'];
                this.parameters = Object.assign({}, this.parameters, {
                    filterType: LAST_DAYS_FILTER.last_24_hrs
                });
                break;
            case LAST_DAYS_FILTER.last_7_days:
                if (this.parameters.hasOwnProperty('filterField')) delete this.parameters['filterField'];
                this.parameters = Object.assign({}, this.parameters, {
                    filterType: LAST_DAYS_FILTER.last_7_days
                });
                break;
            case LAST_DAYS_FILTER.last_30_days:
                if (this.parameters.hasOwnProperty('filterField')) delete this.parameters['filterField'];
                this.parameters = Object.assign({}, this.parameters, {
                    filterType: LAST_DAYS_FILTER.last_30_days
                });
                break;
            case LAST_DAYS_FILTER.last_60_days:
                if (this.parameters.hasOwnProperty('filterField')) delete this.parameters['filterField'];
                this.parameters = Object.assign({}, this.parameters, {
                    filterType: LAST_DAYS_FILTER.last_60_days
                });
                break;
            case LAST_DAYS_FILTER.last_90_days:
                if (this.parameters.hasOwnProperty('filterField')) delete this.parameters['filterField'];
                this.parameters = Object.assign({}, this.parameters, {
                    filterType: LAST_DAYS_FILTER.last_90_days
                });
                break;
            case 'advancedDateFilter':
                delete this.parameters['currDate'];
                switch(this.advancedDateFilterType){
                    case DATE_FILTER_AUTH:
                        filterField = AUTH_DATE;
                        break;
                    case DATE_FILTER_FINALIZE:
                        filterField = FINALIZE_DATE;
                        break;
                    case DATE_FILTER_SETTLE:
                        filterField = SETTLE_DATE;
                        break;
                    default:
                        filterField = AUTH_DATE;
                        this.advancedDateFilterType = DATE_FILTER_AUTH;
                }
                this.parameters = Object.assign({}, this.parameters, {
                    filterType: 'advancedDateFilter',
                    filterField: filterField
                });
                break;
        }

        if (this.filter !== 'advancedDateFilter') {
            this.advancedDateFilterType = '';
        }
        if (this.filter !== 'all' && this.filter !== 'advancedDateFilter') {
            if (this.parameters.hasOwnProperty('startDate')) delete this.parameters['startDate'];
            if (this.parameters.hasOwnProperty('endDate')) delete this.parameters['endDate'];
            this.parameters = Object.assign({}, this.parameters, {
                currDate: new Date().toDateString()
            });
        } else if (this.filter === 'advancedDateFilter') {
            this.parameters = Object.assign({}, this.parameters, {
                startDate: new Date(this.dateRangeValue[0]).toDateString(),
                endDate: new Date(this.dateRangeValue[1]).toDateString()
            });
        }

        if (this.batchedtxns)
            this.parameters = Object.assign({}, this.parameters, {
                xBatchID: this.paramId
            });

        console.log('this.parameters=====>', this.parameters);

        this.setGridProperties();
    }

    /**
    * sets advanced data filter
    *
    * @params : `type` : string : set which filter to set
    */
    setDateFilter(type){
        console.log('setDateFilter:type',type);
        this.advancedDateFilterType = type;
        this.onDateFilterClick();
    }

    onDateFilterClick() {
        console.log('this.dateRangeValue', this.dateRangeValue);
        // console.log(
        //     "this.dateRangeValue",
        //     JSON.parse(JSON.stringify(this.dateRangeValue[0]))
        // );
        if (this.dateRangeValue && this.dateRangeValue.length) {
            this.filter = 'advancedDateFilter';
            this.filterData();
        }
    }

    fetchBatchInfo(id: string) {
        this.batchStore.get(id).subscribe(
            (batchResponse: any) => {
                this.batchNum = batchResponse.data ? batchResponse.data.xBatchNum : '';
                let storeID = 
                    validateFor(BE_KEY.data, batchResponse) 
                    && validateFor(XPESETTLEDTXNS.xStoreID, batchResponse[BE_KEY.data])  
                    ? batchResponse[BE_KEY.data][XPESETTLEDTXNS.xStoreID]: '';
                    if(storeID){
                        this.setStoreName(storeID);
                    }
            },
            (error: any) => {
                this.notificationService.error(JSON.parse(error._body).name, JSON.parse(error._body).message);
            }
        );
    }

    /**
     * Get and set Store-Name
     * @param storeID: string: mongoID of store
     */
    setStoreName(storeID: string){
        console.log('storeID: setStoreName', storeID);
        let storeParams = {
            _id: storeID,
            selectKey: XPESETTLEDTXNS.xName,
            noGrid: true
        }
        this.setupBusinessStructureStoreSetupStore.getAll(storeParams).subscribe(storeResponse => {
            console.log('storeResponse: setStoreName', storeResponse);
            this.storeName = 
                validateFor(BE_KEY.data, storeResponse) 
                && storeResponse[BE_KEY.data].length
                && validateFor(XPESETTLEDTXNS.xName, storeResponse[BE_KEY.data][0])
                ? storeResponse[BE_KEY.data][0][XPESETTLEDTXNS.xName]: '-';
                this.abbreviatedStoreName = 
                    this.storeName.length > STORE_NAME_ABBREVIATION_LENGTH_MAX_CAP 
                    ? abbreviateStoreName(this.storeName, KEEP_NO_OF_CHARACTERS_FIRST, KEEP_NO_OF_CHARACTERS_LAST): this.storeName;
            console.log('storeName: setStoreName', this.storeName);
        }, error => {
            console.log('error: setStoreName', error);
            this.notificationService.error(UNABLE_TO_SET_STORE_NAME_ERROR, 'Error');
        })
    }

    setGridProperties() {
        this.storeValue = 'SettledTransactionsStore';
        this.storeObject = this.settledTransactionsStore;

        //All columns--------->
        this.attributes = [
            { header: 'Sel. All', mapTo: '', width: '1%' },
            { header: 'Type', mapTo: 'xTxnActTag', width: '4%' },
            { header: 'Transaction #', mapTo: 'xTxnNum', width: '10%' },
            { header: 'Invoice', mapTo: 'xInvNum', width: '7%' },
            { header: 'Customer #', mapTo: 'xCustNum', width: '7%'},
            { header: 'Card', mapTo: 'xBrandTag', width: '4%' },
            { header: 'Account #', mapTo: 'xMaskedAcctNum', width: '7%' },
            { header: 'fa fa-credit-card', mapTo: 'xAcctEntrTag', width: '3%' },
            { header: 'Amount', mapTo: 'xMainAmt', width: '6%' },
            { header: 'Tip', mapTo: 'xTipAmt', width: '4%' },
            { header: 'Total', mapTo: 'xTtlAmt', width: '5%' },
            { header: 'App Code', mapTo: 'xAuthCode', width: '7%' },
            {
                header: 'Auth Time',
                mapTo: 'xAuthDateTime',
                width: '7%'
            },
            {
                header: 'Settle Time',
                mapTo: 'xSettleDateTime',
                width: '7%'
            }
        ];
        console.log('setGridProperties:advancedDateFilterType',this.advancedDateFilterType);

        this.action = [
            {
                type: 'view',
                visible: true,
                text: 'Details',
                icon: 'fa fa-eye',
                action: 'emit'
            },
            {
                type: 'modal',
                visible: true,
                text: 'Credit',
                icon: 'fa fa-info',
                action: 'emit'
            } //TODO-: Type is to be changed accordingly in future
        ];
    }

    // when input box is focused data is selected
    onModalInputFocused(event: any) {
        console.log('click called out txn addtip', event);
        event.target.select();
    }

    // disable tip amount if obj has no tip amt
    checkTipAmtDisable(obj) {
        if ((obj.hasOwnProperty('disabled') && obj.disabled) || (obj.hasOwnProperty('disableOnlyEdit') && obj.disableOnlyEdit)) {
            return true;
        } else {
            return obj.hasOwnProperty('tipAmt') ? false : true;
        }
    }

    // prevent user to enter anything other than numbers
    onKeypress(event: any) {
        GlobalValidator.amountFormat(event);
    }

    // prevent user to enter anything other than numbers
    onPaste(event: any) {
        GlobalValidator.amountFormat(event);
    }

    // reset all actions when modal is closed
    onEditModalClose() {
        this.showEditModal = false;
        console.log('onEditModalClose: currentaction', this.currentAction);
        console.log('onEditModalClose: previousAction', this.previousAction);
        switch (this.currentAction) {
            case 'redo':
                console.log('onEditModalClose redo');
                this.transactionActionService.setDoRedo(false);
                this.redoLoader = false;
                break;
            case 'reverse':
                console.log('onEditModalClose reverse');
                this.transactionActionService.setDoReverse(false);
                this.reverseLoader = false;
                break;
        }
        // this.transactionActionService.setDoRedo(false);
        this.disableActionBtns = false;
        this.previousAction = this.currentAction;
        this.currentAction = '';
        this.disableActionBtns = false;
        console.log('onEditModalClose: updated currentaction', this.currentAction);
        console.log('onEditModalClose: updated previousAction', this.previousAction);
    }

    // convert it to edit txns data for modal
    convertDataForEditModal(txnResponseData, pctrResponse, txnResponseMeta) {
        console.log('convertDataForEditModal txnResponse', txnResponseData);
        console.log('convertDataForEditModal pctrResponse', pctrResponse);
        console.log('convertDataForEditModal txnResponseMeta', txnResponseMeta);
        console.log('convertDataForEditModal currentAction', this.currentAction);
        let filteredHashedData = [];
        txnResponseData.map(singleTxnData => {
            console.log('convertDataForEditModal: singleTxnData', singleTxnData);
            if (singleTxnData && singleTxnData.hasOwnProperty('_id') && singleTxnData._id) {
                let hashedDataOfTxn = txnResponseMeta[singleTxnData._id] ? txnResponseMeta[singleTxnData._id] : {};
                // let doAddHashData = false;
                let editTxnObj = { disabled: true };
                let doAddTip: boolean = false;
                switch (pctrResponse[singleTxnData.xPCtrID]) {
                    case TAGSCONSTS.industryTags.industryItemTags.restaurant:
                    case TAGSCONSTS.industryTags.industryItemTags.retail:
                        console.log('convertDataForEditModal: rest/retail', pctrResponse[singleTxnData.xPCtrID]);
                        // this.setDataInEditTxnsAndFilteredHash(singleTxnData, true, filteredHashedData, hashedDataOfTxn);
                        // doAddHashData = this.setDataInEditTxnsAndFilteredHash(singleTxnData, true);
                        doAddTip = true;
                        editTxnObj = Object.assign({}, createEditObj(singleTxnData, doAddTip, this.currentAction));
                        console.log('convertDataForEditModal: edittxns', this.editTxns);
                        break;
                    default:
                        console.log('convertDataForEditModal: not rest/retail', pctrResponse[singleTxnData.xPCtrID]);
                        // this.setDataInEditTxnsAndFilteredHash(singleTxnData, false, filteredHashedData, hashedDataOfTxn);
                        doAddTip = false;
                        editTxnObj = Object.assign({}, createEditObj(singleTxnData, doAddTip, this.currentAction));
                        console.log('convertDataForEditModal: edittxns', this.editTxns);
                }
                // if (doAddHashData) filteredHashedData.push(hashedDataOfTxn);
                this.editTxns.push(editTxnObj);
                if (editTxnObj && editTxnObj.hasOwnProperty('disabled') && !editTxnObj.disabled) {
                    filteredHashedData.push(hashedDataOfTxn);
                }
            }
        });
        console.log('convertDataForEditModal: edittxns', this.editTxns);
        console.log('convertDataForEditModal: filteredHashedData', filteredHashedData);
        this.finalHashedData = filteredHashedData.length ? filteredHashedData : [];
        this.showEditModal = true;
    }

    // get txn data and indst tag
    getDataForEditModal(params) {
        console.log('getDataForEditModal: params', params);
        return this.settledTransactionsStore.getAll(params).subscribe(
            txnResponse => {
                console.log('getDataForEditModal: txnResponse', txnResponse);
                // if(txnResponse && txnResponse.hasOwnProperty('data') && txnResponse['data']){
                let txnResponseData = txnResponse && txnResponse.hasOwnProperty('data') && txnResponse.data ? txnResponse.data : {};
                let txnResponseMeta = txnResponse && txnResponse.hasOwnProperty('meta') && txnResponse.meta ? txnResponse.meta : {};
                console.log('getDataForEditModal: txnResponseData', txnResponseData);
                console.log('getDataForEditModal: txnResponseMeta', txnResponseMeta);
                if (Object.keys(txnResponseData).length && Object.keys(txnResponseMeta).length) {
                    let pctrParams = {
                        PCtrIDs: []
                    };
                    let txnHashedData =
                        Object.keys(txnResponseMeta).length &&
                        txnResponseMeta.hasOwnProperty('hashedData') &&
                        txnResponseMeta.hashedData &&
                        txnResponseMeta.hashedData.length
                            ? txnResponseMeta.hashedData
                            : {};
                    const PRIMARY_FIELD = 'record_id';
                    const NOT_ONE_TO_ONE = false;
                    let txnMetaAsscObj = processToAssociativeObject(txnHashedData, PRIMARY_FIELD, '', NOT_ONE_TO_ONE);
                    console.log('getDataForEditModal: txnMetaAsscObj', txnMetaAsscObj);
                    txnResponseData.map(singleTxnData => {
                        if (singleTxnData.hasOwnProperty('xPCtrID') && singleTxnData.xPCtrID) {
                            pctrParams.PCtrIDs.push(singleTxnData.xPCtrID);
                        }
                    });
                    console.log('getDataForEditModal: pctrid', pctrParams.PCtrIDs);
                    this.httpService.store('pCtrIndstTags', pctrParams).subscribe(
                        pctrResponse => {
                            console.log('getDataForEditModal: pctrResponse', pctrResponse);
                            const PRIMARY_FIELD = 'pCtrID';
                            const SECONDARY_FIELD = 'indstType';
                            const ONE_TO_ONE = true;
                            let pctrAsscObj = processToAssociativeObject(pctrResponse, PRIMARY_FIELD, SECONDARY_FIELD, ONE_TO_ONE);
                            console.log('getDataForEditModal: pctrAsscObj', pctrAsscObj);
                            if (pctrResponse && pctrResponse.length) {
                                this.convertDataForEditModal(txnResponseData, pctrAsscObj, txnMetaAsscObj);
                            }
                        },
                        tagError => {
                            console.log('getDataForEditModal: tagerror', tagError);
                            this.notificationService.error('Failed to fetch industry information', 'Error');
                        }
                    );
                }
            },
            error => {
                console.log('getDataForEditModal: error', error);
                this.notificationService.error('Failed to fetch latest transaction data.', 'Error');
            }
        );
    }

    performAction() {
        console.log('performAction action', this.currentAction);
        let editModalErrorExists: boolean = false;
        editModalErrorExists = !this.editTxns.every(txn => {
            console.log('performAction: txn', txn);
            let tipAmtError = txn && txn.hasOwnProperty('tipAmtError') ? txn.tipAmtError : false;
            let baseAmtError = txn && txn.hasOwnProperty('baseAmtError') ? txn.baseAmtError : false;
            let taxAmtError = txn && txn.hasOwnProperty('taxAmtError') ? txn.taxAmtError : false;
            if (tipAmtError || baseAmtError || taxAmtError) {
                return false;
            }
            return true;
        });
        if (editModalErrorExists) {
            console.log('performAction: return as error exists');
            this.notificationService.error(INVALID_AMT_MSG, 'Error');
            return;
        }
        this.showEditModal = false;
        switch (this.currentAction) {
            case 'redo':
                console.log('performAction redo');
                this.performRedo_ReverseAction(TRANSACTIONS_ACTION_REDO);
                // this.performRedoAction();
                break;
            case 'reverse':
                console.log('performAction reverse');
                this.performRedo_ReverseAction(TRANSACTIONS_ACTION_REVERSE);
                // this.performReverseAction();
                break;
        }
    }

    // checks for txn ids if available adds them to params, if no ids available resets action status and loaders
    createTxnParams(obj) {
        let txnParams = {};
        let ids = obj && obj.hasOwnProperty('ids') && obj.ids ? obj.ids : '';
        txnParams = {
            xBusinessID: this.businessService.businessID.getValue(),
            txnIds: ids.toString(),
            hashData: true
        };
        return txnParams;
    }

    onEmitChecked(obj: any) {
        console.log('onEmitChecked: obj', obj);
        this.currentAction = obj.action;
        this.transactionActionService.setActionPerformed(false);
        this.finalHashedData = [];
        this.editTxns = [];
        this.selectAll = false;
        let txnParams = {};
        switch (obj.action) {
            case 'reverse':
                // console.log("Object inside reverse", obj);
                txnParams = this.createTxnParams(obj);
                console.log('onEmitChecked: reverse txnParams', txnParams);
                this.typeForResponseModal = 'Reverse';
                this.checkedIds = obj.ids;
                this.finalHashedData = obj.hashedData;
                this.editModalTitle = 'Reverse Transaction';
                if (!obj.hasOwnProperty('selAll') || !obj.selAll) {
                    if (obj && obj.ids.length) {
                        if (obj.ids.length > 5) {
                            this.limitExceededSwal('Reverse');
                        } else {
                            // this.performReverseAction();
                            this.getDataForEditModal(txnParams);
                            // this.performRedo_ReverseAction(TRANSACTIONS_ACTION_REVERSE);
                        }
                    } else {
                        this.noTransactionSelectedSwal('Reverse');
                    }
                } else {
                    if (obj.hasOwnProperty('totalRecords')) {
                        if (obj.totalRecords <= 5) {
                            // this.performReverseAction();
                            this.getDataForEditModal(txnParams);
                            // this.performRedo_ReverseAction(TRANSACTIONS_ACTION_REVERSE);
                        } else {
                            if (obj.uncheckedIds && obj.uncheckedIds.length && obj.totalRecords - obj.uncheckedIds.length <= 5) {
                                // this.performReverseAction();
                                this.getDataForEditModal(txnParams);
                                // this.performRedo_ReverseAction(TRANSACTIONS_ACTION_REVERSE);
                            } else {
                                this.limitExceededSwal('Reverse');
                            }
                        }
                    }
                }
                break;
            case 'redo':
                txnParams = this.createTxnParams(obj);
                console.log('onEmitChecked: reverse txnParams', txnParams);
                this.checkedIds = obj.ids;
                this.finalHashedData = obj.hashedData;
                this.typeForResponseModal = 'Redo';
                this.editModalTitle = 'Redo Transaction';
                // this.getDataForEditModal(txnParams)
                if (!obj.hasOwnProperty('selAll') || !obj.selAll) {
                    if (obj && obj.ids.length) {
                        if (obj.ids.length > 5) {
                            this.limitExceededSwal('Redo');
                        } else {
                            // this.performRedoAction();
                            this.getDataForEditModal(txnParams);
                            // this.performRedo_ReverseAction(TRANSACTIONS_ACTION_REDO);
                        }
                    } else {
                        this.noTransactionSelectedSwal('Redo');
                    }
                } else {
                    if (obj.hasOwnProperty('totalRecords')) {
                        if (obj.totalRecords <= 5) {
                            // this.performRedoAction();
                            this.getDataForEditModal(txnParams);
                            // this.performRedo_ReverseAction(TRANSACTIONS_ACTION_REDO);
                        } else {
                            if (obj.uncheckedIds && obj.uncheckedIds.length && obj.totalRecords - obj.uncheckedIds.length <= 5) {
                                // this.performRedoAction();
                                this.getDataForEditModal(txnParams);
                                // this.performRedo_ReverseAction(TRANSACTIONS_ACTION_REDO);
                            } else {
                                this.limitExceededSwal('Redo');
                            }
                        }
                    }
                }
                break;
            case 'reprint':
                // console.log("Checked object inside reprint", obj);
                this.rePrint = false;
                this.checkedIds = obj.ids;
                if ((obj && obj.ids.length) || (obj.hasOwnProperty('selAll') && obj.selAll)) {
                    this.typeForResponseModal = 'Re-Print';
                    this.typeForPaginationModal = 'Re-Print';
                    if (obj && obj.hasOwnProperty('selAll')) {
                        this.selectAll = true;
                        this.onPaginationModal(obj, 'rePrint');
                    } else {
                        this.selectAll = false;
                        if (obj && obj.data.length) {
                            let observable = [];
                            let storeIDArr = [];
                            this.transactionStoreData = [];
                            obj.data.map(txnData => {
                                if (storeIDArr.indexOf(txnData.xStoreID) === -1) {
                                    storeIDArr.push(txnData.xStoreID);
                                }
                            });
                            observable.push(
                                this.httpService
                                    .getAll('xbcStores', {
                                        storeIds: storeIDArr.toString()
                                    })
                            );
                            forkJoin(observable).subscribe(
                                (response: any) => {
                                    obj.data.map(singleTxnData => {
                                        response[0].data.map(storeRes => {
                                            if (storeRes._id === singleTxnData.xStoreID) {
                                                this.transactionStoreData.push({
                                                    txnData: singleTxnData,
                                                    storeData: storeRes
                                                });
                                            }
                                        });
                                    });
                                    this.performReprintSwal();
                                },
                                error => {}
                            );
                        } else {
                            this.noTransactionSelectedSwal('Re-print');
                        }
                    }
                } else {
                    this.noTransactionSelectedSwal('Re-print');
                }
                break;
            case 'printDetail':
                this.checkedIds = obj.ids;
                // console.log("object received inside transaction printDetails", obj);
                if ((obj && obj.ids.length) || (obj.hasOwnProperty('selAll') && obj.selAll)) {
                    this.typeForResponseModal = 'Print Detail';
                    this.typeForPaginationModal = 'Print Detail';
                    if (obj && obj.hasOwnProperty('selAll')) {
                        this.selectAll = true;
                        this.onPaginationModal(obj, 'printDetail');
                    } else {
                        this.selectAll = false;
                        if (obj && obj.ids.length) {
                            this.visiblePrintDetailModal = true;
                        } else {
                            this.noTransactionSelectedSwal('Export Detail');
                        }
                    }
                } else {
                    this.noTransactionSelectedSwal('Export Detail');
                }
                break;
        }
    }

    performCommonActionsOnErrorResponse() {
        this.treeService.setShow(true);
        this.treeService.setEnable(true);
        this.disableActionBtns = false;
        this.reverseLoader = false;
        this.redoLoader = false;
        this.rePrintLoader = false;
        this.printDetailLoader = false;
        this.storeObject = this.settledTransactionsStore;
        this.parameters = JSON.parse(JSON.stringify(this.parameters));
    }

    onPaginationModal(obj: any, type?: string) {
        // console.log("Inside onPaginationModal", obj, JSON.parse(JSON.stringify(this.donePages)), this.previousAction, this.currentAction);
        let recordsPerPage = 100;
        if (type === 'printDetail' || type === 'rePrint') {
            recordsPerPage = 500;
        }
        if (this.previousAction !== this.currentAction) {
            this.donePages = [];
            this.previousAction = this.currentAction;
        }
        if (obj && obj.hasOwnProperty('parameters')) {
            // console.log("obj.paramters", obj);
            if (obj.parameters.hasOwnProperty('selAll')) delete obj.parameters.selAll;
            // if(obj.parameters.hasOwnProperty('excludeIDs'))
            let params = JSON.parse(JSON.stringify(obj.parameters));

            params['selectAll'] = true;
            // params['excludeIDs']="";

            if (obj.hasOwnProperty('ids')) params['excludeIDs'] = obj.ids.toString();
            this.paginationParams = JSON.parse(JSON.stringify(params));
            this.totalNumberOfRecords = obj.totalRecords;

            if (obj.ids && obj.ids.length) {
                this.totalNumberOfRecords = this.totalNumberOfRecords - obj.ids.length;
            }

            for (let i: number = 0; i < Math.floor(this.totalNumberOfRecords / recordsPerPage); i++) {
                if (this.totalPages.indexOf(i + 1) === -1) this.totalPages.push(i + 1);
            }
            // console.log("this.totalNumbers",this.totalNumberOfRecords, this.totalPages);
            if (this.totalNumberOfRecords % recordsPerPage) {
                this.totalPages.push(this.totalPages.length + 1);
            }
            // console.log("this.totalNumbers",this.totalNumberOfRecords, this.totalPages, this.showPaginationModal)
            this.showPaginationModal = true;
            // console.log("this.totalNumbers",this.totalNumberOfRecords, this.totalPages, this.showPaginationModal);
            // })
        }
    }

    onTxnsDetailReceive(value) {
        // console.log("HERE I'm",value);
        this.showPaginationModal = false;
        if (value && value.hasOwnProperty('actionType') && value['actionType']) {
            if (value.hasOwnProperty('currPage')) {
                // console.log("value.pagesDone", value.currPage, value.actionType, this.previousAction, this.currentAction);
                // console.log("value.pagesDone", this.previousAction === value.actionType, this.previousAction === '');

                // this.donePages=[];
                if (this.previousAction === '' || this.previousAction === value.actionType) {
                    if (this.donePages.indexOf(value.currPage) === -1) this.donePages.push(value.currPage);
                }
                //  JSON.parse(JSON.stringify(value.pagesDone));
                else {
                    this.donePages = [];
                }
                if (this.currentAction !== this.previousAction) {
                    this.previousAction = this.currentAction;
                    // this.donePages=[];
                }
            }
            switch (value.actionType) {
                case 'printDetail':
                    this.visiblePrintDetailModal = true;
                    break;
                case 'reprint':
                    this.transactionStoreData = JSON.parse(JSON.stringify(value.transactionStoreData));
                    this.performReprintSwal();
                    break;
            }
        }
    }

    performReprintSwal() {
        this.rePrint = true;
        customSwal.fire({
            title: 'Are you sure?',
            text: 'Click Yes to continue, No to decline',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.transactionActionService.setPrint(true);
            } else {
                if (this.donePages.length) {
                    this.donePages.splice(this.donePages.length - 1, 1);
                }
                this.totalPages = [];
                this.transactionActionService.setPrint(false);
                this.transactionActionService.setDoRePrint(false);
                this.rePrintLoader = false;
                this.disableActionBtns = false;
                this.rePrint = false;
            }
        })
    }

    performRedo_ReverseAction(mode) {
        console.log('performRedoAction: edittxns', this.editTxns);
        console.log('performRedoAction: finalHashedData', this.finalHashedData);
        //-------------------------------IMPORTANT NOTE-------------------------------------------------------
        // This code is needed when running redo/reverse action with custom amounts (edit modal)

        let params = {
            txnIds: [],
            editAmts: [],
            txnType: TRANSACTIONS_TYPE_CLOSED_TXNS
        };
        this.editTxns.map(value => {
            if (value && value.hasOwnProperty('disabled') && !value.disabled) {
                let editAmtsObj = {
                    txnId: value.txnId,
                    mainAmt: value.baseAmt,
                    incTaxAmt: value.taxAmt ? value.taxAmt : '0'
                };

                if (value.hasOwnProperty('tipAmt')) editAmtsObj['tipAmt'] = value.tipAmt ? value.tipAmt : '0';

                params['txnIds'].push(value.txnId);
                params['editAmts'].push(editAmtsObj);
            }
        });
        params['originalData'] = this.finalHashedData;

        //---------------------------------END-----------------------------------------------------

        //-------------------------------IMPORTANT NOTE-------------------------------------------------------
        // This code is needed when running redo/reverse action w/o custom amounts (edit modal)
        // let params = {
        //     txnIds: this.checkedIds,
        //     txnType: 'settledTxns',
        //     originalData: this.finalHashedData
        // };
        //-------------------------------------END-------------------------------------------------

        console.log('performRedoAction: params', params);
        customSwal.fire({
            title: 'Are you sure?',
            text: 'Click Yes to continue, No to decline',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                let API_END_POINT = '';
                let redoReverseActionCallback;

                let redoCallBack = () => {
                    this.transactionActionService.setDoRedo(false);
                    this.redoLoader = false;
                };

                let reverseCallBack = () => {
                    this.transactionActionService.setDoReverse(false);
                    this.reverseLoader = false;
                };

                switch (mode) {
                    case TRANSACTIONS_ACTION_REDO:
                        API_END_POINT = RE_AUTH_END_POINT;
                        redoReverseActionCallback = redoCallBack;
                        break;
                    case TRANSACTIONS_ACTION_REVERSE:
                        API_END_POINT = REVERSE_END_POINT;
                        redoReverseActionCallback = reverseCallBack;
                        break;

                    default:
                        //TODO: Write default case
                        break;
                }

                this.showOverLay = true;
                if (!this.checkedIds || !this.checkedIds.length) {
                    this.showOverLay = false;
                    redoReverseActionCallback();

                    this.disableActionBtns = false;
                    this.notificationService.error('Inconsistent data. Please refresh.', 'Error');
                    return;
                }

                this.httpService
                    .store(API_END_POINT, params)
                    .subscribe(
                        response => {
                            console.log('Success response inside redo', response);
                            this.responseForResponseModal = [response];
                            this.showResponseModal = true;

                            redoReverseActionCallback();

                            this.disableActionBtns = false;
                            this.transactionActionService.setActionPerformed(true);
                            this.showOverLay = false;
                        },
                        error => {
                            console.log('Error response inside redo', error);
                            this.showResponseModal = false;

                            redoReverseActionCallback();
                            // this.transactionActionService.setDoRedo(false);
                            // this.redoLoader = false;

                            this.disableActionBtns = false;
                            this.transactionActionService.setActionPerformed(true);
                            this.showOverLay = false;
                            this.performCommonActionsOnErrorResponse();
                        },
                        () => {}
                    );
            } else {
                this.transactionActionService.setDoRedo(false);
                this.transactionActionService.setDoReverse(false);
                this.redoLoader = false;
                this.reverseLoader = false;
                this.disableActionBtns = false;
            }
        })
    }

    limitExceededSwal(actionType) {
        customSwal.fire({
            title: `Selected Transactions limit exceeded`,
            text: 'Please select atmost 5 transactions at a time, to ' + actionType,
            icon: 'warning',
            confirmButtonText: 'Okay'
        }).then((result) => {
            if (result.value) {
                if (actionType === 'Redo') {
                    this.transactionActionService.setDoRedo(null);
                    this.redoLoader = false;
                } else if (actionType === 'Reverse') {
                    this.transactionActionService.setDoReverse(null);
                    this.reverseLoader = false;
                }
                this.disableActionBtns = false;
            }
        })
    }

    noTransactionSelectedSwal(actionType) {
        customSwal.fire({
            title:  `No Transactions are selected`,
            text: 'Please select a transaction, to ' + actionType,
            icon: 'warning',
            confirmButtonText: 'Okay'
        }).then((result) => {
            if (result.value) {
                switch (actionType) {
                    case 'Reverse':
                        this.transactionActionService.setDoReverse(false);
                        this.reverseLoader = false;
                        break;
                    case 'Redo':
                        this.transactionActionService.setDoRedo(false);
                        this.redoLoader = false;
                        break;
                    case 'Re-print':
                        this.transactionActionService.setDoRePrint(false);
                        this.rePrintLoader = false;
                        break;
                    case 'Export Detail':
                        this.transactionActionService.setPrintDetail(false);
                        this.printDetailLoader = false;
                        break;
                }
                this.disableActionBtns = false;
            }
        })
    }

    onTxnObjReceived(event) {
        // console.log("event==>", event);
        if (event) {
            if (event.hasOwnProperty('totTxn') && event['totTxn'] && event.hasOwnProperty('totTxnAmt') && event['totTxnAmt']) {
                this.totalObj = {
                    totTxn: event.totTxn,
                    totTxnAmt: event.totTxnAmt
                };
            } else {
                this.totalObj = {};
            }
            if (event.hasOwnProperty('selTxn') && event['selTxn'] && event.hasOwnProperty('selTxnAmt')) {
                this.selectedObj = {
                    selTxn: event.selTxn,
                    selTxnAmt: event.selTxnAmt
                };
            } else {
                this.selectedObj = {};
            }
        } else {
            this.totalObj = {};
            this.selectedObj = {};
        }
    }

    handleExternalApiError(response) {
        let failedIds = [];
        if (response[0]['failedAPI'].length) {
            response[0]['failedAPI'].map(failedRes => {
                // console.log("failedRes", failedRes);
                if (failedRes.hasOwnProperty('modelID') && failedRes['modelID'].length === 1) {
                    // console.log(
                    //     "failedRes['modelID'][0]",
                    //     failedRes["modelID"][0]
                    // );
                    failedIds.push(failedRes['modelID'][0]);
                } else if (failedRes.hasOwnProperty('modelID') && failedRes['modelID'].length > 1) {
                    // console.log("failedRes['modelID'", failedRes["modelID"]);
                    failedRes['modelID'].map(id => {
                        // console.log("iddddd", id);
                        failedIds.push(id);
                    });
                }
            });
        }
        let idString = '';
        failedIds.map((id, index) => {
            if (index === 0) {
                idString = id;
            } else {
                idString = idString + ', ' + id;
            }
        });
        let errorMessage = 'Cannot perform this action on transactions with ID:- ' + idString;
        this.notificationService.error(errorMessage, 'Error');
    }

    setDoReverse() {
        this.reverseLoader = true;
        this.disableActionBtns = true;
        this.transactionActionService.setDoReverse(true);
    }

    setRePrint() {
        // this.rePrint=true;
        this.rePrintLoader = true;
        this.disableActionBtns = true;
        this.transactionActionService.setDoRePrint(true);
    }

    setDoRedo() {
        // this.rePrint=true;
        this.redoLoader = true;
        this.disableActionBtns = true;
        this.transactionActionService.setDoRedo(true);
    }

    setPrintDetail() {
        this.printDetailLoader = true;
        this.disableActionBtns = true;
        this.transactionActionService.setPrintDetail(true);
    }

    onSummaryModalClose(stateObj: any) {
        this.visibleModal = false;
    }

    onActionIsCancel(isCancelled: boolean) {
        if (isCancelled) {
            this.treeService.setShow(true);
            this.treeService.setEnable(true);
        }
        this.showResponseModal = false;
        // this.transactionActionService.setActionPerformed(true);
        this.reverseLoader = false;
        this.redoLoader = false;
        this.rePrintLoader = false;
        this.printDetailLoader = false;
        this.disableActionBtns = false;
    }

    onResponseModalClose() {
        this.transactionActionService.setActionPerformed(true);
        this.transactionActionService.setRefreshData(true);
        this.disableActionBtns = false;
        // this.transactionActionService.setContinuousFetch(true); //Added for BG polling
        this.showResponseModal = false;
    }

    onPaginationModalClose(cancel: boolean = false) {
        // console.log("Here in pg modal close", cancel);
        this.totalNumberOfRecords = 0;
        this.totalPages = [];
        // this.donePages=[];
        if (cancel) {
            // this.previousAction='';
            this.currentAction = '';
            this.reverseLoader = false;
            this.rePrintLoader = false;
            this.printDetailLoader = false;
            this.disableActionBtns = false;
        }
        this.showPaginationModal = false;
    }

    onPrintDetailModalClose(stateObj: any) {
        this.visiblePrintDetailModal = false;
        this.transactionActionService.setPrintDetail(false);
        this.printDetailLoader = false;
        this.disableActionBtns = false;
        // console.log("Select all", this.selectAll);
        if (this.selectAll) {
            this.showPaginationModal = true;
        }
        // this.transactionActionService.setRefreshData(true);
    }

    onRePrintComplete(event) {
        this.rePrint = false;
        this.rePrintLoader = false;
        this.disableActionBtns = false;
        this.summaryReportService.print = false;
        if (event) {
            if (this.selectAll) {
                this.showPaginationModal = true;
            }
        }
    }

    onActionRequest(obj: any) {
        if (obj.act.type === 'view') {
            this.transactionID = `${obj.id}?${GET_PROC_KEY}=${GET_PROC_FIELDS}`;
            // this.detailVisible = true;
        }
    }

    onGenerateReport(obj: any) {
        // console.log("modal is visible");
        this.visibleModal = true;
    }

    onShowDetails(obj: any) {
        // console.log("Show object", obj);
        this.showDetails = obj;
    }

    onRefreshRequest(event) {
        // this.refreshGrid=false;
        // setTimeout(()=>{  //setTimeout is used because the change is so fast that it does not get detected.
        //     this.refreshGrid=event;
        // }, 100);
        this.transactionActionService.setRefreshData(true);
    }

    viewClosed(visible: boolean) {
        if (!visible) {
            this.transactionID = '';
            this.showDetails = false;
        }
    }

    fetchFilter() {
        switch (this.filter) {
            case LAST_DAYS_FILTER.all:
                // console.log('here', JSON.parse(JSON.stringify(this.parameters)));
                if (
                    this.parameters.hasOwnProperty('filterType') &&
                    (this.parameters.hasOwnProperty('currDate') ||
                        (this.parameters.hasOwnProperty('startDate') && this.parameters.hasOwnProperty('endDate')))
                ) {
                    // console.log('here 1');
                    this.filterData();
                }
                this.dateRangeValue = [];
                break;
            case LAST_DAYS_FILTER.last_24_hrs:
                this.filterData();
                this.dateRangeValue = [];
                break;
            case LAST_DAYS_FILTER.last_7_days:
                this.filterData();
                this.dateRangeValue = [];
                break;
            case LAST_DAYS_FILTER.last_30_days:
                this.filterData();
                this.dateRangeValue = [];
                break;
            case LAST_DAYS_FILTER.last_60_days:
                this.filterData();
                this.dateRangeValue = [];
                break;
            case LAST_DAYS_FILTER.last_90_days:
                this.filterData();
                this.dateRangeValue = [];
                break;
            default:
                console.log('reached default of date filter');
                break;
        }
    }

    /*
     * called on blur event of base,tip and tax amt fields of edit modal
     *
     * @params : amount : string : amount value
     * @params : field : string : depending upon fields check condition
     */
    validateTxnAmts(object, field, position) {
        console.log('validateTxnAmts:amount', object);
        console.log('validateTxnAmts:field', field);
        let amountInNumber = parseFloat(object[field]);
        console.log('validateTxnAmts:amountInNumber', amountInNumber);
        this.editTxns[position][field + 'Error'] = !checkValidAmt(amountInNumber, field);
        console.log('validateTxnAmts:checkValidAmt(amountInNumber, field)', checkValidAmt(amountInNumber, field));
        console.log('validateTxnAmts:edittxns', this.editTxns);
        // console.log('validateTxnAmts: editModalErrors', this.editModalErrors);
    }

     /**
     * Based on user permission, enable/disable txn actions
     */
      setTxnActionPermissions(){
        const userPerms = this.userHandlerService.userPerms;
        this.txnActionPermissions.canViewDetails = userPerms.canViewDetails;
        this.txnActionPermissions.canRedo = userPerms.canRedoTxn;
        this.txnActionPermissions.canReprint = userPerms.canReprintTxn;
        this.txnActionPermissions.canReverse = userPerms.canReverseTxn;
        this.txnActionPermissions.canPrintDetail = userPerms.canPrintTxn;
    }

    ngOnDestroy() {
        this.alive = false;
        this.summaryReportService.setCheckedObj([]);
        this.summaryReportService.setUnCheckedObj([]);
    }

    handleWatcher_NullValueCase(watcher_type) {
        //This case arrises for all other watchers whenever a tree-node is clicked.
        //Don't perfrom any action. Can be used for logs purpose
        // console.log('Null value for -->', watcher_type);
    }
}
