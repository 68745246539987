import {Component, OnInit, OnDestroy, ViewChild, TemplateRef} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl, NgModel } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { TAGSCONSTS } from '../../../../../../constants/tags.constants';
import {BsModalRef, BsModalService, ModalDirective} from 'ngx-bootstrap/modal';
import { WEB_APP_HOST } from './../../../../../../shared/config/webApp.config';
import { takeWhile, skip } from 'rxjs/operators';
import {
    BreadcrumbService,
    NotificationService,
    BusinessService,
    GlobalValidator,
    XpaConstsService,
    XsaConstsService,
    HttpService,
    TreeService,
    GmNumberService,
    UrlStoreService,
    TabStructureService,
    UserHandlerService
} from '../../../../../../shared/services';
import {
    SetupStructureTerminalStore,
    SetupBusinessStructureStore,
    SetupStructureProfitCenterStore,
    SetupBusinessStructureStoreSetupStore,
    ConstantStore,
    TransactionsStore /*, BusinessTreeStore*/
} from '../../../../../../store';
import { SysInfo, EntrCaps, ProfitCenter, SecCaps, AcptCaps, Terminal, DBAudit, VTermInitForm } from '../../../../../../models';
import { ConfigModalComponent } from './config-modal/config-modal.component';
// import { StandAloneVTermLinkModalComponent } from "./stand-alone-vTerm-link-modal/stand-alone-vTerm-link-modal.component";

// import { VTERM_HOST } from "../../../../../../shared/config/vTerm.config";
// import { VtermConfig } from '../../shared/config/vTerm.config';
import { VtermConfig } from '../../../../../../shared/config/vTerm.config';
import {
    setDataForTokenCreation,
    validatePgComFields,
    SETUP_ALTERED_MESSAGE,
    validateTerminalForMerchantCapabilities,
    UNABLE_TO_GENERATE_MESSAGE,
    NO_CC_PREFIX_FLAG,
    NO_PREFIX_FLAG
} from '../../../../../common-functions/validate-txn-terminals';
import { API_END_POINT, NO_PERMISSION_MSG, XPCTERMINALS } from 'app/constants';
import { ApiConfig } from 'app/shared/config/api.config';
import { TEMPLATE_ARR } from './template';
import { validateFor } from '../../../../../../common-functions';
import {Observable} from 'rxjs/Observable';

const CREATE_TERMINAL_BEFORE_SNIPPET_CAN_BE_GENERATED_MSG = 'Please setup Terminal.'
const UNABLE_TO_PROCEED_MESSAGE = 'Unable to proceed. Please try again.';
const VTERM_INFO_DATA_VALIDATION_FAIL_MSG = 'Configuration Setup has been corrupted. Please contact support.';
const SNIPPET_GENERATION_FAILED_MESSAGE = 'Could not generate vTerm snippet.';

// API endpoints
const vTermInfoEndPoint = 'vTerm_info';
const VTERM_ENCRYPT_END_POINT = 'vTerm/encrypt';

// BE fields
const BE_DATA_KEY = 'data';
const BE_VTERM_INFO_PCTR_REC_KEY = 'pCtrRecord';
const BE_VTERM_INFO_STORE_REC_KEY = 'storeRecord';
const BE_VTERM_INFO_TERMINAL_REC_KEY = 'terminalRecords';

const BUSINESS_DATA_KEY = 'businessData';

//DB keys
const DB_MEMBER_NUMBER_LABEL_KEY: string = 'xMemberNumberLabel';
const DB_PO_NUMBER_LABEL_KEY: string = 'xPONumberLabel';
const DB_INVOICE_NUMBER_LABEL_KEY: string = 'xInvoiceNumberLabel';
const DB_LABEL_CONFIG_KEY: string = 'xLabelConfigs';
const DB_XNAME_KEY = 'xName';

// config label fields
const PO_NUMBER: string = 'PO Number';
const INVOICE_NUMBER: string = 'Invoice Number';
const MEMBER_NUMBER: string = 'Member Number';

export const NO_CC_PREFIX_MESSAGE =
	'Only Credit Card based transactions are Supported in Dealer Mode.';
export const NO_PREFIX_MESSAGE:string =`SETUP NEEDED TO PROCEED!
Please tell us how you want to use this terminal:
* To setup terminal for Credit Cards, add [CC] prefix to related merchant account name (e.g. [CC]Processor1)
* To setup terminal for ACH, add [ACH] prefix to related merchant account name (e.g. [ACH]Processor2)
* Note: separate processor entries are needed for CC and ACH processing.`;

@Component({
    selector: 'add-edit',
    templateUrl: './add-edit.component.html',
    styleUrls: ['./add-edit.component.scss']
})
export class SetupStructureTerminalComponent implements OnInit, OnDestroy {
    modalRef?: BsModalRef;
    @ViewChild('configModal', {static: true})
    configModal: ConfigModalComponent;

    @ViewChild('standAloneVTermLinkModal', {static: true})
    public standAloneVTermLinkModal: ModalDirective;
    @ViewChild('templateDropdown', {static: true})
    public templateDropdown: NgModel;
    // standAloneVTermLinkModal: StandAloneVTermLinkModalComponent;
    public vTermToken: string = "";
    public standAloneVTermSnippet: string = "";
    public vTermURL: string = '';
    public VTERM_HOST: string = '';

    bizID: string = ''; //for storing the businessID fetched via service
    nodePath: any[] = []; //For storing the path where the payment device belongs
    storeId: string = '';

    payDevInfo: any = {}; //For storing value retrieved from server in edit mode
    serverErrors: any = {}; //Stores errors returned by server
    form: FormGroup; //Required for reactive form validation
    submitting: boolean = false; //For handling form submission
    edit: boolean = false; //add/edit mode
    formErrors = {
        xName: '',
        xTag: '',
        xRefNum: '',
        xTerminalNum: '',
        xShortCode: '',
        xMainLaneNum: ''
    };
    public abc: boolean = true;

    mediumTagList: any[] = []; //For storing list of medium tags
    brandTagList: any[] = []; //For storing list of brand tags
    transactionTagList: any[] = []; //For storing list of transaction tags
    alive: boolean = true;
    loading: boolean = true;
    // tag:string;
    gtID: string;
    xRefNumEditMode: string = '';
    terminalTypeList: any[] = [
        {value: 'Ingenico'},
        {value: 'Pax'},
        {value: 'Dejavoo'},
        {value: 'WinPDC'},
        {value: 'M6Plus'},
    ];
    profitCenterlist: ProfitCenter[] = [];
    capabilities: any[] = [{ label: 'Yes', value: true }, { label: 'No', value: false }];

    bizChangeEvent: boolean = false;

    //Controls the checkbox of acceptance section
    isAllAcceptanceTagsChecked: any = {
        medium: false,
        brand: false,
        transaction: false
    };

    accountEntryTags: string[] = []; //manual/msr/icc/contactLess/micr
    pinEntryTags: string[] = [];
    sigEntryTags: string[] = [];
    certCapTags: string[] = [];
    encCapTags: string[] = [];

    storeName: string;
    merAccName: string;
    chainName: string;
    regionName: string;
    propName: string;
    pctrName: string;
    numberKey: string = 'xTerminalNum';
    parentMode: boolean = false;
    public data: VTermInitForm;
    public GV = GlobalValidator;
    public toggleValidationMessage: boolean = true;
    public shouldGenerateSnippet: boolean = false;
    public standAloneVTermSnippetError: string[] = []; //This will contain set of messages which needs to be renderd in new line
    public TEMPLATE_ARR = TEMPLATE_ARR;
    public selectedTemplate = -1; //default value for stand alone snippet modal
    canEdit = true;
    canAdd = true;
    noPermMsg = NO_PERMISSION_MSG;
    private xGMID: string;
    private operation: string;
    private xActualGTID: string;
    private xActualGMID: Observable<string>;
    private xActualName: string;
    private xActualSerialNumber: string;
    private xTerminalType: string;
    private serialNumLength: number = 0;
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private breadcrumbService: BreadcrumbService,
        private notificationService: NotificationService,
        public businessService: BusinessService,
        private setupStructureTerminalStore: SetupStructureTerminalStore,
        private setupBusinessStructureStore: SetupBusinessStructureStore,
        private setupBusinessStructureStoreSetupStore: SetupBusinessStructureStoreSetupStore,
        private setupStructureProfitCenterStore: SetupStructureProfitCenterStore,
        private constantStore: ConstantStore,
        // protected businessTreeStore:BusinessTreeStore,
        private fb: FormBuilder,
        private xpaConstsService: XpaConstsService,
        private xsaConstsService: XsaConstsService,
        private httpService: HttpService,
        public treeService: TreeService,
        private gmNumberService: GmNumberService,
        private transactionsStore: TransactionsStore,
        public urlStoreService: UrlStoreService,
        private tabStructureService: TabStructureService,
        public userHandlerService: UserHandlerService,
        public globalValidator: GlobalValidator,
        private vtermConfig: VtermConfig,
        private apiConfig: ApiConfig,
        private modalService: BsModalService,
    ) {
        this.VTERM_HOST = vtermConfig.VTERM_HOST;
        this.vTermURL = '"' + this.VTERM_HOST + '"';
        console.log('vtermConfig: host', this.VTERM_HOST);
    }

    ngOnInit() {
        console.log('terminal:add-edit:init: webapp-host', WEB_APP_HOST);
        this.data = new VTermInitForm();
        this.setupPerms();
        this.tabStructureService.checkAndSetTabNameToDef(
            this.tabStructureService.tabMode,
            this.tabStructureService.isCancelClick,
            this.tabStructureService.isSubmitClick
        );
        this.urlStoreService.submitAction = false;
        this.setBreadCrumbValue();
        this.bizID = this.businessService.businessID.getValue();
        this.nodePath = this.setupBusinessStructureStore.getNodePath();
        this.route.params.forEach((params: Params) => {
            this.edit = false;
            this.loading = false;
            this.initValidation();
            if (params.hasOwnProperty('Id') && params.Id) {
                this.edit = true;
                console.log(
                    'TERMINAL this.tabStructureService.editMode || this.treeService.nodeClick) && !this.tabStructureService.tabMode',
                    this.tabStructureService.editMode,
                    this.treeService.nodeClick,
                    this.tabStructureService.tabMode
                );
                // if (
                //     !this.treeService.terminalID.getValue() &&
                //     ((this.tabStructureService.editMode ||
                //         this.treeService.nodeClick) &&
                //         !this.tabStructureService.tabMode)
                // ) {
                if (!this.treeService.terminalID.getValue() && !this.tabStructureService.tabMode) {
                    console.log('Inside If');
                    this.treeService.setId(params.Id, 'terminal');
                    //     this.fetchData(params);
                    // } else {
                }
                this.loading = true;
                this.setupStructureTerminalStore.get(params['Id']).subscribe(async res => {
                    // let param={
                    //     Id: params.Id,
                    //     storeId: res && res.data && res.data.xStoreID? res.data.xStoreID : '',

                    // }
                    this.payDevInfo = res.data;
                    console.log('paymentDevInfo', this.payDevInfo);
                    if (this.payDevInfo && this.payDevInfo.hasOwnProperty('xTerminalNum') && this.payDevInfo['xTerminalNum']) {
                        // this.generateCustomerLink(this.payDevInfo['xTerminalNum']);
                    }
                    this.pctrID.setValue(
                        this.payDevInfo.hasOwnProperty('xMainPCtrID') && this.payDevInfo.xMainPCtrID ? this.payDevInfo.xMainPCtrID : ''
                    );
                    this.merAccID.setValue(
                        this.payDevInfo.hasOwnProperty('xMainMerAcctID') && this.payDevInfo.xMainMerAcctID
                            ? this.payDevInfo.xMainMerAcctID
                            : ''
                    );
                    this.storeName = '';
                    this.merAccName = '';
                    this.tabStructureService.defaultTabStructureLabel.map(singleLabel => {
                        if (
                            singleLabel &&
                            singleLabel.hasOwnProperty('label') &&
                            singleLabel.hasOwnProperty('name') &&
                            singleLabel.label === 'Store'
                        ) {
                            this.storeName = singleLabel.name.substring(3, singleLabel.name.length);
                        }
                        if (
                            singleLabel &&
                            singleLabel.hasOwnProperty('label') &&
                            singleLabel.hasOwnProperty('name') &&
                            singleLabel.label.includes('Merchant')
                        ) {
                            this.merAccName = singleLabel.name.substring(3, singleLabel.name.length);
                        }
                    });
                    try{
                        await this.fetchData();
                    }catch(error){
                        console.log("error in catch block of fetch data", error);
                        this.handleErrorResponse(error);
                    } 
                    try{
                        await this.generateToken(params['Id']);
                    }catch(error){
                        console.log("error in catch block of generateToken", error);
                        if(error && Object.keys(error).length){
                            this.notificationService.error(SNIPPET_GENERATION_FAILED_MESSAGE, 'Error');
                        }
                    }
                    this.loading = false;
                });
            }
        });
        if (this.tabStructureService.tabMode) {
            this.checkParentMode();
        }
        // this.setTabNameNewTerminal();
        this.setupBusinessStructureStore.watchNodePath$
            .pipe(
                takeWhile(() => this.alive),
                skip(1)
            )
            .subscribe((nodePath: any[]) => {
                console.log('HEREEEEEEEEEEEEE');
                if (this.treeService.terminalID.getValue()) {
                    this.nodePath = nodePath;
                    this.addModeActions();
                } else if (!this.edit && !this.tabStructureService.tabMode) {
                    this.nodePath = nodePath;
                    this.addModeActions();
                }
                this.parentMode = false;
            });
        if (!this.edit) this.addModeActions();

        this.tabStructureService.watchParentFetched$
            .pipe(
                takeWhile(() => this.alive),
                skip(1)
            )
            .subscribe(res => {
                console.log('Here in watcher of parent fetched', res);
                if (res) {
                    console.log('Here in watcher of parent fetched res true');
                    this.setTabNameNewTerminal();
                }
            });
        this.businessService.watchBusinessID$
            .pipe(
                takeWhile(() => this.alive),
                skip(1)
            )
            .subscribe(bizId => {
                if (bizId) {
                    this.bizChangeEvent = true;
                } else {
                    this.router.navigate(['/dashboard/setup/business/structure/information']);
                }
            });
    }

    checkParentMode() {
        if (this.tabStructureService.tabLabelItems.length) {
            this.tabStructureService.tabLabelItems.map(labelItem => {
                if (labelItem.label.includes('Terminal')) {
                    if (labelItem.hasOwnProperty('type') && labelItem['type'] === 'Parent') {
                        this.parentMode = true;
                    } else {
                        this.parentMode = false;
                    }
                }
            });
        }
    }

    generateToken(terminalID) {
        const params = { terminalID: terminalID };
        const userData = Object.keys(this.userHandlerService.userData).length ? this.userHandlerService.userData : {};
        return new Promise((resolve, reject) => {
            this.httpService.getAll(vTermInfoEndPoint, params).subscribe(
                vtermInfoResponse => {
                    console.log('generateToken: res', vtermInfoResponse);
                    let vTermInfoData = validateFor(BE_DATA_KEY,vtermInfoResponse) ? vtermInfoResponse.data : {};
                    if (!vTermInfoData || !validateFor(BE_VTERM_INFO_PCTR_REC_KEY, vTermInfoData) || !validateFor(BE_VTERM_INFO_STORE_REC_KEY,vTermInfoData ) || !validateFor(BE_VTERM_INFO_TERMINAL_REC_KEY,vTermInfoData)) {
                        console.log('generateToken: no vterm_info data');
                        this.setVtermSnippetErrorMessage(VTERM_INFO_DATA_VALIDATION_FAIL_MSG);
                        return reject(false)
                    }
                    // if (vTermInfoData && validateFor(BE_VTERM_INFO_PCTR_REC_KEY, vTermInfoData)  && validateFor(BE_VTERM_INFO_STORE_REC_KEY,vTermInfoData ) && validateFor(BE_VTERM_INFO_TERMINAL_REC_KEY,vTermInfoData)) {
                    console.log("****inside has property*****")
                    let terminalRecords =  vTermInfoData.terminalRecords;
                    console.log("terminalRecords: generateToken--->", terminalRecords);
                    let errorFlag = validateTerminalForMerchantCapabilities(terminalRecords);
                    console.log('generateToken: errorFlag',errorFlag);
                    let errorMessage = '';
                    switch(errorFlag){
                        case NO_CC_PREFIX_FLAG:
                            errorMessage = NO_CC_PREFIX_MESSAGE;
                            break;
                        case NO_PREFIX_FLAG:
                            errorMessage = NO_PREFIX_MESSAGE;
                            break;
                    }
                    if(errorMessage){
                        console.log('INVALID TERMINAL. validateTerminalForParentMerAcctName returned error message',errorMessage);
                        // this.setVtermSnippetErrorMessage(errorMessage);
                        this.setVtermSnippetErrorMessage(errorMessage);
                        return reject(false);
                    }
                    let bizName =
                        validateFor(DB_XNAME_KEY, this.businessService[BUSINESS_DATA_KEY])
                            ? this.businessService[BUSINESS_DATA_KEY][DB_XNAME_KEY]
                            : '';
                    console.log("bizName: generateToken: terminal add/edit", bizName);                    
                    this.data = setDataForTokenCreation(bizName, vTermInfoData, userData, TAGSCONSTS.roleTags.roleItemTags.representativeRole, terminalRecords[0]);
                    if(!validatePgComFields(this.data)){                        
                        this.setVtermSnippetErrorMessage(SETUP_ALTERED_MESSAGE);
                        return reject(false);
                    }
                    this.standAloneVTermSnippetError = []; //Clear error array if previous error messages were added
                    this.shouldGenerateSnippet = true;
                    console.log('generateToken: data', this.data);
                    this.httpService.store(VTERM_ENCRYPT_END_POINT, this.data).subscribe(tokenEncryptionResponse => {
                        console.log('I got response token', tokenEncryptionResponse)
                        if(!validateFor('success',tokenEncryptionResponse) 
                            || !validateFor('data',tokenEncryptionResponse) 
                            || !validateFor('vTermToken',tokenEncryptionResponse.data)){
                            this.setVtermSnippetErrorMessage(UNABLE_TO_GENERATE_MESSAGE)
                            return reject(false);
                        }
                        this.vTermToken = tokenEncryptionResponse.data.vTermToken;
                        return resolve(true);
                    }, 
                    error => {
                        console.log("token ecnryption failed--->",error);
                        return reject(error);
                    });
                    // }
                },
                error => {
                    return reject(error);
                }
            );
        }) 
    }

     /**
     * Set v-term snippet error message. When a new line is encountered, display it in new line.
     * @param `message`: string: Error message why the snippet generation failed 
     */
    setVtermSnippetErrorMessage(message: string){
        this.shouldGenerateSnippet = false;
        this.standAloneVTermSnippetError = message.split('\n');
        console.log('standAloneVTermSnippetError: setVtermSnippetErrorMessage', this.standAloneVTermSnippetError);
    }

    setTabNameNewTerminal() {
        if (!this.edit) {
            console.log('%c Add mode hai k??', 'background: blue; color: white');
            if (this.tabStructureService.tabLabelItems.length) {
                // console.log('%c Add mode hai k??', 'background: pink; color: white');
                this.tabStructureService.tabLabelItems.map(labelItem => {
                    // console.log('%c Add mode hai k??', 'background: grey; color: black', labelItem);
                    if (labelItem.label.includes('Terminal')) {
                        if (labelItem.hasOwnProperty('type')) {
                            labelItem['label'] = 'Terminal';
                            labelItem['type'] = 'New';
                            labelItem['name'] = '';
                        }
                    }
                });
                console.log('%c Terminal Add mode hai k??', 'background: grey; color: black', this.tabStructureService.tabLabelItems);
                this.tabStructureService.setTabStructureNames(this.tabStructureService.tabLabelItems);
            }
        }
    }

    addModeActions() {
        this.bizID = this.businessService.businessID.getValue();
        this.form.controls['xWall2EntyID'].setValue(this.bizID);
        this.setStoreId();
        this.setBusinessID();
        this.setPctrID();
        this.setMerAccID();

        if (!this.edit) {
            this.fetchData();
        }
        //     // this.bizID = this.businessService.businessID.getValue();
        //     // this.form.controls["xWall2EntyID"].setValue(this.bizID);
        //     // this.setStoreId();
        //     // this.setBusinessID();
        //     // this.setPctrID();
        //     // this.setMerAccID();
        // }
        // // else {
        // //     this.fetchData({ Id: this.treeService.terminalID.getValue() });
        // // }
    }

    fetchData(params?: any): Promise<boolean> {
        if (this.treeService.nodeClick) {
            this.storeId = this.nodePath.filter(node => node.type === 'store').reduce((previous, node) => node.id, '');
            // console.log('******* here ******* 1: this.storeId -->', this.storeId);
        }
        // console.log('******* here ******* 2: this.storeId -->', this.storeId);
        //Get Contants
        const observables = [
            // this.xpaConstsService.getXpaConsts('indst').map((res: Response) => res),// Industries
            // this.xpaConstsService.getXpaConsts('acctent').map((res: Response) => res), // Account Entry Types
            // this.xpaConstsService.getXpaConsts('pinent').map((res: Response) => res), // PIN Entry Types
            // this.xpaConstsService.getXpaConsts('sigent').map((res: Response) => res), // Signature Entry Types
            this.xpaConstsService.getXpaItems('cert'), // Certificate Types
            this.xpaConstsService.getXpaItems('enc'), // Encryption Types

            // this.xpaConstsService.getXpaItems("mdm").map((res: Response) => res), // Medium Tags
            this.xpaConstsService.getXpaItems('brand'), //Brand Tags
            this.xsaConstsService.getXsaItems('perm') // TODO: Add backend and add proper tag
        ];

        /*===================================================
        =            StoreID check for PCtr API             =
        ===================================================*/
        //(TODO-: Modify params logic,  later [as per need -> fetch by coressponding business etc.])
        // Current Logic Fetches "all PCtrs" if no storeID is available

        let pCtrParams = {};
        if (this.storeId) {
            pCtrParams = { xStoreID: this.storeId };
        }

        observables.push(this.httpService.getAll(`xbcPCtrs`, pCtrParams, true));
        /*=====  End of StoreID check for PCtr API   ======*/

        if (this.edit && params && params.hasOwnProperty('Id')) {
            //Start-: Fetches transaction under selected terminal.
            // observables.push(
            //   this.transactionsStore.getAll({ xTerminalID: params["Id"] }).map((res: Response) => res)
            // )
            //End-: Fetches transaction under selected terminal.
        } else {
            observables.push(
                this.gmNumberService.fetchGMData('pgComm/numberAPI_GTIDs', {
                    count: '1'
                })
            );
        }
        return new Promise((resolve, reject) => {
            forkJoin(observables).subscribe(
                (response: any) => {
                    console.log('response', response);
                    // this.mediumTagList = response[2].data;
                    this.brandTagList = response[2].data.filter(tag => tag.xStatus);
                    this.transactionTagList = response[3].data.filter(tag => tag.xStatus);
                    this.profitCenterlist = response[4].data.filter(tag => tag.xStatus);
                    this.gtID = response[5].data.NumList;
                    // this.loading = false;
                    // this.mediumTagList = response[5].data;
                    // this.brandTagList = response[6].data;
                    // this.transactionTagList = response[7].data;
                    // this.profitCenterlist = response[8].data;
                    if (this.edit) {
                        console.log('Data--------->', response[5]);
                        // this.payDevInfo = response[5].data;
                        this.setFormValues(this.payDevInfo);
                        this.setAcceptanceField(
                            // this.mediumTagList,
                            this.brandTagList,
                            this.transactionTagList
                        );
                        //Start-: Disables xMainPCtrID when there exists a transaction under selected terminal.
    
                        // if (this.form && this.form.value.hasOwnProperty('xMainPCtrNum')) {
                        //   if (response[6].data.length > 0) {
                        //     this.form.get('xMainPCtrNum').disable();
                        //   }
                        //   else {
                        //     this.form.get('xMainPCtrNum').enable();
                        //   }
                        // }
    
                        //End-: Disables xMainPCtrID when there exists a transaction under selected terminal.
                    } else {
                        this.form.controls.xGTID.setValue(this.gtID); //To add fetched GTID.
                        this.setRefNum(this.gtID);
                    }
                    this.setConstants(response[0].data, response[1].data); /*, response[2].data, response[3].data, response[4].data);*/
                    return resolve(true)
                },
                (error: any) => {
                    this.handleErrorResponse(error);
                    // this.urlStoreService.routeBackToPreviousUrl();
                    this.urlStoreService.routeToSecondLastUrl();
                    return reject(false);
                },
                () => {}
            );
        })
    }

    setAcceptanceField(
        // mediumTags: any[],
        brandTags: any[],
        transactionTags: any[]
    ) {
        let tagsArray: any[] = [];

        // if (this.payDevInfo.xAcptCaps.xAcptMdmTags.length) {
        //   if (this.payDevInfo.xAcptCaps.xAcptMdmTags[0] !== "all") {
        //     tagsArray = this.getObjectsPresentInArray(
        //       mediumTags,
        //       this.payDevInfo.xAcptCaps.xAcptMdmTags
        //     );
        //   } else {
        //     this.isAllAcceptanceTagsChecked.medium = true;
        //     tagsArray = mediumTags;
        //   }
        //   this.setAcceptanceTags(this.acceptedMediumTags, tagsArray);
        // }

        if (this.payDevInfo.xAcptCaps && this.payDevInfo.xAcptCaps.xAcptBrandTags.length) {
            if (this.payDevInfo.xAcptCaps.xAcptBrandTags[0] !== 'all') {
                tagsArray = this.getObjectsPresentInArray(brandTags, this.payDevInfo.xAcptCaps.xAcptBrandTags);
            } else {
                this.isAllAcceptanceTagsChecked.brand = true;
                tagsArray = brandTags;
            }
            this.setAcceptanceTags(this.acceptedBrandTags, tagsArray);
        }

        if (this.payDevInfo.xAcptCaps && this.payDevInfo.xAcptCaps.xAcptTxnActTags.length) {
            if (this.payDevInfo.xAcptCaps.xAcptTxnActTags[0] !== 'all') {
                tagsArray = this.getObjectsPresentInArray(transactionTags, this.payDevInfo.xAcptCaps.xAcptTxnActTags);
            } else {
                this.isAllAcceptanceTagsChecked.transaction = true;
                tagsArray = transactionTags;
            }
            this.setAcceptanceTags(this.acceptedTransactionTags, tagsArray);
        }
    }

    setAcceptanceTags(acceptedTags: FormArray, tags: any[]) {
        tags.forEach((tag: any) => {
            acceptedTags.push(new FormControl(tag));
        });
    }

    //Returns an array of objects whose xTag matches the list of provided xTag
    getObjectsPresentInArray(tagList: any[], tags: string[]) {
        return tagList.filter(tagObj => {
            return tags.findIndex(t => t === tagObj.xTag) >= 0 ? true : false;
        });
    }

    setConstants(/*aactEntTags: any[], pinEntTags: any[], sigEntTags: any[], */ certTags: any[], encTags: any[]) {
        // this.accountEntryTags = aactEntTags;
        // this.pinEntryTags = pinEntTags;
        // this.sigEntryTags = sigEntTags;
        this.certCapTags = certTags.filter(tag => tag.xStatus);
        this.encCapTags = encTags.filter(tag => tag.xStatus);
    }
    // setConstants() {
    // 	this.accountEntryTags = ['Yes', 'No'];
    // 	this.pinEntryTags = ['Yes', 'No'];
    // 	this.sigEntryTags = ['Yes', 'No'];
    // 	this.certCapTags = ['Yes', 'No'];
    // 	this.encCapTags = ['Yes', 'No'];
    // }

    setBreadCrumbValue(): void {
        const breadCrumb: any = {};
        breadCrumb.root = 'Setup';
        breadCrumb.child = 'Business';
        breadCrumb.subChild = 'Structure';
        breadCrumb.subChildLink = 'setup/business/structure/information';
        breadCrumb.grandChild = 'Terminal-setup';
        breadCrumb.rootList = [];
        this.breadcrumbService.setBreadCrumb(breadCrumb);
    }

    initValidation(): void {
        this.form = this.fb.group({
            xTntTag: ['tnttag', []],
            xWall1EntyID: ['', []],
            xWall2EntyID: [this.bizID || '', []],
            xTag: ['dev', []],
            xStatus: ['1', []],
            xTerminalType: ['', []],
            xName: ['', [Validators.required, GlobalValidator.validateName]],
            xTypeTag: [TAGSCONSTS.terminalTags.regularTerminal, []],
            xNotes: ['', []],
            xRefNum: ['', []],
            xRegSerNum: ['', []],
            xTerminalNum: ['', [Validators.required]],
            xShortCode: ['', [Validators.required]],
            xBusinessID: ['', []],
            xStoreID: ['', []],
            xMainPCtrID: ['', []],
            xMainMerAcctID: ['', []],
            xMainPCtrNum: ['', []],
            xMainLaneNum: ['1', [Validators.required]],
            xGTID: ['', [Validators.required]],
            xUName: ['', []],
            xPwd: ['', []],
            xConnTok: ['', []],
            xCfgInfoStr: ['', []],
            xHWSysInfo: this.fb.group(new SysInfo()),
            xSWSysInfo: this.fb.group(new SysInfo()),
            xCtrlSysInfo: this.fb.group(new SysInfo()),
            xEntrCaps: this.fb.group(new EntrCaps()),
            xSecCaps: this.fb.group(new SecCaps()),
            // xCertTag: ['', []],
            // xEncTag: ['', []],
            // xCertNum: ['', []],
            xAcptCaps: this.fb.group({
                xAcptMdmTags: this.fb.array([]),
                xAcptBrandTags: this.fb.array([]),
                xAcptTxnActTags: this.fb.array([])
            }),
            xIsRfrTerminal: [false, []],
            xRfrCfgInfoStr: ['', []],
            xDBAudit: this.fb.group(
                new DBAudit({
                    xDataSrc: TAGSCONSTS.dataSourceTags.dataSrcItemTags.self
                })
            ),
            xLabelConfigs: this.fb.group({
                xPONumberLabel: [PO_NUMBER],
                xInvoiceNumberLabel: [INVOICE_NUMBER],
                xMemberNumberLabel: [MEMBER_NUMBER]
            })
        });
        this.form.get('xGTID').setAsyncValidators(GlobalValidator.checkGTID('', 'VALID'));
    }

    setStoreId() {
        const storeObj: any = this.nodePath.filter(node => node.type === 'store');
        if (storeObj.length) {
            console.log('object inside storeObj if', storeObj[0].id);
            this.storeID.setValue(storeObj[0].id);
            this.storeName = storeObj[0].name;
        } else {
            console.log('storeID else: setStoreId', this.storeId);
            let storeID = validateFor(XPCTERMINALS._id, this.treeService.treeData) ?  this.treeService.treeData[XPCTERMINALS._id] : '';
            if(storeID){
                this.storeID.setValue(storeID);  
            }
            this.storeName = validateFor(XPCTERMINALS.xName, this.treeService.treeData) ?  this.treeService.treeData.xName : '-';
        }
        this.storeId = this.storeID && this.storeID.value ? this.storeID.value : '';
        // console.log('******* here ******* 3: this.storeId -->', this.storeId);
    }

    setBusinessID() {
        const bizObj: any = this.nodePath.filter(node => node.type === 'business');
        if (bizObj.length) {
            if (this.bizID === bizObj[0].id) {
                this.businessID.setValue(bizObj[0].id);
            } else {
                if (this.bizID) {
                    this.businessID.setValue(this.bizID);
                } else {
                    this.notificationService.error('Cannot modify this business', 'ERROR');
                    this.urlStoreService.routeBackToPreviousUrl();
                }
            }
        } else {
            this.businessID.setValue(this.bizID);
        }
    }

    setPctrID() {
        const pctrObj: any = this.nodePath.filter(node => node.type === 'profit_center');
        console.log('nodePath: setPctrID', this.nodePath);
        console.log('pctrObj: setPctrID', pctrObj);
        if (pctrObj.length) {
            if (pctrObj.length) {
                console.log('object inside pctr if', pctrObj[0].id);
                this.pctrID.setValue(pctrObj[0].id);
                this.pctrName = pctrObj[0].name;
            } else {
                this.notificationService.error('Cannot modify this Profit Center', 'ERROR');
                this.urlStoreService.routeBackToPreviousUrl();
            }
        } else {
            let pctrID = validateFor(XPCTERMINALS.xMainPCtrID, this.treeService.treeDataUnderStore) ?  this.treeService.treeDataUnderStore[XPCTERMINALS.xMainPCtrID] : '';
            if(pctrID){
                this.pctrID.setValue(pctrID);
            }
        }
    }

    setMerAccID(obj?: any) {
        const merObj: any = this.nodePath.filter(node => node.type === 'merchant_account');
        if (merObj.length) {
            this.merAccID.setValue(merObj[0].id);
            this.merAccName = merObj[0].name;
        } else {
            if (obj) {
                this.merAccID.setValue(obj.merAcct.id);
                this.merAccName = obj.merAcct.name;
            } else {
                if (this.treeService.merchantAccID.getValue()) {
                    this.merAccID.setValue(this.treeService.merchantAccID.getValue());
                    this.merAccName = this.tabStructureService.currentNodeName;
                }
                // else{
                //     this.merAccID.setValue("");
                //     this.merAccName = "";
                //     this.notificationService.error(
                //         "Cannot modify this Merchant",
                //         "ERROR"
                //     );
                //     this.urlStoreService.routeBackToPreviousUrl();
                // }
            }
        }
    }

    setFormValues(data: Terminal): void {
        console.log('data====>', data);
        this.form.patchValue({
            xTntTag: data.xTntTag,
            xWall1EntyID: data.xWall1EntyID,
            xWall2EntyID: data.xWall2EntyID,
            xTag: data.xTag,
            xStatus: data.xStatus,
            xTerminalType: data.xTerminalType,
            xName: data.xName,
            xTypeTag: data.xTypeTag,
            xNotes: data.xNotes,
            xRefNum: data.xRefNum,
            xRegSerNum: (data.hasOwnProperty('xRegSerNum') && data['xRegSerNum'] && data['xRegSerNum'].length > 4) ? data['xRegSerNum'] : '',
            xTerminalNum: data.xTerminalNum,
            xShortCode: data.xShortCode,
            xBusinessID: data.xBusinessID,
            xStoreID: data.xStoreID,
            xMainPCtrID: data.xMainPCtrID,
            xMainPCtrNum: data.xMainPCtrNum,
            xMainLaneNum: data.xMainLaneNum,
            xGTID: data.xGTID,
            xUName: data.xUName,
            xPwd: data.xPwd,
            xConnTok: data.xConnTok,
            xCfgInfoStr: data.xCfgInfoStr,
            xHWSysInfo: data.xHWSysInfo || new SysInfo(),
            xSWSysInfo: data.xSWSysInfo || new SysInfo(),
            xCtrlSysInfo: data.xCtrlSysInfo || new SysInfo(),
            xEntrCaps: data.xEntrCaps || new EntrCaps(),
            xSecCaps: data.xSecCaps || new SecCaps(),
            xIsRfrTerminal: data.xIsRfrTerminal,
            xRfrCfgInfoStr: data.xRfrCfgInfoStr,
            xDBAudit: data.xDBAudit ? data.xDBAudit : TAGSCONSTS.dataSourceTags.dataSrcItemTags.self
        });

        if(validateFor(DB_LABEL_CONFIG_KEY, data)){
             this.form.patchValue({
                xLabelConfigs: {
                    xPONumberLabel: validateFor(DB_PO_NUMBER_LABEL_KEY, data[DB_LABEL_CONFIG_KEY]) ? 
                                    data[DB_LABEL_CONFIG_KEY][DB_PO_NUMBER_LABEL_KEY]: 
                                    PO_NUMBER,
                    xMemberNumberLabel: validateFor(DB_MEMBER_NUMBER_LABEL_KEY, data[DB_LABEL_CONFIG_KEY]) ? 
                                    data[DB_LABEL_CONFIG_KEY][DB_MEMBER_NUMBER_LABEL_KEY]: 
                                    MEMBER_NUMBER,
                    xInvoiceNumberLabel: validateFor(DB_INVOICE_NUMBER_LABEL_KEY, data[DB_LABEL_CONFIG_KEY]) ? 
                                    data[DB_LABEL_CONFIG_KEY][DB_INVOICE_NUMBER_LABEL_KEY]: 
                                    INVOICE_NUMBER
                }
             })   
        }

        if (this.edit) {
            this.form.controls['xGTID'].disable();
            this.form.controls['xRefNum'].disable();
            this.xRefNumEditMode = data.xRefNum;
        } else {
            this.form.controls['xGTID'].enable();
            this.form.controls['xRefNum'].enable();
        }
    }

    // get acceptedMediumTags(): FormArray {
    //   return this.form.get("xAcptCaps.xAcptMdmTags") as FormArray;
    // }
    get acceptedBrandTags(): FormArray {
        return this.form.get('xAcptCaps.xAcptBrandTags') as FormArray;
    }
    get acceptedTransactionTags(): FormArray {
        return this.form.get('xAcptCaps.xAcptTxnActTags') as FormArray;
    }

    get storeID(): any {
        return this.form.get('xStoreID');
    }
    get businessID(): any {
        return this.form.get('xBusinessID');
    }
    get pctrID(): any {
        return this.form.get('xMainPCtrID');
    }
    get merAccID(): any {
        return this.form.get('xMainMerAcctID');
    }

    //Search for processor in processorList with search key a 'xTag'
    getIndexOfObject(tagList: FormArray, tag: any) {
        return tagList.value.findIndex(t => t._id === tag._id);
    }

    //Adds or removes all Tags from tagArray based upon checkox is checked or not
    addAllTags(tagList: any[], tagArray: FormArray, isChecked: boolean) {
        if (isChecked) {
            while (tagArray.length) {
                tagArray.removeAt(0);
            }
            tagList.forEach((tag: any) => {
                tagArray.push(new FormControl(tag));
            });
        } else {
            while (tagArray.length) {
                tagArray.removeAt(0);
            }
        }
    }

    //Inserts or deletes tags based upon checkbox is checked or not
    updateTagsArray(tagArray: FormArray, tag: any, relatedCheckbox: boolean) {
        relatedCheckbox = false;
        const index = this.getIndexOfObject(tagArray, tag);
        if (index >= 0) {
            tagArray.removeAt(index);
        } else {
            tagArray.push(new FormControl(tag));
        }
    }

    viewClosed($event) {}

    onSubmit(form: FormGroup): void {
        this.urlStoreService.submitAction = true;
        this.tabStructureService.isSubmitClick = true;
        console.log('form ki value', form);

        this.submitting = true;
        this.serverErrors = {}; //Reset Errors
        let payDevModelValue = form.getRawValue();
        console.log('onSubmit: before checkpayDevModelValue', JSON.parse(JSON.stringify(payDevModelValue)));
        if (payDevModelValue.hasOwnProperty('xRegSerNum') && payDevModelValue['xRegSerNum'] === '') {
            delete payDevModelValue['xRegSerNum'];
            // console.log('onSubmit: after checkpayDevModelValue', JSON.parse(JSON.stringify(payDevModelValue)));
        }
        const payDevModel = payDevModelValue;
        console.log('paydevmodel', payDevModel);

        if (this.edit) {
            payDevModel['xRefNum'] = this.xRefNumEditMode;
        }

        // payDevModel.xAcptCaps.xAcptMdmTags = this.isAllAcceptanceTagsChecked.medium
        //   ? ["all"]
        //   : payDevModel.xAcptCaps.xAcptMdmTags.map(tag => tag.xTag);
        payDevModel.xAcptCaps.xAcptBrandTags = this.isAllAcceptanceTagsChecked.brand
            ? ['all']
            : payDevModel.xAcptCaps.xAcptBrandTags.map(tag => tag.xTag);
        payDevModel.xAcptCaps.xAcptTxnActTags = this.isAllAcceptanceTagsChecked.transaction
            ? ['all']
            : payDevModel.xAcptCaps.xAcptTxnActTags.map(tag => tag.xTag);

        if (payDevModel.xEntrCaps) {
            for (const field of Object.keys(payDevModel.xEntrCaps)) {
                console.log(field, 'field');
                if (payDevModel.xEntrCaps[field] === 'null') {
                    console.log(field, 'field');
                    payDevModel.xEntrCaps[field] = false;
                }
            }
        }
        console.log(payDevModel, 'payDevModel');

        if (this.edit) {
            this.setupStructureTerminalStore.update(this.payDevInfo._id, payDevModel).subscribe(
                (response: any) => {
                    this.handleSuccessResponse(response.data, payDevModel);
                },
                (error: any) => {
                    this.serverErrors = error.errors;
                    this.handleError(error);
                },
                () => {}
            );
        } else {
            this.setupStructureTerminalStore.store(payDevModel).subscribe(
                (response: any) => {
                    this.handleSuccessResponse(response.data, payDevModel);
                },
                (error: any) => {
                    this.serverErrors = error.errors;
                    this.handleError(error);
                },
                () => {}
            );
        }
    }

    handleSuccessResponse(data?: any, payDevModel?: any): void {
        this.submitting = false;
        const msg = this.edit ? 'Record Successfully Updated.' : 'Record Successfully Added.';
        this.notificationService.success(msg, 'Success!');
        this.businessService.toggleRefreshTree();
        // Proper routing depending upon nodeClick or any other click
        if (this.treeService.nodeClick) {
            // this.router.navigated = false;
            if (this.edit) {
                // this.urlStoreService.routeToCurrentUrl(data, "terminal");
                this.setupStructureTerminalStore.get(data.id).subscribe(response => {
                    this.payDevInfo = response.data;
                    this.setFormValues(this.payDevInfo);
                    // this.setAcceptanceField(
                    //   this.mediumTagList,
                    //   this.brandTagList,
                    //   this.transactionTagList
                    // );
                });
            } else {
                this.tabStructureService.currentNodeName = payDevModel.xName;
                this.urlStoreService.routeToCurrentUrl(data, 'terminal', 'add');
            }
        } else {
            if (this.tabStructureService.tabMode) {
                this.urlStoreService.routeBackToPreviousUrl();
            } else {
                if (this.tabStructureService.addNodeBtnClick) {
                    this.tabStructureService.currentNodeName = payDevModel.xName;
                    this.urlStoreService.routeToCurrentUrl(data, 'terminal', 'add');
                } else {
                    this.treeService.setId(this.businessService.businessID.getValue(), 'business');
                    console.log(
                        'onsuccess no tab mode and node click   terminal',
                        this.treeService.businessID.getValue(),
                        this.treeService.chainID.getValue()
                    );
                    this.urlStoreService.routeBackToPreviousUrl();
                }
            }
        }
    }

    handleError(error: any) {
        this.submitting = false;
        for (const field of Object.keys(error.errors)) {
            if (this.formErrors.hasOwnProperty(field)) {
                // this.formErrors[field] = "Field is not allowed to be empty";
                if (field === 'xTerminalNum') {
                    console.log('Inside handle error', error);
                    if (error.name === 'Conflict') {
                        let err = error.errors.xTerminalNum.replace(/terminal/g, 'Terminal');
                        this.notificationService.error(err, 'Conflict');
                        this.clearErrors('xTerminalNum');
                    } else {
                        this.formErrors[field] = 'Field is not allowed to be empty';
                    }
                } else {
                    this.formErrors[field] = 'Field is not allowed to be empty';
                }
            }
            if (field === 'xRefNum') {
                console.log('Inside reference number handle error', error);
                if (error.name === 'Conflict') {
                    let err = error.errors.xRefNum.replace(/terminal/g, 'Terminal');
                    this.notificationService.error(err, 'Conflict');
                    this.clearErrors('xRefNum');
                }
            }
        }
        console.log('formErrors', this.formErrors);
    }

    handleErrorResponse(error: any): void {
        this.submitting = false;
        const errMsgIfBadRequest: string = this.edit ? 'Record Not Updated' : 'Record Not Added';
        try {
            this.serverErrors = JSON.parse(error._body).errors;
            this.notificationService.error(
                JSON.parse(error._body).name === 'BadRequest' ? errMsgIfBadRequest : JSON.parse(error._body).name,
                JSON.parse(error._body).message === 'Invalid data' ? 'Please fill all Required Details' : JSON.parse(error._body).message
            );
        } catch (err) {
            this.notificationService.error('Something went Wrong', 'ERROR');
        }
        this.submitting = false;
    }

    clearErrors(errorName: string): void {
        if (errorName === 'xGTID') {
            this.toggleValidationMessage = false;
        }
        if (this.serverErrors[errorName]) {
            delete this.serverErrors[errorName];
        }
    }

    onKeyPress(event: any) {
        GlobalValidator.numberOnlyFormat(event);
    }

    onPaste(event: any) {
        GlobalValidator.numberOnlyFormat(event);
    }

    onCancel(event: Event) {
        event.preventDefault();
        if (this.tabStructureService.tabMode) {
            console.log(
                'tabmode===>',
                this.tabStructureService.previousTabStructureLabel,
                this.edit,
                this.urlStoreService.previousUrl,
                this.urlStoreService.currentUrl,
                this.urlStoreService.secondLastUrl
            );
            if (!this.edit) {
                this.urlStoreService.routeBackToPreviousUrl();
            } else {
                this.tabStructureService.defaultTabStructureLabel = this.tabStructureService.previousTabStructureLabel;
                this.urlStoreService.routeToSecondLastUrl();
            }
        } else {
            if (this.edit) {
                console.log('no tabmode and edit');
                if (!this.tabStructureService.editMode) {
                    console.log('no tabmode and edit and editmode');
                    this.tabStructureService.defaultTabStructureLabel = this.tabStructureService.previousTabStructureLabel;
                    if (this.urlStoreService.previousUrl && !this.urlStoreService.previousUrl.includes('add-edit')) {
                        this.treeService.setId(this.treeService.previousSelectedNode.id, this.treeService.previousSelectedNode.type);
                    }
                    this.urlStoreService.routeBackToPreviousUrl();
                    // }
                } else {
                    console.log('no tabmode and edit and no editmode');
                    if (this.treeService.nodeClick) {
                        this.urlStoreService.routeToSecondLastUrl();
                    } else {
                        this.treeService.setId(this.treeService.previousSelectedNode.id, this.treeService.previousSelectedNode.type);
                        this.urlStoreService.routeBackToPreviousUrl();
                    }
                }
            } else {
                console.log('no tabmode and no edit');
                this.urlStoreService.routeBackToPreviousUrl();
            }
        }
    }

    ngOnDestroy() {
        this.alive = false;
    }

    onEditClick(event: Event) {
        console.log('Here onEditClick function in biz===>', this.parentMode, JSON.parse(JSON.stringify(this.payDevInfo)));
        this.tabStructureService.previousTabStructureLabel = JSON.parse(JSON.stringify(this.tabStructureService.defaultTabStructureLabel));
        this.treeService.isParent = false;
        this.parentMode = false;
        this.edit = true;
        this.treeService.setId(this.payDevInfo._id, 'terminal');
        // this.router.navigate(['dashboard/setup/business/structure/information'])
    }

    activateGTIDValidation(event: any) {
        this.setRefNum(this.form.get('xGTID').value);             
        this.toggleValidationMessage = true;
    }

    getNewGTID() {
        this.form.controls.xGTID.disable();
        this.GV.isValidatingGTID = true;
        this.gmNumberService.fetchGMData('pgComm/numberAPI_GTIDs', { count: '1' }).subscribe(
            (res: any) => {
                this.form.controls.xGTID.enable();
                this.form.controls.xGTID.setValue(res.data.NumList);
                this.setRefNum(res.data.NumList);             
            },
            (err: any) => {
                this.handleErrorResponse(err);
                this.urlStoreService.routeBackToPreviousUrl();
            }
        );
    }

    /**
     * Set RefNum value with GTID value, where 'GT' is removed
     * @param {string} GTID
     * @memberof SetupStructureTerminalComponent
     */
    setRefNum(GTID: string){
        let newRefNum = GTID 
            ? (GTID).toString().replace('GT', '')
            : '';
        this.form.controls.xRefNum.setValue(newRefNum);
    }

    show() {
        console.log('show: isBusinessEmployee', this.userHandlerService.userRoles.isBusinessEmployee);
        console.log('show: roles', this.userHandlerService.userRoles);
        if (!this.userHandlerService.userRoles.isDealer) {
            return;
        }
        if(!this.edit){
            console.log('add mode: setVtermSnippetErrorMessage called');
            this.setVtermSnippetErrorMessage(CREATE_TERMINAL_BEFORE_SNIPPET_CAN_BE_GENERATED_MSG);
        }
        this.standAloneVTermSnippet = ''; //Clear any snipppet genereated previously
        this.standAloneVTermLinkModal.show()
        console.log('standAloneVTermLinkModal', this.standAloneVTermLinkModal);
    }

    onSelectTemplate(event){
        console.log('onSelectTemplate:event',event.target.value);
        if(event.target.value == -1){
            console.log('onSelectTemplate:base statement');
            this.standAloneVTermSnippet = '';
            return;
        }
        if(this.shouldGenerateSnippet && this.edit){
            let encryptAPI = this.apiConfig.API_HOST + '/api/' + API_END_POINT.vtermEncrypt;
            this.standAloneVTermSnippet = TEMPLATE_ARR[event.target.value].createHtmlCode(this.vTermURL,this.vTermToken, encryptAPI);
            // console.log(this.standAloneVTermSnippet);
        }
    }

    hideSnippetModal() {
        this.standAloneVTermLinkModal.hide();
        //template dropdown will not be available incase of terminal add and invalid 
        //merchant account setup. So this check is needed.
        if(this.templateDropdown){
            this.templateDropdown.reset(-1);
        }
    }

    selectHTMLSnippet(target) {
        console.log('selectHTMLSnippet: target', target);
        let copyTarget = document.getElementById(target);
        console.log('selectHTMLSnippet: copytarget', copyTarget);
        window.getSelection().empty();
        window.getSelection().removeAllRanges();
        var range = document.createRange();
        range.selectNode(copyTarget);
        window.getSelection().addRange(range);
        document.execCommand('copy');
    }

    snippetClick(target: string) {
        this.selectHTMLSnippet(target);
    }

    setupPerms(){
        this.canAdd = this.userHandlerService.userPerms.canCreateSetup;
        this.canEdit = this.userHandlerService.userPerms.canEditSetup;
    }

    openRegisterTerminalModal(template: TemplateRef<any>, operation: string) {
        this.xActualGMID = this.treeService.xGMID;
        this.xActualName = this.form.get('xName').value;
        this.xActualGTID = this.form.get('xGTID').value;
        this.xActualSerialNumber = this.form.get('xRegSerNum').value;
        this.xTerminalType = this.form.get('xTerminalType').value;
        this.operation  = operation;
        this.modalRef = this.modalService.show(template);
    }
    saveRegisterTerminal(xGMID, xGTID, xSerialNumber, xTerminalType?, xTerminalTypeU?) {
        let termType = '';
        if (xTerminalTypeU !== '' && xTerminalTypeU !== undefined) {
            termType = xTerminalTypeU;
        } else if (xTerminalType !== '' && xTerminalType !== undefined ) {
            termType = xTerminalType
        }
        if (this.operation !== '' && xGMID !== '' && xSerialNumber !== '') {
            this.treeService.registerTerminal(xGMID, xSerialNumber, this.xActualName, termType, xGTID, this.operation).then(res=>{
                if (res){
                    this.setupStructureTerminalStore.get(this.payDevInfo._id).subscribe(response => {
                    this.payDevInfo = response.data;
                    this.setFormValues(this.payDevInfo);
                })
            }})
        } else {
            this.notificationService.error('All field must be filled ', 'Error!')
        }
        this.modalRef.hide();
        setTimeout(() => {
            this.ngOnInit();
        }, 1500 )
    }
    serialNumberInput(event: any) {
        this.xActualSerialNumber = event.target.value;
    }
    setSNLength(event: any) {
        switch (event.target.value) {
            case 'select':
                this.serialNumLength = 0;
                break;
            case 'Ingenico':
                this.serialNumLength = 8;
                this.xTerminalType = event.target.value;
                break;
            case 'Pax':
                this.serialNumLength = 10;
                this.xTerminalType = event.target.value;
                break;
            case 'M6Plus':
                this.serialNumLength = 10;
                this.xTerminalType = event.target.value;
                break;
            case 'Dejavoo':
                this.serialNumLength = 10;
                this.xTerminalType = event.target.value;
                break;
            case 'WinPDC':
                this.serialNumLength = 0;
                this.xTerminalType = event.target.value;
                break;
            default:
                this.serialNumLength = 8;
                this.xTerminalType = event.target.value;
                break;
        }
    }



}
