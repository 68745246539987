import { Component, ViewChild, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
    AuthenticationService,
    CompanyService,
    BusinessService,
    TreeService,
    HttpService,
    NotificationService,
    LoginStoreService,
} from '../shared/services';
import { AUTH_MESSAGES, MESSAGES } from '../constants/messages.constants';
import { validateFor } from '../common-functions';
import { API_END_POINT } from '../constants';
import { GOOGLE_RECAPTCHA_SITE_KEY, XSCUSERS } from '../constants/index';
import { Subscription, timer } from 'rxjs';
import { WEB_APP_HOST } from '../shared/config/webApp.config';

import { environment } from '../../environments/environment';

const customerRegistrationEndPoint = 'customer_context';
const TWO_FA_TOKEN_KEY: string = 'twoFAToken';
const ACCESS_TOKEN_KEY: string = 'accessToken';
const OTP_VERIFICATION_RESP_KEY: string = 'otpVerificationResp';
const OTP_KEY: string = 'otp';
const REMAINING_ATTEMPTS_KEY: string = 'remAttempts';
const HOME_URL: string = '/dashboard/home';
const NO_OF_ATTEMPTS_REMAINING: string = 'No of attempts remaining is: ';
const ERR_NAMES = {
    too_many_requests: 'TooManyRequests',
    forbidden: 'Forbidden'
}
const SOMETHING_WENT_WRONG_ERR = 'Something went wrong. Please try again.';
const OTP_EXPIRY_INIT_TIME: number = 300; //60*5 seconds for otp expiry
const USER_KEY = 'user';
const WEB_APP_HOST_STAGING = 'https://testgatewaydashboard.netevia.com';
@Component({
    selector: 'login-cmp',
    templateUrl: 'login.component.html',
})
export class LoginComponent implements OnDestroy, OnInit {
    @ViewChild('uName', {static: true})
    uName: ElementRef;

    @ViewChild('oneTimePassword', {static: true})
    oneTimePassword: ElementRef;

    username: string = '';
    password: string = '';
    otp = '';
    terminalNumber: string = '';
    rememberMe: boolean = false;
    // hitCount: number=0;
    disable: boolean = false;
    showEnterTerminalNumber: boolean = false;
    fetchingTerminal: boolean = false;
    inSecureUser = false; //True if twoFAtoken is generated
    twoFAtoken = ''; //Token which contains encrypted data for 2FA
    otpVerificationLoader = false;
    newOTPLoader = false; //set to true on generating new request
    timeLeft = 0; //remaining time for otp expiry
    countDown: Subscription;
    tick = 1000; //rxjs variable
    recaptchaVerified = ''; //boolean for recaptcha
    env_status_for_recapt = environment.hmr || environment.production || environment.staging || environment.netevia;
    xLoginCoolDown = ''; //user cooldown time
    showTwoFABtns = false; //controller for Skip for now and Enable Two FA buttons
    loginResponse = {}; //response from login api
    disableTwoFABtn = false;
    EMAIL_SENT_MESSAGE = AUTH_MESSAGES.EMAIL_SENT_MESSAGE;
    NO_OF_ATTEMPTS_REMAINING: string;
    OTP_EXPIRED_ERR_MSG = AUTH_MESSAGES.OTP_EXPIRED_ERR_MSG;
    GOOGLE_RECAPTCHA_SITE_KEY = GOOGLE_RECAPTCHA_SITE_KEY;
    
    constructor(
        public authenticationService: AuthenticationService,
        private companyService: CompanyService,
        private businessService: BusinessService,
        private treeService: TreeService,
        private router: Router,
        private httpService: HttpService,
        private notificationService: NotificationService,
        private loginStoreService: LoginStoreService
    ) {
        //TODO Code for logging out should be centralized
        //Clear User Session Data
        this.companyService.intialiseServiceData();
        this.businessService.intialiseServiceData();
        // this.companyService.resetCompanyID();
        // this.businessService.resetBusinessID();
        this.treeService.resetStore();
        this.authenticationService.logout();
    }

    ngOnInit(){
        //to disable 2fa on testportal
        if(WEB_APP_HOST === WEB_APP_HOST_STAGING){
            this.disableTwoFABtn = true;
        }
    }

    ngAfterViewInit() {
        //since username is inside ngIf, nativeElement can't be used
        document.getElementById('username').focus();
    }

    recaptchaError($event){
        this.notificationService.error('Something went wrong.','Error');
    }

    navigateToCustomerLogin() {
        this.showEnterTerminalNumber = true;
    }

    onEnterTerminalNumModalClose(cancel: boolean = false) {
        console.log('Cancel onEnterTerminalNumModalClose===>', cancel);
        this.showEnterTerminalNumber = false;
    }

    onEnterTerminalNumModalContinue() {
        console.log('Cancel onEnterTerminalNumModalContinue===>', this.terminalNumber);
        if (this.terminalNumber.length) {
            this.httpService.authenticatedAPI = false;
            this.fetchingTerminal = true;
            const params = {
                terminalNum: this.terminalNumber,
            };
            this.httpService.store(customerRegistrationEndPoint, params).subscribe(
                res => {
                    console.log('onEnterTerminalNumModalContinue: res', res);
                    this.fetchingTerminal = false;
                    this.httpService.authenticatedAPI = true;
                    if (res && res.hasOwnProperty('storeData') && res.hasOwnProperty('terminalData') && res.hasOwnProperty('pctrData')) {
                        this.loginStoreService.storeData = res['storeData'] ? res['storeData'] : {};
                        this.loginStoreService.terminalData = res['terminalData'] ? res['terminalData'] : {};
                        this.loginStoreService.pctrData = res['pctrData'] ? res['pctrData'] : {};

                        let storeObj = {};
                        let addressObj = this.loginStoreService.storeData['xAddr'];
                        storeObj['id'] = this.loginStoreService.storeData['_id'];
                        storeObj['storeName'] = this.loginStoreService.storeData['xName'];
                        storeObj['storePhoneNum'] = this.loginStoreService.storeData['xMainContact']['xPhone'];
                        storeObj['storeEmail'] = this.loginStoreService.storeData['xMainContact']['xEmail'];
                        storeObj['storeAddress'] =
                            (addressObj['xLine1'] ? addressObj['xLine1'] + ', ' : '') +
                            (addressObj['xLine2'] ? addressObj['xLine2'] + ', ' : '') +
                            (addressObj['xCity'] ? addressObj['xCity'] + ', ' : '') +
                            (addressObj['xSOP'] ? addressObj['xSOP'] + ', ' : '') +
                            (addressObj['xCountry'] ? addressObj['xCountry'] : '');
                        this.loginStoreService.storeObj = storeObj;
                        console.log('onEnterTerminalNumModalContinue: loginStoreservice store', this.loginStoreService.storeData);
                        console.log('onEnterTerminalNumModalContinue: loginStoreservice terminal', this.loginStoreService.terminalData);
                        console.log('onEnterTerminalNumModalContinue: loginStoreservice pctr', this.loginStoreService.pctrData);
                        this.router.navigate(['customer/login'], { queryParams: { termNum: this.terminalNumber } });
                    }
                },
                error => {
                    console.log('onEnterTerminalNumModalContinue: error', error);
                    this.httpService.authenticatedAPI = true;
                    this.fetchingTerminal = false;
                    this.notificationService.error('Unable to fetch Terminal data. Please try again.', 'Error');
                }
            );
        } else {
            this.fetchingTerminal = false;
            this.notificationService.error('Please enter a terminal number', 'Error');
        }
    }

    login({ username, password }: { username: string; password: string }, enableTwoFA: boolean) {
        this.disable = true;
        this.rememberMe ? localStorage.setItem('rememberMe', 'true') : localStorage.setItem('rememberMe', 'false');
        this.authenticationService
            .login(username, password, {}, enableTwoFA)
            .then(res => {
                this.loginResponse = res;
                // console.log('login response: login', JSON.parse(JSON.stringify(res)));
                // console.log('check enable two fa', this.shouldEnableTwoFA());
				this.loginResponse = res;
				if(validateFor(TWO_FA_TOKEN_KEY, this.loginResponse)){
					this.handleLoginResponse();
					return;
				}
                if(this.checkInsecureUser() && this.shouldEnableTwoFA()){
                    this.showTwoFABtns = true;
                    return;
                }
                this.handleAccessToken();
            })
            .catch(err => {
                this.handleLoginError(err);
            });
    }

    handleLoginError(err){
        if (err) {
            this.showTwoFABtns = false;
            console.log('Inside error of login cmp', err);
            let errorMessage = '';
            let errorName = '';
            this.username = '';
            this.password = '';
            setTimeout(() => {
                this.disable = false;
                if (err.message && err.message.includes('Missing credentials')) {
                    errorMessage = MESSAGES['MissingCredentials'].message;
                    errorName = MESSAGES['MissingCredentials'].name;
                } else if (err.name === 'NotAuthenticated' && err.message === 'Error') {
                    errorMessage = MESSAGES['notAuthenticated'].message;
                    errorName = MESSAGES['notAuthenticated'].name;
                }
                if (errorMessage && errorName) {
                    this.notificationService.error(errorMessage, errorName);
                }
                if(err.name == ERR_NAMES.too_many_requests){
                    this.inSecureUser = false;
                    //clear tokens stored
                    this.authenticationService.logout();
                }
            }, 10000);
        } else {
            this.disable = false;
            this.notificationService.error('Please login again', 'Session Expired');
        }
    }
   
    /**
     * Store token only when user submits OTP
     * Verify OTP, if valid, route to home page
     * @param value 
     */
    onSubmitOTP(value: any){
        if(!validateFor(OTP_KEY, value)){
            this.notificationService.error(AUTH_MESSAGES.OTP_IS_REQUIRED_ERR_MSG, 'Error');
            return;
        }
        console.log('form value: onSubmitOTP', value);
        this.EMAIL_SENT_MESSAGE = '';
        this.OTP_EXPIRED_ERR_MSG = '';
        this.authenticationService.storeAccessToken();
        const twoFAData = {
            otp: value[OTP_KEY],
            requestTimeStamp: new Date(),
            token: this.twoFAtoken,
            xLoginCoolDown: new Date(this.xLoginCoolDown)
        }
        this.otpVerificationLoader = true;
        this.httpService.store(API_END_POINT.verifyOTP, twoFAData)
            .subscribe(resp => {
                this.otp = '';
                this.disable = false;
                this.showTwoFABtns = false;
                const otpVerificationResp = validateFor(OTP_VERIFICATION_RESP_KEY, resp)  
                    ? resp[OTP_VERIFICATION_RESP_KEY]
                    : {}
                this.NO_OF_ATTEMPTS_REMAINING = validateFor(REMAINING_ATTEMPTS_KEY, otpVerificationResp)
                    ? NO_OF_ATTEMPTS_REMAINING + otpVerificationResp[REMAINING_ATTEMPTS_KEY]
                    : '';
                if(!this.NO_OF_ATTEMPTS_REMAINING){
                    this.handleTooManyRequests();
                    return;
                }
                this.otpVerificationLoader = false;
                console.log('otp verification response: onSubmitOTP', otpVerificationResp);
                if(otpVerificationResp.success){
                    if(validateFor(ACCESS_TOKEN_KEY, otpVerificationResp)){
                        this.authenticationService.accessToken = otpVerificationResp[ACCESS_TOKEN_KEY];
                        this.authenticationService.storeAccessToken();
                    }
                    let message = otpVerificationResp.message 
                        ? otpVerificationResp.message
                        : AUTH_MESSAGES.OTP_VERIFICATION_SUCCESSFUL_MSG;
                    this.notificationService.success(message, 'Success'); 
                    this.router.navigate([HOME_URL]);
                    this.httpService.authenticatedAPI = true;
                }else{
                    this.otpVerificationLoader = false;
                    this.disable = false;
                    let message = otpVerificationResp.message 
                        ? otpVerificationResp.message
                        : AUTH_MESSAGES.OTP_VERIFICATION_FAILED_MSG;
                    this.notificationService.error(message, 'Error'); 
                }
            }, error => {
                this.otpVerificationLoader = false;
                this.disable = false;
                this.showTwoFABtns = false;
                this.otp = '';
                console.log('onSubmitOTP: error', error);
                let message = error.message ? error.message: AUTH_MESSAGES.OTP_VERIFICATION_FAILED_MSG;
                this.notificationService.error(message, 'Error');
                if(error.name == ERR_NAMES.too_many_requests){
                    this.handleTooManyRequests();
                }
            })
    }

    /**
     * Show error notification, set boolans and clear tokens
     */
    handleTooManyRequests(){
        this.notificationService.error(AUTH_MESSAGES.TOO_MANY_REQUESTS, 'Error');
        this.NO_OF_ATTEMPTS_REMAINING = '';
        this.inSecureUser = false;
        this.otpVerificationLoader = false;
        if(this.countDown) this.countDown.unsubscribe();
        this.authenticationService.logout(); //clears token
    }

    /**
     * Decrease timeLeft each second until it reaches 0
     */
    startTimer() {
        this.countDown = timer(0, this.tick)
            .subscribe(() => {
                if(this.timeLeft > 0) this.timeLeft--;
                else{ 
                    this.OTP_EXPIRED_ERR_MSG = AUTH_MESSAGES.OTP_EXPIRED_ERR_MSG; 
                    this.countDown.unsubscribe();
                };
            })
    }

    /**
     * On click of generate new verification code, re initiate login request
     */
    generateNewOTP(){
        this.countDown.unsubscribe();
        this.newOTPLoader = true;
        this.onEnableTwoFA();
    }

    /**
     * Call login function with enableTwoFA set to false
     * @memberof LoginComponent
     */
    skipForNow(){
        this.authenticationService
            .login(this.username, this.password, {}, false)
            .then(res => {
                this.loginResponse = res;
                this.handleAccessToken();
            }).catch(err => {
                console.log('error: onEnableTwoFA', err);
                this.handleLoginError(err);
            })
    }

    /**
     * Show otp related fields by setting showTwoFABtns to false
     * and call handleLoginResponse
     */
    onEnableTwoFA(){
        this.inSecureUser = true;
        this.showTwoFABtns = false;
        this.OTP_EXPIRED_ERR_MSG = '';
        this.authenticationService
            .login(this.username, this.password, {}, true)
            .then(res => {
                this.loginResponse = res;
                this.newOTPLoader = false;
                this.handleLoginResponse();
            }).catch(err => {
                this.newOTPLoader = false;
                console.log('error: onEnableTwoFA', err);
                this.handleLoginError(err);
            })
    }

    /**
     * From login response 
     * set otp timer
     * set xLoginCoolDown and inSecureUser
     * set twoFAtoken 
     * @returns
     * @memberof LoginComponent
     */
    handleLoginResponse(){
        /** If user logs in with unregistered ip, twoFAToken will be present in response */
        if(validateFor(TWO_FA_TOKEN_KEY, this.loginResponse)){
            this.timeLeft = OTP_EXPIRY_INIT_TIME;
            this.EMAIL_SENT_MESSAGE = AUTH_MESSAGES.EMAIL_SENT_MESSAGE;
            this.startTimer();
            this.inSecureUser = true;
            this.xLoginCoolDown = validateFor(USER_KEY, this.loginResponse) && validateFor(XSCUSERS.xLoginCoolDown, this.loginResponse[USER_KEY])
                ? this.loginResponse[USER_KEY][XSCUSERS.xLoginCoolDown]
                : '';
            this.twoFAtoken = this.loginResponse[TWO_FA_TOKEN_KEY];
            this.disable = false;
        }
    }

    /**
     * if accessToken is present in login response, route to home page
     * @memberof LoginComponent
     */
    handleAccessToken(){
        // console.log('handleAccessToken: loginresponse', JSON.parse(JSON.stringify(this.loginResponse)));
        // console.log('user: handleAccessToken', JSON.parse(JSON.stringify(this.loginResponse['user'])));
        if(validateFor(ACCESS_TOKEN_KEY, this.loginResponse)){
            this.authenticationService.storeAccessToken();
        }
        /** Route to home only if token is present */
        // this.isSuspendedSubscription = this.companyS
        if (this.authenticationService.accessToken) {
            this.disable = false;
            this.router.navigate([HOME_URL]);
            this.httpService.authenticatedAPI = true;
        } 
    }

    /**
     * Check xInSecure bool of user
     * @returns boolean
     * @memberof LoginComponent
     */
    checkInsecureUser(){
        const user = validateFor(USER_KEY, this.loginResponse)
            ? this.loginResponse[USER_KEY]
            : {}
        let xInSecure = user.hasOwnProperty(XSCUSERS.xInSecure)
            ? user[XSCUSERS.xInSecure]
            : true;
        // console.log('insecure user: checkInsecureUser', xInSecure);
        return xInSecure;
    }

    /**
     * If user has no enableTwoFA property or enableTwoFA is set to true
     * return true, else return false
     * @returns boolean
     * @memberof LoginComponent
     */
    shouldEnableTwoFA(){
        return (
            (
                validateFor(USER_KEY, this.loginResponse) 
                && (!this.loginResponse[USER_KEY].hasOwnProperty(XSCUSERS.enableTwoFA) 
                || !this.loginResponse[USER_KEY][XSCUSERS.enableTwoFA])
            )
            || this.loginResponse[USER_KEY][XSCUSERS.enableTwoFA]
        )
    }

    ngOnDestroy(){
        if(this.countDown) this.countDown.unsubscribe();
    }
}
