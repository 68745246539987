export interface vTermTokenDataModel {
	billingAccountInfo: {
		firstName: string;
		lastName: string;
		phone: string;
		email: string;
		company: string;
		address: string;
		city: string;
		state: string;
		country: string;
		zipCode: string;
		_id: string;
		memberNumber: string;
	};
	storeInfo: {
		email: string;
		name: string;
		phone: string;
		address: {
			xCountry: string;
			xPostCode: string;
			xSOP: string;
			xCity: string;
			xSuite: string;
			xLine2: string;
			xLine1: string;
			xCO: string;
		};
		refNum: string;
		_id: string;
	};
	terminalInfo: any;
	webAppInfo: {
		bizID: string;
		terminalID: string;
		actionBy: string;
		xBusinessName: string;
	};
	userInfo: {
		userID: string;
		permissions: {}
	};
	merchantInfo: {
		name: string;
		id: string;
	};
	businessInfo: {
		xVTerminalShowExpandBtn: boolean;
		name: string;
		_id: string;
	};	
	xTerminalName: string;
	xGTID: string;
	xGMID: string;
	xGMPW: string;
	xPCtrName: string;
	initiatorRole: string;
	paymentMode: string;
	authToken: string;
}

export const vTermTokenData:vTermTokenDataModel = {
	billingAccountInfo: {
		firstName: '',
		lastName: '',
		phone: '',
		email: '',
		company: '',
		address: '',
		city: '',
		state: '',
		country: '',
		zipCode: '',
		_id: '',
		memberNumber: '',
	},
	storeInfo: {
		email: '',
		name: '',
		phone: '',
		address: {
			xCountry: '',
			xPostCode: '',
			xSOP: '',
			xCity: '',
			xSuite: '',
			xLine2: '',
			xLine1: '',
			xCO: '',
		},
		refNum: '',
		_id: '',
	},
	terminalInfo: {},
	webAppInfo: {
		bizID: '',
		terminalID: '',
		actionBy: '',
		xBusinessName: '',
	},
	userInfo: {
		userID: '',
		permissions: {}
	},
	merchantInfo: {
		name: '',
		id: '',
	},
	businessInfo: {
		xVTerminalShowExpandBtn: true,
		name: '',
		_id: '',
	},
	xTerminalName: '',
	xGTID: '',
	xGMID: '',
	xGMPW: '',
	xPCtrName: '',
	initiatorRole: '',
	paymentMode: '',
	authToken: '',
}
