import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { skip, takeWhile } from 'rxjs/operators';
import * as crypto from 'crypto-js';
import {
	TransactionsStore,
	BatchStore,
	SetupStructureProfitCenterStore,
	SetupBillingCustomerStore,
    SetupBusinessStructureStoreSetupStore
} from '../../../../store/index';
import { LAST_DAYS_FILTER, customSwal, TAGSCONSTS, TXN_MODE, NO_PERMISSION_MSG } from '../../../../constants';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TransactionParams, TxnActionPerms } from '../../../../models';
import {
	NotificationService,
	TransactionActionService,
	TreeService,
	HttpService,
	BusinessService,
	GlobalValidator,
	SummaryReportService,
	LoginStoreService,
	UserHandlerService,
	BreadcrumbService
	// TimerService
} from 'app/shared/services';
import {
    processToAssociativeObject,
    createEditObj,
    checkValidAmt,
    INVALID_AMT_MSG,
    INVALID_BASE_AMT_MSG,
    abbreviateStoreName,
    KEEP_NO_OF_CHARACTERS_LAST,
    KEEP_NO_OF_CHARACTERS_FIRST,
    STORE_NAME_ABBREVIATION_LENGTH_MAX_CAP
} from '../../shared/common-functions/generic-functions';
import { XPETXNS, BE_KEY } from '../../../../constants/index';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { validateFor } from '../../../../common-functions';

// const SETTLE_ACTION_RESPONSE_INITIATION_WAIT_TIME = 15000; // set initiation wait time to 15 sec

const SETTLE_SELECT_ACTION_INTERVAL = 1000;
const SETTLE_ALL_ACTION_INTERVAL = 2000;

const NODE_TYPE_BUSINESS = 'business';
const STRUCT_TYPE_CHAIN = 'chain';
const STRUCT_TYPE_REGION = 'region';
const STRUCT_TYPE_PROPERTY = 'property';
const NODE_TYPE_STORE = 'store';
const NODE_TYPE_PCTR = 'profit_center';
const NODE_TYPE_MERCHANT_ACCT = 'merchant_account';
const NODE_TYPE_TERMINAL = 'terminal';
const NODE_TYPE_PERIPH = 'peripheral';
const NO_TXN_ID_ERR_MSG = "Unable to fetch transactions ID's for the selected transactions.";

const RECORD_COUNT_PCTR_FETCH_FAIL = 'Unable to fetch record count for Profit Centers';
const NO_SETTLE_ALLOW_SWAL_MSG = 'Please select only one Profit Center to Settle. Multi Profit Center Settlement not supported.';
const NO_SETTLE_ALLOW_SWAL_TITLE = 'Cannot proceed with Settle Action.';

const TIP_AMT_FIELD = 'tipAmt';

const GET_PROC_KEY = 'getProc';
const GET_PROC_FIELDS = 'xName';

const UNABLE_TO_SET_STORE_NAME_ERROR: string = 'Unable to set Store Name.';

// API end point constants
const RE_AUTH_END_POINT = 'pgComm/reAuth';

const TRANSACTIONS_TYPE_OPEN_TXNS = 'txns';

const MODAL_TXT_REDO = 'Redo';
const MODAL_TXT_EDIT = 'Edit';

// Advanced Date Filter Types
const DATE_FILTER_AUTH = 'auth';
const DATE_FILTER_FINALIZE = 'finalize';

// Advanced Date Filter Field
const AUTH_DATE = 'xAuthDate';
const FINALIZE_DATE = 'xFinalizeDate';


@Component({
    selector: 'app-outstanding-transactions',
    templateUrl: './outstanding-transactions.component.html',
    styleUrls: ['./outstanding-transactions.component.scss']
})
export class OutstandingTransactionsComponent implements OnInit, OnDestroy, AfterViewInit {
    // @ViewChild('NgxAsidePanelRight') ngxAsidePanelRight: any;
    @ViewChild('addTipsModal')
    addTipsModal: any;

    batchedtxns: boolean;
    paramId: string;
    batchNum: number = 0;
    fetchByStore: boolean = true;
    showDetails: boolean = false;
    showOverLay: boolean = false;
    showAddTipModal: boolean = false;
    showEditModal: boolean = false;
    showPaginationModal: boolean = false;
    observables: any[] = [];
    finalizeTxnArr: any[] = [];
    tipTxnObj: any[] = [];
    editTxns: any[] = [];
    rePrint: boolean = false;
    transactionStoreData = [];
    storeData: any[] = [];
    type: string = 'txn';
    queueId: string = 'txn';
    totalNumberOfRecords: number = 0;
    totalPages: number[] = [];
    finalHashedData: any[] = [];
    paginationParams: any = {};
    isCustomer: boolean = false;
    loginStoreObj: any = {};
    customerTxns: boolean = false;
    custNum: string = '';
    public txn_mode: string = TXN_MODE.STANDARD;

    public visibleModal: boolean = false;
    public showResponseModal: boolean = false;
    public filter: string = 'all';
    public advancedDateFilterType:string = ''; // defines which kind of date filter needs to be run auth or finalize
    public DATE_FILTER_AUTH:string = DATE_FILTER_AUTH;
    public DATE_FILTER_FINALIZE:string = DATE_FILTER_FINALIZE;
    public responseForResponseModal: any = {};
    public typeForResponseModal: String = '';
    public typeForPaginationModal: String = '';
    public refreshGrid: boolean = false;
    public selectAll: boolean = false;
    // public responseModalClose: boolean = false;
    public attributes: any[] = [];
    public storeValue: string = '';
    public storeObject: any = {};
    public action: any[] = [];
    public donePages: any[] = [];
    public previousAction: string = '';
    public currentAction: string = '';
    public parameters: any = {
        // noGrid: true
    };
    private paramList: TransactionParams[];
    public visiblePrintDetailModal: boolean = false;
    public showTxn: boolean = true;
    public editLoader: boolean = false;
    public finalizeLoader: boolean = false;
    public voidLoader: boolean = false;
    public closeLoader: boolean = false;    
    public settleLoader: boolean = false;
    public redoLoader: boolean = false;
    public rePrintLoader: boolean = false;
    public printDetailLoader: boolean = false;
    public disableActionBtns: boolean = false;
    public transactionID: string = '';
    public transactionType: string = 'all';
    public dateRangeValue: Date[] = [];
    public checkedIds: string[];
    alive: boolean = true;
    totalObj: any = {
        totTxn: 0,
        totTxnAmt: '$0.00'
    };
    selectedObj: any = {
        selTxn: 0,
        selTxnAmt: 0.0
    };
    pctrListString: string = '';
    gmidList = [];
    public modalText: string = ''; //'Redo' for Redo action, Edit for 'Edit' action

    tagConstants = TAGSCONSTS;
    txnActionPermissions: TxnActionPerms = {};
    noPermMsg = NO_PERMISSION_MSG;
    public INVALID_AMT_MSG: string = INVALID_AMT_MSG;
    public INVALID_BASE_AMT_MSG: string = INVALID_BASE_AMT_MSG;

    public viewDetails: any[] = [
        { header: 'Store Name', mapTo: 'xStoreName' },
        { header: 'Store Number', mapTo: 'xStoreNum' },
        { header: 'Status', mapTo: 'xStatus' },
        { header: 'Type', mapTo: 'xTxnActTag' },
        { header: 'Is Offline', mapTo: 'xIsOffline' },
        { header: 'Transaction Number', mapTo: 'xTxnNum' },
        { header: 'Customer Number', mapTo: 'xCustNum'},
        { header: 'GTRC', mapTo: 'xSysTrcNum' },
        { header: 'Processor', mapTo: 'processorName'},
        { header: 'Transaction Account Tag', mapTo: 'xTxnActTag' },
        { header: 'Medium', mapTo: 'xMdmTag' },
        { header: 'Brand', mapTo: 'xBrandTag' },
        { header: 'Entry Type', mapTo: 'xAcctEntrTag' },
        { header: 'Account Number', mapTo: 'xMaskedAcctNum' },
        { header: 'Is Token Used', mapTo: 'xIsTokUsed' },
        { header: 'Authorization Code', mapTo: 'xAuthCode' },
        { header: 'Invoice Number', mapTo: 'xInvNum' },
        {
            header: 'Main amount',
            mapTo: 'xMainAmt'
        },
        { header: 'Included tax amount', mapTo: 'xIncTaxAmt' },
        { header: 'Included shipping amount', mapTo: 'xIncShipAmt' },
        { header: 'Included duty amount', mapTo: 'xIncDutyAmt' },
        { header: 'Included surcharge amount', mapTo: 'xIncSurcAmt' },
        { header: 'Tip Amount', mapTo: 'xTipAmt' },
        { header: 'Total Amount', mapTo: 'xTtlAmt' },
        { header: 'Balance Amount', mapTo: 'xBalAmt' },
        {
            header: 'Transaction business date',
            mapTo: 'xBizDate'
        },
        {
            header: 'Transaction authorization time',
            mapTo: 'xAuthDateTime'
        },
        {
            header: 'Transaction voided time',
            mapTo: 'xVoidDateTime'
        },
        {
            header: 'Transaction finalized time',
            mapTo: 'xFinalizeDateTime'
        },
        {
            header: 'Transaction settlement time',
            mapTo: 'xSettleDateTime'
        },
        {
            header: 'Response Code',
            mapTo: 'xRspCode'
        },
        {
            header: 'Response Text',
            mapTo: 'xRspText'
        },
        {
            header: 'Source of Transaction',
            mapTo: 'sourceOfTransaction'
        },
        {
            header: 'Items Detail',
            mapTo: 'xItemsInfo',
        }
    ];
    public storeName: string = '';
    public abbreviatedStoreName: string = '';

    constructor(
        public transactionsStore: TransactionsStore,
        private batchStore: BatchStore,
        private setupStructureProfitCenterStore: SetupStructureProfitCenterStore,
        // private setupStructureTerminalStore: SetupStructureTerminalStore,
        private route: ActivatedRoute,
        private router: Router,
        private notificationService: NotificationService,
        private transactionActionService: TransactionActionService,
        private treeService: TreeService,
        private httpService: HttpService,
        public businessService: BusinessService,
        private breadcrumbService: BreadcrumbService,
        private summaryReportService: SummaryReportService, // private timerService: TimerService
        public loginStoreService: LoginStoreService,
        public userHandlerService: UserHandlerService,
        private customerStore: SetupBillingCustomerStore,
		private setupBusinessStructureStoreSetupStore: SetupBusinessStructureStoreSetupStore,
    ) {
        // this.transactionActionService.fetchTxnConsts();
    }

    ngAfterViewInit(): void {
		if (this.customerTxns) {
			Promise.resolve(null).then(() => {
				this.treeService.setEnable(false); //Close tree if transactions is opened from customers.
			});
		} else {
			Promise.resolve(null).then(() => {
				this.treeService.setEnable(true); //Open tree in other cases.
			});
		}
	}

    ngOnInit() {
        this.isCustomer = this.userHandlerService.userRoles.isCustomer;
        this.setTxnActionPermissions();
		console.log('isCustomer', this.isCustomer);
		this.loginStoreObj = this.loginStoreService.storeObj;
		console.log('login store service', this.loginStoreObj);
        this.setBreadCrumbValue('GMID - ', '', true);
        this.route.params.forEach((params: Params) => {
            const routePath = this.route['routeConfig'].path;
            if (params.hasOwnProperty('Id')) {
                if (routePath && routePath.includes('/batch/')) {
					this.batchedtxns = true;
					// if (!params.Id) {
					// 	//TODO-: Handle No ID case
					// }
					this.paramId = params['Id'];
					this.filter = 'all';
                    this.fetchBatchInfo(this.paramId);
                }
                if (routePath && routePath.includes('/cust/')) {
					this.customerTxns = true;
					this.paramId = params['Id'];
					this.filter = 'all';
					this.fetchCustomerInfo(this.paramId);
				}
            }
        });
        this.setInitParams();
        this.filterData();

        //Watchers for changes in the tree--------->
        this.businessService.watchBusinessID$
            .pipe(
                takeWhile(() => this.alive),
                skip(1)
            )
            .subscribe(bizID => {
                // console.log("bizID->", bizID);
                if (bizID !== null) {
                    // this.parameters = Object.assign({}, this.parameters, {xBusinessID: this.treeService.businessID.getValue()});
                    this.setBreadCrumbValue('GMID - ', '', true);
                    this.parameters = {
                        xBusinessID: this.treeService.businessID.getValue(),
                        hashData: true
                    };
                    this.batchedtxns = false;
                    this.batchNum = 0;
                    // console.log("this.parameter", JSON.parse(JSON.stringify(this.parameters)));
                    //Setting customer mode to false in watcher because
                    //whenever business gets changed, its outstanding txn list should be shown.
                    this.customerTxns = false;
                    this.filterData();
                } else {
                    // console.log("Re routing from here 1");
                    // this.router.navigate(['/dashboard/home']);
                    this.handleWatcher_NullValueCase('biz');
                    // this.parameters = {
                    //     xBusinessID: null
                    // };
                }
            });
        this.treeService.watchBusinessID$
            .pipe(
                takeWhile(() => this.alive),
                skip(1)
            )
            .subscribe(bizID => {
                if (bizID) {
                    // console.log("Inside bisid watcher");
                    this.parameters = {
                        xBusinessID: this.treeService.businessID.getValue(),
                        hashData: true
                    };
                    this.filterData();
                } else this.handleWatcher_NullValueCase('biz');
            });
        this.treeService.watchChainID$
            .pipe(
                takeWhile(() => this.alive),
                skip(1)
            )
            .subscribe(chainID => {
                if (chainID) {
                    // console.log("Inside chainID watcher", chainID);
                    this.parameters = {
                        struct_type: STRUCT_TYPE_CHAIN,
                        structID: this.treeService.chainID.getValue(),
                        hashData: true
                    };
                } else this.handleWatcher_NullValueCase('chain');
            });
        this.treeService.watchRegionID$
            .pipe(
                takeWhile(() => this.alive),
                skip(1)
            )
            .subscribe(regionID => {
                if (regionID) {
                    // console.log("Inside regionID watcher", regionID);
                    this.parameters = {
                        struct_type: STRUCT_TYPE_REGION,
                        structID: this.treeService.regionID.getValue(),
                        hashData: true
                    };
                } else this.handleWatcher_NullValueCase('region');
            });
        this.treeService.watchPropertyID$
            .pipe(
                takeWhile(() => this.alive),
                skip(1)
            )
            .subscribe(propertyID => {
                if (propertyID) {
                    // console.log("Inside propertyID watcher", propertyID);
                    this.parameters = {
                        struct_type: STRUCT_TYPE_PROPERTY,
                        structID: this.treeService.propertyID.getValue(),
                        hashData: true
                    };
                } else this.handleWatcher_NullValueCase('property');
            });
        this.treeService.watchStoreID$
            .pipe(
                takeWhile(() => this.alive),
                skip(1)
            )
            .subscribe(storeID => {
                if (storeID) {
                    // console.log("Inside storeid watcher");
                    this.parameters = {
                        xStoreID: this.treeService.storeID.getValue(),
                        hashData: true
                    };
                    this.filterData();
                } else this.handleWatcher_NullValueCase('store');
            });
        this.treeService.watchProfitCenterID$
            .pipe(
                takeWhile(() => this.alive),
                skip(1)
            )
            .subscribe(profitCenterID => {
                if (profitCenterID) {
                    // console.log("Inside pctrid watcher");
                    this.parameters = {
                        xPCtrID: this.treeService.profitCenterID.getValue(),
                        hashData: true
                    };
                    this.filterData();
                } else this.handleWatcher_NullValueCase('profit_center');
            });
        this.treeService.watchMerchantAccID$
            .pipe(
                takeWhile(() => this.alive),
                skip(1)
            )
            .subscribe(merID => {
                if (merID) {
                    // console.log("Inside meractid watcher");
                    this.parameters = {
                        xMerAcctID: this.treeService.merchantAccID.getValue(),
                        hashData: true
                    };
                    this.filterData();
                } else this.handleWatcher_NullValueCase('merchant_account');
            });
        this.treeService.watchTerminalID$
            .pipe(
                takeWhile(() => this.alive),
                skip(1)
            )
            .subscribe(paymentDevID => {
                if (paymentDevID) {
                    // console.log("Inside terminalid watcher");
                    this.parameters = {
                        xTerminalID: this.treeService.terminalID.getValue(),
                        hashData: true
                    };
                    this.filterData();
                } else this.handleWatcher_NullValueCase('terminal');
            });
        this.treeService.watchPeriphID$
            .pipe(
                takeWhile(() => this.alive),
                skip(1)
            )
            .subscribe(perphID => {
                if (perphID) {
                    // console.log("Inside periphid watcher");
                    this.parameters = {
                        xPeriphID: this.treeService.periphID.getValue(),
                        hashData: true
                    };
                    this.filterData();
                } else this.handleWatcher_NullValueCase('peripheral');
            });
        //End of Watchers for changes in the tree--------->
    }

    setBreadCrumbValue(gmidString: string, tooltip: string, loading: boolean): void {
        console.log('tooltipstring', tooltip.length);
        const breadCrumb: any = {};
        breadCrumb.root = 'Transactions';
        breadCrumb.child = gmidString;
        breadCrumb.subChild = '';
        breadCrumb.subChildLink = null;
        breadCrumb.grandChild = null;
        if (tooltip.length === 0) {
            breadCrumb.tooltip = false;
        } else {
            breadCrumb.toolTip = true;
        }
        breadCrumb.toolTipData = tooltip;
        breadCrumb.loading = loading;
        this.breadcrumbService.setBreadCrumb(breadCrumb);
    }

    /**
     * Get and set Store-Name
     * @param storeID: string: mongoID of store
     */
    setStoreName(storeID: string){
        console.log('storeID: setStoreName', storeID);
        let storeParams = {
            _id: storeID,
            selectKey: XPETXNS.xName,
            noGrid: true
        }
        this.setupBusinessStructureStoreSetupStore.getAll(storeParams).subscribe(storeResponse => {
            console.log('storeResponse: setStoreName', storeResponse);
            this.storeName = 
                validateFor(BE_KEY.data, storeResponse) 
                && storeResponse[BE_KEY.data].length
                && validateFor(XPETXNS.xName, storeResponse[BE_KEY.data][0])
                ? storeResponse[BE_KEY.data][0][XPETXNS.xName]: '-';
            this.abbreviatedStoreName = 
                this.storeName.length > STORE_NAME_ABBREVIATION_LENGTH_MAX_CAP 
                ? abbreviateStoreName(this.storeName, KEEP_NO_OF_CHARACTERS_FIRST, KEEP_NO_OF_CHARACTERS_LAST): this.storeName;
            console.log('storeName: setStoreName', this.storeName);
            console.log('abbreviatedStoreName: setStoreName', this.abbreviatedStoreName);
        }, error => {
            console.log('error: setStoreName', error);
            this.notificationService.error(UNABLE_TO_SET_STORE_NAME_ERROR, 'Error');
        })
    }

    recievePCtrList(event: any) {
        console.log('event in pctrlist', event);
        this.gmidList = [];
        this.pctrListString = '';
        event.map(item => {
            this.pctrListString += item + ',';
            // remove this statement once api call added
            // this.gmidList.push(item);
        });
        this.pctrListString = this.pctrListString.slice(0, -1);
        let params = { pctrIDs: this.pctrListString, selectKey: 'xGMID' };
        this.setBreadCrumbValue('GMID - ', '', true);
        console.log('params for gmid', params);
        this.httpService.getAll('xbcPCtrs', params).subscribe(
            response => {
                console.log('response get gmid', response);
                response.data.map(item => {
                    this.gmidList.push(item.xGMID);
                });
                console.log('gmidlist', this.gmidList);
                console.log('pctrliststring', this.pctrListString);
                let gmidStringBreadCrumb = 'GMID - None',
                    tooltipGMID = '';
                if (this.gmidList.length === 1) gmidStringBreadCrumb = 'GMID - ' + this.gmidList[0];
                else if (this.gmidList.length >= 2) gmidStringBreadCrumb = 'GMID - ' + this.gmidList[0] + ', ' + this.gmidList[1];
                this.gmidList.map(item => {
                    tooltipGMID += item + ',';
                });
                tooltipGMID = tooltipGMID.slice(0, -1);
                this.setBreadCrumbValue(gmidStringBreadCrumb, tooltipGMID, false);
            },
            error => {
                console.log('Failed api call for gmid');
            }
        );
    }

    setInitParams() {
        let customerID = '';
		if (this.isCustomer) {
			customerID = this.userHandlerService.userData.xEntyID; 
		}
		console.log('customerID:setInitParams ', customerID);
		console.log('business id', this.businessService.businessID.getValue());
        if (this.treeService.businessID.getValue())
            this.parameters = Object.assign({}, this.parameters, {
                xBusinessID: this.treeService.businessID.getValue(),
                hashData: true
            });

        if (this.treeService.chainID.getValue())
            this.parameters = Object.assign({}, this.parameters, {
                struct_type: STRUCT_TYPE_CHAIN,
                structID: this.treeService.chainID.getValue(),
                hashData: true
            });

        if (this.treeService.regionID.getValue())
            this.parameters = Object.assign({}, this.parameters, {
                struct_type: STRUCT_TYPE_REGION,
                structID: this.treeService.regionID.getValue(),
                hashData: true
            });

        if (this.treeService.propertyID.getValue())
            this.parameters = Object.assign({}, this.parameters, {
                struct_type: STRUCT_TYPE_PROPERTY,
                structID: this.treeService.propertyID.getValue(),
                hashData: true
            });

        if (this.treeService.storeID.getValue())
            this.parameters = Object.assign({}, this.parameters, {
                xStoreID: this.treeService.storeID.getValue(),
                hashData: true
            });

        if (this.treeService.profitCenterID.getValue())
            this.parameters = Object.assign({}, this.parameters, {
                xPCtrID: this.treeService.profitCenterID.getValue(),
                hashData: true
            });

        if (this.treeService.merchantAccID.getValue())
            this.parameters = Object.assign({}, this.parameters, {
                xMerAcctID: this.treeService.merchantAccID.getValue(),
                hashData: true
            });

        if (this.treeService.terminalID.getValue())
            this.parameters = Object.assign({}, this.parameters, {
                xTerminalID: this.treeService.terminalID.getValue(),
                hashData: true
            });

        if (this.treeService.periphID.getValue())
            this.parameters = Object.assign({}, this.parameters, {
                xPeriphID: this.treeService.periphID.getValue(),
                hashData: true
            });

        if (this.batchedtxns) {
            this.txn_mode = TXN_MODE.BATCH;
            this.parameters = Object.assign({}, this.parameters, {
                xBatchID: this.paramId,
                hashData: true
            });
        }
        if (this.customerTxns || customerID) {
			this.txn_mode = TXN_MODE.CUSTOMER;
			this.parameters = Object.assign({}, this.parameters, {
				xCustomerID: this.paramId ? this.paramId : customerID ? customerID : '',
				hashData: true
			});
		}
    }

    filterData() {
        let filterField: string = '';
        this.transactionActionService.setResetFilter(true);
        switch (this.filter) {
            case LAST_DAYS_FILTER.all:
                // console.log('here 2');
                delete this.parameters['filterType'];
                if (this.parameters.hasOwnProperty('startDate')) delete this.parameters['startDate'];
                if (this.parameters.hasOwnProperty('endDate')) delete this.parameters['endDate'];
                if (this.parameters.hasOwnProperty('currDate')) delete this.parameters['currDate'];
                if (this.parameters.hasOwnProperty('filterField')) delete this.parameters['filterField'];
                this.parameters = Object.assign({}, this.parameters);
                break;
            case LAST_DAYS_FILTER.last_24_hrs:
                if (this.parameters.hasOwnProperty('filterField')) delete this.parameters['filterField'];
                this.parameters = Object.assign({}, this.parameters, {
                    filterType: LAST_DAYS_FILTER.last_24_hrs
                });
                break;
            case LAST_DAYS_FILTER.last_7_days:
                if (this.parameters.hasOwnProperty('filterField')) delete this.parameters['filterField'];
                this.parameters = Object.assign({}, this.parameters, {
                    filterType: LAST_DAYS_FILTER.last_7_days
                });
                break;
            case LAST_DAYS_FILTER.last_30_days:
                if (this.parameters.hasOwnProperty('filterField')) delete this.parameters['filterField'];
                this.parameters = Object.assign({}, this.parameters, {
                    filterType: LAST_DAYS_FILTER.last_30_days
                });
                break;
            case LAST_DAYS_FILTER.last_60_days:
                if (this.parameters.hasOwnProperty('filterField')) delete this.parameters['filterField'];
                this.parameters = Object.assign({}, this.parameters, {
                    filterType: LAST_DAYS_FILTER.last_60_days
                });
                break;
            case LAST_DAYS_FILTER.last_90_days:
                if (this.parameters.hasOwnProperty('filterField')) delete this.parameters['filterField'];
                this.parameters = Object.assign({}, this.parameters, {
                    filterType: LAST_DAYS_FILTER.last_90_days
                });
                break;
            case 'advancedDateFilter':
                delete this.parameters['currDate'];
                switch(this.advancedDateFilterType){
                    case DATE_FILTER_AUTH:
                        filterField = AUTH_DATE;
                        break;
                    case DATE_FILTER_FINALIZE:
                        filterField = FINALIZE_DATE;
                        break;
                }
                this.parameters = Object.assign({}, this.parameters, {
                    filterType: 'advancedDateFilter',
                    filterField: filterField
                });
                break;
        }

        if (this.filter !== 'advancedDateFilter') {
            this.advancedDateFilterType = '';
        }
        if (this.filter !== 'all' && this.filter !== 'advancedDateFilter') {
            if (this.parameters.hasOwnProperty('startDate')) delete this.parameters['startDate'];
            if (this.parameters.hasOwnProperty('endDate')) delete this.parameters['endDate'];
            console.log('currentDate', new Date());
            // console.log("currentDate iso", new Date().toISOString());
            this.parameters = Object.assign({}, this.parameters, {
                // currDate: new Date().toISOString()
                currDate: new Date().toDateString()
            });
        } else if (this.filter === 'advancedDateFilter') {
            console.log('new Date(this.dateRangeValue[0])', this.dateRangeValue[0]);
            // console.log("new Date(this.dateRangeValue[0]) iso", new Date(this.dateRangeValue[0]).toUTCString());
            this.parameters = Object.assign({}, this.parameters, {
                startDate: this.dateRangeValue[0].toDateString(),
                endDate: this.dateRangeValue[1].toDateString()
                // startDate: new Date(this.dateRangeValue[0]).toUTCString(),
                // endDate: new Date(this.dateRangeValue[1]).toUTCString()
            });
        }

        if (this.batchedtxns) {
			this.parameters = Object.assign({}, this.parameters, {
				xBatchID: this.paramId
			});
        }
        
        if (this.customerTxns) {
			this.parameters = Object.assign({}, this.parameters, {
				xCustomerID: this.paramId,
				hashData: true
			});
		}

        console.log('this.parameters=====>', this.parameters);

        this.setGridProperties();
    }

    onDateFilterClick() {
        console.log('this.dateRangeValue', this.dateRangeValue);
        // console.log(
        //     "this.dateRangeValue",
        //     JSON.parse(JSON.stringify(this.dateRangeValue[0]))
        // );
        if (this.dateRangeValue && this.dateRangeValue.length && this.advancedDateFilterType) {
            this.filter = 'advancedDateFilter';
            this.filterData();
        }
    }

    fetchBatchInfo(id: string) {
        this.batchStore.get(id).subscribe(
            (batchResponse: any) => {
                console.log("Batch response: fetchBatchInfo", batchResponse);
                let storeID = 
                    validateFor(BE_KEY.data, batchResponse) 
                    && validateFor(XPETXNS.xStoreID, batchResponse[BE_KEY.data])  
                    ? batchResponse[BE_KEY.data][XPETXNS.xStoreID]: '';
                    if(storeID){
                        this.setStoreName(storeID);
                    }
                this.batchNum = batchResponse.data ? batchResponse.data.xBatchNum : '';
            },
            (error: any) => {
                this.notificationService.error(JSON.parse(error._body).name, JSON.parse(error._body).message);
            }
        );
    }

    fetchCustomerInfo(id: string) {
		this.customerStore.get(id).subscribe(
			(response: any) => {
				console.log('customer response:fetchCustomerInfo: outstanding-tranactions', response);
				this.custNum = response.data ? response.data.xCustomerNum : '';
			},
			(error: any) => {
				this.notificationService.error(JSON.parse(error._body).name, JSON.parse(error._body).message);
			}
		);
	}

    setGridProperties() {
        this.storeValue = 'TransactionsStore';
        this.storeObject = this.transactionsStore;
        // console.log("Transaction==========>",this.storeObject,"Transaction Value==========>", this.storeValue);
        this.attributes = [
            { header: 'Sel. All', mapTo: '', width: '1%' },
            { header: 'Type', mapTo: 'xTxnActTag', width: '4%' },
            { header: 'Trans#', mapTo: 'xTxnNum', width: '10%' },
            { header: 'Invoice', mapTo: 'xInvNum', width: '6%' },
            { header: 'Customer#', mapTo: 'xCustNum', width: '7%'},
            { header: 'Card', mapTo: 'xBrandTag', width: '4%' },
            { header: 'Account #', mapTo: 'xMaskedAcctNum', width: '8%' },
            { header: 'fa fa-credit-card', mapTo: 'xAcctEntrTag', width: '3%' },
            { header: 'Amount', mapTo: 'xMainAmt', width: '6%' },
            { header: 'Tip', mapTo: 'xTipAmt', width: '4%' },
            { header: 'Total', mapTo: 'xTtlAmt', width: '5%' },
            { header: 'App Code', mapTo: 'xAuthCode', width: '7%' },
            {
                header: 'Auth Date',
                mapTo: 'xAuthDateTime',
                width: '7%'
            },
            {
                header: 'Finalize Date',
                mapTo: 'xFinalizeDateTime',
                width: '8%'
            }
        ];

        this.action = [
            {
                type: 'view',
                visible: true,
                text: 'Details',
                icon: 'fa fa-eye',
                action: 'emit'
            },
            {
                type: 'modal',
                visible: true,
                text: 'Void',
                icon: 'fa fa-info',
                action: 'emit',
                value: 'void'
            },
            {
                type: 'modal',
                visible: true,
                text: 'Finalize',
                icon: 'fa fa-info',
                action: 'emit',
                value: 'finalize'
            }
        ];
    }

    onActionRequest(obj: any) {
        if (obj.act.type === 'view') {
            // this.transactionID = obj.id;
            this.transactionID = `${obj.id}?${GET_PROC_KEY}=${GET_PROC_FIELDS}`;
            // this.detailVisible = true;
            // this.ngxAsidePanelRight.show();
        }
    }

    onEmitChecked(obj: any) {
        console.log('Here===>', obj);
        this.currentAction = obj.action;
        this.transactionActionService.setActionPerformed(false);
        this.finalHashedData = [];
        this.editTxns = [];
        this.selectAll = false;
        switch (obj.action) {
            case 'void':
                this.checkedIds = obj.ids;
                this.finalHashedData = obj.hashedData;
                this.typeForResponseModal = 'Void';
                if (!obj.hasOwnProperty('selAll') || !obj.selAll) {
                    if (obj && obj.ids.length) {
                        // console.log("Maybe here");
                        if (obj.ids.length > 5) {
                            this.limitExceededSwal('Void');
                        } else {
                            this.performVoidAction();
                        }
                    } else {
                        // console.log("HERE in no txn select");
                        this.noTransactionSelectedSwal('Void');
                    }
                } else {
                    // this.checkedIds= obj.uncheckedIds;
                    if (obj.hasOwnProperty('totalRecords')) {
                        if (obj.totalRecords <= 5) {
                            this.performVoidAction();
                        } else {
                            if (obj.uncheckedIds && obj.uncheckedIds.length && obj.totalRecords - obj.uncheckedIds.length <= 5) {
                                this.performVoidAction();
                            } else {
                                this.limitExceededSwal('Void');
                            }
                        }
                    }
                }
                break;
                //@cary add by cary
                case 'close':
                    console.log("enter close branch");
                    this.checkedIds = obj.ids;
                    this.finalHashedData = obj.hashedData;
                    this.typeForResponseModal = 'Close';
                    if (!obj.hasOwnProperty('selAll') || !obj.selAll) {
                        if (obj && obj.ids.length) {
                            // console.log("Maybe here");
                            if (obj.ids.length > 5) {
                                this.limitExceededSwal('Close');
                            } else {
                                this.performCloseAction();
                            }
                        } else {
                            // console.log("HERE in no txn select");
                            this.noTransactionSelectedSwal('Close');
                        }
                    } else {
                        // this.checkedIds= obj.uncheckedIds;
                        if (obj.hasOwnProperty('totalRecords')) {
                            if (obj.totalRecords <= 5) {
                                this.performCloseAction();
                            } else {
                                if (obj.uncheckedIds && obj.uncheckedIds.length && obj.totalRecords - obj.uncheckedIds.length <= 5) {
                                    this.performCloseAction();
                                } else {
                                    this.limitExceededSwal('Close');
                                }
                            }
                        }
                    }
                    break;                
            case 'settle':
                // if ((obj && obj.ids.length) || (obj.hasOwnProperty('selAll') && obj.selAll)) {
                this.validateSettleAction()
                .then(shouldAllowAction => {
                    console.log('onEmitChecked: validateSettleAction: shouldAllowAction', shouldAllowAction);
                    if (!shouldAllowAction) {
                        console.log('onEmitChecked: validateSettleAction: show swal');
                        this.wrongTypeOfTreeNodeSelectedForSettleSwal();
                        return;
                    }
                    if ((obj && obj.ids.length) || (obj.hasOwnProperty('selAll') && obj.selAll)) {
                        this.typeForResponseModal = 'Settle';
                        let params = {};
                        if (obj && obj.hasOwnProperty('selAll')) {
                            // console.log("Sel all mode", obj);
                            let reqstID = crypto.HmacSHA256(this.businessService.businessID.getValue(), Math.random().toString()).toString();
                            this.selectAll = true;
                            params['queryParams'] = obj.hasOwnProperty('parameters') ? obj.parameters : {};

                            params['queryParams']['requestID'] = reqstID;
                            params['selectAll'] = true;
                            // params['xBusinessID']=this.businessService.businessID.getValue();
                            if (obj.ids.length) params['excludeIDs'] = obj.ids.toString();
                            else params['excludeIDs'] = '';

                            this.performSettleAction(params, reqstID);
                        } else {
                            this.selectAll = false;
                            this.checkedIds = obj.ids;
                            params['txnIds'] = obj.ids.toString();
                            params['hashData'] = true;
                            params['xBusinessID'] = this.businessService.businessID.getValue();
                            this.transactionsStore.getAll(JSON.parse(JSON.stringify(params))).subscribe(
                                txnResponse => {
                                    if (
                                        txnResponse &&
                                        txnResponse.hasOwnProperty('data') &&
                                        txnResponse.data.length &&
                                        txnResponse.hasOwnProperty('meta') &&
                                        txnResponse.meta.hasOwnProperty('hashedData') &&
                                        txnResponse.meta.hashedData.length
                                    ) {
                                        this.finalHashedData = [];
                                        txnResponse.data.map(singleTxnData => {
                                            if (
                                                singleTxnData.hasOwnProperty('xPullSyncInfo') &&
                                                singleTxnData.xPullSyncInfo.hasOwnProperty('xHasPendingSync') &&
                                                !singleTxnData.xPullSyncInfo.xHasPendingSync
                                            ) {
                                                this.finalHashedData.push(
                                                    txnResponse.meta.hashedData.filter(
                                                        singleHashedData => singleHashedData.record_id === singleTxnData._id
                                                    )[0]
                                                );
                                            } else {
                                                this.checkedIds.splice(this.checkedIds.indexOf(singleTxnData), 1);
                                            }
                                        });
                                        if (this.checkedIds.length) {
                                            let reqstID = crypto
                                                .HmacSHA256(this.businessService.businessID.getValue(), Math.random().toString())
                                                .toString();
                                            this.performSettleAction(
                                                {
                                                    txnIds: this.checkedIds,
                                                    originalData: this.finalHashedData,
                                                    requestID: reqstID
                                                },
                                                reqstID
                                            );
                                        } else {
                                            this.showOverLay = false;
                                            this.notificationService.error('Inconsistent data. Please refresh.', 'Error');
                                            return;
                                        }
                                    }
                                },
                                error => {}
                            );
                        }
                    } else {
                        this.noTransactionSelectedSwal('Settle');
                    }
                })
                .catch(error => {
                    console.log('onEmitChecked: validateSettleAction: error', error);
                    this.notificationService.error('Something went wrong', 'Error');
                    this.transactionActionService.setDoSettle(false);
                    this.settleLoader = false;
                    this.disableActionBtns = false;
                });
                break;
            case 'redo':
                this.typeForResponseModal = 'Redo';
                this.checkedIds = obj.ids;
                this.finalHashedData = obj.hashedData;
                console.log('onEmitChecked: obj', obj);
                let redoParams = {
                    xBusinessID: this.businessService.businessID.getValue(),
                    txnIds: obj.ids.toString(),
                    hashData: true
                };
                // console.log('onEmitChecked: redoparams', redoParams);
                if (!obj.hasOwnProperty('selAll') || !obj.selAll) {
                    if (obj && obj.ids.length) {
                        if (obj.ids.length > 5) {
                            this.limitExceededSwal('Redo');
                        } else {
                            // this.performRedoAction();
                            this.getDataForEditModal(redoParams);
                        }
                    } else {
                        this.noTransactionSelectedSwal('Redo');
                    }
                } else {
                    // this.checkedIds= obj.ids;
                    if (obj.hasOwnProperty('totalRecords')) {
                        if (obj.totalRecords <= 5) {
                            // this.performRedoAction();
                            this.getDataForEditModal(redoParams);
                        } else {
                            if (obj.uncheckedIds && obj.uncheckedIds.length && obj.totalRecords - obj.uncheckedIds.length <= 5) {
                                // this.performRedoAction();
                                this.getDataForEditModal(redoParams);
                            } else {
                                this.limitExceededSwal('Redo');
                            }
                        }
                    }
                }
                break;
            case 'reprint':
                // this.transactionActionService.setActionPerformed(true);
                // console.log("Checked object inside reprint", obj);
                this.rePrint = false;
                this.checkedIds = obj.ids;
                // console.log("object received inside transaction edit", obj);
                if ((obj && obj.ids.length) || (obj.hasOwnProperty('selAll') && obj.selAll)) {
                    this.typeForResponseModal = 'Re-Print';
                    this.typeForPaginationModal = 'Re-Print';
                    if (obj && obj.hasOwnProperty('selAll')) {
                        this.selectAll = true;
                        this.onPaginationModal(obj, 'rePrint');
                    } else {
                        this.selectAll = false;
                        if (obj && obj.data.length) {
                            let observable = [];
                            let storeIDArr = [];
                            this.transactionStoreData = [];
                            obj.data.map(txnData => {
                                if (storeIDArr.indexOf(txnData.xStoreID) === -1) {
                                    storeIDArr.push(txnData.xStoreID);
                                }
                            });
                            observable.push(
                                this.httpService
                                    .getAll('xbcStores', {
                                        storeIds: storeIDArr.toString()
                                    })
                            );
                            forkJoin(observable).subscribe(
                                (response: any) => {
                                    obj.data.map(singleTxnData => {
                                        response[0].data.map(storeRes => {
                                            if (storeRes._id === singleTxnData.xStoreID) {
                                                this.transactionStoreData.push({
                                                    txnData: singleTxnData,
                                                    storeData: storeRes
                                                });
                                            }
                                        });
                                    });
                                    this.performReprintSwal();
                                },
                                error => {}
                            );
                        } else {
                            this.noTransactionSelectedSwal('Re-print');
                        }
                    }
                } else {
                    this.noTransactionSelectedSwal('Re-print');
                }
                break;
            case 'finalize':
                // console.log("object received inside transaction finalize", obj);
                // if (obj && obj.ids.length) {
                if ((obj && obj.ids.length) || (obj.hasOwnProperty('selAll') && obj.selAll)) {
                    this.typeForResponseModal = 'Finalize';
                    this.typeForPaginationModal = 'Finalize';
                    if (obj && obj.hasOwnProperty('selAll')) {
                        this.selectAll = true;
                        this.onPaginationModal(obj, 'finalize');
                    } else {
                        this.selectAll = false;
                        this.transactionsStore
                            .getAll({
                                xBusinessID: this.businessService.businessID.getValue(),
                                txnIds: obj.ids.toString(),
                                hashData: true
                            })
                            .subscribe(
                                txnResponse => {
                                    console.log('NEW DATA', txnResponse);
                                    let filteredHashedData = [];
                                    if (
                                        txnResponse &&
                                        txnResponse.hasOwnProperty('data') &&
                                        txnResponse.data.length &&
                                        txnResponse.hasOwnProperty('meta') &&
                                        txnResponse.meta.hasOwnProperty('hashedData') &&
                                        txnResponse.meta.hashedData.length
                                    ) {
                                        let params = {
                                            PCtrIDs: []
                                        };
                                        txnResponse.data.map(singleTxnData => {
                                            params.PCtrIDs.push(singleTxnData.xPCtrID);
                                        });
                                        this.httpService.store('pCtrIndstTags', params).subscribe(
                                            (pctrResponse: any) => {
                                                console.log('Pctr response', pctrResponse);
                                                let tipTxns = [];
                                                let finalizeTxns = [];
                                                this.finalizeTxnArr = [];
                                                this.tipTxnObj = [];
                                                pctrResponse.map(value => {
                                                    // let hashedData=[];
                                                    // if(txnResponse.hasOwnProperty('hashedData') && txnResponse.hashedData.length){
                                                    //     hashedData
                                                    // }
                                                    txnResponse.data.map(singleTxnData => {
                                                        if (
                                                            value.indstType === TAGSCONSTS.industryTags.industryItemTags.restaurant ||
                                                            value.indstType === TAGSCONSTS.industryTags.industryItemTags.retail
                                                        ) {
                                                            if (singleTxnData.xPCtrID === value.pCtrID) {
                                                                tipTxns.push({
                                                                    id: singleTxnData.hasOwnProperty('_id') ? singleTxnData._id : null,
                                                                    txnNum: singleTxnData.hasOwnProperty('xTxnNum')
                                                                        ? singleTxnData.xTxnNum
                                                                        : null,
                                                                    tipAmt: singleTxnData.hasOwnProperty('xTipAmt')
                                                                        ? this.getAmtInDollars(singleTxnData.xTipAmt)
                                                                        : '0.00'
                                                                });
                                                                if (
                                                                    singleTxnData.hasOwnProperty('xPullSyncInfo') &&
                                                                    singleTxnData.xPullSyncInfo.hasOwnProperty('xHasPendingSync') &&
                                                                    !singleTxnData.xPullSyncInfo.xHasPendingSync
                                                                ) {
                                                                    tipTxns[tipTxns.length - 1]['disabled'] = false;
                                                                    filteredHashedData.push(
                                                                        txnResponse.meta.hashedData.filter(
                                                                            singleHashedData =>
                                                                                singleHashedData.record_id === singleTxnData._id
                                                                        )[0]
                                                                    );
                                                                } else {
                                                                    tipTxns[tipTxns.length - 1]['disabled'] = true;
                                                                }
                                                            }
                                                        } else {
                                                            if (singleTxnData.xPCtrID === value.pCtrID) {
                                                                if (
                                                                    singleTxnData.hasOwnProperty('xPullSyncInfo') &&
                                                                    singleTxnData.xPullSyncInfo.hasOwnProperty('xHasPendingSync') &&
                                                                    !singleTxnData.xPullSyncInfo.xHasPendingSync
                                                                ) {
                                                                    finalizeTxns.push(singleTxnData._id);
                                                                    filteredHashedData.push(
                                                                        txnResponse.meta.hashedData.filter(
                                                                            singleHashedData =>
                                                                                singleHashedData.record_id === singleTxnData._id
                                                                        )[0]
                                                                    );
                                                                }
                                                            }
                                                        }
                                                    });
                                                });
                                                console.log('tipTxn and finalizeTxn===>', tipTxns.length, finalizeTxns.length);
                                                this.checkAddTipAndFinalize(tipTxns, finalizeTxns, filteredHashedData);
                                            },
                                            error => {
                                                this.notificationService.error('Unable to fetch Profit Center detail', 'Error');
                                                this.transactionActionService.setDoFinalize(false);
                                                this.finalizeLoader = false;
                                                this.disableActionBtns = false;
                                            },
                                            () => {}
                                        );
                                    } else {
                                        this.notificationService.error('Unable to fetch required data', 'Error');
                                        this.transactionActionService.setDoFinalize(false);
                                        this.finalizeLoader = false;
                                        this.disableActionBtns = false;
                                    }
                                },
                                error => {
                                    this.notificationService.error('Unable to fetch Transaction detail', 'Error');
                                    this.transactionActionService.setDoFinalize(false);
                                    this.finalizeLoader = false;
                                    this.disableActionBtns = false;
                                }
                            );
                    }
                } else {
                    this.noTransactionSelectedSwal('Finalize / Add Tip');
                }
                break;
            // TODO: refactor edit case
            case 'edit':
                console.log('object received inside transaction edit', obj);
                if ((obj && obj.ids.length) || (obj.hasOwnProperty('selAll') && obj.selAll)) {
                    this.typeForResponseModal = 'Modify';
                    this.typeForPaginationModal = 'Modify';
                    if (obj && obj.hasOwnProperty('selAll')) {
                        this.selectAll = true;
                        this.onPaginationModal(obj, 'edit');
                    } else {
                        this.selectAll = false;
                        if (obj && obj.ids.length) {
                            this.transactionsStore
                                .getAll({
                                    xBusinessID: this.businessService.businessID.getValue(),
                                    txnIds: obj.ids.toString(),
                                    hashData: true
                                })
                                .subscribe(txnResponse => {
                                    let filteredHashedData = [];
                                    if (
                                        txnResponse &&
                                        txnResponse.hasOwnProperty('data') &&
                                        txnResponse.data.length &&
                                        txnResponse.hasOwnProperty('meta') &&
                                        txnResponse.meta.hasOwnProperty('hashedData') &&
                                        txnResponse.meta.hashedData.length
                                    ) {
                                        let params = {
                                            PCtrIDs: []
                                        };
                                        txnResponse.data.map(singleTxnData => {
                                            params.PCtrIDs.push(singleTxnData.xPCtrID);
                                        });
                                        this.httpService.store('pCtrIndstTags', params).subscribe(
                                            (pctrResponse: any) => {
                                                console.log('Pctr response', pctrResponse);
                                                pctrResponse.map(value => {
                                                    txnResponse.data.map(singleTxnData => {
                                                        if (
                                                            value.indstType === TAGSCONSTS.industryTags.industryItemTags.restaurant ||
                                                            value.indstType === TAGSCONSTS.industryTags.industryItemTags.retail
                                                        ) {
                                                            if (singleTxnData.xPCtrID === value.pCtrID) {
                                                                this.editTxns.push({
                                                                    txnId: singleTxnData.hasOwnProperty('_id') ? singleTxnData._id : null,
                                                                    txnNum: singleTxnData.hasOwnProperty('xTxnNum')
                                                                        ? singleTxnData.xTxnNum
                                                                        : null,
                                                                    tipAmt: singleTxnData.hasOwnProperty('xTipAmt')
                                                                        ? this.getAmtInDollars(singleTxnData.xTipAmt)
                                                                        : '0.00',
                                                                    baseAmt: singleTxnData.hasOwnProperty('xMainAmt')
                                                                        ? this.getAmtInDollars(singleTxnData.xMainAmt)
                                                                        : '0.00',
                                                                    taxAmt: singleTxnData.hasOwnProperty('xIncTaxAmt')
                                                                        ? this.getAmtInDollars(singleTxnData.xIncTaxAmt)
                                                                        : '0.00'
                                                                });
                                                                if (
                                                                    singleTxnData.hasOwnProperty('xPullSyncInfo') &&
                                                                    singleTxnData.xPullSyncInfo.hasOwnProperty('xHasPendingSync') &&
                                                                    !singleTxnData.xPullSyncInfo.xHasPendingSync
                                                                ) {
                                                                    this.editTxns[this.editTxns.length - 1]['disabled'] = false;
                                                                    filteredHashedData.push(
                                                                        txnResponse.meta.hashedData.filter(
                                                                            singleHashedData =>
                                                                                singleHashedData.record_id === singleTxnData._id
                                                                        )[0]
                                                                    );
                                                                } else {
                                                                    this.editTxns[this.editTxns.length - 1]['disabled'] = true;
                                                                }
                                                            }
                                                        } else {
                                                            if (singleTxnData.xPCtrID === value.pCtrID) {
                                                                this.editTxns.push({
                                                                    txnId: singleTxnData.hasOwnProperty('_id') ? singleTxnData._id : null,
                                                                    txnNum: singleTxnData.hasOwnProperty('xTxnNum')
                                                                        ? singleTxnData.xTxnNum
                                                                        : null,
                                                                    baseAmt: singleTxnData.hasOwnProperty('xMainAmt')
                                                                        ? this.getAmtInDollars(singleTxnData.xMainAmt)
                                                                        : '0.00',
                                                                    taxAmt: singleTxnData.hasOwnProperty('xIncTaxAmt')
                                                                        ? this.getAmtInDollars(singleTxnData.xIncTaxAmt)
                                                                        : '0.00'
                                                                });
                                                                if (
                                                                    singleTxnData.hasOwnProperty('xPullSyncInfo') &&
                                                                    singleTxnData.xPullSyncInfo.hasOwnProperty('xHasPendingSync') &&
                                                                    !singleTxnData.xPullSyncInfo.xHasPendingSync
                                                                ) {
                                                                    this.editTxns[this.editTxns.length - 1]['disabled'] = false;
                                                                    filteredHashedData.push(
                                                                        txnResponse.meta.hashedData.filter(
                                                                            singleHashedData =>
                                                                                singleHashedData.record_id === singleTxnData._id
                                                                        )[0]
                                                                    );
                                                                } else {
                                                                    this.editTxns[this.editTxns.length - 1]['disabled'] = true;
                                                                }
                                                            }
                                                        }
                                                    });
                                                });
                                                this.finalHashedData = filteredHashedData.length ? filteredHashedData : [];
                                                this.showEditModal = true;
                                            },
                                            error => {
                                                this.notificationService.error('Unable to fetch required data', 'Error');
                                                this.transactionActionService.setDoEdit(false);
                                                this.editLoader = false;
                                                this.disableActionBtns = false;
                                            }
                                        );
                                    } else {
                                        this.notificationService.error('Unable to fetch required data', 'Error');
                                        this.transactionActionService.setDoEdit(false);
                                        this.editLoader = false;
                                        this.disableActionBtns = false;
                                    }
                                });
                        }
                    }
                } else {
                    this.noTransactionSelectedSwal('Edit');
                }
                break;
            case 'printDetail':
                // this.transactionActionService.setActionPerformed(true);
                console.log('obj====>', obj);
                this.checkedIds = obj.ids;
                // console.log("object received inside transaction printDetails", obj);
                if ((obj && obj.ids && obj.ids.length) || (obj.hasOwnProperty('selAll') && obj.selAll)) {
                    this.typeForResponseModal = 'Print Detail';
                    this.typeForPaginationModal = 'Print Detail';
                    if (obj && obj.hasOwnProperty('selAll')) {
                        this.selectAll = true;
                        this.onPaginationModal(obj, 'printDetail');
                    } else {
                        this.selectAll = false;
                        if (obj && obj.txnNums.length) {
                            this.visiblePrintDetailModal = true;
                        } else {
                            this.noTransactionSelectedSwal('Export Detail');
                        }
                    }
                } else {
                    this.noTransactionSelectedSwal('Export Detail');
                }
                break;
        }
    }

    /**
     * show swal regarding wrong type of tree node selected for settle action
     *
     */
    wrongTypeOfTreeNodeSelectedForSettleSwal() {
        console.log('wrongTypeOfTreeNodeSelectedForSettleSwal');
        customSwal.fire({
            title: NO_SETTLE_ALLOW_SWAL_TITLE,
            text: NO_SETTLE_ALLOW_SWAL_MSG,
            icon: 'warning',
            confirmButtonText: 'Okay'
        }).then((result) => {
            if (result.value) {
                this.transactionActionService.setDoSettle(false);
                this.settleLoader = false;
                this.disableActionBtns = false;
            }
        });
    }

    /**
     * checks if settle action to be allowed, If selected tree node is of type PCTR or node below PCTR
     * allow settle action, If selected node is above PCTR and that node has 1 PCTR allow settle action
     * If selected nodes is above PCTR and there are multiple PCTR under that node, settle action cannot be performed
     *
     * @return Promise type: boolean : return true if valid, return false if invalid
     */
    validateSettleAction(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            let selectedNode = this.treeService.currentSelectedNode.getValue();
            console.log('validateSettleAction: selected node', selectedNode);
            let nodeType = selectedNode && selectedNode.hasOwnProperty('type') && selectedNode.type ? selectedNode.type : '';
            let nodeID = selectedNode && selectedNode.hasOwnProperty('id') && selectedNode.id ? selectedNode.id : '';
            let query = {
                limit: 0
            };
            console.log('validateSettleAction: type', nodeType);
            switch (nodeType) {
                case NODE_TYPE_BUSINESS:
                    console.log('validateSettleAction: NODE_TYPE_BUSINESS');
                    query['xBusinessID'] = nodeID;
                    break;
                case STRUCT_TYPE_CHAIN:
                    console.log('validateSettleAction: STRUCT_TYPE_CHAIN');
                    query['structID'] = nodeID;
                    query['struct_type'] = STRUCT_TYPE_CHAIN;
                    break;
                case STRUCT_TYPE_REGION:
                    console.log('validateSettleAction: STRUCT_TYPE_REGION');
                    query['structID'] = nodeID;
                    query['struct_type'] = STRUCT_TYPE_REGION;
                    break;
                case STRUCT_TYPE_PROPERTY:
                    console.log('validateSettleAction: STRUCT_TYPE_PROPERTY');
                    query['structID'] = nodeID;
                    query['struct_type'] = STRUCT_TYPE_PROPERTY;
                    break;
                case NODE_TYPE_STORE:
                    console.log('validateSettleAction: NODE_TYPE_STORE');
                    query['xStoreID'] = nodeID;
                    break;
                case NODE_TYPE_PCTR:
                case NODE_TYPE_MERCHANT_ACCT:
                case NODE_TYPE_TERMINAL:
                case NODE_TYPE_PERIPH:
                    console.log('validateSettleAction: PCTR or below');
                    return resolve(true);
                default:
                    return resolve(false);
            }
            this.fetchPCTRCount(query)
                .then(pctrCount => {
                    console.log('validateSettleAction: pctrCount', pctrCount);
                    if (pctrCount === 1) {
                        return resolve(true);
                    }
                    return resolve(false);
                })
                .catch(error => {
                    console.log('validateSettleAction: error', error);
                    this.notificationService.error(RECORD_COUNT_PCTR_FETCH_FAIL, 'Error');
                    return resolve(false);
                });
        });
    }

    /*
     * fetch pctr count by using limit as 0
     *
     * @params : query : query to be sent to BE
     * @return : totalRecords : number : total record count
     */
    fetchPCTRCount(query: Object): Promise<number> {
        return new Promise((resolve, reject) => {
            console.log('fetchPCTRCount: query', query);
            this.setupStructureProfitCenterStore.getAll(query).subscribe(
                pctrCountResponse => {
                    console.log('fetchPCTRCount: pctrCountResponse', pctrCountResponse);
                    let pctrCountMeta =
                        pctrCountResponse && pctrCountResponse.hasOwnProperty('meta') && pctrCountResponse.meta
                            ? pctrCountResponse.meta
                            : {};
                    let totalRecords =
                        Object.keys(pctrCountMeta) && pctrCountMeta.hasOwnProperty('totalRecords') && pctrCountMeta.totalRecords
                            ? pctrCountMeta.totalRecords
                            : -1;
                    if (totalRecords !== -1) {
                        return resolve(totalRecords);
                    }
                    return reject('Unable to fetch record count');
                    // return resolve(pctrCountResponse);
                },
                error => {
                    console.log('fetchPCTRCount: error', error);
                    return reject(error);
                }
            );
        });
    }

    // get txn data and indst tag
    getDataForEditModal(params) {
        console.log('getDataForEditModal: params', params);
        return this.transactionsStore.getAll(params).subscribe(
            txnResponse => {
                console.log('getDataForEditModal: txnResponse', txnResponse);
                // if(txnResponse && txnResponse.hasOwnProperty('data') && txnResponse['data']){
                let txnResponseData = txnResponse && txnResponse.hasOwnProperty('data') && txnResponse.data ? txnResponse.data : {};
                let txnResponseMeta = txnResponse && txnResponse.hasOwnProperty('meta') && txnResponse.meta ? txnResponse.meta : {};
                console.log('getDataForEditModal: txnResponseData', txnResponseData);
                console.log('getDataForEditModal: txnResponseMeta', txnResponseMeta);
                if (Object.keys(txnResponseData).length && Object.keys(txnResponseMeta).length) {
                    let pctrParams = {
                        PCtrIDs: []
                    };
                    let txnHashedData =
                        Object.keys(txnResponseMeta).length &&
                        txnResponseMeta.hasOwnProperty('hashedData') &&
                        txnResponseMeta.hashedData &&
                        txnResponseMeta.hashedData.length
                            ? txnResponseMeta.hashedData
                            : {};
                    const PRIMARY_FIELD = 'record_id';
                    const NOT_ONE_TO_ONE = false;
                    let txnMetaAsscObj = processToAssociativeObject(txnHashedData, PRIMARY_FIELD, '', NOT_ONE_TO_ONE);
                    console.log('getDataForEditModal: txnMetaAsscObj', txnMetaAsscObj);
                    txnResponseData.map(singleTxnData => {
                        if (singleTxnData.hasOwnProperty('xPCtrID') && singleTxnData.xPCtrID) {
                            pctrParams.PCtrIDs.push(singleTxnData.xPCtrID);
                        }
                    });
                    console.log('getDataForEditModal: pctrid', pctrParams.PCtrIDs);
                    this.httpService.store('pCtrIndstTags', pctrParams).subscribe(
                        pctrResponse => {
                            console.log('getDataForEditModal: pctrResponse', pctrResponse);
                            const PRIMARY_FIELD = 'pCtrID';
                            const SECONDARY_FIELD = 'indstType';
                            const ONE_TO_ONE = true;
                            let pctrAsscObj = processToAssociativeObject(pctrResponse, PRIMARY_FIELD, SECONDARY_FIELD, ONE_TO_ONE);
                            console.log('getDataForEditModal: pctrAsscObj', pctrAsscObj);
                            if (pctrResponse && pctrResponse.length) {
                                this.convertDataForEditModal(txnResponseData, pctrAsscObj, txnMetaAsscObj);
                            }
                        },
                        tagError => {
                            console.log('getDataForEditModal: tagerror', tagError);
                            this.notificationService.error('Failed to fetch industry information', 'Error');
                        }
                    );
                }
            },
            error => {
                console.log('getDataForEditModal: error', error);
                this.notificationService.error('Failed to fetch latest transaction data.', 'Error');
            }
        );
    }

    // convert it to edit txns data for modal
    convertDataForEditModal(txnResponseData, pctrResponse, txnResponseMeta) {
        console.log('convertDataForEditModal txnResponse', txnResponseData);
        console.log('convertDataForEditModal pctrResponse', pctrResponse);
        console.log('convertDataForEditModal txnResponseMeta', txnResponseMeta);
        console.log('convertDataForEditModal currentAction', this.currentAction);
        let filteredHashedData = [];
        txnResponseData.map(singleTxnData => {
            console.log('convertDataForEditModal: singleTxnData', singleTxnData);
            if (singleTxnData && singleTxnData.hasOwnProperty('_id') && singleTxnData._id) {
                let hashedDataOfTxn = txnResponseMeta[singleTxnData._id] ? txnResponseMeta[singleTxnData._id] : {};
                // let doAddHashData = false;
                let editTxnObj = { disabled: true };
                let doAddtip: boolean = false;
                switch (pctrResponse[singleTxnData.xPCtrID]) {
                    case TAGSCONSTS.industryTags.industryItemTags.restaurant:
                    case TAGSCONSTS.industryTags.industryItemTags.retail:
                        console.log('convertDataForEditModal: rest/retail', pctrResponse[singleTxnData.xPCtrID]);
                        // this.setDataInEditTxnsAndFilteredHash(singleTxnData, true, filteredHashedData, hashedDataOfTxn);
                        // doAddHashData = this.setDataInEditTxnsAndFilteredHash(singleTxnData, true);
                        doAddtip = true;
                        editTxnObj = Object.assign({}, createEditObj(singleTxnData, doAddtip, this.currentAction));
                        console.log('convertDataForEditModal: edittxns', this.editTxns);
                        break;
                    default:
                        console.log('convertDataForEditModal: not rest/retail', pctrResponse[singleTxnData.xPCtrID]);
                        // this.setDataInEditTxnsAndFilteredHash(singleTxnData, false, filteredHashedData, hashedDataOfTxn);
                        doAddtip = false;
                        editTxnObj = Object.assign({}, createEditObj(singleTxnData, doAddtip, this.currentAction));
                        console.log('convertDataForEditModal: edittxns', this.editTxns);
                }
                console.log('convertDataForEditModal: editTxnObj', JSON.parse(JSON.stringify(editTxnObj)));
                // if (doAddHashData) filteredHashedData.push(hashedDataOfTxn);
                this.editTxns.push(editTxnObj);
                if (editTxnObj && editTxnObj.hasOwnProperty('disabled') && !editTxnObj.disabled) {
                    filteredHashedData.push(hashedDataOfTxn);
                }
            }
        });
        console.log('convertDataForEditModal: edittxns', this.editTxns);
        console.log('convertDataForEditModal: filteredHashedData', filteredHashedData);
        this.finalHashedData = filteredHashedData.length ? filteredHashedData : [];
        this.showEditModal = true;
    }

    getAmtInDollars(amt) {
        return (parseInt(amt) / 100).toFixed(2);
    }

    limitExceededSwal(actionType) {
        customSwal.fire({
            title: `Selected Transactions limit exceeded`,
            text: 'Please select atmost 5 transactions at a time, to ' + actionType,
            icon: 'warning',
            confirmButtonText: 'Okay',
        }).then((result) => {
            if (result.value) {
                if (actionType === 'Void') {
                    this.transactionActionService.setDoVoid(null);
                    this.voidLoader = false;
                    this.disableActionBtns = false;
                } else if (actionType === 'Redo') {
                    this.transactionActionService.setDoRedo(null);
                    this.redoLoader = false;
                    this.disableActionBtns = false;
                } else if (actionType === 'Close') {
                    this.transactionActionService.setDoClose(null);
                    this.closeLoader = false;
                    this.disableActionBtns = false;
                }
            }
        });
    }

    noTransactionSelectedSwal(actionType) {
        customSwal.fire({
            title: `No Transactions are selected`,
            text: 'Please select a transaction, to ' + actionType,
            icon: 'warning',
            confirmButtonText: 'Okay',
        }).then((result) => {
            if (result.value) {
                switch (actionType) {
                    case 'Void':
                        this.transactionActionService.setDoVoid(false);
                        this.voidLoader = false;
                        break;
                    case 'Close':
                        this.transactionActionService.setDoClose(false);
                        this.closeLoader = false;
                        break;                       
                    case 'Settle':
                        this.transactionActionService.setDoSettle(false);
                        this.settleLoader = false;
                        break;
                    case 'Redo':
                        this.transactionActionService.setDoRedo(false);
                        this.redoLoader = false;
                        break;
                    case 'Re-print':
                        this.transactionActionService.setDoRePrint(false);
                        this.rePrintLoader = false;
                        break;
                    case 'Finalize / Add Tip':
                        this.transactionActionService.setDoFinalize(false);
                        this.finalizeLoader = false;
                        break;
                    case 'Edit':
                        this.transactionActionService.setDoEdit(false);
                        this.editLoader = false;
                        break;
                    case 'Export Detail':
                        this.transactionActionService.setPrintDetail(false);
                        this.printDetailLoader = false;
                        break;
                }
                this.disableActionBtns = false;
            }
        });
    }

    performVoidAction() {
        customSwal.fire({
            title: 'Are you sure?',
            text: 'Click Yes to continue, No to decline',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.showOverLay = true;
                if (this.checkedIds.length) {
                    this.observables = [
                        this.httpService
                            .store('pgComm/voidTxns', {
                                txnIds: this.checkedIds,
                                originalData: this.finalHashedData
                            })
                    ];
                    forkJoin(this.observables).subscribe(
                        response => {
                            console.log('Success response inside void', response);
                            this.responseForResponseModal = response;
                            this.showResponseModal = true;
                            this.transactionActionService.setDoVoid(false);
                            this.voidLoader = false;
                            this.disableActionBtns = false;
                            this.performCommonActionsOnResponse();
                        },
                        error => {
                            console.log('Error response inside void', error);
                            this.showResponseModal = false;
                            this.transactionActionService.setDoVoid(false);
                            this.voidLoader = false;
                            this.disableActionBtns = false;
                            this.performCommonActionsOnResponse();
                            this.performCommonActionsOnErrorResponse();
                        },
                        () => {}
                    );
                } else {
                    this.showOverLay = false;
                    this.notificationService.error('Inconsistent data. Please refresh.', 'Error');
                    return;
                }
            } else {
                this.transactionActionService.setDoVoid(false);
                this.voidLoader = false;
                this.disableActionBtns = false;
            }
        })
    }

    //@cary add by cary
    performCloseAction() {
        customSwal.fire({
            title: 'Are you sure?',
            text: 'Click Yes to continue, No to decline',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.showOverLay = true;
                if (this.checkedIds.length) {
                    this.observables = [
                        this.httpService
                            .store('pgComm/closeTxns', {
                                txnIds: this.checkedIds,
                                originalData: this.finalHashedData
                            })
                    ];
                    forkJoin(this.observables).subscribe(
                        response => {
                            console.log('Success response inside close', response);
                            this.responseForResponseModal = response;
                            this.showResponseModal = true;
                            this.transactionActionService.setDoClose(false);
                            this.closeLoader = false;
                            this.disableActionBtns = false;
                            this.performCommonActionsOnResponse();
                        },
                        error => {
                            console.log('Error response inside close', error);
                            this.showResponseModal = false;
                            this.transactionActionService.setDoClose(false);
                            this.closeLoader = false;
                            this.disableActionBtns = false;
                            this.performCommonActionsOnResponse();
                            this.performCommonActionsOnErrorResponse();
                        },
                        () => {}
                    );
                } else {
                    this.showOverLay = false;
                    this.notificationService.error('Inconsistent data. Please refresh.', 'Error');
                    return;
                }
            } else {
                this.transactionActionService.setDoClose(false);
                this.closeLoader = false;
                this.disableActionBtns = false;
            }
        })
    }    

    performRedoAction() {
        console.log('performRedoAction: edittxns', this.editTxns);
        console.log('performRedoAction: finalHashedData', this.finalHashedData);
        //-------------------------------IMPORTANT NOTE-------------------------------------------------------
        // This code is needed when running redo action with custom amounts (edit modal)

        let params = {
            txnIds: [],
            editAmts: [],
            txnType: TRANSACTIONS_TYPE_OPEN_TXNS
        };
        this.editTxns.map(value => {
            if (value && value.hasOwnProperty('disabled') && !value.disabled) {
                let editAmtsObj = {
                    txnId: value.txnId,
                    mainAmt: value.baseAmt,
                    incTaxAmt: value.taxAmt ? value.taxAmt : '0'
                };

                if (value.hasOwnProperty('tipAmt')) editAmtsObj['tipAmt'] = value.tipAmt ? value.tipAmt : '0';

                params['txnIds'].push(value.txnId);
                params['editAmts'].push(editAmtsObj);
            }
        });
        params['originalData'] = this.finalHashedData;
        //TODO-: Refactor: Create global function to call with sucssess/error callbacks to call WARNING_ACTION_CONFIRMATION swal.
        //---------------------------------END-----------------------------------------------------

        //-------------------------------IMPORTANT NOTE-------------------------------------------------------
        // This code is needed when running redo action w/o custom amounts (edit modal)
        // let params = {
        //     txnIds: this.checkedIds,
        //     txnType: 'txns',
        //     originalData: this.finalHashedData
        // };
        //-------------------------------------END-------------------------------------------------;
        customSwal.fire({
            title: 'Are you sure?',
            text: 'Click Yes to continue, No to decline',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.showOverLay = true;
                if (!this.checkedIds || !this.checkedIds.length) {
                    this.showOverLay = false;
                    this.redoLoader = false;
                    this.disableActionBtns = false;
                    this.notificationService.error('Inconsistent data. Please refresh.', 'Error');
                    return;
                }

                this.httpService
                    .store(RE_AUTH_END_POINT, params)
                    // .map((res: Response) => res)
                    .subscribe(
                        response => {
                            console.log('Success response inside redo', response);
                            //-------------------------------IMPORTANT NOTE-------------------------------------------------------
                            // This code is needed when running redo action with custom amounts (edit modal)
                            this.responseForResponseModal = [response];
                            //------------------------------------END--------------------------------------------------------------

                            this.showResponseModal = true;
                            this.transactionActionService.setDoRedo(false);
                            this.redoLoader = false;
                            this.disableActionBtns = false;
                            this.performCommonActionsOnResponse();
                            // if (response.length && response[0].hasOwnProperty('failedAPI')) {
                            //     // this.handleExternalApiError(response);
                            // }
                        },
                        error => {
                            console.log('Error response inside redo', error);
                            this.showResponseModal = false;
                            this.transactionActionService.setDoRedo(false);
                            this.redoLoader = false;
                            this.disableActionBtns = false;
                            this.performCommonActionsOnResponse();
                            this.performCommonActionsOnErrorResponse();
                        },
                        () => {}
                    );
            } else {
                this.transactionActionService.setDoRedo(false);
                this.redoLoader = false;
                this.disableActionBtns = false;
            }
        })
    }

    performReprintSwal() {
        this.rePrint = true;
        customSwal.fire({
            title: 'Are you sure?',
            text: 'Click Yes to continue, No to decline',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.transactionActionService.setPrint(true);
            } else {
                this.rePrintLoader = false;
                if (this.donePages.length) {
                    this.donePages.splice(this.donePages.length - 1, 1);
                }
                this.totalPages = [];
                this.transactionActionService.setPrint(false);
                this.transactionActionService.setDoRePrint(false);
                this.rePrint = false;
                this.disableActionBtns = false;
            }
        })
    }

    performSettleAction(params, reqstID) {;
        customSwal.fire({
            title: 'Are you sure?',
            text: 'Click Yes to continue, No to decline',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.showOverLay = true;
                    console.log('Params in settle case', params);
                    this.observables = [this.httpService.store('pgComm/settleTxns', params)];
                    // setTimeout(()=>{
                    forkJoin(this.observables).subscribe(
                        response => {
                            console.log('Success response inside doSettle', response);
                            if (this.selectAll) {
                                console.log('Select all case response==>', response[0]['action_id']);
                                let settleActionRequestTimer = setInterval(() => {
                                    this.httpService.get('action_worker_queues', response[0]['action_id']).subscribe(res => {
                                        console.log('res===>', res);
                                        if (res.hasOwnProperty('action_progress') && res.action_progress >= 100) {
                                            clearInterval(settleActionRequestTimer);
                                            if (res.hasOwnProperty('status') && res['status'] === 'Failed') {
                                                if (
                                                    res.hasOwnProperty('action_output') &&
                                                    res.action_output.hasOwnProperty('message') &&
                                                    res.action_output.message
                                                ) {
                                                    this.notificationService.error(res.action_output.message, 'Error');
                                                } else {
                                                    this.notificationService.error(
                                                        'Something went wrong. Could not perform selected action.',
                                                        'Error'
                                                    );
                                                }
                                                this.settleLoader = false;
                                                this.disableActionBtns = false;
                                                this.performCommonActionsOnResponse();
                                            } else {
                                                console.log('final res settle all action==>', res);
                                                if (
                                                    res.hasOwnProperty('action_output') &&
                                                    res['action_output'].hasOwnProperty('successRecordNums') &&
                                                    res.action_output.successRecordNums.length
                                                ) {
                                                    let trackerInterval = setInterval(() => {
                                                        this.httpService
                                                            .store('cloud9pg/v1/checkTrackers', {
                                                                requestID: reqstID
                                                            })
                                                            .subscribe(trackerResponse => {
                                                                console.log('trackerResponse', trackerResponse);
                                                                if (trackerResponse && trackerResponse.hasOwnProperty('isTracker')) {
                                                                    if (trackerResponse.isTracker) {
                                                                        clearInterval(trackerInterval);
                                                                        this.actionsAfterSettleAllAction(response[0]['action_id'], res);
                                                                    }
                                                                } else {
                                                                    clearInterval(trackerInterval);
                                                                }
                                                            });
                                                    }, SETTLE_ALL_ACTION_INTERVAL);
                                                } else {
                                                    this.actionsAfterSettleAllAction(response[0]['action_id'], res);
                                                }
                                            }
                                        }
                                    });
                                }, SETTLE_ALL_ACTION_INTERVAL);
                            } else {
                                console.log('Select few case', response);
                                console.log('Start timer now');
                                // setTimeout(() => {
                                if (response[0].hasOwnProperty('succeededAPI') && response[0]['succeededAPI'].length) {
                                    let trackerInterval = setInterval(() => {
                                        this.httpService
                                            .store('cloud9pg/v1/checkTrackers', {
                                                requestID: reqstID
                                            })
                                            .subscribe(trackerResponse => {
                                                console.log('trackerResponse', trackerResponse);
                                                if (trackerResponse && trackerResponse.hasOwnProperty('isTracker')) {
                                                    if (trackerResponse.isTracker) {
                                                        clearInterval(trackerInterval);
                                                        this.actionsAfterSettleAction(response);
                                                    }
                                                } else {
                                                    clearInterval(trackerInterval);
                                                }
                                            });
                                    }, SETTLE_SELECT_ACTION_INTERVAL);
                                } else {
                                    this.actionsAfterSettleAction(response);
                                }
                            }
                        },
                        error => {
                            console.log('Error response inside doSettle', error);
                            this.showResponseModal = false;
                            this.transactionActionService.setDoSettle(false);
                            this.settleLoader = false;
                            this.disableActionBtns = false;
                            this.performCommonActionsOnResponse();
                            this.performCommonActionsOnErrorResponse();
                        },
                        () => {}
                    );
                    // }, 30000);
            } else {
                this.transactionActionService.setDoSettle(false);
                this.settleLoader = false;
                this.disableActionBtns = false;
            }
        });
    }

    performCommonActionsOnErrorResponse() {
        //For customer-mode, Tree should not be altered on performing action on transaction, even in fail case.
        if(!this.customerTxns){
            this.treeService.setShow(true);
            this.treeService.setEnable(true);
        }
        
        this.disableActionBtns = false;
        this.finalizeLoader = false;
        this.voidLoader = false;
        this.closeLoader = false;
        this.editLoader = false;
        this.settleLoader = false;
        this.redoLoader = false;
        this.rePrintLoader = false;
        this.printDetailLoader = false;
        this.storeObject = this.transactionsStore;
        this.parameters = JSON.parse(JSON.stringify(this.parameters));
    }

    actionsAfterSettleAllAction(queueId, res) {
        this.queueId = queueId;
        let txnNumbers = {
            failedNumbers: [],
            successNumbers: []
        };
        if (
            res.hasOwnProperty('action_output') &&
            res['action_output'].hasOwnProperty('failedRecordNums') &&
            res.action_output.failedRecordNums.length
        ) {
            res.action_output.failedRecordNums.map(singleResponse => {
                if (txnNumbers.failedNumbers.indexOf(singleResponse['modelNum']) === -1)
                    txnNumbers.failedNumbers.push(singleResponse['modelNum']);
            });
        }
        if (
            res.hasOwnProperty('action_output') &&
            res['action_output'].hasOwnProperty('successRecordNums') &&
            res.action_output.successRecordNums.length
        ) {
            res.action_output.successRecordNums.map(singleResponse => {
                if (txnNumbers.successNumbers.indexOf(singleResponse['modelNum']) === -1)
                    txnNumbers.successNumbers.push(singleResponse['modelNum']);
            });
        }
        this.responseForResponseModal = txnNumbers;
        this.showResponseModal = true;
        this.performCommonActionsOnResponse();
    }

    actionsAfterSettleAction(response) {
        console.log('Start actions now');
        this.queueId = '';
        let txnNumbers = {
            failedNumbers: [],
            successNumbers: []
        };
        if (response[0].hasOwnProperty('succeededAPI') && response[0]['succeededAPI'].length) {
            response[0]['succeededAPI'].map(singleSuccessRes => {
                if (singleSuccessRes.hasOwnProperty('modelResponseData') && singleSuccessRes.modelResponseData.length) {
                    singleSuccessRes.modelResponseData.map(singleResponse => {
                        if (singleResponse.hasOwnProperty('modelNum') && singleResponse['modelNum']) {
                            if (txnNumbers.successNumbers.indexOf(singleResponse['modelNum']) === -1)
                                txnNumbers.successNumbers.push(singleResponse['modelNum']);
                        }
                    });
                }
            });
        }
        if (response[0].hasOwnProperty('failedAPI') && response[0]['failedAPI'].length) {
            response[0]['failedAPI'].map(singleSuccessRes => {
                if (singleSuccessRes.hasOwnProperty('modelResponseData') && singleSuccessRes.modelResponseData.length) {
                    singleSuccessRes.modelResponseData.map(singleResponse => {
                        if (singleResponse.hasOwnProperty('modelNum') && singleResponse['modelNum']) {
                            if (txnNumbers.failedNumbers.indexOf(singleResponse['modelNum']) === -1)
                                txnNumbers.failedNumbers.push(singleResponse['modelNum']);
                        }
                    });
                }
            });
        }
        console.log(txnNumbers);
        this.responseForResponseModal = txnNumbers;
        this.showResponseModal = true;
        // this.transactionActionService.setDoSettle(
        //     false
        // );
        this.performCommonActionsOnResponse();
    }

    checkAddTipAndFinalize(tipTxns, finalizeTxns, hashedData: any = []) {
        console.log('HERE==--==--=>', this.tipTxnObj, hashedData);
        if (tipTxns.length && finalizeTxns.length) {
            console.log('In here 1');
            this.typeForResponseModal = 'Finalize and Add Tip';
            this.tipTxnObj = tipTxns.map(val => {
                return {
                    txnId: val.id,
                    txnNum: val.txnNum,
                    tip: val.tipAmt,
                    isAddTipAct: true,
                    disabled: val.disabled
                };
            });
            this.finalizeTxnArr = finalizeTxns.map(txnId => {
                return txnId;
            });
            this.finalHashedData = hashedData.length ? hashedData : [];
            this.showAddTipModal = true;
        } else if (tipTxns.length && !finalizeTxns.length) {
            console.log('In here 2');
            this.typeForResponseModal = 'Add Tip';
            this.tipTxnObj = tipTxns.map(val => {
                return {
                    txnId: val.id,
                    txnNum: val.txnNum,
                    tip: val.tipAmt,
                    isAddTipAct: true,
                    disabled: val.disabled
                };
            });
            this.finalHashedData = hashedData.length ? hashedData : [];
            this.showAddTipModal = true;
        } else if (finalizeTxns.length && !tipTxns.length) {
            console.log('In here 3');
            this.typeForResponseModal = 'Finalize';
            let params = {
                txnIds: finalizeTxns,
                tipAmts: []
            };
            finalizeTxns.map(txn => {
                params['tipAmts'].push({
                    txnId: txn,
                    isAddTipAct: false
                });
            });
            params['originalData'] = hashedData.length ? hashedData : [];
            this.observables = [this.httpService.store('pgComm/addTip_finalizeTxns', params)];
            this.finalCallFromUser('finalize');
        } else {
            console.log('In here 4');
            this.showOverLay = false;
            this.notificationService.error('Inconsistent data. Please refresh.', 'Error');
            return;
        }
    }

    performCommonActionsOnResponse() {
        // this.transactionActionService.setRefreshData(true);
        this.showOverLay = false;
        this.transactionActionService.setActionPerformed(true);
        // this.transactionActionService.setContinuousFetch(true);
    }

    onPaginationModalClose(cancel: boolean = false) {
        // console.log("Here in pg modal close", cancel);
        this.totalNumberOfRecords = 0;
        this.totalPages = [];
        // this.donePages=[];
        if (cancel) {
            // this.previousAction='';
            this.currentAction = '';
            this.transactionActionService.paginationModal = false;
            this.rePrintLoader = false;
            this.editLoader = false;
            this.finalizeLoader = false;
            this.printDetailLoader = false;
            this.disableActionBtns = false;
        }
        this.showPaginationModal = false;
    }

    onTxnsDetailReceive(value) {
        // console.log("HERE I'm===]]]", value);
        this.showPaginationModal = false;
        if (value && value.hasOwnProperty('actionType') && value['actionType']) {
            if (value.hasOwnProperty('currPage')) {
                console.log('value.pagesDone', value.currPage, value.actionType, this.previousAction, this.currentAction);
                console.log('value.pagesDone', this.previousAction === value.actionType, this.previousAction === '');

                // this.donePages=[];
                if (this.previousAction === '' || this.previousAction === value.actionType) {
                    if (this.donePages.indexOf(value.currPage) === -1) this.donePages.push(value.currPage);
                }
                //  JSON.parse(JSON.stringify(value.pagesDone));
                else {
                    this.donePages = [];
                }
                if (this.currentAction !== this.previousAction) {
                    this.previousAction = this.currentAction;
                    // this.donePages=[];
                }
            }
            switch (value.actionType) {
                case 'finalize':
                    if (
                        value &&
                        value.hasOwnProperty('tipTxnsDetail') &&
                        value.hasOwnProperty('finalizeTxnIds') &&
                        value.hasOwnProperty('filteredHashedData')
                    ) {
                        if (value.tipTxnsDetail.length || value.finalizeTxnIds) {
                            this.checkAddTipAndFinalize(value.tipTxnsDetail, value.finalizeTxnIds, value.filteredHashedData);
                        } else {
                            this.showOverLay = false;
                            this.notificationService.error('Inconsistent data. Please refresh.', 'Error');
                            return;
                        }
                    }
                    break;
                case 'edit':
                    this.editTxns = [];
                    if (value && value.hasOwnProperty('editTxnDetails') && value.hasOwnProperty('filteredHashedData')) {
                        if (value.editTxnDetails.length) {
                            this.editTxns = value.editTxnDetails.map(singleData => singleData);
                            this.finalHashedData = value.filteredHashedData.length ? value.filteredHashedData : [];
                            this.showEditModal = true;
                        } else {
                            this.showOverLay = false;
                            this.notificationService.error('Inconsistent data. Please refresh.', 'Error');
                            return;
                        }
                    }
                    break;
                case 'printDetail':
                    this.visiblePrintDetailModal = true;
                    break;
                case 'reprint':
                    console.log('value-------->', JSON.parse(JSON.stringify(value.transactionStoreData)));
                    this.transactionStoreData = JSON.parse(JSON.stringify(value.transactionStoreData));
                    this.performReprintSwal();
                    break;
            }
        }
    }

    // handleExternalApiError(response) {
    //     let failedIds = [];
    //     if (response[0]['failedAPI'].length) {
    //         response[0]['failedAPI'].map((failedRes) => {
    // console.log("failedRes", failedRes);
    //             if (failedRes.hasOwnProperty('modelID') && failedRes['modelID'].length === 1) {
    // console.log("failedRes['modelID'][0]", failedRes['modelID'][0]);
    //                 failedIds.push(failedRes['modelID'][0]);
    //             }
    //             else if (failedRes.hasOwnProperty('modelID') && failedRes['modelID'].length > 1) {
    //                 console.log("failedRes['modelID'", failedRes['modelID']);
    //                 failedRes['modelID'].map(id => {
    //                     console.log('iddddd', id);
    //                     failedIds.push(id);
    //                 })
    //             }
    //         })
    //     }
    //     let idString = '';
    //     failedIds.map((id, index) => {
    //         if (index === 0) {
    //             idString = id;
    //         }
    //         else {
    //             idString = idString + ', ' + id;
    //         }
    //     })
    //     let errorMessage = "Cannot perform this action on transactions with ID:- " + idString;
    //     this.notificationService.error(errorMessage, 'Error');
    // }

    onPaginationModal(obj: any, type?: string) {
        // console.log("Inside onPaginationModal", obj, JSON.parse(JSON.stringify(this.donePages)), this.previousAction, this.currentAction);
        let recordsPerPage = 100;
        if (type === 'printDetail' || type === 'rePrint') {
            recordsPerPage = 500;
        }
        if (this.previousAction !== this.currentAction) {
            this.donePages = [];
            this.previousAction = this.currentAction;
        }
        if (obj && obj.hasOwnProperty('parameters')) {
            // console.log("obj.paramters", obj);
            if (obj.parameters.hasOwnProperty('selAll')) delete obj.parameters.selAll;
            // if(obj.parameters.hasOwnProperty('excludeIDs'))
            let params = JSON.parse(JSON.stringify(obj.parameters));

            params['selectAll'] = true;
            // params['excludeIDs']="";

            if (obj.hasOwnProperty('ids')) params['excludeIDs'] = obj.ids.toString();
            this.paginationParams = JSON.parse(JSON.stringify(params));
            this.totalNumberOfRecords = obj.totalRecords;

            if (obj.ids && obj.ids.length) {
                this.totalNumberOfRecords = this.totalNumberOfRecords - obj.ids.length;
            }

            for (let i: number = 0; i < Math.floor(this.totalNumberOfRecords / recordsPerPage); i++) {
                this.totalPages.push(i + 1);
            }
            // console.log("this.totalNumbers",this.totalNumberOfRecords, this.totalPages);
            if (this.totalNumberOfRecords % recordsPerPage) {
                this.totalPages.push(this.totalPages.length + 1);
            }
            console.log('this.totalNumbers', this.totalNumberOfRecords, this.totalPages, this.showPaginationModal);
            this.transactionActionService.paginationModal = true;
            this.showPaginationModal = true;
            console.log('this.totalNumbers', this.totalNumberOfRecords, this.totalPages, this.showPaginationModal);
            // })
        }
    }

    /*
     * check if edit txns object have errors
     */
    verifyEditTxnsError() {
        console.log('verifyEditTxnsError: this.editTxns', this.editTxns);
        return !this.editTxns.every(txn => {
            console.log('modalOnProceedAction: txn', txn);
            let tipAmtError = txn && txn.hasOwnProperty('tipAmtError') ? txn.tipAmtError : false;
            let baseAmtError = txn && txn.hasOwnProperty('baseAmtError') ? txn.baseAmtError : false;
            let taxAmtError = txn && txn.hasOwnProperty('taxAmtError') ? txn.taxAmtError : false;
            if (tipAmtError || baseAmtError || taxAmtError) {
                return false;
            }
            return true;
        });
    }

    /**
     * check if tipamt error exist in tipTxnObj
     *
     */
    verifyTipAmtErrors() {
        console.log('verifyTipAmtErrors: this.editTxns', this.editTxns);
        return !this.tipTxnObj.every(txn => {
            console.log('verifyTipAmtErrors: txn', txn);
            let tipAmtError = txn && txn.hasOwnProperty('tipAmtError') ? txn.tipAmtError : false;
            if (tipAmtError) {
                return false;
            }
            return true;
        });
    }

    modalOnProceedAction(type?: string) {
        console.log('modalOnProceedAction: currentAction', this.currentAction);
        console.log('modalOnProceedAction: type', type);
        console.log('modalOnProceedAction: this.editTxns', this.editTxns);
        let editModalErrorExists: boolean = false;
        let tipModalErrorExists: boolean = false;
        switch (type) {
            case 'redo':
                console.log('modalOnProceedAction: redo');
                editModalErrorExists = this.verifyEditTxnsError();
                console.log('modalOnProceedAction: editModalErrorExists', editModalErrorExists);
                if (editModalErrorExists) {
                    console.log('modalOnProceedAction: return as error exists');
                    this.notificationService.error(INVALID_AMT_MSG, 'Error');
                    return;
                }
                this.showEditModal = false;
                this.performRedoAction();
                break;
            case 'edit':
                editModalErrorExists = this.verifyEditTxnsError();
                console.log('modalOnProceedAction: editModalErrorExists', editModalErrorExists);
                if (editModalErrorExists) {
                    console.log('modalOnProceedAction: return as error exists');
                    this.notificationService.error(INVALID_AMT_MSG, 'Error');
                    return;
                }
                let params = {
                    txnIds: [],
                    editAmts: []
                };
                this.showEditModal = false;
                console.log('HashedData', this.finalHashedData);
                this.editTxns.map(value => {
                    if (value && value.hasOwnProperty('disabled') && !value.disabled) {
                        let editAmtsObj = {
                            txnId: value.txnId,
                            mainAmt: value.baseAmt,
                            incTaxAmt: value.taxAmt
                        };

                        if (value.hasOwnProperty('tipAmt')) editAmtsObj['tipAmt'] = value.tipAmt;

                        params['txnIds'].push(value.txnId);
                        params['editAmts'].push(editAmtsObj);
                    }
                    params['originalData'] = this.finalHashedData;
                    this.observables = [this.httpService.store('pgComm/editTxns', params)];
                });
                console.log('Params to be sent', params);
                if (params.hasOwnProperty('txnIds') && params.txnIds.length) {
                    this.finalCallFromUser('edit');
                } else {
                    this.showOverLay = false;
                    this.notificationService.error('Inconsistent data. Please refresh.', 'Error');
                    return;
                }
                break;
            case 'finalize':
                console.log('tipTxnObj', JSON.parse(JSON.stringify(this.tipTxnObj)));
                tipModalErrorExists = this.verifyTipAmtErrors();
                console.log('modalOnProceedAction: tipModalErrorExists', tipModalErrorExists);
                if (tipModalErrorExists) {
                    console.log('modalOnProceedAction: return as error exists');
                    this.notificationService.error(INVALID_AMT_MSG, 'Error');
                    return;
                }
                this.tipTxnObj = this.tipTxnObj.filter(val => {
                    delete val.txnNum;
                    if (val && val.hasOwnProperty('disabled') && !val['disabled']) {
                        return val;
                    }
                });
                // console.log(
                //     "tipTxnObj after",
                //     JSON.parse(JSON.stringify(this.tipTxnObj))
                // );
                this.showAddTipModal = false;
                if (this.tipTxnObj.length) {
                    if (this.finalizeTxnArr.length) {
                        let params = {
                            txnIds: this.tipTxnObj.map(val => {
                                return val.txnId;
                            }),
                            tipAmts: this.tipTxnObj
                        };
                        this.finalizeTxnArr.map(txn => {
                            params.txnIds.push(txn);
                            params.tipAmts.push({
                                txnId: txn,
                                isAddTipAct: false
                            });
                        });
                        params['originalData'] = this.finalHashedData.length ? this.finalHashedData : [];
                        console.log(
                            'params and finalizeTxnArr in tip and finalize mode',
                            JSON.parse(JSON.stringify(params)),
                            this.finalizeTxnArr
                        );
                        this.observables = [
                            // this.httpService
                            //     .store("pgComm/finalizeTxns", {
                            //         txnIds: this.finalizeTxnArr
                            //     })
                            //     .map((res: Response) => res),
                            this.httpService.store('pgComm/addTip_finalizeTxns', params)
                        ];
                    } else {
                        let params = {
                            txnIds: this.tipTxnObj.map(val => {
                                return val.txnId;
                            }),
                            tipAmts: this.tipTxnObj
                        };
                        params['originalData'] = this.finalHashedData.length ? this.finalHashedData : [];
                        console.log('params in tip only mode', JSON.parse(JSON.stringify(params)));
                        this.observables = [this.httpService.store('pgComm/addTip_finalizeTxns', params)];
                    }
                }

                if (this.tipTxnObj.length || this.finalizeTxnArr.length) {
                    this.finalCallFromUser('finalize');
                } else {
                    this.showOverLay = false;
                    this.notificationService.error('Inconsistent data. Please refresh.', 'Error');
                    return;
                }
                break;
        }
    }

    finalCallFromUser(type?: string) {
        customSwal.fire({
            title: 'Are you sure?',
            text: 'Click Yes to continue, No to decline',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.showOverLay = true;
                forkJoin(this.observables).subscribe(
                    response => {
                        // console.log(
                        //     "Success response inside tip and finalize",
                        //     response
                        // );
                        this.responseForResponseModal = response;
                        this.showResponseModal = true;
                        if (type === 'finalize') {
                            this.transactionActionService.setDoFinalize(false);
                            this.finalizeLoader = false;
                            this.disableActionBtns = false;
                        } else if (type === 'edit') {
                            this.transactionActionService.setDoEdit(false);
                            this.editLoader = false;
                            this.disableActionBtns = false;
                        }
                        this.performCommonActionsOnResponse();
                    },
                    error => {
                        // console.log(
                        //     "Error response inside tip and finalize or edit",
                        //     error
                        // );
                        this.performCommonActionsOnResponse();
                        this.performCommonActionsOnErrorResponse();
                    },
                    () => {}
                );
            } else {
                this.onPaginationModalClose();
                this.donePages.splice(this.donePages.length - 1, 1);
                if (type === 'finalize') {
                    this.transactionActionService.setDoFinalize(false);
                    this.finalizeLoader = false;
                    this.disableActionBtns = false;
                } else if (type === 'edit') {
                    this.transactionActionService.setDoEdit(false);
                    this.editLoader = false;
                    this.disableActionBtns = false;
                }
            }
        });
    }

    onTxnObjReceived(event) {
        console.log('onTxnObjReceived event==>', event);
        if (event) {
            if (event.hasOwnProperty('totTxn') && event['totTxn'] && event.hasOwnProperty('totTxnAmt') && event['totTxnAmt']) {
                this.totalObj = {
                    totTxn: event.totTxn,
                    totTxnAmt: event.totTxnAmt
                };
            } else {
                this.totalObj = {};
            }
            if (
                event.hasOwnProperty('selTxn') &&
                event['selTxn'] &&
                event.hasOwnProperty('selTxnAmt')
                // &&
                // event["selTxnAmt"]
            ) {
                this.selectedObj = {
                    selTxn: event.selTxn,
                    selTxnAmt: event.selTxnAmt
                };
            } else {
                this.selectedObj = {};
            }
        } else {
            this.totalObj = {};
            this.selectedObj = {};
        }
    }

    setDoVoid() {
        // this.showOverLay=true;
        this.voidLoader = true;
        this.disableActionBtns = true;
        this.transactionActionService.setDoVoid(true);
    }

    setDoClose() {
        this.closeLoader = true;
        this.disableActionBtns = true;
        this.transactionActionService.setDoClose(true);
    }

    //Added for BG polling
    // stopTimer(){
    //     this.timerService.stopMainTimer();
    //     this.timerService.stopMiniTimer();
    // }

    setDoFinalize() {
        // this.showOverLay=true;
        // this.stopTimer(); //Added for BG polling
        this.finalizeLoader = true;
        this.disableActionBtns = true;
        this.transactionActionService.setDoFinalize(true);
    }

    setDoEdit() {
        // this.showOverLay=true;
        // this.stopTimer(); //Added for BG polling
        this.editLoader = true;
        this.disableActionBtns = true;
        this.modalText = MODAL_TXT_EDIT;
        this.transactionActionService.setDoEdit(true);
    }

    setDoSettle() {
        // this.stopTimer(); //Added for BG polling
        this.settleLoader = true;
        this.disableActionBtns = true;
        this.transactionActionService.setDoSettle(true);
    }

    setPrintDetail() {
        // this.stopTimer();
        this.printDetailLoader = true;
        this.disableActionBtns = true;
        this.transactionActionService.setPrintDetail(true);
    }

    setRePrint() {
        // this.rePrint=true;
        // this.stopTimer(); //Added for BG polling
        this.rePrintLoader = true;
        this.disableActionBtns = true;
        this.transactionActionService.setDoRePrint(true);
    }

    setDoRedo() {
        // this.rePrint=true;
        // this.stopTimer(); //Added for BG polling
        this.redoLoader = true;
        this.disableActionBtns = true;
        this.modalText = MODAL_TXT_REDO;
        this.transactionActionService.setDoRedo(true);
    }

    onPrintDetailModalClose(stateObj: any) {
        this.visiblePrintDetailModal = false;
        this.transactionActionService.setPrintDetail(false);
        this.printDetailLoader = false;
        this.disableActionBtns = false;
        this.summaryReportService.print = false;
        // console.log("Select all", this.selectAll);
        if (this.selectAll) {
            this.showPaginationModal = true;
        }
        // this.transactionActionService.setRefreshData(true);
    }

    onRePrintComplete(event) {
        this.rePrint = false;
        this.rePrintLoader = false;
        this.disableActionBtns = false;
        this.summaryReportService.print = false;
        if (event) {
            if (this.selectAll) {
                this.showPaginationModal = true;
            }
        }
    }

    onShowDetails(obj: any) {
        // console.log("Show object", obj, this.transactionID, this.showDetails);
        // this.stopTimer(); //Added for BG polling
        this.showDetails = obj;
    }

    onGenerateReport(obj: any) {
        // console.log("modal is visible");
        // this.stopTimer(); //Added for BG polling
        this.visibleModal = true;
    }

    onSummaryModalClose(stateObj: any) {
        this.visibleModal = false;
        // this.refreshGrid = stateObj;
    }

    onTipModalClose() {
        this.transactionActionService.setDoFinalize(false);
        // this.transactionActionService.setRefreshData(true);
        // this.transactionActionService.setActionPerformed(true);
        this.showAddTipModal = false;
        this.finalizeLoader = false;
        this.disableActionBtns = false;
        this.donePages.splice(this.donePages.length - 1, 1);
        this.totalNumberOfRecords = 0;
        this.totalPages = [];
        // this.donePages=[];
        // if(cancel){
        //     // this.previousAction='';
        this.currentAction = '';
        // }
        // this.previousAction='finalize';
        // this.currentAction='';
    }

    onEditModalClose() {
        this.showEditModal = false;
        console.log('onEditModalClose: currentaction', this.currentAction);
        console.log('onEditModalClose: previousAction', this.previousAction);
        switch (this.currentAction) {
            case 'redo':
                console.log('onEditModalClose redo');
                this.transactionActionService.setDoRedo(false);
                this.redoLoader = false;
                break;
            case 'edit':
                console.log('onEditModalClose edit');
                this.transactionActionService.setDoEdit(false);
                this.editLoader = false;
                this.donePages.splice(this.donePages.length - 1, 1);
                this.totalNumberOfRecords = 0;
                this.totalPages = [];
                break;
        }
        // this.editModalErrors = {
        //     tipAmt: true,
        //     baseAmt: true,
        //     taxAmt: true
        // };
        this.disableActionBtns = false;
        this.previousAction = this.currentAction;
        this.currentAction = '';
        this.disableActionBtns = false;
    }

    onActionIsCancel(isCancelled: boolean) {
        if (isCancelled) {
            this.treeService.setShow(true);
            this.treeService.setEnable(true);
        }
        this.showResponseModal = false;
        // this.transactionActionService.setActionPerformed(true);
        this.settleLoader = false;
        this.finalizeLoader = false;
        this.editLoader = false;
        this.voidLoader = false;
        this.closeLoader = false;
        this.redoLoader = false;
        this.rePrintLoader = false;
        this.printDetailLoader = false;
        this.disableActionBtns = false;
    }

    onResponseModalClose(backToPageList: boolean) {
        console.log('Called from here onResponseModalClose??');
        // console.log("Select all 2", this.selectAll);
        //For customer mode, Tree should not be altered on performing action on transaction.
        if(!this.customerTxns){
            this.treeService.setShow(true);
            this.treeService.setEnable(true);
        }
        this.showResponseModal = false;
        // this.treeService.setShow(true);
        // this.treeService.setEnable(true);
        this.transactionActionService.setActionPerformed(true);
        if (this.typeForResponseModal === 'Settle') {
            console.log('this.prameters onResponseModalClose settle case', this.parameters);
            this.storeObject = this.transactionsStore;
            this.parameters = JSON.parse(JSON.stringify(this.parameters));
            this.settleLoader = false;
            this.disableActionBtns = false;
        } else {
            console.log('this.prameters onResponseModalClose not settle case', this.parameters);
            if (this.selectAll) {
                if (backToPageList) this.showPaginationModal = true;
                else {
                    this.transactionActionService.paginationModal = false;
                    this.onPaginationModalClose();
                }
            } else {
                this.showPaginationModal = false;
                this.transactionActionService.paginationModal = false;
            }
            this.transactionActionService.setRefreshData(true);
        }
        // this.responseModalClose=true;
        // this.transactionActionService.setContinuousFetch(true); //Added for BG polling
    }

    onRefreshRequest(event) {
        // this.refreshGrid=false;
        // setTimeout(()=>{  //setTimeout is used because the change is so fast that it does not get detected.
        //     this.refreshGrid=event;
        // }, 100);
        // this.stopTimer(); //Added for BG polling
        this.transactionActionService.setRefreshData(true);
        // this.transactionActionService.setActionPerformed(true);
    }

    viewClosed(visible: boolean) {
        // console.log("hereinside viewClosed",visible);
        if (!visible) {
            // this.transactionID = "";
            this.showDetails = false;
        }
    }

    checkTipAmtDisable(obj) {
        if (obj.hasOwnProperty('disabled') && obj.disabled) {
            return true;
        } else {
            return obj.hasOwnProperty('tipAmt') ? false : true;
        }
    }

    onModalInputFocused(event: any) {
        console.log('click called out txn addtip', event);
        event.target.select();
        // event.target.selectionStart=0;
        // event.target.selectionEnd=event.target.value.length;
    }
    onKeypress(event: any) {
        GlobalValidator.amountFormat(event);
    }

    onPaste(event: any) {
        GlobalValidator.amountFormat(event);
    }

    fetchTransaction() {
        switch (this.transactionType) {
            case 'all':
                if (this.parameters.hasOwnProperty('filterType')) {
                    this.filterData();
                }
                break;
            case 'open':
                this.filterData();
                break;
            case 'closed':
                this.filterData();
                break;
        }
    }

    fetchFilter() {
        switch (this.filter) {
            case LAST_DAYS_FILTER.all:
                // console.log('here', JSON.parse(JSON.stringify(this.parameters)));
                this.dateRangeValue = [];
                if (
                    this.parameters.hasOwnProperty('filterType') &&
                    (this.parameters.hasOwnProperty('currDate') ||
                        (this.parameters.hasOwnProperty('startDate') && this.parameters.hasOwnProperty('endDate')))
                ) {
                    // console.log('here 1');
                    this.filterData();
                }
                break;
            case LAST_DAYS_FILTER.last_24_hrs:
                this.dateRangeValue = [];
                this.filterData();
                break;
            case LAST_DAYS_FILTER.last_7_days:
                this.dateRangeValue = [];
                this.filterData();
                break;
            case LAST_DAYS_FILTER.last_30_days:
                this.dateRangeValue = [];
                this.filterData();
                break;
            case LAST_DAYS_FILTER.last_60_days:
                this.dateRangeValue = [];
                this.filterData();
                break;
            case LAST_DAYS_FILTER.last_90_days:
                this.dateRangeValue = [];
                this.filterData();
                break;
            default:
                console.log('reached default: outstanding txns');
                break;
        }
    }

    /*
     * called on blur event of base,tip and tax amt fields of edit modal
     *
     * @params : object : object : the object in which the amount fields exist
     * @params : field : string : depending upon fields check condition
     * @params : position : number : position of object in the edittxn array
     */
    validateTxnAmts(object, field, position) {
        console.log('validateTxnAmts:amount', object);
        console.log('validateTxnAmts:field', field);
        let amountInNumber = parseFloat(object[field]);
        console.log('validateTxnAmts:amountInNumber', amountInNumber);
        this.editTxns[position][field + 'Error'] = !checkValidAmt(amountInNumber, field);
        console.log('validateTxnAmts:checkValidAmt(amountInNumber, field)', checkValidAmt(amountInNumber, field));
        console.log('validateTxnAmts:edittxns', this.editTxns);
        // console.log('validateTxnAmts: editModalErrors', this.editModalErrors);
    }

    /**
     * called on blur event of tipamt fields of add-tip modal
     *
     * @params : `object` : object : the object in which the amount fields exist
     * @params : `position` : number : position of object in the tipTxnObj array
     * @return
     */
    validateTipObjAmts(object, position) {
        console.log('validateTipObjAmts: object', object);
        console.log('validateTipObjAmts: position', position);
        let amountInNumber = parseFloat(object && object.hasOwnProperty('tip') && object.tip ? object.tip : 0);
        this.tipTxnObj[position][TIP_AMT_FIELD + 'Error'] = !checkValidAmt(amountInNumber, TIP_AMT_FIELD);
        console.log('validateTipObjAmts: tipTxnObj', this.tipTxnObj);
    }

    ngOnDestroy() {
        this.alive = false;
        this.summaryReportService.setCheckedObj([]);
        this.summaryReportService.setUnCheckedObj([]);
    }

    handleWatcher_NullValueCase(watcher_type) {
        //This case arrises for all other watchers whenever a tree-node is clicked.
        //Don't perfrom any action. Can be used for logs purpose
        // console.log('Null value for -->', watcher_type);
    }

    /**
     * Based on user permission, enable/disable txn actions
     */
    setTxnActionPermissions(){
        const userPerms = this.userHandlerService.userPerms;
        this.txnActionPermissions.canFinalize = userPerms.canFinalizeTxn;
        this.txnActionPermissions.canEdit = userPerms.canEditTxn;
        this.txnActionPermissions.canSettle = userPerms.canSettleTxn;
        this.txnActionPermissions.canVoid = userPerms.canVoidTxn;
        //@cary todo: close action permission
        this.txnActionPermissions.canClose = userPerms.canVoidTxn;
        this.txnActionPermissions.canViewDetails = userPerms.canViewDetails;
        this.txnActionPermissions.canRedo = userPerms.canRedoTxn;
        this.txnActionPermissions.canReprint = userPerms.canReprintTxn;
        this.txnActionPermissions.canPrintDetail = userPerms.canPrintTxn;
    }
}
